import { Stack, Text } from 'native-base';

import { MobileAcitivitiesHeaderProps } from '~/pages/Authenticated/Activities/components/MobileActivitiesHeader/types';

export const MobileAcitivitiesHeader = (
  props: MobileAcitivitiesHeaderProps,
) => {
  const { label, description } = props;

  return (
    <Stack mb={'6'} mt={'4'}>
      <Text fontWeight={500} fontSize={'md'}>
        {label}
      </Text>
      <Text color={'gray.600'} fontSize={'xs'}>
        {description}
      </Text>
    </Stack>
  );
};
