import { FilterDrawerScaffold } from '~/pages/Authenticated/InApp/components/FilterDrawerScaffold';
import FilterForm from '~/pages/Authenticated/InApp/components/FilterForm';
import { useFilterModalController } from '~/pages/Authenticated/InApp/modals/FilterModal/controller';
import { FilterModalProps } from '~/pages/Authenticated/InApp/modals/FilterModal/types';

const FilterModal = (props: FilterModalProps) => {
  const { isOpen } = props;

  const {
    handlCloseButton,
    handleClearFilter,
    handleSubmitButton,
    selectedApp,
    formController,
    hasChanges,
  } = useFilterModalController(props);

  return (
    <>
      <FilterDrawerScaffold
        title="Filters"
        isOpen={isOpen}
        actionButtonLabel="Filter search"
        cancelButtonLabel="Clear all filters"
        cancelButtonIcon="delete"
        onClose={handlCloseButton}
        onCancel={handleClearFilter}
        isDisable={!hasChanges}
        onAction={formController.form.handleSubmit(handleSubmitButton)}
        brandColor={selectedApp?.brandColor}
      >
        <FilterForm
          form={formController.form}
          activityType={props.activityType}
          attendanceType={props.attendanceType}
          filterWhiteList={props.filterWhiteList}
          isTeam={props.isTeam}
        />
      </FilterDrawerScaffold>
    </>
  );
};

export default FilterModal;
