import { FormControl } from 'native-base';
import React from 'react';
import ReactSelect from 'react-select';

import { SimpleSelectProps } from './types';
import { useSelectController } from './useSelectController';

export const SimpleSelect = (props: SimpleSelectProps) => {
  const { name, value, onChange, label, options } = props;
  const { defaultStyle, selected } = useSelectController({ options, value });

  return (
    <>
      {label ? <FormControl.Label>{label}</FormControl.Label> : null}

      <ReactSelect
        name={name}
        options={options}
        menuPortalTarget={document.body}
        styles={defaultStyle}
        defaultValue=""
        value={selected}
        onChange={(value) => {
          onChange(value);
        }}
      />
    </>
  );
};

export default SimpleSelect;
