import { Text, Tooltip, VStack } from 'native-base';
import React from 'react';

import { CalendarDayStyleMap } from '~/components/CalendarInline/components/CalendarDay/constants';
import { CalendarDayState } from '~/components/CalendarInline/components/CalendarDay/types';

export type CalendarDayProps = {
  dayOfMonth: number;
};

export const CalendarDay = (props: CalendarDayProps) => {
  const { dayOfMonth } = props;

  const style = CalendarDayStyleMap.get(CalendarDayState.blocked);

  return (
    <span>
      <VStack
        w="40px"
        h="40px"
        alignItems={'center'}
        justifyContent={'center'}
        borderRadius={6}
        borderColor={style?.borderColor}
        backgroundColor={style?.backgroundColor}
      >
        <Tooltip
          label={style?.tooltipLabel || ''}
          display={!style?.tooltipLabel ? 'none' : 'unset'}
        >
          <Text color={style?.fontColor} fontSize={'md'} mb={1}>
            {dayOfMonth}
          </Text>
        </Tooltip>
      </VStack>
    </span>
  );
};

export default React.memo(CalendarDay);
