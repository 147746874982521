import { ITeam } from 'doit-volunteer/src/types/interfaces/team';
import { ILocation } from 'ui/types/interfaces';

import { IUser } from './user';

export enum ActivityType {
  EVENT = 'Event',
  ACTION = 'Action',
  ONGOING = 'OngoingOpportunity',
}

export enum AttendanceType {
  Online = 'Home',
  Local = 'Local',
}

// todo: check location
export interface IAddress {
  location: {
    type: string;
    coordinates: number[];
  };
  street: string;
  _id: string;
  createdAt: string;
  updatedAt: string;
}
// todo: check location
export interface ICauseOptions {
  deleted: boolean;
  displayName: string;
  relatedTo: string;
  images: [];
  app: string;
  _id: string;
  createdAt: string;
  updatedAt: string;
  icon: string;
}

export interface IBaseActivityDefinitionSubDocument {
  title: string;
  description: string;
  type: ActivityType;
  periodOptions: [];
  causeOptions: ICauseOptions[];
  requirementOptions: [];
  coverImage: string;
  carouselImages: string[];
  isApprovalRequired: boolean;
  ecosystem: string;
  organization: string;
  app: string;
  volunteerRewards: string[];
  volunteerRequirements: string[];
  _id: string;
  createdAt: string;
  updatedAt: string;
  __v?: number;
}

interface IStatusHistory {
  user: {
    _id: string;
  };
  oldStatus: string;
  newStatus: string;
  date: string;
  description: string;
  _id: string;
}

interface IActivityDefinitionSubDocument
  extends IBaseActivityDefinitionSubDocument {
  attendanceType: string;
  durationInHours: string;
  spaceOptions: string;
  typeOfWork: string;
  measurementUnit?: string;
  organizationSubDocument: IOrganization;
  targetAmount?: number;
  measurementUnitPluralLabel?: string;
}

type EventType = 'National' | 'Local';

export enum ActivityApplicationStatus {
  Canceled = 'Canceled',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Pending = 'Pending',
  Referral = 'Referral',
}

export interface IActivity {
  deleted: boolean;
  _id: string;
  startDate: string;
  endDate: string;
  isVolunteerNumberLimited: boolean;
  eventType: EventType;
  address: IAddress;
  activityDefinitionSubDocument: IActivityDefinitionSubDocument;
  activityDefinition: string;
  createdAt: string;
  updatedAt: string;
  __v?: number;
  isOnline: true;
  meetingLink: string;
}

export interface IActivityApplication {
  _id: string;
  applicationDate: string;
  status: ActivityApplicationStatus;
  isAttendanceConfirmed: boolean;
  userProfileSubDocument: IUserProfileSubDocument;
  activitySubDocument: IActivitySubDocument;
  userProfile: string;
  user: string;
  activity: string;
  statusHistory: IStatusHistory[];
  measurementTargetAmount?: number;
  measurementTargetReached?: boolean;
  createdAt: string;
  updatedAt: string;
  teamSubDocument: ITeam;
  __v?: number;
}

export interface IActivitySubDocument {
  _id: string;
  address?: IAddress;
  isVolunteerNumberLimited: boolean;
  activityDefinitionSubDocument: IActivityDefinitionSubDocument;
  bookingsNumber: number;
  ecosystem: string;
  organization: string;
  app: string;
  activityDefinition: string;
  startDate: string;
  endDate: string;
  createdAt: string;
  updatedAt: string;
  deleted: boolean;
  externalApplyLink?: string;
}

interface IUserProfileSubDocument {
  _id: string;
  userSummary: Pick<IUser, 'name' | 'email'>;
  user: string;
  ecosystem: string;
  organization: string;
  userOrganization: string;
  createdAt: string;
  updatedAt: string;
  deleted: false;
}

export interface IActivityDefinition {
  _id: string;
  title: string;
  description: string;
  type: string;
  causeOptions: ICauseOptions[];
  coverImage: string;
  carouselImages: [];
  isApprovalRequired: false;
  ecosystem: string;
  organization: string;
  app: string;
  volunteerRewards: string[];
  volunteerRequirements: string[];
  periodOptions: [];
  requirementOptions: [];
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface IActivityCategory {
  _id: string;
  displayName: string;
  relatedTo: string;
  groupBy?: string;
  app?: string;
  icon?: string;
  images?: string[];
}
export interface IFilterActivity {
  apps: string;
  activityTypes: string;
  position?: string;
  categories?: string;
  radius?: number;
  limit?: number;
}

export type IEvent = IActivityDefinition;
export type IPlaces = IActivity;

export type ActivityCauseRequest = {
  _id: string;
};
export interface ActivitySearchParams {
  app: string;
  limit?: number;
  skip?: number;
  radius?: number;
  text?: string;
  categories?: { causeOptions: ActivityCauseRequest[] };
  attendanceTypes?: AttendanceType[];
  activityTypes?: ActivityType[];
  eventType?: EventType[];
  position?: ILocation;
  endDate?: string;
  startDate?: string;
}

// TODO - move from here
export enum MeasurementUnitCategory {
  Time = 'Time',
  Weight = 'Weight',
  Completion = 'Completion',
  CustomUnit = 'Custom unit',
}
