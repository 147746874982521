import { useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { PAGES } from '~/constants/pages.constants';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import TeamService from '~/services/resources/teams';
import { redirectSliceActions } from '~/store/slices/redirect';
import { ICreateTeamDTO } from '~/types/dtos';
import { IMemberValue } from '~/types/interfaces/team';

export const useCreateTeamController = () => {
  const [selectedMembers, setSelectedMembers] = useState<IMemberValue[]>([]);
  const [teamName, setTeamName] = useState<string>('');

  const { selectedUserProfile } = useAppSelector(({ auth }) => auth);
  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);
  const { createTeam } = useAppSelector(({ redirect }) => redirect);

  const dispatch = useAppDispatch();

  const { goBack, replaceRoute, goToRoute } = useRouter();

  const { mutate: mutateCreateTeam, isLoading } = useMutation({
    mutationFn: async (payload: ICreateTeamDTO) =>
      await TeamService.createTeam(payload),
    onSuccess: (data) => {
      toast.success('Team created successfully');
      if (createTeam.isFromEventPage) {
        const route = PAGES.ActivitiesDetails.replace(
          ':id',
          createTeam.activityDefinitionId,
        );

        dispatch(redirectSliceActions.removeCreateTeamRedirect);

        replaceRoute(route);
      } else {
        goToRoute(PAGES.ViewTeam + `/${data.data._id}`);
      }
    },
    onError: (error: any) => {
      toast.error(error.message);
    },
  });

  const handleAddMember = (value: IMemberValue) => {
    const memberAlreadyExists = selectedMembers.find((selectedEmail) => {
      return (
        selectedEmail.value === value.value ||
        selectedEmail.value === value.email ||
        selectedEmail.email === value.value
      );
    });

    if (memberAlreadyExists) {
      return toast.error('Member already added');
    }
    setSelectedMembers((prevState) => [...prevState, value]);
  };

  const handleRemoveMember = (emailIndex: number) => {
    const updatedEmails = selectedMembers.toSpliced(emailIndex, 1);
    setSelectedMembers(updatedEmails);
  };

  const handleCreateTeam = () => {
    if (teamName.length < 5 && selectedMembers.length === 0) {
      return toast.error('Missing information, please try again!');
    }

    const membersId = [];
    const newMembers = [];

    for (const member of selectedMembers) {
      if (member.__isNew__) {
        newMembers.push(member.value);
      } else {
        membersId.push(member.value);
      }
    }

    const payload = {
      name: teamName,
      leader: selectedUserProfile?._id as string,
      members: membersId,
      newMembers: newMembers,
      ecosystem: selectedEcosystem?._id as string,
    };

    mutateCreateTeam(payload);
  };

  return {
    selectedMembers,
    handleAddMember,
    handleRemoveMember,
    teamName,
    setTeamName,
    isLoading,
    handleCreateTeam,
    goBack,
  };
};
