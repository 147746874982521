import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { useAppSelector } from '~/hooks/useAppSelector';
import { SearchModalProps } from '~/pages/Authenticated/InApp/components/SearchTopBar/modals/SearchModal/types';
import { ActivitySearchSection } from '~/pages/Authenticated/InApp/Explorer/types';

export const useSearchModal = (props: SearchModalProps) => {
  const { isOpen, initialValue, onSubmit } = props;

  const { selectedApp } = useAppSelector(({ inApp }) => inApp);

  const { control, watch, setValue, handleSubmit } = useForm<{
    search: string;
  }>({
    defaultValues: { search: initialValue },
  });

  const search = watch('search');

  const selectOption = (sectionType: ActivitySearchSection) => {
    onSubmit({ search, sectionType });
  };

  const onSubmitSearch = (data: { search: string }) => {
    onSubmit({ search: data.search });
  };

  const handleKeyPress = (event: any) => {
    if (event.nativeEvent.key === 'Enter') {
      handleSubmit(onSubmitSearch)();
    }
  };

  useEffect(() => {
    if (isOpen === false) {
      setValue('search', initialValue);
    }
  }, [setValue, isOpen, initialValue]);

  return {
    control,
    search,
    selectedApp,
    selectOption,
    handleKeyPress,
  };
};
