import { Avatar, Stack, Text } from 'native-base';
import React from 'react';

import { CardRecommendationsAvatarProps } from '~/components/ActivityCards/CardRecommendations/components/CardRecommendationsAvatar/cardRecommendationsAvatar.types';

export const CardActivityAvatar = ({
  logo,
  initials,
}: CardRecommendationsAvatarProps) => {
  return (
    <Stack minW={12} display={{ base: 'none', lg: 'flex' }}>
      <Avatar
        bg="gray.400"
        borderColor={'gray.300'}
        borderWidth={1}
        source={{ uri: logo }}
        h={'24'}
        w={'24'}
        rounded={24}
        _image={{
          rounded: 24,
        }}
      >
        <Text color={'singletons.white'} fontSize={'xs'}>
          {initials}
        </Text>
      </Avatar>
    </Stack>
  );
};
