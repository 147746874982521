import { Hidden, HStack, Stack, Text, VStack } from 'native-base';
import { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import SimpleSelect from 'ui/components/SimpleSelect';
import { colors } from 'ui/theme/colors';
import { ActivityType } from 'ui/types/activities';

import { ActivityCardRecommendationsSkeletonList } from '~/components/ActivityCards/CardRecommendations';
import { ListEmptyState } from '~/components/EmptyState/ListEmptyState';
import { LocationToggle } from '~/components/LocationToggle';
import { MobileAppBar } from '~/components/MobileAppBar/MobileAppBar';
import { Scaffold } from '~/components/Scaffold';
import TabsNavigator from '~/components/TabsNavigator';
import MobileTrayDrawer from '~/components/TrayDrawer';
import { useUserLocationController } from '~/hooks/useUserLocation/useUsersLocation';
import { ExporerHeader } from '~/pages/Authenticated/InApp/components/ExplorerHeader';
import { FilterToggle } from '~/pages/Authenticated/InApp/components/FilterToggle';
import SearchOngoingResultItem from '~/pages/Authenticated/InApp/components/SearchOngoingResultItem';
import SearchResultItem from '~/pages/Authenticated/InApp/components/SearchResultItem';
import { ActivitySearchResult } from '~/pages/Authenticated/InApp/components/SearchResultItem/types';
import SearchTopBar from '~/pages/Authenticated/InApp/components/SearchTopBar';
import { ActivitySearchSection } from '~/pages/Authenticated/InApp/Explorer/types';
import CausesModal from '~/pages/Authenticated/InApp/modals/CausesModal';
import FilterModal from '~/pages/Authenticated/InApp/modals/FilterModal';
import FilterSidebar from '~/pages/Authenticated/InApp/modals/FilterSideBar';
import { ATTENDANCE_TYPE_TABS } from '~/pages/Authenticated/InApp/SearchView/constants';
import { useInAppSearchViewController } from '~/pages/Authenticated/InApp/SearchView/controller';
import {
  MiniAppSearchViewProps,
  SortByType,
} from '~/pages/Authenticated/InApp/SearchView/types';

export const InAppSearchView = (props: MiniAppSearchViewProps) => {
  const {
    activityType,
    causeOptions,
    filterWhiteList,
    sectionType,
    onSearchSectionChange,
  } = props;

  const {
    tabIndex,
    attendanceType,
    handleTabPress,
    closeModals,
    openModal,
    setSortBy,
    modalsState,
    sortByLabel,
    filterValues,
    setCauses,
    handleSearchBarSubmit,
    sortByOptions,
    activitySearchResults,
    handleOnScrollEndReached,
    shoudlRenderNoMoreActivities,
    shouldRenderIllustration,
    shouldRenderSkeleton,
    handleActivityResultPress,
    selectedApp,
  } = useInAppSearchViewController(props);

  const { openLocationModal, location } = useUserLocationController();

  return (
    <>
      <Scaffold
        title={''}
        px={2}
        onScrollReachEnd={handleOnScrollEndReached}
        pb={{ base: 16, md: 0 }}
      >
        <Hidden till={'md'}>
          <ExporerHeader
            sectionType={sectionType}
            searchFoundResults={activitySearchResults?.length ?? 0}
            searchText={filterValues.search}
            onSubmit={onSearchSectionChange} // trocar tipo aqui
          />
        </Hidden>
        <VStack w="100%" maxW={'1248px'} margin={'auto'} space={4}>
          {/* Header */}
          <VStack pt={4} pb={2}>
            <SearchTopBar
              initialValues={{
                sectionType: sectionType,
                search: filterValues.search,
                causes: filterValues.causes,
              }}
              onSubmit={handleSearchBarSubmit}
              searchFoundResults={activitySearchResults?.length ?? 0}
              onFilterPress={() => openModal('causes')}
              causesOptions={causeOptions}
              showResults
            />
          </VStack>

          {/* Main */}
          <VStack flex={1} minH={'80vh'} paddingBottom={6}>
            {sectionType !== ActivitySearchSection.Action && (
              <TabsNavigator
                tabs={ATTENDANCE_TYPE_TABS}
                index={tabIndex}
                onChange={handleTabPress}
              />
            )}
            <HStack
              space={{ base: 0, lg: 4, xl: 8 }}
              backgroundColor={colors.white}
              pt={{ base: 4, lg: 6 }}
              px={{ base: 0, lg: 3, xl: 6 }}
              flex={1}
            >
              {/* Aside */}
              <VStack w={{ base: 0, lg: '286', xl: '304' }} space={6}>
                <Hidden till="lg">
                  <>
                    <VStack space={3}>
                      <Text fontSize={'sm'} fontWeight={500}>
                        Sort by
                      </Text>

                      <SimpleSelect
                        name="sortBy"
                        value={filterValues.sortBy}
                        onChange={(v) => setSortBy(v.value)}
                        options={sortByOptions}
                      />

                      <FilterSidebar
                        activityType={activityType}
                        filterWhiteList={filterWhiteList}
                        attendanceType={attendanceType}
                        isTeam={sectionType === ActivitySearchSection.TeamEvent}
                      />
                    </VStack>
                  </>
                </Hidden>
              </VStack>

              {/* Article */}
              <VStack flex={1}>
                {/* Toolbar */}
                <Hidden from={'lg'}>
                  <VStack px={4} space={2}>
                    <Stack px={2} py={1}>
                      <LocationToggle
                        onPress={openLocationModal}
                        label={location?.rawLocation || 'Select your location'}
                        brandColor={selectedApp?.brandColor}
                      />
                    </Stack>

                    <HStack justifyContent={'space-between'} px={2}>
                      <FilterToggle
                        icon="switch"
                        label="Sort by"
                        size={5}
                        isCustomIcon
                        value={sortByLabel}
                        onPress={() => openModal('sort')}
                        brandColor={selectedApp?.brandColor}
                      />

                      <FilterToggle
                        icon="sliders"
                        label="Filter"
                        onPress={() => openModal('filter')}
                        brandColor={selectedApp?.brandColor}
                      />
                    </HStack>
                  </VStack>
                </Hidden>

                {(activitySearchResults ?? []).length > 0 &&
                  activitySearchResults.map((item: ActivitySearchResult) =>
                    item.type === ActivityType.Ongoing ? (
                      <SearchOngoingResultItem
                        key={item.id}
                        data={item}
                        onPress={() =>
                          handleActivityResultPress(
                            item.id,
                            item.selectedActivity?._id,
                          )
                        }
                      />
                    ) : (
                      <SearchResultItem
                        key={item.id}
                        data={item}
                        onPress={() =>
                          handleActivityResultPress(
                            item.id,
                            item.selectedActivity?._id,
                          )
                        }
                      />
                    ),
                  )}
                {shouldRenderSkeleton && (
                  <Stack flexShrink={0} mt={4}>
                    <ActivityCardRecommendationsSkeletonList quantity={3} />
                  </Stack>
                )}

                {shouldRenderIllustration && (
                  <ListEmptyState
                    text={`We’re sorry! Currently, there are no listed, but stay tuned for upcoming opportunities.`}
                    illustrationName={
                      ILLUSTRATIONS_NAME.ERROR_SORRY_STATE_BROKEN
                    }
                  />
                )}

                {shoudlRenderNoMoreActivities && (
                  <Text
                    alignSelf={'center'}
                    fontSize={'md'}
                    color={'gray.400'}
                    pt={4}
                    pb={6}
                  >
                    No more activities.
                  </Text>
                )}
              </VStack>
            </HStack>
          </VStack>
        </VStack>
      </Scaffold>

      <Hidden from={'md'}>
        <MobileAppBar />
      </Hidden>

      {/* Sort Modal*/}
      <MobileTrayDrawer
        title="Sort by"
        options={sortByOptions}
        isOpen={modalsState.sort}
        onClose={closeModals}
        onPress={(value) => {
          setSortBy(value as SortByType);
        }}
      />

      {/* Causes Modal*/}
      <CausesModal
        causeOptions={causeOptions}
        initialValues={filterValues.causes}
        isOpen={modalsState.causes}
        onSubmit={setCauses}
        onClose={closeModals}
      />

      {/*  filterModal*/}
      <Hidden from="lg">
        <FilterModal
          activityType={activityType}
          filterWhiteList={filterWhiteList}
          attendanceType={attendanceType}
          isOpen={modalsState.filter}
          isTeam={sectionType === ActivitySearchSection.TeamEvent}
          onClose={closeModals}
        />
      </Hidden>
    </>
  );
};

export default InAppSearchView;
