import { Avatar, HStack, Stack, Text, VStack, ZStack } from 'native-base';
import { PopOverOptions } from 'ui/components/PopOverOptions';
import Tag from 'ui/components/Tags/Tag';

import ModalRemove from '~/components/ModalRemove';
import { TeamCardProps } from '~/pages/Authenticated/Settings/Teams/TeamsList/components/TeamCard/types';
import { useTeamCardController } from '~/pages/Authenticated/Settings/Teams/TeamsList/components/TeamCard/useTeamCardController';
import { colors } from '~/theme/colors';
import { getNameInitials } from '~/utils/getInitials';

export const TeamCard = ({ team, onRemoveTeam }: TeamCardProps) => {
  const {
    teamPopoverOptions,
    isMobile,
    deleteTeamModalRef,
    isLoading,
    handleModalSubmit,
    handleCloseModal,
    modalInfo,
    leader,
    members,
    membersQuantity,
  } = useTeamCardController({ team, onRemoveTeam });

  return (
    <>
      <VStack
        p={4}
        borderRadius={'2xl'}
        w={{ base: 'full', md: '100%' }}
        bg="gray.50"
        space={4}
        borderWidth={1}
        borderColor="gray.200"
        mb={4}
      >
        <HStack alignItems={'flex-start'} justifyContent="space-between" pr={1}>
          <HStack
            flexDir={{ base: 'column', md: 'row' }}
            alignItems={{ base: 'flex-start', md: 'center' }}
            w={{ base: '100%', md: '90%' }}
            space={1}
          >
            <Stack
              minW={'container'}
              maxW={{ base: 'full', md: '65%' }}
              mb={{ base: 1, md: 'unset' }}
            >
              <Text
                fontSize={{ base: 'sm', md: 'md' }}
                fontWeight="bold"
                color="singletons.darkText"
                noOfLines={2}
              >
                {team?.name}
              </Text>
            </Stack>
            <Stack ml={{ base: -1, md: 'unset' }} alignSelf="flex-start">
              <Tag
                text={`${membersQuantity} ${
                  membersQuantity === 1 ? 'member' : 'members'
                }`}
                type="actions"
                tagTextColor={colors.singletons.black}
                tagBgColor={colors.gray[200]}
                fontSize={12}
                tagProps={{ m: 0 }}
              />
            </Stack>
          </HStack>
          <Stack>
            <PopOverOptions
              options={teamPopoverOptions}
              popovText={isMobile ? '' : 'Options'}
              popovIcon="more-vertical"
              popovTextPosition="right"
            />
          </Stack>
        </HStack>
        <Stack flex={1} flexDir={{ base: 'column', md: 'row' }}>
          <VStack
            space={3}
            mr={{ base: 0, md: 2 }}
            mb={{ base: 2, md: 0 }}
            flex={members.length > 0 ? 0.5 : 1}
          >
            <Text fontSize="xs" color="gray.600" fontWeight="medium">
              Team Leader
            </Text>

            <HStack alignItems="center" space={2}>
              <Stack>
                <Avatar
                  source={{
                    uri: leader.avatarUrl,
                  }}
                  size={10}
                >
                  {getNameInitials(leader.name)}
                </Avatar>
              </Stack>
              <Stack>
                <Text fontSize="sm" color="singletons.darkText">
                  {leader.name}
                </Text>
              </Stack>
            </HStack>
          </VStack>
          {members.length > 0 && (
            <VStack
              space={3}
              ml={{ base: 0, md: 2 }}
              mt={{ base: 2, md: 0 }}
              flex={0.5}
            >
              <Text fontSize="xs" color="gray.600" fontWeight="medium">
                Teammates
              </Text>

              <HStack alignItems="flex-start" space={2}>
                <ZStack position="relative">
                  {members.map((member, index) => (
                    <Avatar
                      key={member._id}
                      borderWidth={1}
                      borderColor="white"
                      ml={index * 8}
                      source={{ uri: member.avatarUrl }}
                      size={10}
                    >
                      {getNameInitials(member.name)}
                    </Avatar>
                  ))}
                </ZStack>
              </HStack>
            </VStack>
          )}
        </Stack>
      </VStack>
      <ModalRemove
        modalRef={deleteTeamModalRef}
        headerText={modalInfo.modalTitle}
        handleCloseModal={handleCloseModal}
        handleDelete={handleModalSubmit}
        rightBtnLoading={isLoading}
        rightBtnText={modalInfo.buttonText}
      >
        <Stack space={5}>
          <Text fontWeight={400} fontSize="md" color="gray.500">
            {modalInfo.text}
          </Text>

          <Text fontWeight={400} fontSize="md" color="gray.500">
            {modalInfo.description}
          </Text>
        </Stack>
      </ModalRemove>
    </>
  );
};
