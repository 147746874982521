import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { EEventApplicationType } from 'ui/enums';

import { ActivityViewLayout } from '~/components/ActivityViewLayout';
import { InAppViewLayout } from '~/components/InAppViewLayout';
import { AuthLayout } from '~/components/Layouts/AuthLayout';
import { AuthLayoutBackButton } from '~/components/Layouts/AuthLayoutBackButton';
import { AuthLayoutNoSideBar } from '~/components/Layouts/AuthLayoutNoSideBar';
import { SuspenseEcosystemLayout } from '~/components/Layouts/SuspenseEcosystemLayout';
import { PAGES } from '~/constants/pages.constants';
import { useFirebaseRoutesAnalytics } from '~/hooks/useFirebaseAnalytics';
import { useLoadUserEcosystemData } from '~/hooks/useLoadUserEcosystemData';
import { Activities } from '~/pages/Authenticated/Activities';
import { ActivityApplicationCancelConfirmation } from '~/pages/Authenticated/Activities/ActivityApplicationCancelConfirmation';
import { ExternalApplyLinkRedirect } from '~/pages/Authenticated/ExternalApplyLinkRedirect';
import { FindOpportunitiesPage } from '~/pages/Authenticated/FindOpportunitiesPage';
// import { FindOpportunitiesPage } from '~/pages/Authenticated/FindOpportunitiesPage';
import Explorer from '~/pages/Authenticated/InApp/Explorer';
import { InAppHomeView } from '~/pages/Authenticated/InApp/HomeView';
import { LogActivities } from '~/pages/Authenticated/LogActivities';
import { LogActivity } from '~/pages/Authenticated/LogActivity';
import { LogActivitySuccess } from '~/pages/Authenticated/LogActivity/components/LogActivitySuccess';
import { OffPlatformApplicationPage } from '~/pages/Authenticated/OffPlatformApplication';
import { OffplatformActivitySuccess } from '~/pages/Authenticated/OffPlatformApplication/components/OffplatformActivitySuccess';
import { EventApplicationPage } from '~/pages/Authenticated/OpportunityApplication/EventApplicationPage';
import { JoinActionPage } from '~/pages/Authenticated/OpportunityApplication/JoinActionPage';
import { OpportunityApplicationConfirmation } from '~/pages/Authenticated/OpportunityApplication/OpportunityApplicationConfirmation';
import { AccountSettings } from '~/pages/Authenticated/Settings/AccountSettings';
import { ChangePassword } from '~/pages/Authenticated/Settings/ChangePassword';
import { DeleteAccount } from '~/pages/Authenticated/Settings/DeleteAccount';
import { EmailPreferences } from '~/pages/Authenticated/Settings/EmailPreferences';
import { CreateTeam } from '~/pages/Authenticated/Settings/Teams/CreateTeam';
import { EditTeam } from '~/pages/Authenticated/Settings/Teams/EditTeam';
import { TeamList } from '~/pages/Authenticated/Settings/Teams/TeamsList';
import { ViewTeam } from '~/pages/Authenticated/Settings/Teams/ViewTeam';
import SplashScreen from '~/pages/Authenticated/SplashScreen';
import { SwitchPersona } from '~/pages/Authenticated/SwitchPersona';
import { UserApps } from '~/pages/Authenticated/UserApps';
import { UserProfile } from '~/pages/Authenticated/UserProfile';
import { ActivityViewPage } from '~/pages/Authenticated/v2/ActivityView';
import SignInMobile from '~/pages/SignInMobile';
import { CommonRoutes } from '~/routes/Common';

import { AcceptInvite } from '../../pages/Common/AcceptInvite';

const MyWalletRoutes = (
  <React.Fragment>
    <Route
      element={
        <AuthLayout>
          <Outlet />
        </AuthLayout>
      }
    >
      <Route path={PAGES.Opportunities} element={<FindOpportunitiesPage />} />
      <Route path={PAGES.AllApps} element={<UserApps />} />
      <Route path={PAGES.Activities} element={<Activities />} />
      <Route path={PAGES.Teams} element={<TeamList />} />
      <Route path={PAGES.Profile} element={<UserProfile />} />
    </Route>

    <Route
      element={
        <AuthLayoutBackButton>
          <Outlet />
        </AuthLayoutBackButton>
      }
    >
      <Route path={`${PAGES.ViewTeam}/:id`} element={<ViewTeam />} />
      <Route path={PAGES.CreateTeam} element={<CreateTeam />} />
      <Route path={`${PAGES.EditTeam}/:id`} element={<EditTeam />} />
    </Route>
  </React.Fragment>
);

const ActivitiesRoutes = (
  <>
    <Route
      path={PAGES.ExternalApplyLinkRedirect}
      element={<ExternalApplyLinkRedirect />}
    />
    <Route path={PAGES.ActivitiesDetails} element={<ActivityViewPage />} />

    <Route path={PAGES.EventApplication} element={<EventApplicationPage />} />
    <Route path={PAGES.JoinAction} element={<JoinActionPage />} />
    <Route
      path={PAGES.OngoingOpportunityApplication}
      element={<EventApplicationPage isOngoingOpportunity />}
    />
    <Route
      path={PAGES.TeamEventApplication}
      element={
        <EventApplicationPage
          isTeamEvent
          applicationType={EEventApplicationType.Team}
        />
      }
    />
    {/* Off Platform */}
    <Route
      path={PAGES.CreateOffPlatformActivity}
      element={<OffPlatformApplicationPage />}
    />
    <Route
      path={PAGES.UpdateOffPlatformActivity}
      element={<OffPlatformApplicationPage />}
    />
    <Route
      path={PAGES.OffplatformActivitySuccess}
      element={<OffplatformActivitySuccess />}
    />
  </>
);

const LogActivityRoutes = (
  <>
    <Route path={PAGES.LogActivities} element={<LogActivities />} />
    {/*<Route*/}
    {/*  path={PAGES.CancelApplication}*/}
    {/*  element={<CentralizedRouteNameText />}*/}
    {/*/>*/}
    {/*<Route path={PAGES.EndActivity} element={<CentralizedRouteNameText />} />*/}
    {/*<Route path={PAGES.LogAction} element={<CentralizedRouteNameText />} />*/}
    {/*<Route*/}
    {/*  path={PAGES.LogOngoingOpportunity}*/}
    {/*  element={<CentralizedRouteNameText />}*/}
    {/*/>*/}
    {/*<Route path={PAGES.LogEvent} element={<CentralizedRouteNameText />} />*/}
  </>
);

const SettingsRoutes = (
  <>
    <Route
      element={
        <AuthLayout isMobileBordered={false}>
          <Outlet />
        </AuthLayout>
      }
    >
      <Route path={PAGES.AccountSettings} element={<AccountSettings />} />
      <Route path={PAGES.EmailPreferences} element={<EmailPreferences />} />
    </Route>
    <Route
      element={
        <AuthLayoutNoSideBar>
          <Outlet />
        </AuthLayoutNoSideBar>
      }
    >
      <Route path={PAGES.ChangePassword} element={<ChangePassword />} />
      <Route path={PAGES.DeleteAccount} element={<DeleteAccount />} />
    </Route>
  </>
);

const SignUpFailsafeRedirects = [
  PAGES.SignUp,
  PAGES.AccessCode,
  PAGES.AuthorizationPersonalData,
].map((path) => (
  <Route
    key={path}
    path={path}
    element={<Navigate to={PAGES.Root} replace />}
  />
));

const InAppRoutes = (
  <>
    <Route
      path={PAGES.InAppRoot}
      element={
        <InAppViewLayout>
          <Outlet />
        </InAppViewLayout>
      }
    >
      <Route path={PAGES.InAppRoot} element={<InAppHomeView />} />
      <Route path={PAGES.InAppSearchView} element={<Explorer />} />
    </Route>
  </>
);

const InvitationRoutes = (
  <>
    {/**
     * This is a workaround. When a user that is logged in copy the
     * invitation url, the system add /signup to the url. So, if they copy and send
     * to a friend, and this friend is logged in the plataform, he will be redirect to AcceptInvite
     * instead of getting a 404. InvitationRoutes are usually in the routes.common.tsx file
     */}
    <Route
      path={`${PAGES.InvitationSlug}/:code/signup`}
      element={<AcceptInvite />}
    />
  </>
);

export const AuthenticatedRoutes = () => {
  useFirebaseRoutesAnalytics();

  const { isLoading: isEcosystemLoading, selectedEcosystem } =
    useLoadUserEcosystemData();

  return (
    <Routes>
      <Route path={PAGES.Root} element={<SplashScreen />} />
      {/* Ecosystem selected routes */}
      <Route
        element={
          <SuspenseEcosystemLayout
            selectedEcosystem={selectedEcosystem}
            isEcosystemLoading={isEcosystemLoading}
          >
            <Outlet />
          </SuspenseEcosystemLayout>
        }
      >
        <Route path={PAGES.LogActivity} element={<LogActivity />} />

        <Route
          path={PAGES.LogActivitySuccess}
          element={<LogActivitySuccess />}
        />

        {MyWalletRoutes}

        <Route
          element={
            <AuthLayout>
              <Outlet />
            </AuthLayout>
          }
        >
          {LogActivityRoutes}
        </Route>

        <Route
          element={
            <ActivityViewLayout>
              <Outlet />
            </ActivityViewLayout>
          }
        >
          {ActivitiesRoutes}
        </Route>

        <Route
          path={PAGES.OpportunityApplicationConfirmation}
          element={<OpportunityApplicationConfirmation />}
        />

        <Route
          path={PAGES.CancelApplicationConfirmation}
          element={<ActivityApplicationCancelConfirmation />}
        />

        {SettingsRoutes}

        {InAppRoutes}
      </Route>

      {/* Don`t need ecosystem selected */}

      <Route path="/mobile" element={<SignInMobile />} />

      <Route path={PAGES.SwitchPersona} element={<SwitchPersona />} />

      {InvitationRoutes}

      {SignUpFailsafeRedirects}

      {CommonRoutes}
    </Routes>
  );
};
