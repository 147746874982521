import {
  FlatList,
  Flex,
  Hidden,
  HStack,
  Stack,
  Text,
  VStack,
} from 'native-base';
import { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';

import {
  ActivityCardRecommendations,
  ActivityCardRecommendationsSkeletonList,
} from '~/components/ActivityCards/CardRecommendations';
import { Scaffold } from '~/components/Scaffold';
import { Tab } from '~/components/Tabs';
import { HeaderSearchForm } from '~/pages/Authenticated/FindOpportunitiesPage/components/HeaderSearchForm';
import { FindOpportunitiesPageProps } from '~/pages/Authenticated/FindOpportunitiesPage/types';

import { ListEmptyState } from '../../../components/EmptyState/ListEmptyState';
import {
  findOpportunitiesContentPageTabs,
  useFindOpportunitiesPageController,
} from './useFindOpportunitiesPageController';

export const FindOpportunitiesPage = ({
  isGuestPage,
}: FindOpportunitiesPageProps) => {
  const {
    control,
    currentTabIndex,
    handleChangeTab,
    isLoadingOpportunities,
    activities,
    activitiesHasNextPage,
    activitiesIsFetchingNextPage,
    handleOnScrollEndReached,
    filteredOpportunitiesTabs,
  } = useFindOpportunitiesPageController({ isGuestPage });

  return (
    <>
      <Scaffold
        title={'Find Opportunities'}
        rightElement={<HeaderSearchForm control={control} />}
        onScrollReachEnd={handleOnScrollEndReached}
      >
        {/* zIndex -1 was necessary to avoid the menu of the Google Autocomplete Input */}
        {/* on HeaderSearchForm to be overwritten */}
        <VStack flex={1} _important={{ zIndex: -1 }} mx={{ base: 2 }}>
          <HStack w={'100%'} space={2} justifyContent={'space-between'}>
            {filteredOpportunitiesTabs.length > 0
              ? filteredOpportunitiesTabs.map((tab, tabIndex) => {
                  return (
                    <Tab
                      key={tab.label}
                      isActive={tabIndex === currentTabIndex}
                      onPress={() => handleChangeTab(tabIndex)}
                      {...tab}
                    />
                  );
                })
              : null}
          </HStack>
          <VStack
            bg={'singletons.white'}
            px={4}
            py={{ base: 3, lg: 6 }}
            pb={0}
            flex={1}
            rounded={8}
            roundedTop={0}
          >
            <Hidden from={'sm'}>
              {filteredOpportunitiesTabs.length > 0 ? (
                <Stack mb={'2'} mt={'4'}>
                  <Text fontWeight={500} fontSize={'md'}>
                    {findOpportunitiesContentPageTabs[currentTabIndex].label}
                  </Text>
                  <Text color={'gray.600'} fontSize={'xs'}>
                    {
                      findOpportunitiesContentPageTabs[currentTabIndex]
                        .description
                    }
                  </Text>
                </Stack>
              ) : null}
            </Hidden>
            {isLoadingOpportunities ? (
              <Stack flexShrink={0} mt={4}>
                <ActivityCardRecommendationsSkeletonList quantity={3} />
              </Stack>
            ) : !!activities?.length && activities?.length > 0 ? (
              <FlatList
                data={activities}
                // TODO: Solve types
                keyExtractor={(item) => item._id}
                renderItem={({ item }) => (
                  <Stack mt={4}>
                    {/*// TODO: Solve types*/}
                    <ActivityCardRecommendations
                      activity={item}
                      isGuest={isGuestPage}
                    />
                  </Stack>
                )}
              />
            ) : (
              <ListEmptyState
                text={`We’re sorry! Currently, there are no ${findOpportunitiesContentPageTabs[
                  currentTabIndex
                ].label.toLowerCase()} listed, but stay tuned for upcoming opportunities.`}
                illustrationName={ILLUSTRATIONS_NAME.ERROR_SORRY_STATE_BROKEN}
              />
            )}
            <Flex mt={4}>
              {activitiesIsFetchingNextPage && (
                <ActivityCardRecommendationsSkeletonList quantity={3} />
              )}
              {!isLoadingOpportunities &&
                !activitiesIsFetchingNextPage &&
                !activitiesHasNextPage &&
                !!activities?.length &&
                activities?.length > 0 && (
                  <Text
                    alignSelf={'center'}
                    fontSize={'md'}
                    color={'gray.400'}
                    pt={4}
                    pb={6}
                  >
                    No more activities.
                  </Text>
                )}
            </Flex>
          </VStack>
        </VStack>
      </Scaffold>
    </>
  );
};
