import { Checkbox, Text, VStack } from 'native-base';
import { Controller } from 'react-hook-form';
import { colors } from 'ui/theme/colors';

import { RootPortal } from '~/components/RootPortal';
import { FilterDrawerScaffold } from '~/pages/Authenticated/InApp/components/FilterDrawerScaffold';
import { useCausesModalController } from '~/pages/Authenticated/InApp/modals/CausesModal/controller';
import { CausesModalProps } from '~/pages/Authenticated/InApp/modals/CausesModal/types';

const CausesModal = (props: CausesModalProps) => {
  const { isOpen = false, causeOptions } = props;

  const {
    control,
    selectedApp,
    handleCancelButton,
    handleClearFilters,
    handleSubmit,
    handleSubmitButton,
    hasChanges,
  } = useCausesModalController(props);

  return (
    <>
      <RootPortal>
        <FilterDrawerScaffold
          title="Search Causes"
          isOpen={isOpen}
          actionButtonLabel="Set causes"
          isDisable={!hasChanges}
          cancelButtonLabel="Clear search"
          cancelButtonIcon="delete"
          onClose={handleCancelButton}
          onCancel={handleClearFilters}
          onAction={handleSubmit(handleSubmitButton)}
          brandColor={selectedApp?.brandColor}
        >
          <Controller
            control={control}
            name="causes"
            render={({ field: { onChange, value } }) => {
              return (
                <Checkbox.Group
                  onChange={onChange}
                  value={value}
                  accessibilityLabel="Cause Options"
                  _checkbox={{
                    borderColor: 'gray.300',
                    _hover: { borderColor: 'gray.300' },
                    size: 'md',
                  }}
                >
                  <VStack flex={1} w="full" flexWrap="wrap" space={4}>
                    {(causeOptions ?? []).map((option, i) => (
                      <Checkbox
                        key={option.value}
                        value={option.value}
                        defaultIsChecked={false}
                      >
                        <Text
                          fontSize={'sm'}
                          fontWeight={400}
                          color={colors.gray[500]}
                        >
                          {option.label}
                        </Text>
                      </Checkbox>
                    ))}
                  </VStack>
                </Checkbox.Group>
              );
            }}
          />
        </FilterDrawerScaffold>
      </RootPortal>
    </>
  );
};

export default CausesModal;
