export const makeBreadCrumbs = (searchText: string) => {
  return [
    {
      icon: 'home',
      path: '/mini-app/659351fbea6975704c8df58c',
      label: 'home',
    },
    {
      icon: 'search',
      label: `Search result for : ${searchText}`,
    },
  ];
};
