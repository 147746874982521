import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StoreApi } from 'zustand';

import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useLoadActivitiesByActivityDefinition } from '~/hooks/useLoadActivity/useLoadActivitiesByActivityDefinition';
import { useLoadManyActivityApplications } from '~/hooks/useLoadActivityApplication/useLoadManyActivityApplications';
import { useLoadActivityDefinition } from '~/hooks/useLoadActivityDefinition';
import { useRouter } from '~/hooks/useRouter';
import { mapUserActivities } from '~/pages/Authenticated/v2/ActivityView/controllers/activity-mapper';
import {
  APPLICATION_POPULATE,
  APPLICATION_PROJECTION,
} from '~/pages/Authenticated/v2/ActivityView/controllers/constants';
import {
  ActivityViewState,
  createActivityViewStore,
} from '~/pages/Authenticated/v2/ActivityView/store';
import { IUserActivity } from '~/pages/Authenticated/v2/ActivityView/types';
import { ViewActivityDetailsProps } from '~/pages/Authenticated/ViewActivityDetails/types';
import { IActivityDefinition } from '~/types/interfaces/activity';
import { getActivityDefinitionSemanticType } from '~/utils/activity/activity-definition-getters';
import { getCoordinatesFromLocation } from '~/utils/getCoordinates';
export const useActivityViewLoadController = (
  props: ViewActivityDetailsProps,
) => {
  const { isGuestPage } = props;
  const { goToRoute } = useRouter();
  const { id: activityDefinitionId } = useParams();
  const { selectedUserProfile } = useAppSelector(({ auth }) => auth);
  const { location } = useAppSelector((state) => state.inApp);
  const useStoreRef = useRef<StoreApi<ActivityViewState>>();

  // -- 1. Load Activity definition
  const { data: activityDefinition, isFetched: isActivityDefinitionFetched } =
    useLoadActivityDefinition(String(activityDefinitionId));

  // -- 2. Load Activities
  const shouldLoadActivities =
    !!isGuestPage || (!!activityDefinitionId && !!location);

  const { data: activitiesData, isFetched: isActivitiesFetched } =
    useLoadActivitiesByActivityDefinition(
      String(activityDefinitionId),
      location ? getCoordinatesFromLocation(location) : undefined,
      shouldLoadActivities,
    );

  // -- 3. Load Activities Applications
  const shouldLoadApplications =
    !isGuestPage && !!selectedUserProfile?._id && !!activityDefinitionId;

  const { data: activitiesApplications, isLoading: isApplicationsLoading } =
    useLoadManyActivityApplications(
      {
        userProfile: selectedUserProfile?._id || '',
        'activitySubDocument.activityDefinition': activityDefinitionId,
      },
      APPLICATION_PROJECTION,
      APPLICATION_POPULATE,
      shouldLoadApplications,
    );

  useEffect(() => {
    const store = useStoreRef.current;

    /* Should prevent re-creating store before all data had been fetched */
    if (
      !!store ||
      !isActivitiesFetched ||
      !isActivityDefinitionFetched ||
      isApplicationsLoading
    ) {
      return;
    }

    if (!activityDefinition || !activitiesData.length) {
      toast.error(
        `We're sorry, we couldn't find the activity you're looking for.`,
      );
      goToRoute(PAGES.Page404, { replace: true });
      return;
    }

    const _activityDefinition =
      activityDefinition as unknown as IActivityDefinition;

    // Map Activities with its applications
    const activities = mapUserActivities(
      activitiesData,
      activitiesApplications ?? [],
    );

    // Pre compute semantic type
    const activityType = getActivityDefinitionSemanticType(_activityDefinition);

    // Create a activities map to improve performance
    const activitiesMap = new Map();
    activities.forEach((activity: IUserActivity) => {
      activitiesMap.set(activity._id, activity);
    });

    const newStore = createActivityViewStore({
      activityDefinition: _activityDefinition,
      activitiesMap,
      activityType,
      activities,
    });

    useStoreRef.current = newStore;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activityDefinition,
    activitiesData,
    activitiesApplications,
    isApplicationsLoading,
    isActivitiesFetched,
    isActivityDefinitionFetched,
  ]);

  return {
    store: useStoreRef.current,
  };
};
