import 'react-modern-drawer/dist/index.css';

import {
  Avatar,
  Box,
  HStack,
  Pressable,
  Stack,
  Text,
  VStack,
} from 'native-base';
import Drawer from 'react-modern-drawer';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import { colors } from 'ui/theme/colors';

import { drawerMenuSections } from '~/components/MobileAppDrawer/constants';
import { useMobileAppDrawerController } from '~/components/MobileAppDrawer/controller';
import { MobileAppDrawerProps } from '~/components/MobileAppDrawer/types';
import ModalSignOut from '~/components/ModalSignOut';
import { RootPortal } from '~/components/RootPortal';

export const MobileAppDrawer = (props: MobileAppDrawerProps) => {
  const { isOpen, onToggle } = props;

  const {
    modalRef,
    onSwitchProfilePress,
    handleCloseSignOutModal,
    handleMenuItemPress,
    userParsedData,
  } = useMobileAppDrawerController(props);

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={onToggle}
        direction="left"
        overlayColor={colors.black}
        overlayOpacity={0.3}
        duration={200}
        size={300}
      >
        <VStack>
          <Stack
            p={6}
            pb={3}
            borderBottomColor={'gray.300'}
            borderBottomWidth={1}
          >
            <HStack justifyContent="space-between">
              <Avatar.Group flexDirection={'row'} pl={3}>
                <Avatar
                  bg="gray.400"
                  borderColor={'gray.300'}
                  source={{
                    uri: userParsedData.ecosystemLogo,
                  }}
                >
                  {userParsedData.ecosystemInitial}
                </Avatar>
                <Avatar
                  zIndex={1}
                  bg="gray.400"
                  borderColor={'gray.300'}
                  source={{
                    uri: userParsedData.userAvatar,
                  }}
                >
                  {userParsedData.userInitials}
                </Avatar>
              </Avatar.Group>
              <Button
                _text={{ fontSize: 'xs', color: 'darkText', fontWeight: 500 }}
                padding={2}
                maxW="97px"
                minW="97px"
                width="100%"
                bgColor="gray.100"
                onPress={onSwitchProfilePress}
              >
                Switch profile
              </Button>
            </HStack>
            <Text mt={3} fontWeight={500}>
              {userParsedData.userName}
            </Text>
            <Text mt={1} fontSize={'sm'}>
              {userParsedData.ecosystemName}
            </Text>
          </Stack>
          <Stack>
            {drawerMenuSections.map(({ items, section }, index) => {
              const isLastSection = index === drawerMenuSections.length - 1;

              return (
                <Box
                  key={section}
                  px={6}
                  pb={3}
                  borderBottomWidth={isLastSection ? 0 : 1}
                  borderBottomColor={'gray.300'}
                  pt={4}
                >
                  {section && (
                    <Text fontSize={'sm'} color={'gray.600'} mb={2}>
                      {section}
                    </Text>
                  )}
                  <VStack space={2}>
                    {items.map((item) => {
                      const color =
                        item.text == 'Sign out' ? colors.error[600] : 'black';
                      return (
                        <Pressable
                          key={item.text}
                          onPress={() => handleMenuItemPress(item)}
                        >
                          <HStack space={3} p={3} pl={0}>
                            <Icon
                              size={'24px'}
                              icon={item.icon}
                              color={color}
                              enableAutoCustom
                            />
                            <Text
                              fontSize={'sm'}
                              fontWeight={500}
                              color={color}
                            >
                              {item.text}
                            </Text>
                          </HStack>
                        </Pressable>
                      );
                    })}
                  </VStack>
                </Box>
              );
            })}
          </Stack>
          <HStack flexDir={'row'} alignItems={'center'} space={2} p={3} pt={6}>
            <Text fontSize={'xs'} color={'gray.500'}>
              Powered by{' '}
            </Text>
            <Stack>
              <Illustration
                width="30px"
                height="16px"
                name={ILLUSTRATIONS_NAME.DOIT_TRANSPARENT_LOGO}
              />
            </Stack>
          </HStack>
        </VStack>
      </Drawer>
      <RootPortal>
        <ModalSignOut modalRef={modalRef} onClose={handleCloseSignOutModal} />
      </RootPortal>
    </>
  );
};

export default MobileAppDrawer;
