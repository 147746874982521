import { Box, HStack, Text } from 'native-base';

import Icon from '../../Icon';
import { passwordRules, rulesArray } from './consts';
import usePasswordRuleController from './controller';
import { PasswordRuleProps, PasswordRulesListProps } from './types';

const PasswordRule = (props: PasswordRuleProps) => {
  const { icon, color, rule } = usePasswordRuleController(props);
  return (
    <HStack space={2} key={rule} flexDir="row" alignItems="center" mt={2}>
      <Icon size={15} icon={icon} color={color} />
      <Text color="gray.500" fontWeight={400} fontSize="12">
        {rule}
      </Text>
    </HStack>
  );
};

const PasswordRulesList = ({ value }: PasswordRulesListProps) => {
  return (
    <Box>
      {rulesArray?.map((rule) => {
        const success = passwordRules[rule].isValidSync(value);
        return <PasswordRule key={rule} rule={rule} success={success} />;
      })}
    </Box>
  );
};

export default PasswordRulesList;
