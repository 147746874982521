import {
  TableOperatorNames,
  TableOperatorType,
} from 'ui/components/DataTable/types';

export const defaultFilterValue = [
  {
    name: 'startDate',
    operator: TableOperatorNames.AfterOrOn,
    type: TableOperatorType.Date,
    value: '',
  },
  {
    name: 'activityDefinitionSubDocument.title',
    operator: TableOperatorNames.StartsWith,
    type: TableOperatorType.String,
    value: '',
  },
];

// NOTE:  id: 'typeOfAttendance' -- Old value:
// { value: 'home', text: 'From home' },
// { value: 'local', text: 'In a local area' },
// Using dictionary:
// @mycatdoitbetter NOTE: Exported cause is used on EventFull screen.
export enum EAttendanceTypeKeys {
  Home = 'Home',
  Local = 'Local',
}

// NOTE: Old value:
// { value: 'alone', text: 'Alone' },
// { value: 'group', text: 'In Group' },
// { value: 'both', text: 'Both' },
// Using dictionary
export enum ETypeOfWorkKeys {
  Alone = 'alone',
  Group = 'group',
  Both = 'both',
}

// NOTE:  id: 'typeOfWork' -- Old value:
// { value: 'outdoor', text: 'Outdoor' },
// { value: 'indoor', text: 'Indoor' },
// { value: 'both', text: 'Both' },
// Using dictionary:
export enum ESpaceOptionsKeys {
  Indoor = 'indoor',
  Outdoor = 'outdoor',
  Both = 'both',
}

// @mycatdoitbetter NOTE: Exported cause is used on EventFull/EventSimple screen.
export const attendanceTypeOptionsDictionary = {
  [EAttendanceTypeKeys.Home]: 'From home',
  [EAttendanceTypeKeys.Local]: 'In a local area',
};

export const spaceOptionsDictionary = {
  [ESpaceOptionsKeys.Indoor]: 'Indoor',
  [ESpaceOptionsKeys.Outdoor]: 'Outdoor',
  [ESpaceOptionsKeys.Both]: 'Both',
};

export const typeOfWorkDictionary = {
  [ETypeOfWorkKeys.Alone]: 'Alone',
  [ETypeOfWorkKeys.Group]: 'In Group',
  [ETypeOfWorkKeys.Both]: 'Both',
};

export const attendanceTypeOptions = [
  {
    text: attendanceTypeOptionsDictionary[EAttendanceTypeKeys.Home],
    value: EAttendanceTypeKeys.Home,
  },
  {
    text: attendanceTypeOptionsDictionary[EAttendanceTypeKeys.Local],
    value: EAttendanceTypeKeys.Local,
  },
];

export const spaceOptions = [
  {
    text: spaceOptionsDictionary[ESpaceOptionsKeys.Indoor],
    value: ESpaceOptionsKeys.Indoor,
  },
  {
    text: spaceOptionsDictionary[ESpaceOptionsKeys.Outdoor],
    value: ESpaceOptionsKeys.Outdoor,
  },
  {
    value: ESpaceOptionsKeys.Both,
    text: spaceOptionsDictionary[ESpaceOptionsKeys.Both],
  },
];

export const typeOfWorkOptions = [
  {
    value: ETypeOfWorkKeys.Alone,
    text: typeOfWorkDictionary[ETypeOfWorkKeys.Alone],
  },
  {
    value: ETypeOfWorkKeys.Group,
    text: typeOfWorkDictionary[ETypeOfWorkKeys.Group],
  },
  {
    value: ETypeOfWorkKeys.Both,
    text: typeOfWorkDictionary[ETypeOfWorkKeys.Both],
  },
];
