import { useInfiniteQuery } from 'react-query';

import {
  BASE_URL,
  ITEMS_PER_PAGE,
} from '~/hooks/useInfinityActivitySearch/constants';
import { serializeResponse } from '~/hooks/useInfinityActivitySearch/serializer';
import { publicApi } from '~/services/api';
import {
  OngoingInPersonSearchFilters,
  OngoingOnlineSearchFilters,
} from '~/types/dtos/activity-definition-public-search';
import { AttendanceType } from '~/types/interfaces/activity';

import { GenericObject, getQueryKey } from '../types';
import {
  EQueryKeys,
  IBaseGetPaginatedData,
  UseInfinityActivitySearchResponse,
} from './types';

const getOngoingActivitySearch = async (
  attendanceType: AttendanceType,
  props: IBaseGetPaginatedData<
    OngoingInPersonSearchFilters | OngoingOnlineSearchFilters
  >,
): UseInfinityActivitySearchResponse => {
  const { pageParam = 0, params, disabled } = props;
  if (disabled) return { data: [], skip: 0 };

  const attendanceSlug =
    attendanceType === AttendanceType.Local ? 'in-person' : 'from-home';

  const url = `${BASE_URL}/ongoing-opportunity/${attendanceSlug}`;

  const { data } = await publicApi.get(url, {
    params: { ...params, skip: pageParam },
  });
  return { data, skip: pageParam + ITEMS_PER_PAGE };
};

function useInfinityOngoingActivitySearch(
  attendanceType: AttendanceType,
  params: any,
  disabled = false,
) {
  const queryKey = getQueryKey<GenericObject>(
    EQueryKeys.INFINITY_QUERY_ONGOING_ACTIVITIES_SEARCH,
    params,
  );

  const response = useInfiniteQuery(
    queryKey,
    ({ pageParam }) =>
      getOngoingActivitySearch(attendanceType, { pageParam, params, disabled }),
    {
      getNextPageParam: (lastPage) => {
        if ((lastPage.data ?? []).length < ITEMS_PER_PAGE) return undefined;
        return lastPage.skip;
      },
    },
  );

  return serializeResponse(response as any);
}

export default useInfinityOngoingActivitySearch;
