import {
  Flex,
  Hidden,
  HStack,
  Pressable,
  Spinner,
  Stack,
  Text,
} from 'native-base';
import Icon from 'ui/components/Icon';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import Input from 'ui/components/Input';
import { colors } from 'ui/theme/colors';
import { IEcosystem } from 'ui/types/interfaces/ecosystem';

import { ListEmptyState } from '~/components/EmptyState/ListEmptyState';
import { SwitchPersonaCard } from '~/pages/Authenticated/SwitchPersona/components/SwitchPersonaCard';
import { SwitchPersonaShimmer } from '~/pages/Authenticated/SwitchPersona/components/SwitchPersonaShimmer';
import { IOrganization } from '~/types/interfaces/organization';

import { useSwitchPersonaController } from './controller';

export const SwitchPersona = () => {
  const {
    control,
    filteredUserProfiles,
    isLoading,
    user,
    selectedEcosystem,
    isSearching,
    isChangingPersona,
    handleGoBack,
    handleChangePersona,
    handleOnChangeText,
  } = useSwitchPersonaController();

  if (isChangingPersona) {
    return (
      <Stack
        space={4}
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100vh"
      >
        <Text>Changing your persona</Text>
        <Spinner color="gray.900" size="lg" />
      </Stack>
    );
  }

  return (
    <Stack minH="100vh" paddingX={{ base: 4, lg: 24 }}>
      <Stack paddingY={4}>
        <Pressable
          onPress={handleGoBack}
          paddingY={2}
          paddingX={3}
          variant="unstyled"
        >
          <HStack alignItems="center">
            <HStack space={2} alignItems="center">
              <Icon
                icon="chevron-left"
                size={16}
                color={colors.singletons.darkText}
              />
              <Text
                fontSize={{ base: 'xs' }}
                fontWeight={500}
                color={colors.singletons.darkText}
              >
                Back
              </Text>
            </HStack>
            <Hidden from="lg">
              <Stack width="100%">
                <Text
                  ml="auto"
                  mr="auto"
                  color="gray.600"
                  fontSize="xl"
                  fontWeight={500}
                >
                  Persona
                </Text>
              </Stack>
            </Hidden>
          </HStack>
        </Pressable>
      </Stack>

      <Stack alignItems="center" justifyContent="center">
        <Hidden till="lg">
          <Text color="gray.600" fontSize="xl" fontWeight={500}>
            Click on a persona to enter
          </Text>
        </Hidden>

        <Stack width="100%" maxW="450px" mt={{ lg: 4 }}>
          <Input
            name="search"
            control={control}
            label="Search"
            labelColor={colors.gray[600]}
            placeholder="Search organisation, ecosystem..."
            leftIconName="search"
            leftIconSize={16}
            leftIconColor={colors.gray[400]}
            onChangeText={(value) => handleOnChangeText(value)}
          />
        </Stack>
      </Stack>

      <Stack flex={1} width="100%" alignItems="center">
        <Stack
          maxW={{ base: '100%', md: '34rem', lg: '60.125rem' }}
          width="100%"
          alignItems="center"
        >
          <Flex
            width="100%"
            alignItems="center"
            justifyContent={{ base: 'space-between', sm: 'flex-start' }}
            mt={4}
            flexWrap="wrap"
            flexDir="row"
          >
            {isSearching || isLoading ? (
              <HStack width="100%" space={{ base: 2, lg: 6 }} mt={6}>
                <SwitchPersonaShimmer />
                <SwitchPersonaShimmer />
                <Hidden till="lg">
                  <SwitchPersonaShimmer />
                </Hidden>
              </HStack>
            ) : null}

            {!isSearching &&
              filteredUserProfiles?.map((userProfile) => {
                const ecosystem =
                  userProfile.ecosystem as unknown as IEcosystem;
                const organizationName = (
                  userProfile.ecosystemSummary
                    .organization as unknown as IOrganization
                ).name;
                return (
                  <Stack ml={{ sm: 6 }} key={userProfile._id} mt={6}>
                    <SwitchPersonaCard
                      ecosystemName={ecosystem.name}
                      ecosystemLogo={ecosystem.logo}
                      isSelectedEcosystem={
                        ecosystem._id === selectedEcosystem?._id
                      }
                      userName={user?.name || ''}
                      organizationName={organizationName}
                      userImage={userProfile?.profilePicture}
                      onPress={() => handleChangePersona(userProfile)}
                    />
                  </Stack>
                );
              })}
          </Flex>

          {!isLoading &&
          filteredUserProfiles &&
          filteredUserProfiles?.length === 0 ? (
            <Stack>
              <ListEmptyState
                text={`Sorry, no results found`}
                illustrationName={ILLUSTRATIONS_NAME.ERROR_SORRY_STATE_BROKEN}
              />
            </Stack>
          ) : null}
        </Stack>
      </Stack>

      <Stack
        width="100%"
        alignItems="center"
        justifyContent="center"
        marginTop="auto"
        marginBottom={10}
      >
        <HStack mt={10} space={2} alignItems="center">
          <Text fontWeight={500} color="gray.400">
            Powered by
          </Text>
          <Illustration
            name={ILLUSTRATIONS_NAME.DOIT_TRANSPARENT_LOGO}
            width={50}
            height={25}
            tintColor={colors.gray[400]}
          />
        </HStack>
      </Stack>
    </Stack>
  );
};
