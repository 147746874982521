import { Flex, HStack, Pressable, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import CardPropInfo from 'ui/components/Cards/CardPropInfo';

import { ActivitySummaryTitle } from '~/components/ActivityCards/CardRecommendations/components/ActivitySummaryTitle';
import { CardRecommendationsAvatar } from '~/components/ActivityCards/CardRecommendations/components/CardRecommendationsAvatar';

import { TeamActivityCardProps } from './types';
import { useTeamActivityCardController } from './useTeamActivityCardController';

export const TeamActivityCard = ({ activity }: TeamActivityCardProps) => {
  const { eventSummary, handleGoToActivityDetail, isMobile } =
    useTeamActivityCardController({
      activity,
    });

  return (
    <Pressable
      onPress={() =>
        isMobile
          ? handleGoToActivityDetail(eventSummary.activityDefinitionId)
          : null
      }
    >
      <Flex
        w={'full'}
        borderWidth={1}
        borderColor={'gray.300'}
        padding={{ base: 3, lg: 4 }}
        borderRadius={12}
        bg={'gray.50'}
        my={2}
      >
        <HStack flex={1} w={'100%'} justifyContent={'space-between'}>
          <VStack
            flex={1}
            w={'fit-content'}
            space={'2.5'}
            maxW={{ base: '100%', lg: '100%' }}
          >
            <HStack h={'fit-content'} space={2} w={'100%'}>
              <CardRecommendationsAvatar
                logo={eventSummary.organizationLogo}
                initials={eventSummary.organizationInitials}
              />

              <VStack space={3} w={'100%'}>
                <ActivitySummaryTitle
                  activityName={eventSummary.activityName}
                  appName={eventSummary.appName}
                  organizationName={eventSummary.organizationName}
                />
                <HStack alignItems={'center'} space={3}>
                  <CardPropInfo
                    icon="calendar"
                    info={new Date(activity.startDate).toLocaleDateString(
                      'en-GB',
                      {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                      },
                    )}
                  />
                </HStack>
              </VStack>
            </HStack>
          </VStack>

          <VStack space={4} display={{ base: 'none', md: 'flex' }}>
            <Button
              w={'container'}
              bg={'singletons.black'}
              onPress={() =>
                handleGoToActivityDetail(activity.activityDefinition)
              }
              leftIconName="eye"
              leftIconColor="white"
            >
              <Text color={'singletons.white'}>View Activity</Text>
            </Button>
          </VStack>
        </HStack>
      </Flex>
    </Pressable>
  );
};
