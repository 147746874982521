import { useMemo } from 'react';
import { StylesConfig } from 'react-select';

import { theme } from '../../theme';

const getStyle = ({ errorMessage, hasError }: any): StylesConfig => ({
  control: (baseStyles) => ({
    ...baseStyles,
    fontFamily: theme.fontConfig.default,
    fontSize: 12,
    padding: 5.8,
    borderColor: errorMessage || hasError ? theme.colors.error[600] : '#d4d4d4',
    borderRadius: 12,
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    fontFamily: theme.fontConfig.default,
    fontSize: 12,
  }),
  menuPortal: (baseStyles) => ({
    ...baseStyles,
    fontFamily: theme.fontConfig.default,
    fontSize: 12,
    Index: 9999,
  }),
  indicatorSeparator: (baseStyles) => ({
    ...baseStyles,
    display: 'none',
  }),
});

export const useSelectController = ({ options, value }: any) => {
  const defaultStyle = useMemo(() => getStyle({}), []);

  const selected = useMemo(() => {
    return options.find((opt: any) => opt.value == value);
  }, [options, value]);

  return { defaultStyle, selected };
};
