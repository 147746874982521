import './config/sentry';
import './config/amplify';
import './config/firebase';

import Providers from '~/Providers';
import { Routes } from '~/routes';

const App = () => (
  <Providers>
    <Routes />
  </Providers>
);

export default App;
