import { ActivityNation } from 'ui/types/activities';

import { useQuery } from '~/hooks/useQuery';
import { IPaginatedResponse } from '~/types';

export const useActivityNations = () => {
  const { data, isLoading, refetch } = useQuery<
    IPaginatedResponse<ActivityNation[]>
  >(`/activity-region-public/nations`, {
    key: `activity-region-public-nations`,
  });

  return { data, isLoading, refetch };
};
