import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import {
  FilterFormFields,
  IFilterFormController,
} from '~/pages/Authenticated/InApp/components/FilterForm/types';

import { schema } from './schema';

export const useFilterFormController: IFilterFormController = (props) => {
  const { initialValues } = props;

  const form = useForm<FilterFormFields>({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  return { form };
};
