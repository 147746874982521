import { logEvent } from 'firebase/analytics';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { analytics } from '~/config/firebase';

/**
 * @description This hook is used to log the screen view event to firebase analytics
 */
export const useFirebaseRoutesAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    logEvent(analytics, 'screen_view', {
      firebase_screen: location.pathname,
      firebase_screen_class: 'doit-volunteer',
    });
  }, [location]);

  return null;
};
