import { StylesConfig } from 'react-select';

import { theme } from '~/theme';

import { IOption } from './types';

const defaultStyle: StylesConfig = {
  control: (baseStyles) => ({
    ...baseStyles,
    fontFamily: theme.fontConfig.default,
    fontSize: theme.fontSizes.sm,
    paddingLeft: 26,
    height: '3rem',
    borderRadius: 12,
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    fontFamily: theme.fontConfig.default,
    fontSize: 12,
  }),
  menuPortal: (baseStyles) => ({
    ...baseStyles,
    fontFamily: theme.fontConfig.default,
    fontSize: 12,
    Index: 9999,
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    fontWeight: 400,
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[400],
  }),
};

export const useCauseOptionsAutoCompleteController = ({
  causesOptions,
}: {
  causesOptions: { value: string; label: string }[];
}) => {
  const filterCauses = (inputValue: string) => {
    return causesOptions.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase()),
    );
  };

  const loadOptions = (
    inputValue: string,
    callback: (options: IOption[]) => void,
  ) => {
    setTimeout(() => {
      callback(filterCauses(inputValue));
    }, 250);
  };

  return { defaultStyle, loadOptions };
};
