export interface CalendarInlineProps {
  date: Date | null;
  onChange: (newDate: Date) => void;
  dates: Date[];
  blockedDates: Date[];
}

export interface DateSlot {
  date: Date;
  isBlocked: boolean;
}

export function getDateSlotKey(date: Date) {
  return `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`;
}
