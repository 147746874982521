import { HStack, Text } from 'native-base';
import React, { useMemo } from 'react';
import Icon from 'ui/components/Icon';

import { convertMetersToMiles } from '~/pages/Authenticated/InApp/utils/metersToMiles';
import { colors } from '~/theme/colors';

export interface DistanceLabelProps {
  distance: number;
}

export const DistanceLabel = (props: DistanceLabelProps) => {
  const { distance } = props;

  const metersToMiles = useMemo(() => {
    if (distance < 0) return '';
    const distanceInMiles = convertMetersToMiles(distance);
    return String(
      distanceInMiles < 1
        ? distanceInMiles.toFixed(1)
        : Math.ceil(distanceInMiles),
    );
  }, [distance]);

  if (distance == -1) return <></>;

  const getMileText = () => {
    if (Number(metersToMiles) <= 0) return 'Near you';
    return `${metersToMiles} miles from you`;
  };

  return (
    <HStack alignItems={'center'} space={1}>
      <Icon icon="map-pin" size={16} color={colors.gray[400]} />
      <Text
        fontSize={'xs'}
        fontWeight={400}
        color={colors.gray[600]}
        noOfLines={1}
      >
        {getMileText()}
      </Text>
    </HStack>
  );
};

export default DistanceLabel;
