import { Stack, Text } from 'native-base';
import Button from 'ui/components/Button';

import { Tab } from '~/components/Tabs';
import { AcitivitiesHeaderPros } from '~/pages/Authenticated/Activities/components/ActivitiesHeader/types';

export const AcitivitiesHeader = (props: AcitivitiesHeaderPros) => {
  const { onActionButton, label, description } = props;

  return (
    <Stack flexShrink={0}>
      <Tab
        key={'actvities-registered'}
        label={label}
        description={description}
        icon="calendar"
        isActive
        rightElement={
          onActionButton && (
            <Button
              leftIconName="plus-circle"
              minW="150px"
              w="fit-content"
              minH="44px"
              onPress={onActionButton}
              display={{ base: 'none', md: 'flex' }}
              leftIconSize={24}
            >
              <Text fontSize="md" color="white" fontWeight="medium">
                New Activity
              </Text>
            </Button>
          )
        }
      />
    </Stack>
  );
};
