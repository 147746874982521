import { formattedTime } from 'ui/utils/formatter';

import { Answers } from '~/store/slices/quiz/types';
import { IUpdateProfilePreferencesDTO } from '~/types/dtos';
import { IActivity } from '~/types/interfaces/activity';
import { Preferences } from '~/types/interfaces/user';

export const rangeActivityTimeText = (activity?: IActivity) => {
  return `${
    activity?.startDate
      ? formattedTime(new Date(activity.startDate), "hh:mm aaaaa'm'")
      : ''
  } to ${
    activity?.endDate
      ? formattedTime(new Date(activity.endDate), "hh:mm aaaaa'm'")
      : ''
  }`;
};

export const convertHourAndMinutesToSeconds = (hour: number, minutes: number) =>
  hour * 3600 + minutes * 60;

export const mountQuizPayload = (answers: Answers | null) => {
  const causeOptionsIds = answers?.causeOptions?.map((item) => item._id) || [];
  const attendanceTypes = [];
  const coordinates = [];

  if (answers?.attendanceType?.fromHome) {
    attendanceTypes.push('Home');
  }

  if (answers?.attendanceType?.inYourLocalArea) {
    attendanceTypes.push('Local');
  }

  if (answers?.position?.lng) {
    if (answers?.position?.lat) {
      coordinates.push(answers?.position?.lng);
      coordinates.push(answers?.position?.lat);
    }
  }

  const preferences: IUpdateProfilePreferencesDTO = {
    activityTypes:
      answers?.periodOptions?.map((activityType) => activityType._id) || [],
    attendanceTypes: attendanceTypes,
    causesToSupport: causeOptionsIds,
    address: {
      street: answers?.position?.rawLocation || '',
      location: {
        type: 'Point',
        coordinates,
      },
    },
  };

  return preferences;
};

export const mountPayloadChangeUpdateQuiz = (preferences?: Preferences) => {
  const payload: Answers = {
    attendanceType: {
      fromHome: preferences?.attendanceTypes?.includes('Home') ? true : false,
      inYourLocalArea: preferences?.attendanceTypes?.includes('Local')
        ? true
        : false,
    },
    causeOptions: preferences?.causesToSupport?.map((causeOption: string) => {
      return {
        _id: causeOption,
      };
    }),
    position: preferences?.address
      ? {
          lat: preferences?.address?.location?.coordinates[1] || 0,
          lng: preferences?.address?.location?.coordinates[0] || 0,
          rawLocation: preferences?.address?.street || '',
        }
      : undefined,
    periodOptions: preferences?.activityTypes?.map((activityType: string) => {
      return {
        _id: activityType,
      };
    }),
  };

  return payload;
};

export const secondsToHoursMinutes = (totalSeconds: number) => {
  if (totalSeconds === 0) return { hours: '00', minutes: '00' };
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  return { hours: formattedHours, minutes: formattedMinutes };
};
