/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ILocation } from 'ui/types/interfaces';

import { useAppSelector } from '~/hooks/useAppSelector';
import { useAuth } from '~/hooks/useAuth';
import UserService from '~/services/resources/user';
import { inAppSlice } from '~/store/slices/inApp';
import { Preferences } from '~/types/interfaces/user';

export const useUserLocationController = () => {
  // -- Providers
  const { location, showLocationModal } = useAppSelector(({ inApp }) => inApp);
  const { user } = useAppSelector(({ auth }) => auth);
  const { handleGetUserInformation } = useAuth();
  const dispatch = useDispatch();

  // -- States
  const [isLoading, setIsLoading] = useState(false);

  // -- Hooks
  const usersPreferredLocation = useMemo(() => {
    if (!user?.preferences?.address) return null;
    const { address } = user.preferences;
    const userLocation = {
      lat: address.location!.coordinates[1],
      lng: address.location!.coordinates[0],
      placeID: undefined,
      rawLocation: address.street,
    };
    return userLocation;
  }, [user]);

  // -- Set User's Preferred location
  useEffect(() => {
    if (!usersPreferredLocation || !!location) return;
    dispatch(inAppSlice.actions.setLocation(usersPreferredLocation));
  }, [dispatch, location, usersPreferredLocation]);

  // -- Handlers
  const openLocationModal = () => {
    dispatch(inAppSlice.actions.setShowLocationModal(true));
  };

  const closeLocationModal = () => {
    dispatch(inAppSlice.actions.setShowLocationModal(false));
  };

  const handleNewLocation = (
    newLocation?: ILocation,
    saveAsPreference = false,
  ) => {
    dispatch(inAppSlice.actions.setLocation(newLocation || null));
    if (saveAsPreference) {
      saveLocationAsPreference(newLocation);
    }
  };

  const clearLocation = () => {
    dispatch(inAppSlice.actions.setLocation(null));
  };

  const saveLocationAsPreference = async (newLocation?: ILocation) => {
    if (!newLocation) return;
    try {
      const preferencesPayload = {
        address: {
          location: {
            type: 'Point',
            coordinates: [newLocation.lng, newLocation.lat],
          },
          street: newLocation.rawLocation,
        },
      };

      setIsLoading(true);
      await UserService.updateUser(user?._id as string, {
        preferences: preferencesPayload as Preferences,
      });

      await handleGetUserInformation({ shouldFetchAttributes: true });
    } catch (error) {
      toast.error('Unable to update Location preferences');
    } finally {
      setIsLoading(false);
    }
  };

  return {
    location,
    isLoading,
    showLocationModal,
    openLocationModal,
    closeLocationModal,
    handleNewLocation,
    clearLocation,
  };
};
