import { AxiosError } from 'axios';
import {
  Checkbox,
  Hidden,
  HStack,
  Stack,
  Text,
  useDisclose,
  VStack,
} from 'native-base';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import Loading from 'ui/components/Loading';
import { EEventApplicationType } from 'ui/enums';

import { BookingDateSelectCard } from '~/components/BookingDateSelectCard/BookingDateSelectCard';
import { transformActivityList } from '~/components/CardBookingSelection/CardBookingSelectionEvent/cardBookingSelectionEvent.controller';
import { queryClient } from '~/config/react-query.config';
import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useOpportunityApplicationCommonController } from '~/pages/Authenticated/OpportunityApplication/controllers/OpportunityApplicationCommonController';
import { getLabelByApplicationType } from '~/pages/Authenticated/OpportunityApplication/EventApplicationPage/helpers/getLabelByEnventType';
import { ActivitiesService } from '~/services/resources/activities';
import { ActivityApplicationsService } from '~/services/resources/activity-applications';
import TeamService from '~/services/resources/teams';
import { IActivity } from '~/types/interfaces/activity';

const Header = ({
  isOngoingOpportunity,
  isTeamEvent,
}: {
  isOngoingOpportunity?: boolean;
  isTeamEvent?: boolean;
}) => (
  <VStack
    space={1}
    borderBottomColor={'singletons.black'}
    borderBottomWidth={{ base: 0, md: 2 }}
    marginLeft={{ base: 0, md: -4 }}
    w={{ base: 'unset', md: 'calc(100% + 30px)' }}
    p={{ base: 0, md: 4 }}
    flexDir={{ base: 'column', md: 'row' }}
    alignItems={{ base: 'unset', md: 'center' }}
  >
    <Hidden till={'md'}>
      <Stack mr={4}>
        <Icon size={32} icon={'check-circle'} />
      </Stack>
    </Hidden>
    <Stack w={'full'}>
      <Text fontSize={'xl'} fontWeight={500} w={'75%'}>
        {isOngoingOpportunity
          ? 'Would like to apply to this opportunity?'
          : 'Would you like to register for this event?'}
      </Text>
      <Text fontSize={'sm'} color={'gray.600'} w={'90%'} mt={'2'}>
        Please take a moment to review your application carefully.
      </Text>
      {isTeamEvent && (
        <Text
          mt={{ base: 2, md: 0 }}
          fontSize={'sm'}
          color={'gray.600'}
          fontWeight={600}
        >
          Only the members of the team present at the time of the application
          will apply for the opportunity
        </Text>
      )}
    </Stack>
  </VStack>
);

interface ActivityDetailsProps {
  activity: Partial<IActivity>;
  teamName?: string;
}

const ActivityDetails = ({ activity, teamName }: ActivityDetailsProps) => {
  const { availableAppsFromPermissionGroupsById } = useAppSelector(
    ({ auth }) => auth,
  );
  const [
    {
      day,
      month,
      bookingDateSelectDetails,
      activityId,
      activityTitle,
      appName,
      organizationName,
      organizationLogo,
      spotsRemaining,
      noVolunteerLimit,
    },
  ] = transformActivityList(
    [activity],
    false,
    availableAppsFromPermissionGroupsById,
  );

  return (
    <VStack space={4}>
      <Text fontSize={'md'}>Activity details</Text>
      <BookingDateSelectCard
        organizationLogo={organizationLogo}
        activityId={activityId}
        day={day}
        month={month}
        bookingDateSelectDetails={bookingDateSelectDetails}
        hideCardSelectIcon
        hideActivitySummary={false}
        activityTitle={activityTitle}
        appName={appName}
        organizationName={organizationName}
        teamName={teamName}
        spotsRemaining={spotsRemaining}
        noVolunteerLimit={noVolunteerLimit}
      />
    </VStack>
  );
};

interface SubmitCheckboxProps {
  onToggleCheckBox: () => void;
}

const SubmitCheckbox = ({ onToggleCheckBox }: SubmitCheckboxProps) => {
  return (
    <Checkbox value={'terms'} onChange={onToggleCheckBox}>
      <Text fontSize={'xs'} ml={4}>
        By submitting an application to a volunteer role on our platform, I
        accept the transfer of my personal information to third parties.
      </Text>
    </Checkbox>
  );
};

interface ActionsFooterProps {
  isLoading: boolean;
  onPressPrimaryAction: () => void;
  onPressSecondaryAction: () => void;
  isTermsAccepted: boolean;
  isOngoingOpportunity?: boolean;
  applicationType?: EEventApplicationType;
}

const ActionsFooter = ({
  isLoading,
  isTermsAccepted,
  onPressSecondaryAction,
  onPressPrimaryAction,
  isOngoingOpportunity,
  applicationType = EEventApplicationType.Team,
}: ActionsFooterProps) => {
  return (
    <VStack
      space={3}
      flexDir={{ base: 'column', md: 'row-reverse' }}
      px={{ base: 2, md: 6 }}
    >
      <Stack w={'full'}>
        <Button
          isLoading={isLoading}
          isDisabled={!isTermsAccepted}
          onPress={onPressPrimaryAction}
        >
          {isOngoingOpportunity
            ? 'Yes, apply'
            : getLabelByApplicationType(applicationType)}
        </Button>
      </Stack>
      <Stack w={'full'}>
        <Button variant={'ghost'} onPress={onPressSecondaryAction}>
          Cancel
        </Button>
      </Stack>
    </VStack>
  );
};

interface EventApplicationPageProps {
  isTeamEvent?: boolean;
  isOngoingOpportunity?: boolean;
  applicationType?: EEventApplicationType;
}

export const EventApplicationPage = ({
  isTeamEvent,
  isOngoingOpportunity,
  applicationType,
}: EventApplicationPageProps) => {
  const { eventId, teamId } = useParams();
  const { selectedUserProfile } = useAppSelector(({ auth }) => auth);
  const { isOpen: isTermsAccepted, onToggle: handleAcceptTermsToggle } =
    useDisclose();

  const navigate = useNavigate();

  const { data: activityData, isLoading } = useQuery({
    queryKey: [ActivitiesService.URL, { eventId }],
    queryFn: async ({ queryKey }) => {
      const [, params] = queryKey;
      const { eventId } = params as { eventId: string };

      return await ActivitiesService.findById(eventId);
    },
    enabled: !!eventId,
  });

  const { applicationAppId } = useOpportunityApplicationCommonController({
    activityDefinitionId: activityData?.activityDefinition,
  });

  const { data: teamData } = useQuery({
    queryKey: [TeamService.URL, { teamId }],
    queryFn: async ({ queryKey }) => {
      const [, params] = queryKey;
      const { teamId } = params as { teamId: string };

      return await TeamService.findAll({
        filter: JSON.stringify({
          _id: teamId,
        }),
      });
    },
    enabled: !!teamId,
  });

  const { mutate: applyToActivity, isLoading: applicationIsLoading } =
    useMutation({
      mutationFn: async () => {
        if (isTeamEvent && !!teamId) {
          await ActivitiesService.applyTeamToActivity(
            eventId as string,
            teamId,
            applicationAppId,
          );

          return;
        }

        await ActivitiesService.applyToActivity(
          eventId as string,
          selectedUserProfile?._id as string,
          applicationAppId,
        );
      },
      onSuccess: async () => {
        await queryClient.refetchQueries({
          queryKey: [ActivityApplicationsService.BASE_PATH],
        });

        navigate(PAGES.OpportunityApplicationConfirmation, {
          state: { appId: applicationAppId },
        });
      },
      onError: (error: AxiosError) => {
        const isConflict = error?.response?.status === 409;

        if (isConflict && isTeamEvent) {
          const team = teamData?.data[0];
          const quantityOfMembers = team?.members.length || 0;
          const teamsMaxSize = activityData?.teamsMaxSize || 0;
          const teamsMinSize = activityData?.teamsMinSize || 0;

          const memberString = quantityOfMembers > 1 ? 'members' : 'member';

          if (quantityOfMembers > teamsMaxSize) {
            toast.error(
              `Your team ${team?.name} has ${quantityOfMembers} ${memberString} and the event has a max size of ${teamsMaxSize}.`,
            );
            return;
          }

          if (quantityOfMembers < teamsMinSize) {
            toast.error(
              `Your team ${team?.name} has ${quantityOfMembers} ${memberString} and the event has a min size of ${teamsMinSize}.`,
            );
            return;
          }
        } else {
          toast.error(
            'The application could not be submitted. Please try again later.',
          );
        }
      },
    });

  const handleApplyToActivity = () => {
    applyToActivity();
  };

  const handleCancel = () => {
    navigate(-1);
  };

  if (isLoading) {
    return (
      <VStack
        py={6}
        px={4}
        bg={'singletons.white'}
        m={2}
        rounded={12}
        h={'100%'}
      >
        <Loading containerHeight={'100%'} />
      </VStack>
    );
  }

  if (!activityData) {
    navigate('/404', { replace: true });

    return null;
  }

  return (
    <VStack
      space={9}
      py={{ base: 4, md: 6 }}
      px={{ base: 1, sm: 6, md: 10, lg: 20 }}
      h={'full'}
      justifyContent={'space-between'}
    >
      <HStack h={'full'} justifyContent={'center'} space={{ base: 0, md: 24 }}>
        <Hidden till={'md'}>
          <Stack
            alignItems={'center'}
            minH={'612px'}
            justifyContent={'center'}
            minW={'10rem'}
            w="30vw"
          >
            <Illustration
              resizeMode={'contain'}
              minW={'10rem'}
              alignSelf={'center'}
              minWidth={{ base: '15rem', lg: '25rem' }}
              maxWidth={{ base: '20rem', lg: '30rem' }}
              minH={'612px'}
              maxHeight={{ base: '20rem', lg: '30rem' }}
              name={ILLUSTRATIONS_NAME.DATA_MANAGEMENT}
            />
          </Stack>
        </Hidden>
        <VStack
          h={{ base: '70vh', md: 'full' }}
          minH={{ base: '70vh', md: '612px' }}
          w={{ base: 'full', md: '40vw' }}
          py={{ base: 6, md: 0 }}
          px={4}
          bg={'singletons.white'}
          m={2}
          rounded={12}
          space={{ base: 6, md: 0 }}
          justifyContent={{ base: 'flex-start', md: 'space-between' }}
        >
          <VStack space={6}>
            <Header
              isOngoingOpportunity={isOngoingOpportunity}
              isTeamEvent={isTeamEvent}
            />
            <ActivityDetails
              activity={activityData}
              teamName={teamData?.data[0].name}
            />
          </VStack>
          <VStack space={{ base: 0, md: 6 }} py={{ base: 0, md: 6 }}>
            <SubmitCheckbox onToggleCheckBox={handleAcceptTermsToggle} />
            <Hidden till={'md'}>
              <ActionsFooter
                isTermsAccepted={isTermsAccepted}
                isLoading={applicationIsLoading}
                onPressPrimaryAction={handleApplyToActivity}
                onPressSecondaryAction={handleCancel}
                isOngoingOpportunity={isOngoingOpportunity}
                applicationType={
                  activityData.activityDefinitionSubDocument
                    .eventApplicationType
                }
              />
            </Hidden>
          </VStack>
        </VStack>
      </HStack>
      <Hidden from={'md'}>
        <ActionsFooter
          isTermsAccepted={isTermsAccepted}
          isLoading={applicationIsLoading}
          onPressPrimaryAction={handleApplyToActivity}
          onPressSecondaryAction={handleCancel}
          isOngoingOpportunity={isOngoingOpportunity}
          applicationType={
            activityData.activityDefinitionSubDocument.eventApplicationType
          }
        />
      </Hidden>
    </VStack>
  );
};
