import {
  Avatar,
  HStack,
  Image,
  Pressable,
  Skeleton,
  Stack,
  Text,
} from 'native-base';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';

import { CommonContainer } from '~/components/Layouts/Scaffolds/CommonContainer';
import { AccountDropdown } from '~/components/NavBar/components/AccountDropdown';
import { MobileNavBar } from '~/components/NavBar/components/MobileNavBar';
import { NonAuthenticatedHeader } from '~/components/NavBar/components/NonAuthenticatedHeader';
import { AuthenticatedMobileHeaderProps } from '~/components/NavBar/navBar.types';
import { RootPortal } from '~/components/RootPortal';
import { useUserLocationController } from '~/hooks/useUserLocation/useUsersLocation';
import { SelectAddressButton } from '~/pages/Authenticated/InApp/components/SelectAddressButton';
import LocationModal from '~/pages/Authenticated/InApp/modals/LocationModal';

import { navbarHeightInPx, useNavBarController } from './navBar.controller';

type NavBarProps = Partial<AuthenticatedMobileHeaderProps>;

export const NavBar = ({
  hideBackButton,
  hideCloseButton,
  onPressClose,
  hideMobileNavBar,
  isGuest,
}: NavBarProps) => {
  const {
    userParsedData,
    breakpoints,
    currentPath,
    selectedEcosystem,
    handleGoToWalletHome,
    pathWithNoParams,
    headerLogo,
    isDefaultSignInEcosystem,
  } = useNavBarController({ isGuest });

  const {
    location,
    showLocationModal,
    isLoading: isLocationLoading,
    openLocationModal,
    closeLocationModal,
    handleNewLocation,
  } = useUserLocationController();

  if (!userParsedData.hasUser)
    return (
      <NonAuthenticatedHeader
        headerLogo={headerLogo}
        isDefaultSignInEcosystem={isDefaultSignInEcosystem}
        ecosystemName={userParsedData?.ecosystemName}
        isGuest={isGuest}
      />
    );

  if (breakpoints?.showMobileHeader) {
    return (
      <MobileNavBar
        userParsedData={userParsedData}
        currentPath={pathWithNoParams || currentPath}
        hideBackButton={hideBackButton}
        hideCloseButton={hideCloseButton}
        onPressClose={onPressClose}
        hideMobileNavBar={hideMobileNavBar}
      />
    );
  }

  return (
    <>
      <HStack
        w="100%"
        bg="white"
        borderBottomWidth="1"
        h={`${navbarHeightInPx}px`}
        borderBottomColor="gray.200"
      >
        <CommonContainer>
          <HStack
            h="full"
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Pressable onPress={handleGoToWalletHome}>
              <HStack space={3} alignItems={'center'}>
                <Skeleton
                  w={'48px'}
                  h={'48px'}
                  borderRadius={'full'}
                  isLoaded={!!selectedEcosystem}
                >
                  {isDefaultSignInEcosystem ? (
                    <Image
                      src={headerLogo}
                      w={{
                        base: '200px',
                        md: '72px',
                      }}
                      h={{
                        base: '112px',
                        md: '42px',
                      }}
                      alt="DoIT Logo"
                    />
                  ) : (
                    <Avatar
                      source={{
                        uri: userParsedData?.ecosystemLogo,
                      }}
                      w={'48px'}
                      h={'48px'}
                      borderRadius={'full'}
                    >
                      {userParsedData?.ecosystemInitial}
                    </Avatar>
                  )}
                </Skeleton>
                <Stack space={0.5}>
                  <Skeleton.Text lines={1} isLoaded={!!selectedEcosystem}>
                    <Text fontSize={'sm'} fontWeight={700}>
                      {userParsedData?.ecosystemName}
                    </Text>
                  </Skeleton.Text>
                  {!isDefaultSignInEcosystem ? (
                    <HStack flexDir={'row'} alignItems={'center'} space={1}>
                      <Skeleton.Text
                        lines={1}
                        w="100px"
                        isLoaded={!!selectedEcosystem}
                      >
                        <Text fontSize={'xs'} color={'gray.500'}>
                          Powered by{' '}
                        </Text>
                      </Skeleton.Text>

                      <Stack>
                        <Skeleton
                          width="30px"
                          height="16px"
                          borderRadius="full"
                          isLoaded={!!selectedEcosystem}
                        >
                          <Illustration
                            width="30px"
                            height="16px"
                            name={ILLUSTRATIONS_NAME.DOIT_TRANSPARENT_LOGO}
                          />
                        </Skeleton>
                      </Stack>
                    </HStack>
                  ) : null}
                </Stack>
              </HStack>
            </Pressable>

            <HStack alignItems="center" space={6}>
              <SelectAddressButton
                isLoading={isLocationLoading}
                onPress={openLocationModal}
                address={location?.rawLocation || ''}
              />
              <AccountDropdown />
            </HStack>
          </HStack>
        </CommonContainer>
      </HStack>

      {/* Location Modal */}
      <RootPortal>
        <LocationModal
          isOpen={showLocationModal}
          onClose={closeLocationModal}
          onSubmit={handleNewLocation}
          initialLocation={location}
        />
      </RootPortal>
    </>
  );
};
