import { IActivityDefinition } from '~/types/interfaces/activity';

export enum EQueryKeys {
  INFINITY_QUERY_ACTION_ACTIVITIES_SEARCH = 'infinity-query-action-activities-search',
  INFINITY_QUERY_EVENT_ACTIVITIES_SEARCH = 'infinity-query-event-activities-search',
  INFINITY_QUERY_ONGOING_ACTIVITIES_SEARCH = 'infinity-query-ongoing-activities-search',
}

export interface IPagination<T> {
  data: T;
  skip: number;
}

export type KeyParams = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export type UseInfinityActivitySearchResponse = Promise<
  IPagination<IActivityDefinition[]>
>;

export interface IBaseGetPaginatedData<T> {
  pageParam?: number;
  disabled?: boolean;
  params: T;
}
