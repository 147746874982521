import { Flex, Hidden, HStack, Stack, VStack } from 'native-base';

import { Tab } from '~/components/Tabs';
import { AppCard } from '~/pages/Authenticated/UserApps/components/AppCard';
import { useUserAppsController } from '~/pages/Authenticated/UserApps/useUserAppsController';

export const UserApps = () => {
  const { handleAppSelect, appsList } = useUserAppsController();

  return (
    <>
      <Flex
        flex={1}
        h={{ base: 'auto', md: 'full' }}
        w={{ base: 'auto', md: 'full' }}
        bg="white"
        rounded={8}
      >
        <Hidden till="md">
          <Stack flexShrink={0}>
            <Tab
              key={'UserApps'}
              label="Apps"
              description="All the apps available in this ecosystem"
              icon="grid"
              isActive
            />
          </Stack>
        </Hidden>

        <VStack py={6} px={{ base: 0, md: 4 }}>
          <HStack
            flexWrap={'wrap'}
            overflow={'hidden'}
            justifyContent={{
              base: 'space-between',
              lg: 'flex-start',
            }}
          >
            {(appsList || [])?.map((app: any, i) => {
              return (
                <VStack
                  p={2}
                  pb={4}
                  key={app?._id || i}
                  minW={'124px'}
                  w={{ base: '50%', sm: '33%', md: '25%', xl: '240px' }}
                >
                  <AppCard
                    key={app?._id || ''}
                    app={app}
                    onPress={() => handleAppSelect(app)}
                  />
                </VStack>
              );
            })}
          </HStack>
        </VStack>
      </Flex>
    </>
  );
};
