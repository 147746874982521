import { useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { useInAppFiltersController } from '~/components/InAppViewLayout/useInAppFiltersController';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useFilterFormController } from '~/pages/Authenticated/InApp/components/FilterForm/controller';
import { FilterFormFields } from '~/pages/Authenticated/InApp/components/FilterForm/types';
import { FilterModalProps } from '~/pages/Authenticated/InApp/modals/FilterModal/types';
import { inAppSliceActions } from '~/store/slices/inApp';

export const useFilterModalController = (props: FilterModalProps) => {
  const { onClose } = props;
  // Providers
  const dispatch = useDispatch();
  const { searchFilters, selectedApp } = useAppSelector((state) => state.inApp);

  const { clearFilters } = useInAppFiltersController();

  // -- States
  const forceResetRef = useRef(false);

  // -- Hooks
  const initialValues = useMemo(() => {
    return {
      distanceRadius: searchFilters.distanceRadius,
      endDate: searchFilters.endDate,
      startDate: searchFilters.startDate,
      countries: searchFilters.countries,
      regions: searchFilters.regions,
      showOnline: searchFilters.showOnline,
      teamsNumber: searchFilters.teamsNumber,
      teamsSize: {
        min: searchFilters.teamsMinSize,
        max: searchFilters.teamsMaxSize,
      },
    };
  }, [searchFilters]);

  const formController = useFilterFormController({
    initialValues,
  });

  // ( Reset form after submit or clear)
  useEffect(() => {
    if (
      formController.form.formState.isDirty ||
      forceResetRef.current == true
    ) {
      formController.form.reset(initialValues);
      forceResetRef.current = false;
    }
  }, [initialValues]);

  // -- Handlers
  const handleSubmitButton = (data: FilterFormFields) => {
    dispatch(
      inAppSliceActions.setSearchFilters({
        distanceRadius: data.distanceRadius,
        startDate: data.startDate,
        endDate: data.endDate,
        teamsNumber: data.teamsNumber,
        countries: data.countries,
        regions: data.regions,
        showOnline: data.showOnline,
        teamsMinSize: data.teamsSize.min,
        teamsMaxSize: data.teamsSize.max,
      }),
    );
    onClose();
  };

  const handlCloseButton = () => {
    onClose();
  };

  const handleClearFilter = () => {
    clearFilters();
    forceResetRef.current = true;
    onClose();
  };

  // -- Computed
  const hasChanges = formController.form.formState.isDirty;

  return {
    handlCloseButton,
    handleSubmitButton,
    handleClearFilter,
    formController,
    selectedApp,
    hasChanges,
  };
};
