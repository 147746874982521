import { useMemo } from 'react';

import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import useInfinityOffPlatformMeasurementDefinitions from '~/hooks/useMeasurementDefinition/useInfinityOffPlatformMeasurementDefinitions';
import { useRouter } from '~/hooks/useRouter';

export const useLogOffPlatformActivitiesController = () => {
  const {
    selectedUserProfile,
    availableAppsFromPermissionGroupsById: availableApps,
  } = useAppSelector(({ auth }) => auth);

  const { goToRoute } = useRouter();

  const {
    data,
    isLoading,
    refetch,
    isRefetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfinityOffPlatformMeasurementDefinitions({});

  const offPlatformActivities = useMemo(
    () => data?.pages?.flatMap((page: any) => page.responseData?.data) ?? [],
    [data],
  );

  const totalOffPlatformActivities = useMemo(() => {
    const [firstPage] = data?.pages ?? [];
    return firstPage?.responseData?.count ?? 0;
  }, [data]);

  const handleOnScrollEndReached = async () => {
    if (!isRefetching && !isFetchingNextPage && hasNextPage) {
      await fetchNextPage();
    }
  };

  const handleOffPlatformCreate = () => {
    goToRoute(PAGES.CreateOffPlatformActivity);
  };

  return {
    offPlatformActivities,
    totalOffPlatformActivities,
    isLoading,
    refetch,
    isFetchingNextPage,
    handleOnScrollEndReached,
    handleOffPlatformCreate,
  };
};

export default useLogOffPlatformActivitiesController;
