import { HStack, Pressable, Skeleton, Stack, Text, VStack } from 'native-base';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';

import { ActivityLogsBannerProps } from './types';

export const ActivityLogsBanner = (props: ActivityLogsBannerProps) => {
  const { isLoading = true, onPress, count } = props;

  if (isLoading)
    return (
      <Skeleton
        h={32}
        borderRadius={12}
        alignItems={'center'}
        justifyContent={'space-between'}
        rounded={12}
        w="100%"
      />
    );

  return (
    <Pressable onPress={() => onPress()}>
      <HStack
        bg={isLoading ? 'transparent' : '#4E44BF'}
        h={32}
        rounded={12}
        alignItems={'center'}
        justifyContent={'space-between'}
        px={{ base: 4 }}
        mb={4}
      >
        <VStack w={'70%'} space={3}>
          <Text
            color={'singletons.white'}
            fontWeight={500}
            fontSize={{ base: 'md', lg: 'xl' }}
          >
            {count > 0 ? (
              <>
                Don’t forget! You have{'\n'} <b>{count} activities</b> to log{' '}
              </>
            ) : (
              <>Don’t forget to log your activities!</>
            )}
          </Text>
          <Text fontSize={{ base: 'xs', lg: 'md' }} color={'singletons.white'}>
            Help charities measure their impact!
          </Text>
        </VStack>
        <Stack>
          <Illustration
            name={ILLUSTRATIONS_NAME.BUSINESS_TASK_MANAGER}
            minW={{ base: 104, lg: 128 }}
            h={{ base: 104, lg: 128 }}
            resizeMode={'contain'}
          />
        </Stack>
      </HStack>
    </Pressable>
  );
};
