import { useQuery } from '~/hooks/useQuery';
import { IPopulatedActivityDefinition } from '~/types/interfaces/activity';

export const useLoadActivityDefinition = (id: string) => {
  const { data, isLoading, isFetched } = useQuery<IPopulatedActivityDefinition>(
    `activity-definition-public/${id}`,
    {
      key: `activity-definition-public-${id}`,
      queryOptions: { enabled: !!id },
    },
  );
  return { data: data || null, isLoading, isFetched };
};
