import { HStack, Pressable, Text } from 'native-base';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

export interface FilterToggleProps {
  icon: string;
  label: string;
  onPress: (data: any) => void;
  value?: string;
  brandColor?: string;
  isCustomIcon?: boolean;
  size?: number;
}

export const FilterToggle = (props: FilterToggleProps) => {
  const {
    icon,
    label,
    onPress,
    value = false,
    brandColor = colors.primary[400],
    isCustomIcon = false,
    size = 16,
  } = props;
  return (
    <Pressable onPress={() => onPress(1)}>
      <HStack alignItems={'center'} space={2.5}>
        {icon && (
          <Icon
            icon={icon}
            isCustom={isCustomIcon}
            size={size}
            color={brandColor}
          />
        )}
        <Text
          py={2}
          fontSize={'xs'}
          lineHeight={'150%'}
          fontWeight={500}
          color={brandColor}
        >
          {label}
        </Text>

        {value && (
          <Text
            fontSize={'xs'}
            lineHeight={'150%'}
            fontWeight={400}
            color={colors.gray[600]}
          >
            {value}
          </Text>
        )}
      </HStack>
    </Pressable>
  );
};
