import { useMemo } from 'react';

import { useAppSelector } from '~/hooks/useAppSelector';
import useInfinityOngoingActivitySearch from '~/hooks/useInfinityActivitySearch/useInfinityOngoingActivitySearch';
import {
  IUseSearchController,
  SearchControllerProps,
} from '~/pages/Authenticated/InApp/Explorer/controllers/interface';
import { mapOngoingFilter } from '~/pages/Authenticated/InApp/Explorer/mappers';
import { MapActivityToSearchResult } from '~/pages/Authenticated/InApp/SearchView/mapper';
import { IActivityDefinition } from '~/types/interfaces/activity';

export const useOngoinhActivityController: IUseSearchController = (
  props: SearchControllerProps,
) => {
  // -- Props
  const { appId, attendanceType } = props;

  // -- Providers
  const { searchFilters, regionOptions, location } = useAppSelector(
    (state) => state.inApp,
  );

  const sortBy = searchFilters.sortBy;
  const distanceRadius = searchFilters.distanceRadius;
  const startDate = searchFilters.startDate;
  const endDate = searchFilters.endDate;

  // -- Hooks
  const queryParams: any = useMemo(() => {
    return mapOngoingFilter(
      appId,
      attendanceType,
      searchFilters,
      regionOptions,
      location,
    );
  }, [appId, searchFilters, attendanceType, regionOptions, location]);

  const {
    data,
    isFetchingNextPage,
    isRefetching,
    hasNextPage,
    isLoading,
    fetchNextPage,
  } = useInfinityOngoingActivitySearch(attendanceType, queryParams);

  const activitySearchResults = useMemo(() => {
    return (data as any)
      .map(
        MapActivityToSearchResult(
          location as any,
          sortBy,
          distanceRadius,
          startDate,
          endDate,
        ),
      )
      .filter((v: IActivityDefinition | null) => v != null);
  }, [data, location, sortBy, distanceRadius, startDate, endDate]);

  const handleOnScrollEndReached = async () => {
    if (isRefetching || isFetchingNextPage || !hasNextPage) return;
    await fetchNextPage();
  };

  // Render staments
  const shouldRenderSkeleton = isLoading || isFetchingNextPage;

  const shoudlRenderNoMoreActivities =
    activitySearchResults.length > 0 && !hasNextPage;

  const shouldRenderIllustration =
    (activitySearchResults ?? []).length == 0 && !isLoading;

  return {
    activitySearchResults,
    shouldRenderSkeleton,
    shoudlRenderNoMoreActivities,
    shouldRenderIllustration,
    handleOnScrollEndReached,
  };
};
