import { useMediaQuery } from 'native-base';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { EEventApplicationType } from 'ui/enums';

import { getHeaderText } from '~/components/ActivityCards/CardRecommendations/cardRecommendations.controller';
import { PAGES } from '~/constants/pages.constants';
import { getNameInitials } from '~/utils/getInitials';

import { TeamActivityCardControllerParams } from './types';

export const useTeamActivityCardController = ({
  activity,
}: TeamActivityCardControllerParams) => {
  const navigate = useNavigate();

  const [isMobile] = useMediaQuery({
    maxWidth: 480,
  });

  const eventSummary = useMemo(() => {
    const { activityDefinitionSubDocument } = activity;
    const { organizationSubDocument } = activityDefinitionSubDocument;

    const headerText = getHeaderText(
      activity.startDate,
      activity.createdAt,
      activityDefinitionSubDocument.type,
      undefined,
      activity?.externalApplyLink,
    );

    const organizationInitials = getNameInitials(organizationSubDocument.name);

    return {
      headerText,
      activityName: activityDefinitionSubDocument.title,
      activityType: activityDefinitionSubDocument.type,
      appName: activityDefinitionSubDocument.app.name,
      organizationInitials: organizationInitials,
      organizationName: organizationSubDocument.name,
      organizationLogo: organizationSubDocument.logo,

      requirementOptions: activityDefinitionSubDocument.requirementOptions,
      isTeamEvent:
        activity.activityDefinitionSubDocument.eventApplicationType ===
        EEventApplicationType.Team,
      activityDefinitionId: activity.activityDefinitionSubDocument._id,
    };
  }, [activity]);

  const handleGoToActivityDetail = (activityDefinitionId: string) => {
    const route = PAGES.ActivitiesDetails.replace(':id', activityDefinitionId);

    navigate(route);
  };

  return {
    eventSummary,
    handleGoToActivityDetail,
    isMobile,
  };
};
