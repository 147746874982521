import { Box, FlatList, Flex, Stack, Text } from 'native-base';
import Button from 'ui/components/Button';
import { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import Loading from 'ui/components/Loading';

import { ListEmptyState } from '~/components/EmptyState/ListEmptyState';
import { Tab } from '~/components/Tabs';
import { TeamCard } from '~/pages/Authenticated/Settings/Teams/TeamsList/components/TeamCard';

import { useTeamsListController } from './useTeamsListController';

export const TeamList = () => {
  const {
    handleAddTeam,
    teamsData,
    isLoading,
    refetch,
    isRefetching,
    quantityOfColumns,
  } = useTeamsListController();
  return (
    <Flex
      flex={1}
      w="full"
      h={{ base: `85%`, md: '100%' }}
      flexDir="column"
      bg="singletons.white"
      rounded={12}
    >
      <Stack flexShrink={0}>
        <Tab
          key={'TeamsList'}
          label="Teams"
          description="Create and manage your teams"
          icon="users"
          isActive
          rightElement={
            <Button
              leftIconName="plus-circle"
              minW="150px"
              w="150px"
              minH="44px"
              onPress={handleAddTeam}
              display={{ base: 'none', md: 'flex' }}
              leftIconSize={24}
            >
              <Text fontSize="md" color="white" fontWeight="medium">
                New team
              </Text>
            </Button>
          }
        />
      </Stack>

      {isLoading || isRefetching ? (
        <Box flex={1} h={'full'}>
          <Loading containerHeight={'full'} />
        </Box>
      ) : teamsData && teamsData.length > 0 ? (
        <FlatList
          key={quantityOfColumns}
          p={6}
          mt={2}
          keyExtractor={(item) => item._id}
          data={teamsData}
          numColumns={quantityOfColumns}
          columnWrapperStyle={
            (quantityOfColumns || 0) > 1 ? { gap: 16 } : undefined
          }
          renderItem={({ item }) => {
            return (
              <TeamCard key={item._id} team={item} onRemoveTeam={refetch} />
            );
          }}
        />
      ) : (
        <ListEmptyState
          text={`Currently, there are no teams that you are a part of. Join or create a team and they will appear here.`}
          illustrationName={ILLUSTRATIONS_NAME.ERROR_SORRY_STATE_BROKEN}
        />
      )}

      <Box display={{ md: 'none', lg: 'none' }} h={16}></Box>
      <Stack
        position="fixed"
        bottom={0}
        w="full"
        alignSelf="center"
        px={6}
        pb={24}
        pt={6}
        bg="white"
        display={{ base: 'flex', md: 'none' }}
      >
        <Button
          leftIconName="plus-circle"
          minH="44px"
          w="full"
          onPress={handleAddTeam}
          leftIconSize={24}
        >
          <Text fontSize="md" color="white" fontWeight="medium">
            New team
          </Text>
        </Button>
      </Stack>
    </Flex>
  );
};
