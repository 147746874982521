import { VStack } from 'native-base';

export const CommonContainer = ({ children }: any) => {
  return (
    <VStack
      flex={1}
      px={[2, 4, 8, 12, 20]}
      marginLeft={'auto'}
      marginRight={'auto'}
      w="full"
      maxWidth={'1440px'}
    >
      {children}
    </VStack>
  );
};
