import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { DEFAULT_ECOSYSTEM_ID } from '~/config';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useQuery } from '~/hooks/useQuery';
import { InsightsService } from '~/services/resources/insights/InsightsService';
import { ecosystemSliceActions } from '~/store/slices/ecosystem';
import { IEcosystem } from '~/types/interfaces/ecosystem';

export const useLoadUserEcosystemData = () => {
  const prevSelectedUserProfileRef = useRef('');

  const { selectedUserProfile, isLoading: isSelectedUserProfileLoading } =
    useAppSelector(({ auth }) => auth);

  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);
  const dispatch = useDispatch();

  const { data: ecosystem, isLoading: isLoadingUserEcosystem } =
    useQuery<IEcosystem>(`ecosystem/${selectedUserProfile?.ecosystem}`, {
      key: `ecosystems-${selectedUserProfile?.ecosystem}`,
      queryOptions: {
        enabled: !!selectedUserProfile?.ecosystem,
      },
    });

  useEffect(() => {
    if (!!isLoadingUserEcosystem || !!isSelectedUserProfileLoading) return;

    if (ecosystem && ecosystem._id !== selectedEcosystem?._id) {
      const isDefaultEcosystem = ecosystem._id === DEFAULT_ECOSYSTEM_ID;
      const payload = { isDefaultEcosystem, ecosystem };
      dispatch(ecosystemSliceActions.selectEcosystem(payload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ecosystem,
    selectedEcosystem,
    isLoadingUserEcosystem,
    isSelectedUserProfileLoading,
  ]);

  const handleInsights = async (userProfileId: string) => {
    try {
      const isSameUserProfile =
        prevSelectedUserProfileRef.current === userProfileId;

      if (!userProfileId || !!isSameUserProfile) return;

      await InsightsService.createUserProfileEngagement(userProfileId);
    } catch (error: unknown) {}
  };

  useEffect(() => {
    handleInsights(selectedUserProfile?._id || '');
  }, [selectedUserProfile]);

  const isLoading = isLoadingUserEcosystem || isSelectedUserProfileLoading;

  return {
    isLoading,
    selectedEcosystem,
    isSelectedUserProfileLoading,
  };
};
