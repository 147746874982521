import { ActivityType } from 'ui/types/activities';

import { IUseSearchController } from '~/pages/Authenticated/InApp/Explorer/controllers/interface';
import {
  FilterWhiteList,
  SortByType,
} from '~/pages/Authenticated/InApp/SearchView/types';
import { AttendanceType } from '~/types/interfaces/activity';

export enum ActivitySearchSection {
  Event = 'Event',
  TeamEvent = 'TeamEvent',
  Action = 'Action',
  Ongoing = 'OngoingOpportunity',
}

export type SortByAttendanceTypeMap = {
  [AttendanceType.Local]: Array<SortByType>;
  [AttendanceType.Online]: Array<SortByType>;
};

export type SectionConfig = {
  activityType: ActivityType;
  useController: IUseSearchController;
  filterWhiteList: FilterWhiteList;
  sortByOptions: SortByAttendanceTypeMap;
  isTeam: boolean;
};
