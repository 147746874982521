import { colors } from 'doit-bho-admin/src/theme/colors';
import {
  Avatar,
  FlatList,
  Hidden,
  HStack,
  Text,
  useBreakpointValue,
  VStack,
} from 'native-base';
import React from 'react';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';

import { MembersListProps } from './types';

export const MembersList = ({ members, onRemoveMember }: MembersListProps) => {
  const iconSize = useBreakpointValue({
    base: '24',
    md: '18',
  });
  return (
    <VStack
      mt={8}
      space={6}
      overflowY="auto"
      width="full"
      mb={{ base: 32, md: 0 }}
    >
      <FlatList
        data={members}
        renderItem={({ item, index }) => (
          <HStack
            key={item.value}
            py="3"
            borderBottomWidth="1"
            borderBottomColor="gray.300"
            justifyContent="space-between"
            alignItems="center"
          >
            <HStack alignItems="center" space="3" overflow="hidden">
              {item.isExisting ? (
                <Hidden from={item.isExisting ? 'none' : 'md'}>
                  <HStack alignItems="center" space={2}>
                    <Avatar
                      source={{ uri: item.profileImage }}
                      flexShrink={0}
                      size={6}
                    >
                      {item.label.charAt(0)}
                    </Avatar>
                    <Text fontSize="xs" color="gray.600" numberOfLines={1}>
                      {item.label}
                    </Text>
                  </HStack>
                </Hidden>
              ) : null}
              {!item.isExisting ? (
                <Hidden from={!item.isExisting ? 'none' : 'base'}>
                  <Text fontSize="xs" color="gray.600" numberOfLines={1}>
                    {item.__isNew__ ? item.value : item.email}
                  </Text>
                </Hidden>
              ) : null}
            </HStack>
            <Button
              minW={{ base: '50px', lg: 'container' }}
              w={{ base: '50px', lg: 'container' }}
              variant="ghost"
              onPress={() => onRemoveMember(index)}
            >
              <HStack space={3} alignItems="center">
                <Icon
                  icon="x-circle"
                  color={colors.error[600]}
                  size={iconSize}
                />
                <Hidden from="base" till="lg">
                  <Text fontSize="xs" color="red.600" fontWeight="500">
                    Remove
                  </Text>
                </Hidden>
              </HStack>
            </Button>
          </HStack>
        )}
      />
    </VStack>
  );
};
