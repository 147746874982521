import { Box, HStack, Pressable, Text } from 'native-base';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { useOptionsLabelController } from '~/pages/Authenticated/InApp/components/OptionsLabelWrap/controller';
import { OptionsLabelWrapProps } from '~/pages/Authenticated/InApp/components/OptionsLabelWrap/types';

export const OptionsLabelWrap = (props: OptionsLabelWrapProps) => {
  const { options, alternative } = props;

  const { handlePress } = useOptionsLabelController(props);

  return (
    <>
      <HStack
        alignItems={'center'}
        w="100%"
        flexWrap={'wrap'}
        space={2}
        style={{ rowGap: 8 }}
      >
        {options.map((option) => (
          <Box
            key={option.value}
            borderRadius={14}
            py={2}
            px={3}
            backgroundColor={alternative ? colors.white : colors.primary[400]}
            borderColor={alternative ? colors.gray[400] : 'trasnparent'}
            borderWidth={alternative ? 1 : 0}
          >
            <HStack space={2.5} alignItems={'center'}>
              <Text
                fontSize={{ base: 'xs', md: alternative ? 'xs' : 'sm' }}
                fontWeight={500}
                color={alternative ? colors.darkText : colors.white}
              >
                {option.label}
              </Text>
              <Pressable onPress={() => handlePress(option.value)}>
                <Icon
                  icon="x-circle"
                  size={alternative ? 14 : 20}
                  color={alternative ? colors.darkText : colors.white}
                />
              </Pressable>
            </HStack>
          </Box>
        ))}
      </HStack>
    </>
  );
};

export default OptionsLabelWrap;
