import {
  HStack,
  Pressable,
  Text,
  useBreakpointValue,
  VStack,
} from 'native-base';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

export interface FlatTabsProps {
  label: string;
  icon: string;
  subtitle: string;
  isActive?: boolean;
  onPress?: () => void;
}

export const FlatTab = (props: FlatTabsProps) => {
  const { icon, label, subtitle, isActive, onPress } = props;

  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  });

  return (
    <Pressable flex={1} onPress={onPress} w={'100%'}>
      <HStack
        bg={isActive ? 'singletons.white' : 'transparent'}
        borderTopRadius={12}
        borderBottomWidth={3}
        borderBottomColor={isActive ? 'singletons.black' : 'transparent'}
        backgroundColor={isActive ? colors.white : 'transparent'}
        alignItems={'center'}
        space={3}
        py={{ base: 2, md: 3 }}
        px={{ base: 0, md: 4 }}
      >
        {!isMobile && (
          <Icon
            icon={icon}
            color={isActive ? colors.darkText : colors.gray[400]}
            size={23}
          />
        )}

        <VStack
          flex={1}
          overflow={'hidden'}
          alignItems={{ base: 'center', md: 'flex-start' }}
          justifyContent={'center'}
        >
          <Text
            fontSize={{ base: 'sm', md: 'md' }}
            fontWeight={500}
            color={isActive ? colors.darkText : colors.gray[500]}
          >
            {label}
          </Text>

          {!isMobile && (
            <Text fontSize={'sm'} fontWeight={400} color={colors.gray[600]}>
              {subtitle}
            </Text>
          )}
        </VStack>
      </HStack>
    </Pressable>
  );
};

export default FlatTab;
