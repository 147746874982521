import { Input, Stack, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import Loading from 'ui/components/Loading';

import BreadCrumbs from '~/components/Breadcrumbs';
import ModalRemove from '~/components/ModalRemove';
import { Tab } from '~/components/Tabs';
import { PAGES } from '~/constants/pages.constants';
import { AddMemberInput } from '~/pages/Authenticated/Settings/Teams/components/AddMemberInput';
import { MembersList } from '~/pages/Authenticated/Settings/Teams/components/MembersList';

import { useEditTeamController } from './useEditTeamController';

export const EditTeam = () => {
  const {
    handleAddMember,
    handleRemoveMember,
    teamName,
    setTeamName,
    isLoading,
    isLoadingSubmit,
    isLoadingRemoveMember,
    handleSubmit,
    goBack,
    membersList,
    removeModalRef,
    mutateRemoveMember,
    handleCloseModal,
  } = useEditTeamController();
  return (
    <>
      <Stack mb={2}>
        <BreadCrumbs
          breadcrumbs={[
            { icon: 'users', label: `Teams`, path: PAGES.Teams },
            { icon: 'users', label: 'Edit Team' },
          ]}
        />
      </Stack>
      <Stack
        bg={{ base: 'transparent', md: 'white' }}
        flex={1}
        w="full"
        h={{ base: 'auto', md: 'full' }}
        flexDir="column"
        flexShrink={0}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <Stack display={{ base: 'none', md: 'flex' }}>
              <Tab
                key={'TeamsList'}
                label="Teams"
                description="Create and manage your teams"
                icon="users"
                isActive
              />
            </Stack>
            <Stack
              flex={1}
              direction={{ base: 'column', md: 'row' }}
              h={{ base: 'auto', md: 'full' }}
              px={{ base: 4, md: 6 }}
              flexShrink={0}
              pt={{ base: 0, lg: 6 }}
              space={{ base: 0, lg: 4 }}
            >
              <VStack
                flex={{ base: 'auto', md: 0.5 }}
                flexShrink={0}
                mb={{ base: 10, md: 0 }}
              >
                <VStack space={2} display={{ base: 'none', md: 'flex' }}>
                  <Text
                    fontSize={{ base: 'sm', md: 'xl' }}
                    fontWeight="medium"
                    color="gray.600"
                  >
                    Edit Team
                  </Text>
                  <Text fontSize="sm" color="gray.600">
                    Create a new team to apply for team activities
                  </Text>
                </VStack>

                <VStack space={2}>
                  <VStack pt={{ base: 0, lg: 8 }}>
                    <Text
                      mb="3"
                      fontSize="sm"
                      fontWeight="medium"
                      color="gray.600"
                    >
                      Name
                    </Text>
                    <Input
                      width={'100%'}
                      value={teamName}
                      placeholder="Enter the name of your new team"
                      onChange={(e: any) => setTeamName(e.target.value)}
                      isRequired
                      bg="white"
                    />
                  </VStack>
                </VStack>
              </VStack>
              <VStack h="full" flex={{ base: 'auto', md: 0.5 }} flexShrink={0}>
                <VStack space={2}>
                  <Text fontSize="xl" fontWeight="medium" color="gray.600">
                    Teammates
                  </Text>
                  <Text fontSize="sm" color="gray.600">
                    Invite teammates to join your team from your ecosystem
                  </Text>
                </VStack>
                <VStack>
                  <AddMemberInput
                    label="Find new teammates"
                    onAddMember={handleAddMember as any}
                  />
                  <MembersList
                    members={membersList}
                    onRemoveMember={handleRemoveMember}
                  />
                </VStack>
              </VStack>
            </Stack>
            <Stack
              position={{ base: 'fixed', lg: 'relative' }}
              bottom={{ base: 16, sm: 0 }}
              w="full"
              alignSelf="center"
              direction={{ base: 'column-reverse', md: 'row' }}
              p={{ base: 4, md: 6 }}
              space={4}
              justifyContent={{ base: 'space-between', md: 'center' }}
              bg="white"
            >
              <Button
                minW={{ base: 'full', md: '200px' }}
                w={{ base: 'full', md: '200px' }}
                minH="44px"
                variant="ghost"
                onPress={goBack}
              >
                <Text
                  color="singletons.darkText"
                  fontSize={{ base: 'md', md: 'sm' }}
                  fontWeight="medium"
                >
                  Cancel
                </Text>
              </Button>
              <Button
                isLoading={isLoadingSubmit}
                onPress={handleSubmit}
                minW={{ base: 'full', md: '200px' }}
                w={{ base: 'full', md: '200px' }}
                minH="44px"
                fontSize={{ base: 'md', md: 'sm' }}
              >
                <Text
                  color="white"
                  fontSize={{ base: 'md', md: 'sm' }}
                  fontWeight="medium"
                >
                  Edit team
                </Text>
              </Button>
            </Stack>
          </>
        )}
      </Stack>
      <ModalRemove
        modalRef={removeModalRef}
        headerText="Remove team member"
        handleCloseModal={handleCloseModal}
        handleDelete={mutateRemoveMember}
        rightBtnLoading={isLoadingRemoveMember}
        rightBtnText="Remove"
      >
        <Stack space={5}>
          <Text fontWeight={400} fontSize="md" color="gray.500">
            Are you sure that you want to remove this member of your team?
          </Text>

          <Text fontWeight={400} fontSize="md" color="gray.500">
            The member will no longer be able to partcipate in team events of
            this team.
          </Text>
        </Stack>
      </ModalRemove>
    </>
  );
};
