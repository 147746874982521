import { useEffect, useMemo, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';

import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import { SearchDtoValues } from '~/pages/Authenticated/InApp/components/SearchTopBar/types';
import { ActivitySearchSection } from '~/pages/Authenticated/InApp/Explorer/types';
import {
  modalReducer,
  MODALS_INITIAL_STATE,
  SORT_OPTIONS,
} from '~/pages/Authenticated/InApp/SearchView/constants';
import {
  MiniAppSearchViewProps,
  ModalsStateActionName,
  SortByType,
} from '~/pages/Authenticated/InApp/SearchView/types';
import { inAppSliceActions } from '~/store/slices/inApp';
import { AttendanceType } from '~/types/interfaces/activity';
import { generateUrlWithQueryParams } from '~/utils/transformURL';

export const useInAppSearchViewController = (props: MiniAppSearchViewProps) => {
  const {
    activityType,
    sortByOptions: sortByAttendanceOptions,
    searchController,
    onSearchSectionChange,
    sectionType,
  } = props;

  const { selectedApp, searchFilters } = useAppSelector((state) => state.inApp);
  const { goToRoute } = useRouter();
  const dispatch = useDispatch();

  const [tabIndex, setTabIndex] = useState(0);

  const [modalsState, dispatchModalsState] = useReducer(
    modalReducer,
    MODALS_INITIAL_STATE,
  );

  const attendanceType =
    tabIndex === 0 ? AttendanceType.Local : AttendanceType.Online;

  useEffect(() => {
    const expectedSortByOptions = sortByAttendanceOptions[attendanceType];
    dispatch(
      inAppSliceActions.setSearchFilters({ sortBy: expectedSortByOptions[0] }),
    );
  }, [attendanceType, dispatch]);

  const sortByOptions = useMemo(() => {
    return SORT_OPTIONS.filter((s) => {
      return (sortByAttendanceOptions?.[attendanceType] ?? []).includes(
        s.value,
      );
    });
  }, [sortByAttendanceOptions, attendanceType]);

  const sortByLabel = useMemo(() => {
    return (
      SORT_OPTIONS.find((s) => s.value == searchFilters.sortBy)?.label || ''
    );
  }, [searchFilters]);

  const searchControllerResult = searchController({
    activityType,
    attendanceType,
    appId: String(selectedApp?._id),
    isTeam: sectionType === ActivitySearchSection.TeamEvent,
  });

  const handleTabPress = (index: number) => {
    setTabIndex(index);
  };

  const closeModals = () => {
    dispatchModalsState({ name: '$CLS' });
  };

  const openModal = (name: ModalsStateActionName) => {
    dispatchModalsState({ name });
  };

  const setSortBy = (sortBy: SortByType) => {
    dispatch(inAppSliceActions.setSearchFilters({ sortBy }));
    closeModals();
  };

  const setCauses = (causes: Array<{ label: string; value: string }>) => {
    dispatch(inAppSliceActions.setSearchFilters({ causes }));
  };

  const handleSearchBarSubmit = (fields: SearchDtoValues) => {
    const newFilters: any = { search: fields.search };
    if (fields.causes) {
      newFilters.causes = fields.causes;
    }
    dispatch(inAppSliceActions.setSearchFilters(newFilters));
    if (fields.sectionType) {
      onSearchSectionChange(fields.sectionType);
    }
  };

  const handleActivityResultPress = (
    activityDefinitionId: string,
    activityId?: string,
  ) => {
    const appId = selectedApp?._id;
    const baseUrl = PAGES.ActivitiesDetails.replace(
      ':id',
      activityDefinitionId,
    );

    const finalUrl = generateUrlWithQueryParams(baseUrl, {
      selected: activityId,
      app: appId,
    });

    goToRoute(finalUrl);
  };

  return {
    tabIndex,
    handleTabPress,
    setSortBy,
    modalsState,
    closeModals,
    openModal,
    sortByLabel,
    filterValues: searchFilters,
    setCauses,
    handleActivityTypeChange: onSearchSectionChange,
    handleSearchBarSubmit,
    attendanceType,
    sortByOptions,
    handleActivityResultPress,
    selectedApp,
    ...searchControllerResult,
  };
};
