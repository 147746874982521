import { Avatar, HStack, Text } from 'native-base';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import ModalSignOut from '~/components/ModalSignOut';
import { optionGroups } from '~/components/NavBar/components/AccountDropdown/constants';
import { useAccountDropdownController } from '~/components/NavBar/components/AccountDropdown/controller';
import { AccountPopOverOptions } from '~/components/NavBar/components/AccountDropdown/popover';
import { RootPortal } from '~/components/RootPortal';

export const AccountDropdown = () => {
  const {
    modalRef,
    userParsedData,
    handleCloseSignOutModal,
    handleOptionPress,
  } = useAccountDropdownController();

  return (
    <>
      <AccountPopOverOptions
        optionGroups={optionGroups}
        onPress={handleOptionPress}
      >
        <HStack
          px={2}
          py={2}
          pr={3}
          borderWidth={1}
          borderColor={colors.gray[200]}
          borderRadius={'24px'}
          alignItems={'center'}
          space={2}
        >
          <Avatar
            source={{
              uri: userParsedData?.userAvatar,
            }}
            w={'32px'}
            h={'32px '}
            borderRadius={'full'}
          >
            {userParsedData?.userInitials}
          </Avatar>
          <Text fontWeight={500} fontSize={'sm'} marginRight={1}>
            My account
          </Text>
          <Icon icon="chevron-down" size={'16px'} color={colors.gray[500]} />
        </HStack>
      </AccountPopOverOptions>

      <RootPortal>
        <ModalSignOut modalRef={modalRef} onClose={handleCloseSignOutModal} />
      </RootPortal>
    </>
  );
};
