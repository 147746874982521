import { Box, FormControl, Image, Stack, Text, VStack } from 'native-base';
import Dropzone from 'react-dropzone';
import { Platform } from 'react-native';
import Button from 'ui/components/Button';
import FormError from 'ui/components/Form/FormError';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import useDropzoneController from '../../Dropzone/controller';
import { CircleZoneProps } from './types';
const CircleZone = ({
  btnIconColor = colors.primary[600],
  btnLabelColor = 'primary.600',
  btnBgColor = 'primary.50',
  btnLabel = 'Upload image',
  acceptedHeight = 430,
  acceptedWidth = 1200,
  title,
  titleColor = 'gray.600',
  errorMessage,
  isRequired,
  isDisabled,
  isInvalid,
  isReadOnly,
  hasError,
  iconErrorMessage,
  circleHeight = '128px',
  circleWidth = '128px',
  iconErrorSize,
  leftIconName = 'upload',
  filesAccepted = { 'image/jpeg': [], 'image/png': [], 'image/jpg': [] },
  ...restProps
}: CircleZoneProps) => {
  const {
    showTitle,
    acceptedSize,
    onDropAccepted,
    onDropRejected,
    previewImage,
  } = useDropzoneController({
    ...restProps,
    acceptedWidth,
    acceptedHeight,
  });

  return (
    <Dropzone
      accept={filesAccepted}
      onDropAccepted={onDropAccepted}
      onDropRejected={onDropRejected}
    >
      {({ getRootProps, getInputProps, open }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Stack space={3}>
            <FormControl
              isInvalid={hasError || errorMessage ? true : isInvalid}
              isDisabled={isDisabled}
              isRequired={isRequired}
              isReadOnly={isReadOnly}
            >
              <VStack>
                {showTitle ? (
                  <Box>
                    {title ? (
                      <FormControl.Label>
                        <Text
                          color={titleColor}
                          fontSize={14}
                          mb={3}
                          fontWeight="500"
                        >
                          {title}
                        </Text>
                      </FormControl.Label>
                    ) : null}
                  </Box>
                ) : null}
                <Box
                  borderColor="muted.200"
                  borderWidth={2}
                  rounded="full"
                  w={circleWidth}
                  h={circleHeight}
                  justifyContent="center"
                  alignItems="center"
                  mb={1}
                  alignSelf="center"
                >
                  <Box bgColor="white">
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      space="2"
                      style={{
                        display: 'flex',
                        ...Platform.select({
                          web: {
                            cursor: 'pointer',
                          },
                        }),
                      }}
                    >
                      {previewImage ? (
                        <Image
                          borderRadius="full"
                          width="100%"
                          borderColor="muted.200"
                          borderWidth={2}
                          height="100%"
                          src={previewImage as string}
                          size="xl"
                          alt="Image preview"
                        />
                      ) : (
                        <>
                          <Icon
                            color={colors.gray[300]}
                            size="24"
                            icon="image"
                          />
                          <Stack alignItems="center">
                            <Text
                              color="gray.600"
                              fontSize="2xs"
                              fontWeight="400"
                            >
                              Dimensions {acceptedWidth}x{acceptedHeight}
                            </Text>
                            <Text
                              color="gray.600"
                              fontSize="2xs"
                              fontWeight="400"
                            >
                              (Max {acceptedSize}MB)
                            </Text>
                          </Stack>
                        </>
                      )}
                    </Stack>
                  </Box>
                </Box>
                <Stack mt="2">
                  <Button
                    leftIconName={leftIconName}
                    leftIconSize={16}
                    leftIconColor={btnIconColor}
                    bgColor={btnBgColor}
                    onPress={open}
                  >
                    <Text color={btnLabelColor} fontSize="xs" fontWeight="500">
                      {btnLabel}
                    </Text>
                  </Button>
                </Stack>
              </VStack>

              <FormError
                errorMessage={errorMessage}
                iconErrorMessage={iconErrorMessage}
                iconErrorSize={iconErrorSize}
              />
            </FormControl>
          </Stack>
        </div>
      )}
    </Dropzone>
  );
};

export default CircleZone;
