import { UseInfiniteQueryResult } from 'react-query';

import { IPagination } from '~/hooks/useInfinityActivitySearch/types';
import { IActivityDefinition } from '~/types/interfaces/activity';

export const serializeResponse = (
  response: UseInfiniteQueryResult<IPagination<IActivityDefinition[]>>,
) => {
  const serializedData = (response?.data?.pages ?? []).flatMap(
    (page) => page.data,
  );
  response.data = serializedData as any;
  return response;
};
