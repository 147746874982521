import { VStack } from 'native-base';

import { CommonContainer } from './CommonContainer';

export const PageScaffold = ({ children }: any) => {
  return (
    <VStack
      flex={1}
      minH={'full'}
      w="full"
      h="full"
      pt={{ base: 3, sm: 8 }}
      pb={{ base: 0, md: 14 }}
      overflow="hidden"
    >
      <CommonContainer>{children}</CommonContainer>;
    </VStack>
  );
};
