import Yup from '~/utils/validations/yup';

export const measurementsFieldsSchemaValidation = Yup.object().shape({
  amount: Yup.number(),
  measurementUnitId: Yup.string(),
  _id: Yup.string().optional(),
});

export const schemaValidation = Yup.object().shape({
  measurementDate: Yup.date().required(),
  measurements: Yup.array().of(measurementsFieldsSchemaValidation),
});
