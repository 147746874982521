import { EEventApplicationType } from 'ui/enums';
import { ActivitySemanticType, ActivityType } from 'ui/types/activities';

export function mapToActivitySemanticType(
  activityType: ActivityType,
  applicationType?: EEventApplicationType,
) {
  return applicationType === EEventApplicationType.Team
    ? ActivitySemanticType.TeamEvent
    : (activityType as unknown as ActivitySemanticType);
}
