import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { DEFAULT_APP_ID } from '~/config';
import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import { IAppPopoverOption } from '~/pages/Authenticated/InApp/components/AppSwitcher/types';
import { inAppSliceActions } from '~/store/slices/inApp';

export const useInAppNavBarController = () => {
  // -- Providers
  const { isLoading: isContentLoading } = useAppSelector(
    (state) => state.inApp,
  );
  const dispatch = useDispatch();
  const { goToRoute } = useRouter();
  const { appId } = useParams();

  // -- Hooks
  useEffect(() => {
    dispatch(inAppSliceActions.setIsLoading(false));
  }, [appId]);

  // -- Handlers
  const onMenuOptionPress = (option: IAppPopoverOption) => {
    if (option.key === appId) return;

    dispatch(inAppSliceActions.setIsLoading(true));
    goToRoute(PAGES.InAppRoot.replace(':appId', option.key || DEFAULT_APP_ID), {
      state: {
        logo: option.logo,
        name: option.label,
      },
    });
  };

  return { onMenuOptionPress, isContentLoading };
};
