import { ActivityType } from 'ui/types/activities';
import { ILocation } from 'ui/types/interfaces';
import { formatDateToEnGbLocale } from 'ui/utils/formatDates';

import { ActivityCardProps } from '~/pages/Authenticated/InApp/components/ActivityCard/types';
import { SortByType } from '~/pages/Authenticated/InApp/SearchView/types';
import { getCoverImageFromActivityDefinition } from '~/pages/Authenticated/InApp/utils/activity-utils';
import { getActivitySummaryByRelevance } from '~/pages/Authenticated/InApp/utils/processActivitySummaries';
import { IActivityDefinition } from '~/types/interfaces/activity';

export const MapActivityToActivityCard =
  (location: ILocation | null = null) =>
  (
    activityDefinition: IActivityDefinition,
  ): Pick<ActivityCardProps, 'title' | 'distance' | 'date' | 'image'> => {
    const type = activityDefinition.type as ActivityType;

    const activitiesSummary = activityDefinition.activitiesSummary ?? [];

    let activity;
    if (activitiesSummary.length > 0) {
      activity = getActivitySummaryByRelevance(
        activitiesSummary,
        type,
        location,
        SortByType.NEAREST,
      );
    }

    const distance = activity?.distance ?? -1;
    const dateStr = activity?.startDate
      ? formatDateToEnGbLocale(new Date(activity.startDate))
      : '';

    return {
      title: activityDefinition?.title || '',
      distance,
      date: dateStr,
      image: getCoverImageFromActivityDefinition(activityDefinition),
    };
  };
