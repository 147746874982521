import { Avatar, Box, HStack, Pressable, Text } from 'native-base';
import Icon from 'ui/components/Icon';

import { MobileHeaderProps } from '~/pages/Authenticated/InApp/components/MobileHeader/types';
import { colors } from '~/theme/colors';
import { getNameInitials } from '~/utils/getInitials';

import { Sidebar } from '../Sidebar';
import { useMobileHeaderController } from './controller';

export const MobileHeader = (props: MobileHeaderProps) => {
  const {
    children,
    backButtonIcon = 'chevron-left',
    backButtonTitle = '',
    backButtonIconSize = 16,
  } = props;

  const { selectedApp, isMenuOpen, goBack, closeMenu } =
    useMobileHeaderController(props);

  return (
    <>
      {isMenuOpen ? <Sidebar onClose={closeMenu} /> : null}

      <HStack
        bgColor={colors.singletons.lightText}
        padding={4}
        px={{ base: 2, md: 4 }}
        justifyContent={'space-between'}
      >
        {/* LEFT SECTION */}
        <HStack
          space={3}
          alignItems="center"
          justifyContent={'flex-start'}
          w={{ base: '10%', sm: '16%' }}
          maxW="76px"
          minW={'fit-content'}
        >
          <Pressable px={2} h="full" onPress={goBack}>
            <HStack h={'full'} alignItems={'center'} space={2}>
              <Icon icon={backButtonIcon} size={backButtonIconSize} />
              <Text fontSize={'xs'} fontWeight={500} color={colors.darkText}>
                {backButtonTitle}
              </Text>
            </HStack>
          </Pressable>
        </HStack>

        {/* MIDDLE */}
        <>
          {children ? (
            <Box>{children && children}</Box>
          ) : (
            <HStack alignItems={'center'} space={2} overflow={'hidden'}>
              <Avatar
                borderWidth={1}
                borderColor="gray.300"
                source={{ uri: selectedApp?.logo || '' }}
                width={10}
                height={10}
                rounded={10}
                _image={{ rounded: 10 }}
              >
                {getNameInitials(selectedApp?.name || '')}
              </Avatar>
              <Text
                fontSize="lg"
                fontWeight={500}
                color="gray.600"
                noOfLines={1}
              >
                {selectedApp?.name || ''}
              </Text>
            </HStack>
          )}
        </>

        {/* RIGHT */}
        <Box w={{ base: '10%', sm: '16%' }} />
      </HStack>
    </>
  );
};
