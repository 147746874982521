import { Box, HStack, Text, VStack } from 'native-base';
import { Controller } from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { ActivityAutocompleteProps } from '~/pages/Authenticated/InApp/components/ActivityAutoComplete/types';

import { useActivityAutocompleteController } from './controller';

export const ActivityAutocomplete = (props: ActivityAutocompleteProps) => {
  const {
    control,
    name,
    label,
    placeholder,
    defaultValue,
    isSearchable = true,
    showTypeLabel = false,
    onClear,
  } = props;

  const { defaultStyle, loadOptions, selectedActivityTypeLabel } =
    useActivityAutocompleteController(props);

  const EmptyIndicator = () => <></>;
  return (
    <>
      <HStack alignItems={'center'} pb={2} space={2}>
        {label ? (
          <Text fontWeight={500} fontSize={'sm'} color={colors.gray[600]}>
            {label}
          </Text>
        ) : null}
        {showTypeLabel && (
          <Text fontWeight={400} fontSize={'xs'} color={colors.gray[500]}>
            in {selectedActivityTypeLabel}
          </Text>
        )}
      </HStack>

      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange, ...restField } }) => {
          const handleChange = (newValue: any) => {
            if (!newValue) {
              onChange({ value: defaultValue });
              onClear && onClear();
              return;
            }
            onChange(newValue);
          };
          return (
            <HStack alignItems={'center'} w="100%">
              <Box position={'absolute'} left={'12px'} top={'16px'} zIndex={9}>
                <Icon icon="search" size={'16px'} color={colors.gray[400]} />
              </Box>
              <Box flex={1}>
                <AsyncSelect
                  {...restField}
                  value={value}
                  onChange={handleChange}
                  menuPortalTarget={document.body}
                  styles={defaultStyle}
                  placeholder={placeholder}
                  loadOptions={loadOptions}
                  isClearable={(value?.value[1] || '').length > 0}
                  isSearchable={isSearchable}
                  components={{
                    DropdownIndicator: EmptyIndicator,
                    IndicatorSeparator: EmptyIndicator,
                  }}
                  formatOptionLabel={({ label, value }, meta) => {
                    const [_, textValue] = value;
                    if (meta.context === 'value') {
                      const isEmpty = (textValue || '').length == 0;
                      return (
                        <Text
                          fontSize={'sm'}
                          fontWeight={200}
                          color={isEmpty ? colors.gray[400] : colors.darkText}
                        >
                          {textValue || placeholder}
                        </Text>
                      );
                    }
                    return (
                      <HStack py={1} px={1} space={4} alignItems={'center'}>
                        <Icon
                          icon="search"
                          size={'16px'}
                          color={colors.gray[400]}
                        />
                        <VStack>
                          <Text
                            color={colors.gray[800]}
                            fontSize={'sm'}
                            fontWeight={500}
                          >
                            {textValue}
                          </Text>
                          <Text color={colors.gray[600]} fontSize={'xs'}>
                            in {label}
                          </Text>
                        </VStack>
                      </HStack>
                    );
                  }}
                />
              </Box>
            </HStack>
          );
        }}
      />
    </>
  );
};
