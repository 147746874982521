import { useMemo } from 'react';
import { EEventApplicationType } from 'ui/enums';

import { useAppSelector } from '~/hooks/useAppSelector';
import { useQuery } from '~/hooks/useQuery';
import { useUserLocationController } from '~/hooks/useUserLocation/useUsersLocation';
import { SortByType } from '~/pages/Authenticated/InApp/SearchView/types';
import { getStringifiedPosition } from '~/pages/Authenticated/InApp/utils/locationToString';
import { IActivityDefinition } from '~/types/interfaces/activity';

export const useLoadOngoingNearYou = () => {
  const { selectedApp } = useAppSelector(({ inApp }) => inApp);
  const { location } = useUserLocationController();

  const filter = useMemo(() => {
    if (!selectedApp?._id) return null;

    const params: { [key: string]: unknown } = {
      relevance: SortByType.NEAREST,
      apps: JSON.stringify([selectedApp._id]),
      eventApplicationTypes: JSON.stringify([EEventApplicationType.Individual]),
      limit: 1,
      position: getStringifiedPosition(location),
    };

    return params;
  }, [location, selectedApp]);

  const { data, isLoading } = useQuery<IActivityDefinition[]>(
    `/activity-definition-public/search/ongoing-opportunity/in-person`,
    {
      key: `eventsNearYou-${JSON.stringify(filter)}`,
      queryOptions: {
        enabled: !!filter,
      },
      requestOptions: {
        params: filter,
      },
    },
  );

  return { data, isLoading };
};
