import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import { SideBarProps } from '~/pages/Authenticated/InApp/components/Sidebar/types';

export const menuList = [
  { text: 'Discover', href: `#discover` },
  { text: 'Near your location', href: '#nearToYou' },
  { text: 'About', href: '#about' },
];

export const useSideBarController = ({ onClose }: SideBarProps) => {
  const { goToRoute } = useRouter();

  const { user } = useAppSelector(({ auth }) => auth);
  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);
  const { selectedApp } = useAppSelector(({ inApp }) => inApp);

  const onMenuOptionPress = (href: string) => {
    goToRoute(PAGES.InAppRoot.replace(':appId', selectedApp?._id || ''));
    window.location.href = href;
    onClose();
  };

  const gotoMyWallet = () => {
    goToRoute(PAGES.AllApps);
  };

  return { user, selectedEcosystem, gotoMyWallet, onMenuOptionPress };
};
