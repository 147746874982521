import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { REDUX_KEY, REDUX_WHITELIST } from '~/constants/redux.constants';
import { authSliceReducer } from '~/store/slices/auth';
import { ecosystemSliceReducer } from '~/store/slices/ecosystem';
import { forgotPasswordSliceReducer } from '~/store/slices/forgotPassword';
import { inAppSliceReducer } from '~/store/slices/inApp';
import { measurementsDataSliceReducer } from '~/store/slices/measurementsData';
import { notificationSliceReducer } from '~/store/slices/notification';
import { permissionsSliceReducer } from '~/store/slices/permissions';
import { redirectSliceReducer } from '~/store/slices/redirect';

export const combinedReducers = combineReducers({
  auth: authSliceReducer,
  forgotPassword: forgotPasswordSliceReducer,
  ecosystem: ecosystemSliceReducer,
  redirect: redirectSliceReducer,
  measurementsData: measurementsDataSliceReducer,
  permissions: permissionsSliceReducer,
  inApp: inAppSliceReducer,
  notification: notificationSliceReducer,
});

export const persistedReducers = persistReducer(
  {
    key: REDUX_KEY,
    storage,
    whitelist: REDUX_WHITELIST,
  },
  combinedReducers,
);
