import {
  Box,
  Hidden,
  HStack,
  Image,
  Pressable,
  Skeleton,
  Stack,
  Text,
  VStack,
} from 'native-base';
import React from 'react';
import placeholderImage from 'ui/assets/svgs/placeholder.svg';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import ActivityTypeBadge from 'ui/components/v2/Badges/ActivityTypeBadge';
import { EEventApplicationType } from 'ui/enums';
import { ActivityType } from 'ui/types/activities';

import DistanceLabel from '~/pages/Authenticated/InApp/components/SearchResultItem/fragments/DistanceLabel';
import { SearchResultItemProps } from '~/pages/Authenticated/InApp/components/SearchResultItem/types';
import { colors } from '~/theme/colors';

export const SearchResultItem = (props: SearchResultItemProps) => {
  const { data, onPress } = props;

  const getActivityCardButtonText = (
    activityType: ActivityType,
    applicationType?: EEventApplicationType,
  ) => {
    const buttonTexts = {
      [`${ActivityType.Ongoing}`]: 'Apply',
      [`${ActivityType.Action}`]: 'Join action',
      [`${ActivityType.Event}`]:
        applicationType === EEventApplicationType.Team
          ? 'Register for team event'
          : 'Register for this event',
    };

    return buttonTexts[`${activityType}`];
  };

  return (
    <Pressable p={4} onPress={onPress}>
      <HStack
        flex={1}
        w="full"
        space={3}
        backgroundColor={{ md: colors.gray[100] }}
        borderRadius={8}
        borderWidth={{ md: 1 }}
        borderColor={colors.gray[200]}
        alignItems={'center'}
      >
        <Box
          w={{ base: 24, md: '202' }}
          h={{ base: 24, md: '150px' }}
          borderLeftRadius={8}
          borderRightRadius={{ base: 8, md: 0 }}
          backgroundColor={colors.gray[500]}
          overflow={'hidden'}
        >
          <Stack width="100%" height="100%">
            <Skeleton width="100%" height="100%" />
          </Stack>

          <Image
            source={{ uri: data.thumbnailImage || '' }}
            size={12}
            w={'full'}
            h="full"
            resizeMode="cover"
            alt={`Logo from activity ${data.title}`}
            fallbackSource={{ uri: placeholderImage }}
          />
        </Box>
        <VStack space={{ base: 2, md: 3 }} flex={1} p={{ base: 0, md: 4 }}>
          <HStack
            justifyContent={{ base: 'space-between', md: 'flex-start' }}
            space={3}
          >
            <ActivityTypeBadge activityType={data.semanticType} />
            {!data.hideDistance && <DistanceLabel distance={data.distance} />}
          </HStack>

          <VStack>
            <Text
              fontSize={{ base: 'sm', md: 'lg' }}
              fontWeight={500}
              color={colors.darkText}
              noOfLines={1}
              lineHeight={'140%'}
            >
              {data.title}
            </Text>

            <Text
              fontSize={{ base: 'xs', md: 'sm' }}
              fontWeight={400}
              color={colors.gray[600]}
              noOfLines={1}
              lineHeight={'125%'}
            >
              by {data.organizationName}
            </Text>
          </VStack>

          <HStack alignItems={'center'} space={1}>
            {data.startDate && (
              <>
                <Icon icon="calendar" size={16} color={colors.gray[600]} />
                <Text
                  fontSize={{ base: 'xs', md: 'md' }}
                  fontWeight={500}
                  color={colors.gray[600]}
                  noOfLines={1}
                  lineHeight={'125%'}
                >
                  {data.startDate}
                </Text>
              </>
            )}
          </HStack>
        </VStack>
        <Hidden till="lg">
          <Box position={'absolute'} top={4} right={5}>
            <Button
              px={{ lg: 6, xl: 8 }}
              h={9}
              minW={'unset'}
              variant="solid"
              backgroundColor={colors.black}
              onPress={onPress}
            >
              <Text color={colors.white} fontSize={'sm'} fontWeight="medium">
                {getActivityCardButtonText(data.type, data?.applicationType)}
              </Text>
            </Button>
          </Box>
        </Hidden>
      </HStack>
    </Pressable>
  );
};

export default React.memo(SearchResultItem);
