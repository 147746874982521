import { is } from 'date-fns/locale';
import { useMemo, useRef } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import TeamService from '~/services/resources/teams';

interface ModalInfo {
  modalTitle: string;
  text: string;
  description: string;
  buttonText: string;
}

export const useDeleteOrLeaveTeamFlow = ({
  teamId,
  isLeader,
  isViewDetails,
  onRemoveTeam,
}: {
  teamId: string;
  isLeader?: boolean;
  isViewDetails?: boolean;
  onRemoveTeam?: () => void;
}) => {
  const { goBack } = useRouter();
  const deleteTeamModalRef = useRef<IModalRefProps>();
  const { selectedUserProfile } = useAppSelector(({ auth }) => auth);

  const { mutate: mutateLeaveTeam, isLoading: isLoadingLeaveTeam } =
    useMutation({
      mutationFn: async () =>
        await TeamService.leaveTeam(teamId, selectedUserProfile?._id as string),
      onSuccess: () => {
        handleMutateSuccess();
      },
      onError: (error: any) => {
        toast.error(error.message);
      },
    });

  const { mutate: mutateDeleteTeam, isLoading: isLoadingDeleteTeam } =
    useMutation({
      mutationFn: async () => await TeamService.deleteTeam(teamId),
      onSuccess: () => {
        handleMutateSuccess();
      },
      onError: (error: any) => {
        toast.error(error.message);
      },
    });

  const handleMutateSuccess = () => {
    handleCloseModal();
    if (isLeader) {
      toast.success('Team deleted successfully');
    } else {
      toast.success('You have left the team successfully');
    }
    if (isViewDetails) {
      goBack();
    }
    onRemoveTeam && onRemoveTeam();
  };

  const handleModalSubmit = () => {
    if (isLeader) {
      mutateDeleteTeam();
    } else {
      mutateLeaveTeam();
    }
  };

  const handleCloseModal = () => {
    deleteTeamModalRef.current?.close();
  };

  const handleOpenModal = () => {
    deleteTeamModalRef.current?.open();
  };

  const modalInfo: ModalInfo = useMemo(() => {
    if (isLeader) {
      return {
        modalTitle: 'Delete team',
        text: 'Are you sure you want to delete this team?',
        description:
          'This action is irreversible. This team will be removed of all applied team events and all the members of the team will be unable to participate in the activities.',
        buttonText: 'Delete',
      };
    }
    return {
      modalTitle: 'Leave team',
      text: 'Are you sure you want to leave this team?',
      description:
        'This action is irreversible. After leaving, you will be unable to participate in the activities of this team.',
      buttonText: 'Leave',
    };
  }, [isLeader]);

  const isLoading = useMemo(
    () => isLoadingDeleteTeam || isLoadingLeaveTeam,
    [isLoadingDeleteTeam, isLoadingLeaveTeam],
  );

  return {
    deleteTeamModalRef,
    handleCloseModal,
    handleOpenModal,
    modalInfo,
    handleModalSubmit,
    isLoading,
  };
};
