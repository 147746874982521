import { HStack, Image, Stack, Text } from 'native-base';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { DEFAULT_APP_ID } from '~/config';
import { useAppSwitcherController } from '~/pages/Authenticated/InApp/components/AppSwitcher/controller';
import { AppSwitcherPopOver } from '~/pages/Authenticated/InApp/components/AppSwitcher/popover';
import { AppSwitcherProps } from '~/pages/Authenticated/InApp/components/AppSwitcher/types';

export const AppSwitcher = (props: AppSwitcherProps) => {
  const { selectedApp, appOptions, handleOptionPress } =
    useAppSwitcherController(props);

  return (
    <>
      <AppSwitcherPopOver
        options={appOptions}
        onPress={handleOptionPress}
        selected={selectedApp?._id || ''}
      >
        <HStack space={3} alignItems={'center'} minW={'258px'}>
          <Image
            source={{ uri: selectedApp?.logo || '' }}
            w={{ base: 10, md: 12 }}
            h={{ base: 10, md: 12 }}
            alt={`App Logo`}
            borderWidth={1}
            borderColor={colors.gray[300]}
            borderRadius={'12px'}
          />
          <Stack space={0.5}>
            <Text
              lineHeight={'125%'}
              fontSize={{ base: 'lg', md: 'xl' }}
              fontWeight={{ base: 500, md: 700 }}
              color={{ base: colors.gray[600], md: colors.darkText }}
            >
              {selectedApp?._id === DEFAULT_APP_ID
                ? 'The Big Help Out'
                : selectedApp?.name || ''}
            </Text>
          </Stack>

          <Icon
            icon="chevron-down"
            size={'16px'}
            color={selectedApp?.brandColor || colors.gray[500]}
          />
        </HStack>
      </AppSwitcherPopOver>
    </>
  );
};

export default AppSwitcher;
