import {
  Box,
  Hidden,
  HStack,
  Pressable,
  Stack,
  Text,
  VStack,
} from 'native-base';
import { useMemo } from 'react';
import Icon from 'ui/components/Icon';
import Loading from 'ui/components/Loading';
import { colors } from 'ui/theme/colors';

import MobileTrayDrawer from '~/components/TrayDrawer';
import { PAGES } from '~/constants/pages.constants';
import { ActivityTypeDecorator } from '~/pages/Authenticated/InApp/components/ActivityTypeDecotaror';
import { MobileHeader } from '~/pages/Authenticated/InApp/components/MobileHeader';
import {
  ActivityTypeInfoMap,
  sectionTypeList,
} from '~/pages/Authenticated/InApp/Explorer/constants';
import { useExplorerController } from '~/pages/Authenticated/InApp/Explorer/controller';
import InAppSearchView from '~/pages/Authenticated/InApp/SearchView';

export const Explorer = () => {
  const {
    showSectionSelectModal,
    causeSelectOptions,
    sectionConfig,
    sectionType,
    isLoading,
    selectedApp,
    handleSearchSectionChange,
    toggleSectionSelectModal,
  } = useExplorerController();

  const sectionInfo = useMemo(() => {
    return ActivityTypeInfoMap.get(sectionType);
  }, [sectionType]);

  const backPath = PAGES.InAppRoot.replace(':appId', selectedApp?._id || '');

  if (!sectionType || !sectionConfig || isLoading)
    return <Loading color={selectedApp?.brandColor} />;

  return (
    <>
      <Hidden from="md">
        <MobileHeader backButtonTitle="Back" backButtonPath={backPath}>
          <Stack flex={1} alignItems={'center'}>
            <Pressable onPress={toggleSectionSelectModal}>
              <HStack
                py={1.5}
                px={4}
                borderWidth={1}
                borderRadius={100}
                borderColor={colors.gray[200]}
                alignItems={'center'}
                space={2}
              >
                <Text fontSize={'md'} fontWeight={500} color={colors.gray[600]}>
                  {sectionInfo?.text}
                </Text>
                <Icon
                  icon="chevron-down"
                  size={16}
                  color={selectedApp?.brandColor || colors.primary[400]}
                />
              </HStack>
            </Pressable>
          </Stack>
        </MobileHeader>
      </Hidden>

      <Box>
        <InAppSearchView
          sectionType={sectionType}
          causeOptions={causeSelectOptions}
          activityType={sectionConfig.activityType}
          searchController={sectionConfig.useController}
          filterWhiteList={sectionConfig.filterWhiteList}
          sortByOptions={sectionConfig.sortByOptions}
          onSearchSectionChange={handleSearchSectionChange}
        />
      </Box>

      {/* type tray */}

      <Hidden from="md">
        <MobileTrayDrawer
          title="Search for"
          isOpen={showSectionSelectModal}
          onClose={toggleSectionSelectModal}
        >
          <HStack
            justifyContent={'space-between'}
            flexWrap={'wrap'}
            w="full"
            maxW={482}
            margin={'auto'}
            px={4}
            py={4}
            style={{ rowGap: 16 }}
          >
            {sectionTypeList.map(({ type, label, icon }) => {
              return (
                <Pressable
                  key={type}
                  onPress={() => handleSearchSectionChange(type)}
                >
                  <VStack
                    w={100}
                    h={82}
                    alignItems={'center'}
                    justifyContent={'center'}
                    space={2}
                    overflow={'hidden'}
                  >
                    <ActivityTypeDecorator icon={icon} size="lg" />
                    <Text
                      fontSize={'sm'}
                      lineHeight={'150%'}
                      fontWeight={500}
                      color={colors.gray[600]}
                      textAlign={'center'}
                    >
                      {label}
                    </Text>
                  </VStack>
                </Pressable>
              );
            })}
          </HStack>
        </MobileTrayDrawer>
      </Hidden>
    </>
  );
};

export default Explorer;
