import { IconEnum } from 'ui/components/Icon';
import { TagIcon } from 'ui/components/Tags/Tag/types';
import { ActivityType } from 'ui/types/activities';

import { useActionActivityController } from '~/pages/Authenticated/InApp/Explorer/controllers/action-activity-controller';
import { useEventActivityController } from '~/pages/Authenticated/InApp/Explorer/controllers/event-activity-controller';
import { useOngoinhActivityController } from '~/pages/Authenticated/InApp/Explorer/controllers/ongoing-activity-controller';
import {
  ActivitySearchSection,
  SectionConfig,
  SortByAttendanceTypeMap,
} from '~/pages/Authenticated/InApp/Explorer/types';
import {
  FilterWhiteList,
  SortByType,
} from '~/pages/Authenticated/InApp/SearchView/types';
import { AttendanceType } from '~/types/interfaces/activity';

export const ALLOWED_ACTIVITY_SEARCH_SECTION = Object.values(
  ActivitySearchSection,
);

// -- Filters WhiteList

export const FilterWhiteListManifest: Record<ActivityType, FilterWhiteList> = {
  [ActivityType.Event]: {
    distanceRadius: {
      [AttendanceType.Local]: true,
      [AttendanceType.Online]: false,
    },
    withinArea: {
      [AttendanceType.Local]: false,
      [AttendanceType.Online]: true,
    },
    showOnline: {
      [AttendanceType.Local]: false,
      [AttendanceType.Online]: true,
    },
    sortBy: true,
    dateRange: true,
    teamSize: true,
  },

  [ActivityType.Ongoing]: {
    distanceRadius: {
      [AttendanceType.Local]: true,
      [AttendanceType.Online]: false,
    },
    withinArea: {
      [AttendanceType.Local]: false,
      [AttendanceType.Online]: true,
    },
    showOnline: {
      [AttendanceType.Local]: false,
      [AttendanceType.Online]: true,
    },
    sortBy: true,
    dateRange: false,
    teamSize: false,
  },

  [ActivityType.Action]: {
    sortBy: true,
    showOnline: true,
    dateRange: false,
    distanceRadius: false,
    teamSize: false,
    withinArea: true,
  },
};

//  -- SortBy options

export const eventSortbyOption: SortByAttendanceTypeMap = {
  [AttendanceType.Local]: [
    SortByType.NEAREST,
    SortByType.UPCOMING,
    SortByType.POPULAR,
    SortByType.NEWEST,
  ],
  [AttendanceType.Online]: [
    SortByType.NEWEST,
    SortByType.UPCOMING,
    SortByType.POPULAR,
  ],
};
export const ongoingSortbyOption: SortByAttendanceTypeMap = {
  [AttendanceType.Local]: [
    SortByType.NEAREST,
    SortByType.POPULAR,
    SortByType.NEWEST,
  ],
  [AttendanceType.Online]: [SortByType.NEWEST, SortByType.POPULAR],
};

export const actionSortbyOption: SortByAttendanceTypeMap = {
  [AttendanceType.Local]: [SortByType.NEWEST, SortByType.POPULAR],
  [AttendanceType.Online]: [SortByType.NEWEST, SortByType.POPULAR],
};
// -- Section Config Map

export const SectionConfigMap: Record<ActivitySearchSection, SectionConfig> = {
  [ActivitySearchSection.Action]: {
    activityType: ActivityType.Action,
    useController: useActionActivityController,
    filterWhiteList: FilterWhiteListManifest.Action,
    sortByOptions: actionSortbyOption,
    isTeam: false,
  },
  [ActivitySearchSection.Event]: {
    activityType: ActivityType.Event,
    useController: useEventActivityController,
    filterWhiteList: FilterWhiteListManifest.Event,
    sortByOptions: eventSortbyOption,
    isTeam: false,
  },
  [ActivitySearchSection.TeamEvent]: {
    activityType: ActivityType.Event,
    useController: useEventActivityController,
    filterWhiteList: FilterWhiteListManifest.Event,
    sortByOptions: eventSortbyOption,
    isTeam: true,
  },
  [ActivitySearchSection.Ongoing]: {
    activityType: ActivityType.Ongoing,
    useController: useOngoinhActivityController,
    filterWhiteList: FilterWhiteListManifest.OngoingOpportunity,
    sortByOptions: ongoingSortbyOption,
    isTeam: false,
  },
};

// --

export type ActivityTypeInfo = {
  icon: TagIcon | IconEnum | string;
  text: string;
};

export const ActivityTypeInfoMap = new Map<
  ActivitySearchSection,
  ActivityTypeInfo
>([
  [ActivitySearchSection.Event, { text: 'Events', icon: 'calendar' }],
  [ActivitySearchSection.TeamEvent, { text: 'Team Events', icon: 'users' }],
  [
    ActivitySearchSection.Action,
    {
      text: 'Actions',
      icon: 'hand-pointer',
    },
  ],
  [
    ActivitySearchSection.Ongoing,
    {
      text: 'Ongoing',
      icon: 'repeat',
    },
  ],
]);

export const sectionTypeList = Array.from(
  ActivityTypeInfoMap,
  ([key, value]) => ({
    type: key,
    label: value.text,
    icon: value.icon,
  }),
);
