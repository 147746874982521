import { useMediaQuery } from 'native-base';
import { useMemo } from 'react';
import { IOptions } from 'ui/components/PopOverOptions/types';
import { Team } from 'ui/types/teams';
import { getTeamMembersTotal } from 'ui/utils/teams';

import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useDeleteOrLeaveTeamFlow } from '~/hooks/useDeleteOrLeaveTeamFlow';
import { useRouter } from '~/hooks/useRouter';
import { TeamCardProps } from '~/pages/Authenticated/Settings/Teams/TeamsList/components/TeamCard/types';
import { colors } from '~/theme/colors';
import { TeamMemberStatus } from '~/types/interfaces/team';
import { getLeaderAndMembersByStatus } from '~/utils/activityTeamMembers';

export const useTeamCardController = ({
  team,
  onRemoveTeam,
}: TeamCardProps) => {
  const { goToRoute } = useRouter();
  const { selectedUserProfile } = useAppSelector(({ auth }) => auth);
  const {
    deleteTeamModalRef,
    handleOpenModal,
    handleCloseModal,
    isLoading,
    handleModalSubmit,
    modalInfo,
  } = useDeleteOrLeaveTeamFlow({
    teamId: team?._id as string,
    onRemoveTeam,
    isLeader: selectedUserProfile?._id === team?.leader,
  });

  const onViewTeam = () => {
    return goToRoute(`${PAGES.ViewTeam}/${team?._id}`, {
      state: {
        showBackButton: true,
      },
    });
  };
  const onEditTeam = () => {
    return goToRoute(`${PAGES.EditTeam}/${team?._id}`, {
      state: {
        showBackButton: true,
      },
    });
  };
  const onDeleteTeam = () => {
    return handleOpenModal();
  };
  const onLeaveTeam = () => {
    return handleOpenModal();
  };

  const [isMobile] = useMediaQuery({
    maxWidth: 480,
  });

  const popoverOptions: IOptions[] = [
    {
      icon: {
        color: colors.gray[600],
        icon: 'eye',
        size: 22,
      },
      title: 'View team',
      action: onViewTeam,
    },
  ];

  const teamPopoverOptions =
    team?.leader === selectedUserProfile?._id
      ? [
          ...popoverOptions,
          {
            icon: {
              color: colors.gray[600],
              icon: 'edit',
              size: 22,
            },
            title: 'Edit team',
            action: onEditTeam,
          },
          {
            icon: {
              color: colors.error[600],
              icon: 'trash',
              size: 22,
            },
            title: 'Delete team',
            action: onDeleteTeam,
            titleColor: colors.error[600],
          },
        ]
      : [
          ...popoverOptions,
          {
            icon: {
              color: colors.error[600],
              icon: 'log-out',
              size: 22,
            },
            title: 'Leave team',
            action: onLeaveTeam,
            titleColor: colors.error[600],
          },
        ];

  const { leader, members, membersQuantity } = useMemo(() => {
    if (!team) {
      return {
        leader: { name: '', avatarUrl: '' },
        members: [],
      };
    }

    const { leader, members } = getLeaderAndMembersByStatus(
      team,
      TeamMemberStatus.ACCEPTED,
    );

    const membersQuantity = getTeamMembersTotal(team as unknown as Team);

    return { leader, members, membersQuantity };
  }, [team]);

  return {
    teamPopoverOptions,
    isMobile,
    deleteTeamModalRef,
    isLoading,
    handleModalSubmit,
    handleCloseModal,
    modalInfo,
    leader,
    members,
    membersQuantity,
  };
};
