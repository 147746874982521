import { useBreakpointValue } from 'native-base';
import { useMemo } from 'react';
import { App } from 'ui/types/interfaces/app';

import { DEFAULT_APP_ID } from '~/config';
import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import { ExecutionPlatform } from '~/store/slices/inApp/types';

export const useUserAppsController = () => {
  const { goToRoute } = useRouter();
  const { platform } = useAppSelector(({ inApp }) => inApp);
  const { availableAppsFromPermissionGroupsById } = useAppSelector(
    ({ auth }) => auth,
  );

  // Hooks
  const quantityOfColumns = useBreakpointValue({
    base: 2,
    md: 3,
    lg: 4,
    xl: 6,
  });

  // Computed
  const isWebView = platform === ExecutionPlatform.WEBVIEW;

  const handleAppSelect = (app: App) => {
    goToRoute(PAGES.InAppRoot.replace(':appId', app._id), {
      state: {
        logo: app.logo,
        name: app.name,
      },
    });
  };

  const appsList = useMemo(() => {
    if (!availableAppsFromPermissionGroupsById) return [];
    if (!!isWebView) {
      const getBighHelpOutApp = Object.values(
        availableAppsFromPermissionGroupsById,
      ).find((app) => app._id === DEFAULT_APP_ID);
      return [getBighHelpOutApp];
    }
    return Object.values(availableAppsFromPermissionGroupsById);
  }, [isWebView, availableAppsFromPermissionGroupsById]);

  return {
    availableAppsFromPermissionGroupsById,
    quantityOfColumns,
    appsList,
    handleAppSelect,
  };
};
