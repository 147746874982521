import { Box, HStack } from 'native-base';

import FlatTab from '~/components/TabsNavigator/components/FlatTab';
import { TabsNavigatorProps } from '~/components/TabsNavigator/types';

export const TabsNavigator = (props: TabsNavigatorProps) => {
  const { index, tabs, onChange } = props;

  return (
    <Box>
      <HStack w={'100%'} space={2} justifyContent={'space-between'}>
        {tabs.map((tab, tabIndex) => {
          return (
            <FlatTab
              icon={tab.icon}
              key={tab.identifier}
              label={tab.label}
              subtitle={tab.subtitle}
              isActive={tabIndex === index}
              onPress={() => onChange(tabIndex)}
            />
          );
        })}
      </HStack>
    </Box>
  );
};

export default TabsNavigator;
