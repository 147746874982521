import {
  Avatar,
  Divider,
  Flex,
  Hidden,
  HStack,
  ScrollView,
  Stack,
  Text,
  VStack,
} from 'native-base';
import Button from 'ui/components/Button';
import ContactInfoItem from 'ui/components/ContactInfoItem';
import Loading from 'ui/components/Loading';
import Tag from 'ui/components/Tags/Tag';
import { TeamMemberList } from 'ui/components/Teams/TeamMemberList';

import BreadCrumbs from '~/components/Breadcrumbs';
import ModalRemove from '~/components/ModalRemove';
import { Tab } from '~/components/Tabs';
import { PAGES } from '~/constants/pages.constants';
import { CopyTeamInviteLink } from '~/pages/Authenticated/Settings/Teams/ViewTeam/components/CopyTeamInviteLink';
import { TeamActivityCard } from '~/pages/Authenticated/Settings/Teams/ViewTeam/components/TeamActivityCard';
import { useViewTeamController } from '~/pages/Authenticated/Settings/Teams/ViewTeam/useViewTeamController';
import { colors } from '~/theme/colors';
import { getNameInitials } from '~/utils/getInitials';

export const ViewTeam = () => {
  const {
    isLoading,
    teamInfo,
    leaderAndApprovedMembers: { leader, members },
    activities,
    selectedUserProfile,
    onEditTeam,
    onLeaveTeam,
    onDeleteTeam,
    deleteTeamModalRef,
    handleCloseModal,
    isLoadingModal,
    handleModalSubmit,
    modalInfo,
    membersQuantity,
    handlePublicInvitationReload,
    handleCloseDeleteTeamMemberModal,
    onDeleteTeamMember,
    handleDeleteExternalTeamMember,
    handleDeleteInternalTeamMember,
    isPublicInvitationLoading,
    groupedMembers,
    deleteExternalTeamModalRef,
    isSubmittingRemoveExternalMemberTeam,
    memberToDelete,
  } = useViewTeamController();

  return isLoading ? (
    <Stack mx="auto" h="full" alignItems="center" justifyContent="center">
      <Loading />
    </Stack>
  ) : (
    <>
      <Hidden till="md">
        <Stack mb={2}>
          <BreadCrumbs
            breadcrumbs={[
              { icon: 'users', label: `Teams`, path: PAGES.Teams },
              { icon: 'users', label: `${teamInfo?.name || 'Team'}` },
            ]}
          />
        </Stack>
      </Hidden>
      <Flex
        flex={1}
        w="full"
        h="auto"
        flexDir="column"
        bg="singletons.white"
        rounded={12}
      >
        <Stack flexShrink={0} display={{ base: 'none', md: 'flex' }}>
          <Tab
            key={'TeamsList'}
            label="Teams"
            description="Create and manage your teams"
            icon="users"
            isActive
          />
        </Stack>
        <ScrollView>
          <Flex
            flexDir="row"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            pt={8}
            px={{ base: 4, md: 8 }}
            pb={16}
          >
            <HStack
              w="full"
              alignItems={'flex-start'}
              justifyContent="space-between"
              mb={6}
            >
              <Stack
                direction={{ base: 'column', md: 'row' }}
                alignItems={{ base: 'flex-start', md: 'center' }}
                space={{ base: 0, md: 2 }}
              >
                <Text
                  fontSize={{ base: 'xl', md: '2xl' }}
                  fontWeight="bold"
                  color="singletons.darkText"
                >
                  {teamInfo?.name}
                </Text>
                <Tag
                  text={`${membersQuantity} members`}
                  type="actions"
                  tagTextColor={colors.singletons.black}
                  tagBgColor={colors.gray[200]}
                  fontSize={12}
                />
              </Stack>
              <Stack
                direction={'row'}
                alignItems={{ base: 'flex-start', md: 'center' }}
                space={4}
                display={{ base: 'none', md: 'flex' }}
              >
                <Button
                  leftIconName={
                    teamInfo?.leader === selectedUserProfile?._id
                      ? 'trash'
                      : 'log-out'
                  }
                  minW="150px"
                  w="150px"
                  minH="44px"
                  variant="outline"
                  leftIconColor={colors.error[600]}
                  borderColor={colors.error[400]}
                  onPress={
                    teamInfo?.leader === selectedUserProfile?._id
                      ? onDeleteTeam
                      : onLeaveTeam
                  }
                >
                  <Text fontSize="md" color="error.600">
                    {teamInfo?.leader === selectedUserProfile?._id
                      ? 'Delete team'
                      : 'Leave team'}
                  </Text>
                </Button>
                <Button
                  leftIconName="edit"
                  minW="150px"
                  w="150px"
                  minH="44px"
                  variant="outline"
                  leftIconColor={colors.singletons.darkText}
                  onPress={onEditTeam}
                  borderColor={colors.gray[400]}
                >
                  <Text fontSize="md" color="singletosn.darkText">
                    Edit team
                  </Text>
                </Button>
              </Stack>
            </HStack>
            <Stack
              w="full"
              background="white"
              borderRadius="3xl"
              space={5}
              mt={{ base: 6, md: 0 }}
            >
              <VStack space={3}>
                <Text color="gray.600" fontSize="lg" fontWeight="medium">
                  Team details
                </Text>
                <Divider />
              </VStack>
              <VStack space={{ base: 6, md: 8 }}>
                <Stack
                  flex={1}
                  direction={{ base: 'column-reverse', md: 'row' }}
                  justifyContent="space-between"
                  space={{ base: 6, md: 0 }}
                >
                  <VStack flex={0.5} space={2}>
                    <Text fontSize="sm" fontWeight="medium" color="gray.600">
                      Team Leader
                    </Text>
                    <HStack alignItems="center" space={2}>
                      <Avatar
                        source={{
                          uri: leader.avatarUrl,
                        }}
                        size={10}
                      >
                        {getNameInitials(leader.name)}
                      </Avatar>
                      <Text fontSize="sm" color="singletons.darkText">
                        {leader.name}
                      </Text>
                    </HStack>
                  </VStack>
                  {/*TODO: Add this when we have organizationSubDocument from Leader */}
                  <VStack flex={0.5} space={2}>
                    <Text fontSize="sm" fontWeight="medium" color="gray.600">
                      Ecosystem
                    </Text>
                    <HStack space={2} alignItems="center">
                      <Avatar
                        flexShrink={0}
                        source={{
                          uri: teamInfo?.ecosystem.logo,
                        }}
                        size={10}
                      />
                      <Text fontSize="sm" color={colors.singletons.darkText}>
                        {teamInfo?.ecosystem.name}
                      </Text>
                    </HStack>
                  </VStack>
                </Stack>

                <TeamMemberList
                  title="Teammates"
                  membersData={groupedMembers?.accepted ?? []}
                  emptyMessage="Currently no members in the team."
                  showTopBar={false}
                />

                {groupedMembers?.pending && (
                  <TeamMemberList
                    title="Pending teammates"
                    membersData={groupedMembers?.pending ?? []}
                    emptyMessage="Currently no pending members in the team."
                    onDeleteMember={onDeleteTeamMember}
                    showDeleteMemberButton={true}
                  />
                )}

                {groupedMembers?.rejected && (
                  <TeamMemberList
                    title="Declined invitations"
                    membersData={groupedMembers?.rejected ?? []}
                    emptyMessage="Currently no declined members in the team."
                    onDeleteMember={onDeleteTeamMember}
                    showDeleteMemberButton={true}
                  />
                )}

                <VStack my={8}>
                  <VStack space={3}>
                    <Text color="gray.600" fontSize="lg" fontWeight="medium">
                      Contact information
                    </Text>
                    <Divider />
                  </VStack>
                  <Stack
                    direction={{ base: 'column', md: 'row' }}
                    flex={1}
                    mt={6}
                    justifyContent="space-between"
                    space={{ base: 8, md: 2 }}
                  >
                    <Stack flex={0.5}>
                      <ContactInfoItem
                        infoName="Email address"
                        infoValue={
                          teamInfo?.leaderSubDocument.userSummary.email || ''
                        }
                        icon="mail"
                      />
                    </Stack>

                    {leader?.phoneNumber && (
                      <Stack flex={0.5}>
                        <ContactInfoItem
                          infoName="Phone number"
                          infoValue={leader?.phoneNumber}
                          icon="phone"
                        />
                      </Stack>
                    )}
                  </Stack>
                </VStack>

                {teamInfo?.publicInviteTeamSummary && (
                  <VStack
                    h="full"
                    flex={{ base: 'auto', md: 0.5 }}
                    flexShrink={0}
                  >
                    {isPublicInvitationLoading ? (
                      <Loading containerHeight="fit-content" />
                    ) : (
                      <>
                        <VStack space={2}>
                          <Text
                            fontSize="xl"
                            fontWeight="medium"
                            color="gray.600"
                          >
                            Public invite
                          </Text>
                          <Divider />

                          <Text fontSize="sm" color="gray.600">
                            Invite your friends from anywhere
                          </Text>
                        </VStack>

                        <Stack flex={0.5} maxW={'760px'}>
                          <CopyTeamInviteLink
                            onReload={handlePublicInvitationReload}
                            link={
                              teamInfo?.publicInviteTeamSummary?.inviteURL ?? ''
                            }
                          />
                        </Stack>
                      </>
                    )}
                  </VStack>
                )}
              </VStack>

              {activities.length > 0 && (
                <>
                  <VStack space={3}>
                    <Text color="gray.600" fontSize="xl" fontWeight={500}>
                      Activities
                    </Text>
                    <Divider />
                  </VStack>

                  <VStack>
                    {activities.map((activity) => (
                      <TeamActivityCard
                        key={activity._id}
                        activity={activity}
                      />
                    ))}
                  </VStack>
                </>
              )}
            </Stack>
          </Flex>
        </ScrollView>
        <VStack
          flexShrink={0}
          w="full"
          py={4}
          px={6}
          space={2}
          bg="white"
          display={{ base: 'flex', md: 'none' }}
        >
          {teamInfo?.leader === selectedUserProfile?._id ? (
            <Button
              leftIconName="edit"
              minW="full"
              w="full"
              minH="44px"
              variant="outline"
              leftIconColor={colors.singletons.darkText}
              borderColor={colors.gray[400]}
              onPress={onEditTeam}
            >
              <Text fontSize="md" color="singletosn.darkText">
                Edit team
              </Text>
            </Button>
          ) : null}
          <Button
            leftIconName={
              teamInfo?.leader === selectedUserProfile?._id
                ? 'trash'
                : 'log-out'
            }
            minW="full"
            w="full"
            minH="44px"
            variant="outline"
            leftIconColor={colors.error[600]}
            borderColor={colors.error[400]}
            onPress={
              teamInfo?.leader === selectedUserProfile?._id
                ? onDeleteTeam
                : onLeaveTeam
            }
          >
            <Text fontSize="md" color="error.600">
              {teamInfo?.leader === selectedUserProfile?._id
                ? 'Delete team'
                : 'Leave team'}
            </Text>
          </Button>
        </VStack>
      </Flex>
      <ModalRemove
        modalRef={deleteTeamModalRef}
        headerText={modalInfo.modalTitle}
        handleCloseModal={handleCloseModal}
        handleDelete={handleModalSubmit}
        rightBtnLoading={isLoadingModal}
        rightBtnText={modalInfo.buttonText}
      >
        <Stack space={5}>
          <Text fontWeight={400} fontSize="md" color="gray.500">
            {modalInfo.text}
          </Text>

          <Text fontWeight={400} fontSize="md" color="gray.500">
            {modalInfo.description}
          </Text>
        </Stack>
      </ModalRemove>
      <ModalRemove
        modalRef={deleteExternalTeamModalRef}
        headerText="Remove team member"
        handleCloseModal={handleCloseDeleteTeamMemberModal}
        handleDelete={
          memberToDelete?.isInternalMember
            ? handleDeleteInternalTeamMember
            : handleDeleteExternalTeamMember
        }
        rightBtnLoading={isSubmittingRemoveExternalMemberTeam}
        rightBtnText="Remove"
      >
        <Stack space={5}>
          <Text fontWeight={400} fontSize="md" color="gray.500">
            Are you sure that you want to remove this member of your team?
          </Text>
          <Text fontWeight={400} fontSize="md" color="gray.500">
            The member will no longer be able to participate in team events of
            this team.
          </Text>
        </Stack>
      </ModalRemove>
    </>
  );
};
