import { Box, FormControl, HStack, Text } from 'native-base';
import { Controller } from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { CauseOptionsAutoCompleteProps } from '~/pages/Authenticated/InApp/components/CauseOptionsAutoComplete/types';

import { useCauseOptionsAutoCompleteController } from './controller';

export const CauseOptionsAutoComplete = (
  props: CauseOptionsAutoCompleteProps,
) => {
  const { control, name, label, placeholder, causesOptions } = props;
  const { defaultStyle, loadOptions } = useCauseOptionsAutoCompleteController({
    causesOptions,
  });

  const EmptyIndicator = () => <></>;
  return (
    <>
      {label ? (
        <FormControl.Label>
          <Text fontWeight={500} color="gray.600">
            {label}
          </Text>
        </FormControl.Label>
      ) : null}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <HStack alignItems={'center'} w="100%">
            <Box position={'absolute'} left={'12px'} top={'16px'} zIndex={9}>
              <Icon icon="filter" size={'16px'} color={colors.gray[400]} />
            </Box>
            <Box flex={1}>
              <AsyncSelect
                value={value}
                isMulti
                menuPortalTarget={document.body}
                styles={defaultStyle}
                placeholder={placeholder}
                onChange={onChange}
                defaultOptions={causesOptions}
                loadOptions={loadOptions}
                isClearable={false}
                controlShouldRenderValue={false}
                components={{
                  DropdownIndicator: EmptyIndicator,
                  IndicatorSeparator: EmptyIndicator,
                }}
              />
            </Box>
          </HStack>
        )}
      />
    </>
  );
};
