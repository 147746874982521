import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { matchPath } from 'react-router-dom';
import BhoLogo from 'ui/assets/illustrations/BhoLogo.svg';

import { DEFAULT_APP_ID } from '~/config';
import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useQuery } from '~/hooks/useQuery';
import { useRouter } from '~/hooks/useRouter';
import { inAppSliceActions } from '~/store/slices/inApp';

const MOBILE_NAVBAR_BLACK_LIST: string[] = [PAGES.InAppSearchView];

export const useInAppViewLayoutController = () => {
  // -- Providers
  const dispatch = useDispatch();
  const { params, location: routerLocation } = useRouter();
  const { selectedApp, isLoading: isContentLoading } = useAppSelector(
    (state) => state.inApp,
  );

  // -- Hooks
  const {
    data,
    isLoading: isAppLoading,
    isFetched,
  } = useQuery<any>(`app/${String(params.appId)}`, {
    key: `mini-app-${String(params.appId)}`,
    queryOptions: { enabled: !!params.appId },
  });

  useEffect(() => {
    if (isFetched && data) {
      dispatch(inAppSliceActions.selectApp({ app: data }));
    }
  }, [data, isFetched, dispatch]);

  // -- Computed
  const shouldRenderMobileNavBar = !MOBILE_NAVBAR_BLACK_LIST.some(
    (path: string) => {
      const match = matchPath(path, routerLocation.pathname);
      return match != null;
    },
  );

  const splashScreenInfo = useMemo(() => {
    let appLogo = BhoLogo;
    let appName = undefined;

    if (routerLocation?.state && params?.appId) {
      const {
        state: { logo, name },
      } = routerLocation;
      appLogo = params.appId === DEFAULT_APP_ID ? BhoLogo : logo;
      appName = name;
    }

    return { appLogo, appName };
  }, [routerLocation, params]);

  return {
    isAppLoading,
    isContentLoading,
    isFetched,
    selectedApp,
    shouldRenderMobileNavBar,
    splashScreenInfo,
  };
};
