import { useEffect, useMemo, useRef } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { useAppSelector } from '~/hooks/useAppSelector';
import { FilterFormFields } from '~/pages/Authenticated/InApp/components/FilterForm/types';
import { IOption } from '~/pages/Authenticated/InApp/SearchView/types';

export const useRegionsController = (props: {
  form: UseFormReturn<FilterFormFields, any>;
}) => {
  const { form } = props;

  // -- Providers
  const { nationOptions, regionOptions } = useAppSelector(
    (state) => state.inApp,
  );
  const preventSanitizationRef = useRef(false);

  // -- Hooks
  const selectedContries = form.watch('countries');
  const selectedRegions = form.watch('regions');

  const regionSelectOptions = useMemo(() => {
    const selectedContriesNames = selectedContries.map((c) => c.label);
    let filteredRegions = regionOptions ?? [];

    if (selectedContriesNames.length > 0) {
      filteredRegions = filteredRegions.filter((r) =>
        selectedContriesNames.includes(r.relatedTo || ''),
      );
    }

    preventSanitizationRef.current = false;

    return filteredRegions.map((region: any) => ({
      value: region._id,
      label: region.displayName,
    }));
  }, [regionOptions, selectedContries]);

  const nationSelectOptions = useMemo(() => {
    return (nationOptions ?? []).map((nation) => ({
      value: nation._id,
      label: nation.displayName,
    })) as { value: string; label: string }[];
  }, [nationOptions]);

  const sanitizeRegionValues = (
    selectedRegions: IOption[],
    selectedContryLabels: string[],
  ) => {
    const allowedRegionIds = regionOptions
      .filter((r) => selectedContryLabels.includes(r.relatedTo || ''))
      .map((r) => r._id);

    const sanitizedRegions = selectedRegions.filter((r) =>
      allowedRegionIds.includes(r.value),
    );

    form.setValue('regions', sanitizedRegions);
  };

  useEffect(() => {
    if (preventSanitizationRef.current === true || selectedContries.length == 0)
      return;

    sanitizeRegionValues(
      selectedRegions,
      selectedContries.map((c) => c.label),
    );

    preventSanitizationRef.current = true;
  }, [selectedRegions, selectedContries]);

  return { nationSelectOptions, regionSelectOptions, selectedRegions };
};
