import { Box, HStack } from 'native-base';

import { ShareActivity } from '~/components/ShareActivity';
import { ActivityHeaderProps } from '~/pages/Authenticated/v2/ActivityView/components/ActivityHeader/type';
import { makeActivityLink } from '~/pages/Authenticated/v2/ActivityView/utils';
import { ActivitiesBreadCrumb } from '~/pages/Authenticated/ViewActivityDetails/components/ActivityBreadCrumb';
import { IActivityDefinitionSubDocument } from '~/types/interfaces/activity';

export const ActivityHeader = (props: ActivityHeaderProps) => {
  const { activityDefinition } = props;

  return (
    <HStack justifyContent={'space-between'}>
      <ActivitiesBreadCrumb
        activityDefinition={
          activityDefinition as unknown as IActivityDefinitionSubDocument
        }
      />
      <Box>
        <ShareActivity
          activityLink={makeActivityLink(activityDefinition._id)}
        />
      </Box>
    </HStack>
  );
};

export default ActivityHeader;
