import Loading from 'ui/components/Loading';

import ActivityView from '~/pages/Authenticated/v2/ActivityView/ActivityView';
import { useActivityViewLoadController } from '~/pages/Authenticated/v2/ActivityView/controllers/activity-view-load-controller';
import { ActivityViewContext } from '~/pages/Authenticated/v2/ActivityView/store';
import { ViewActivityDetailsProps } from '~/pages/Authenticated/ViewActivityDetails/types';

/*
    -- ActivityViewPage --

		This is the page layout where we will load all the necessary initial data. 
		The idea is that the store should only exist during the page's lifecycle.
		Whenever the page is navigated to or the URL id changes, 
		we ensure that the store is reset. 
		
		Additionally, the screen will only be displayed once we are certain that 
		all necessary resources have been loaded.
*/

export const ActivityViewPage = (props: ViewActivityDetailsProps) => {
  const { store } = useActivityViewLoadController(props);

  if (!store) return <Loading containerHeight={'100%'} />;

  return (
    <ActivityViewContext.Provider value={store}>
      <ActivityView isGuestPage={false} />
    </ActivityViewContext.Provider>
  );
};
