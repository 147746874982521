import { useDisclose } from 'native-base';
import { ActivityTypeEnum } from 'org-admin/src/enums';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { EEventApplicationType } from 'ui/enums';

import { CardActivityControllerParams } from '~/components/ActivityCards/CardActivity/cardActivity.types';
import {
  getHeaderText,
  getShownInfos,
} from '~/components/ActivityCards/CardRecommendations/cardRecommendations.controller';
import { PAGES } from '~/constants/pages.constants';
import { getNameInitials } from '~/utils/getInitials';

export const useCardActivityController = ({
  activity,
  appName,
}: CardActivityControllerParams) => {
  const navigate = useNavigate();
  const { isOpen, onToggle } = useDisclose();

  const eventSummary = useMemo(() => {
    const { activityDefinitionSubDocument } = activity;
    const { organizationSubDocument } = activityDefinitionSubDocument;

    const headerText = getHeaderText(
      activity.startDate,
      activity.createdAt,
      activityDefinitionSubDocument.type,
      activity.endDate,
      activity?.externalApplyLink,
    );

    const shownInfos = getShownInfos(
      activityDefinitionSubDocument.type as ActivityTypeEnum,
      activity.startDate,
      activity.endDate,
      activityDefinitionSubDocument.targetAmount,
      activityDefinitionSubDocument.measurementUnitPluralLabel,
      activity?.address?.street,
    );

    return {
      headerText,
      activityName: activityDefinitionSubDocument?.title || 'Unnamed',
      activityInitials: getNameInitials(
        activityDefinitionSubDocument?.title || '',
      ),
      activityType: activityDefinitionSubDocument.type,
      appName: appName || 'Unnamed',
      organizationName: organizationSubDocument?.name || 'Unnamed',
      activityCoverImageUrl: activityDefinitionSubDocument.coverImage,
      isTeamEvent:
        activity.activityDefinitionSubDocument.eventApplicationType ===
        EEventApplicationType.Team,
      shownInfos,
    };
  }, [activity, appName]);

  const handleGoToActivityDetail = (activityDefinitionId: string) => {
    const route = PAGES.ActivitiesDetails.replace(':id', activityDefinitionId);

    navigate(route);
  };

  return {
    isOpen,
    onToggle,
    eventSummary,
    handleGoToActivityDetail,
  };
};
