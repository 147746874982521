import { ILocation } from 'ui/types/interfaces';

import { DEFAULT_LOCATON } from '~/store/slices/inApp';
import { getCoordinatesFromLocation } from '~/utils/getCoordinates';

export const getStringifiedPosition = (location?: ILocation | null) => {
  const coordinates = location
    ? getCoordinatesFromLocation(location)
    : getCoordinatesFromLocation(DEFAULT_LOCATON);

  return JSON.stringify(coordinates);
};
