import { StylesConfig } from 'react-select';

import { theme } from '../../theme';

const defaultStyle: StylesConfig = {
  control: (baseStyles) => ({
    ...baseStyles,
    fontFamily: theme.fontConfig.default,
    fontSize: 12,
    padding: 5.8,
    borderRadius: 12,
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    fontFamily: theme.fontConfig.default,
    fontSize: 12,
  }),
  menuPortal: (baseStyles) => ({
    ...baseStyles,
    fontFamily: theme.fontConfig.default,
    fontSize: 12,
    Index: 9999,
  }),
};

export const useSelectController = () => {
  return { defaultStyle };
};
