import { colors } from '../../theme/colors';

export const sliderStyle = {
  handle: {
    backgroundColor: colors.white,
    borderColor: colors.black,
    borderWidth: 3,
    width: 22,
    height: 22,
    opacity: 1,
  },
  track: {
    height: 9,
    backgroundColor: colors.black,
  },
  rail: {
    height: 9,
  },
  tracks: {
    height: 9,
  },
};
