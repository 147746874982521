import Yup from '~/utils/validations/yup';

export const schema = Yup.object().shape({
  startDate: Yup.date().required('Date is required'),
  endDate: Yup.date()
    .required('Date is required')
    .min(Yup.ref('startDate'), "Date can't be before from date"),
  distanceRadius: Yup.number()
    .min(1, 'Distance Radius must be greater than or equal to 1')
    .max(100, 'Distance Radius must be greater than or equal to 1')
    .required('Distance Radius is required'),
});
