import { StatusCodes } from 'http-status-codes';
import { useMediaQuery } from 'native-base';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ILocationSelected } from 'ui/components/InputSearchLocation/types';

import { useAppSelector } from '~/hooks/useAppSelector';
import { useAuth } from '~/hooks/useAuth';
import UserService from '~/services/resources/user';
import { Preferences } from '~/types/interfaces/user';

import { PreferenceLocationModalProps } from './types';

export const usePreferenceLocationModalController = ({
  handleCloseModal,
}: Omit<PreferenceLocationModalProps, 'modalRef'>) => {
  // Providers
  const { user } = useAppSelector(({ auth }) => auth);
  const { handleGetUserInformation } = useAuth();

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<ILocationSelected>();

  // Hooks
  const [isMobile] = useMediaQuery({
    maxWidth: 480,
  });

  const {
    setError,
    formState: { errors },
  } = useForm();

  const handleChangeLocation = (location: ILocationSelected | null) => {
    setError('location', {});
    setSelectedLocation(location || undefined);
  };

  const handleUpdateUserLocation = async () => {
    try {
      setIsLoading(true);
      const preferencesPayload = {
        address: {
          location: {
            type: 'Point',
            coordinates: [selectedLocation?.lng, selectedLocation?.lat],
          },
          street: selectedLocation?.rawLocation,
        },
      };

      const response = await UserService.updateUserPreferences(
        user?._id as string,
        preferencesPayload as Preferences,
      );

      if (response.status === StatusCodes.OK) {
        await handleGetUserInformation({ shouldFetchAttributes: false });
        handleCloseModal();
      } else {
        throw Error('Error setting the location.');
      }
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    errors,
    isMobile,
    isLoading,
    selectedLocation,
    handleChangeLocation,
    handleUpdateUserLocation,
  };
};
