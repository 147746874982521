import { ActivityApplicationStatus } from 'doit-bho-volunteer/src/types/interfaces/activity';

export const pageTitle = 'Activities';
export const pageSubtitle = 'Activities you have registered for';

export const expectedApplicationStatus = [
  ActivityApplicationStatus.Approved,
  ActivityApplicationStatus.Pending,
  ActivityApplicationStatus.Referral,
];
