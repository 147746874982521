import { api } from '~/services/api';

export class InsightsService {
  static MODULE_URL = '/volunteer/insights';

  static createUserProfileEngagement(userProfileId: string) {
    return api.post(
      `${this.MODULE_URL}/user-profile/${userProfileId}/engagement`,
    );
  }
}
