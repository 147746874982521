import FeatherIcon from 'feather-icons-react';
import { Box, FormControl, HStack, Text } from 'native-base';
import React from 'react';
import { Controller } from 'react-hook-form';
import AsyncCreatableSelect from 'react-select/async-creatable';
import Icon from 'ui/components/Icon';

import { colors } from '../../theme/colors';
import { IOptionsItem, ISelectProps } from './types';
import { useSelectController } from './useAutocompleteController';

export const CreatableAsyncAutoComplete = ({
  control,
  name,
  isMulti,
  hasError,
  isDisabled,
  helperText,
  iconErrorMessage,
  leftIconErrorSize = 16,
  errorMessage,
  label,
  loadOptions,
  ...restProps
}: ISelectProps) => {
  const { defaultStyle } = useSelectController();

  return (
    <>
      {label ? <FormControl.Label>{label}</FormControl.Label> : null}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, ...restField } }) => (
          <AsyncCreatableSelect
            {...restField}
            {...restProps}
            menuPortalTarget={document.body}
            styles={defaultStyle}
            isDisabled={isDisabled}
            value={value}
            isMulti={isMulti}
            onChange={(value) => {
              onChange(value as IOptionsItem | IOptionsItem[]);
            }}
            formatCreateLabel={(inputValue) => (
              <HStack
                alignItems={'center'}
                space={2}
                justifyContent={'space-between'}
              >
                <HStack
                  alignItems={'center'}
                  space={2}
                  flex={1}
                  overflow={'hidden'}
                >
                  <Icon icon="user-plus" size={'16px'} />
                  <Text ml={2} numberOfLines={3}>
                    {inputValue}
                  </Text>
                </HStack>

                <Text
                  fontSize={'2xs'}
                  color={colors.muted[400]}
                  backgroundColor={colors.gray[200]}
                  numberOfLines={1}
                >
                  Off-platform invitations
                </Text>
              </HStack>
            )}
            loadOptions={loadOptions}
          />
        )}
      />
      {!hasError && !errorMessage && helperText ? (
        <FormControl.HelperText ml={2}>{helperText}</FormControl.HelperText>
      ) : null}
      {errorMessage ? (
        <Box mt={2} pl={2}>
          {iconErrorMessage ? (
            <FeatherIcon
              icon={iconErrorMessage}
              size={leftIconErrorSize}
              color={colors.error[400]}
            />
          ) : null}
          <Text fontSize="xs" fontWeight={400} color="error.600">
            {errorMessage}
          </Text>
        </Box>
      ) : null}
    </>
  );
};

export default React.memo(CreatableAsyncAutoComplete);
