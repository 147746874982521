import { AxiosError } from 'axios';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { queryClient } from '~/config/react-query.config';
import { useRouter } from '~/hooks/useRouter';
import TeamService from '~/services/resources/teams';
import { ICreateTeamDTO } from '~/types/dtos';
import { IMemberValue, ITeamPopulateEcosystem } from '~/types/interfaces/team';

export const useEditTeamController = () => {
  const removeModalRef = useRef<IModalRefProps>();
  const {
    goBack,
    params: { id: teamId },
  } = useRouter();

  const [teamInfo, setTeamInfo] = useState<ITeamPopulateEcosystem>();
  const [selectedMembersToAdd, setSelectedMembersToAdd] = useState<
    IMemberValue[]
  >([]);
  const [memberToRemove, setMemberToRemove] = useState<IMemberValue>();
  const [teamName, setTeamName] = useState<string>('');

  const { mutate: mutateAddMembers, isLoading: isLoadingAddMembers } =
    useMutation({
      mutationFn: async () => await handleAddMembersMutation(),
      onSuccess: () => {
        handleUpdateTeam();
      },
      onError: (error: AxiosError) => {
        toast.error(error.message);
      },
    });

  const { mutate: mutateRemoveMember, isLoading: isLoadingRemoveMember } =
    useMutation({
      mutationFn: async () => await handleRemoveMemberMutation(),
      onSuccess: () => {
        queryClient.refetchQueries({ queryKey: [TeamService.URL] });
        setMemberToRemove(undefined);
        toast.success('Member removed successfully!');
        removeModalRef.current?.close();
      },
      onError: (error: AxiosError) => {
        toast.error(error.message);
      },
    });

  const { mutate: mutateUpdateTeam, isLoading: isLoadingUpdateTeam } =
    useMutation({
      mutationFn: async (payload: Partial<ICreateTeamDTO>) =>
        await TeamService.updateTeam(teamId as string, payload),
      onSuccess: () => {
        toast.success('Team updated successfully');
        goBack();
      },
      onError: (error: AxiosError) => {
        toast.error(error.message);
      },
    });

  const {
    data: teamInfoResponse,
    isLoading: isLoadingTeam,
    isRefetching: isRefetchingTeam,
  } = useQuery({
    queryKey: [TeamService.URL, { id: teamId }],
    queryFn: ({ queryKey }) => {
      const [, { id }] = queryKey as [string, { id: string }];
      return TeamService.findAll({
        filter: JSON.stringify({
          _id: id,
        }),
        populate: JSON.stringify(['ecosystem']),
      });
    },

    enabled: !!teamId,
  });

  const handleAddMembersMutation = async () => {
    if (selectedMembersToAdd.length > 0) {
      const membersId = [];
      const newMembers = [];

      for (const member of selectedMembersToAdd) {
        if (member.__isNew__) {
          newMembers.push(member.value);
        } else {
          membersId.push(member.value);
        }
      }

      const payload = {
        team: teamId,
        members: membersId,
        newMembers,
      };

      await TeamService.addTeamMembers(payload);
    }
  };

  const handleRemoveMemberMutation = async () => {
    if (!teamId) return;
    if (memberToRemove) {
      const payload = {
        team: teamId,
        members: [memberToRemove.value],
      };
      await TeamService.removeTeamMembers(payload);
    }
  };

  const handleUpdateTeam = () => {
    const payload = {
      name: teamName || teamInfo?.name,
    };

    mutateUpdateTeam(payload);
  };

  const handleAddMember = (value: IMemberValue) => {
    const memberAlreadyExists = membersList.find(
      (member) =>
        member.value === value.value ||
        member.value === value.email ||
        member.email === value.value,
    );

    if (memberAlreadyExists) {
      return toast.error('Member already added');
    }
    setSelectedMembersToAdd((prevState) => [...prevState, value]);
  };

  const handleRemoveMember = (memberIndex: number) => {
    const memberToRemove = membersList[memberIndex];

    if (memberToRemove.isExisting) {
      setMemberToRemove(memberToRemove);
      handlOpenModal();
    } else {
      const indexToRemove =
        teamInfo?.members && teamInfo.members.length > 0
          ? teamInfo?.members.length - 1
          : 0;

      const updatedMembersToAdd = selectedMembersToAdd.toSpliced(
        indexToRemove,
        1,
      );
      setSelectedMembersToAdd(updatedMembersToAdd);
    }
  };

  const handleCloseModal = () => {
    removeModalRef.current?.close();
  };

  const handlOpenModal = () => {
    removeModalRef.current?.open();
  };

  const handleSubmit = () => {
    mutateAddMembers();
  };

  useEffect(() => {
    if (!isLoadingTeam && teamInfoResponse && teamInfoResponse.data) {
      setTeamInfo(teamInfoResponse.data[0]);
      setTeamName(teamInfoResponse.data[0].name);
    }
  }, [teamInfoResponse, isLoadingTeam]);

  const mapMembersList = () => {
    const membersToSelect = teamInfo?.membersSubDocument.map((member) => ({
      value: member._id,
      label: member.userSummary.name,
      email: member.userSummary.email,
      profileImage: member.userSummary.profileImage,
      isExisting: true,
    }));

    if (!membersToSelect) return selectedMembersToAdd;

    return [...membersToSelect, ...selectedMembersToAdd];
  };

  const { membersList, isLoading, isLoadingSubmit } = useMemo(
    () => ({
      membersList: mapMembersList(),
      isLoading: isLoadingTeam || isRefetchingTeam,
      isLoadingSubmit: isLoadingAddMembers || isLoadingUpdateTeam,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isLoadingAddMembers,
      isLoadingUpdateTeam,
      isLoadingTeam,
      isRefetchingTeam,
      teamInfo?.membersSubDocument,
      selectedMembersToAdd,
    ],
  );

  return {
    selectedMembersToAdd,
    handleAddMember,
    handleRemoveMember,
    teamName,
    setTeamName,
    isLoading,
    isLoadingSubmit,
    handleSubmit,
    goBack,
    teamInfo,
    membersList,
    removeModalRef,
    mutateRemoveMember,
    handlOpenModal,
    handleCloseModal,
    isLoadingRemoveMember,
  };
};
