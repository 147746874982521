import { OptionsLabelWrapProps } from '~/pages/Authenticated/InApp/components/OptionsLabelWrap/types';

export const useOptionsLabelController = (props: OptionsLabelWrapProps) => {
  const { onChange, options } = props;

  const handlePress = (value: string) => {
    onChange(options.filter((option) => option.value !== value));
  };

  return { handlePress };
};
