import { useMemo } from 'react';

import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import {
  AppSwitcherProps,
  IAppPopoverOption,
} from '~/pages/Authenticated/InApp/components/AppSwitcher/types';

export const useAppSwitcherController = (props: AppSwitcherProps) => {
  const { onChange } = props;
  // -- Providers
  const { goToRoute } = useRouter();
  const { selectedApp } = useAppSelector((state) => state.inApp);
  const { availableAppsFromPermissionGroupsById } = useAppSelector(
    ({ auth }) => auth,
  );

  // -- Hooks
  const appOptions: IAppPopoverOption[] = useMemo(() => {
    if (!availableAppsFromPermissionGroupsById) return [];
    return (Object.values(availableAppsFromPermissionGroupsById) ?? [])
      .map((app) => {
        return {
          key: app?._id || '',
          logo: app?.logo || '',
          label: app?.name || 'Unnamed',
        };
      })
      .filter((_, index) => index < 3);
  }, [availableAppsFromPermissionGroupsById]);

  // -- Handlers
  const handleOptionPress = (option: IAppPopoverOption) => {
    onChange(option);
  };

  return {
    goToRoute,
    handleOptionPress,
    selectedApp,
    appOptions,
  };
};
