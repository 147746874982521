import { secondsToHoursMinutes } from 'doit-bho-volunteer/src/utils/functions';
import { Avatar, Flex, Hidden, HStack, Text, VStack } from 'native-base';
import React from 'react';
import Icon from 'ui/components/Icon';

import { BookingDateSelectCardProps } from '~/components/BookingDateSelectCard/bookingDateSelectCard.types';
import { BookingDateSelect } from '~/compound-components/booking-selection-card/booking-date-select/booking-date-select.composition';
import { getNameInitials } from '~/utils/getInitials';

interface ActivityDetailSummaryProps {
  activityTitle?: string;
  appName?: string;
  organizationName?: string;
  organizationLogo?: string;
}

const ActivityDetailSummary = ({
  activityTitle,
  appName,
  organizationName,
}: ActivityDetailSummaryProps) => {
  return (
    <VStack space={3} flexDir={{ base: 'column', md: 'row' }}>
      {!!activityTitle && (
        <>
          <Text
            fontSize={'md'}
            fontWeight={700}
            mr={{ base: 0, md: 3 }}
            noOfLines={1}
            maxW={{ base: 'unset', md: '160px' }}
          >
            {activityTitle}
          </Text>
        </>
      )}
      <HStack space={2}>
        {!!appName && (
          <>
            <Text fontSize={'md'} fontWeight={500} noOfLines={1}>
              {appName}
            </Text>
            <Text fontWeight={500}>•</Text>
          </>
        )}
        {!!organizationName && (
          <Text fontSize={'md'} noOfLines={1}>
            by {organizationName}
          </Text>
        )}
      </HStack>
    </VStack>
  );
};

export const BookingDateSelectCard = ({
  organizationLogo,
  hideActivitySummary = true,
  activityTitle,
  appName,
  organizationName,
  day,
  month,
  isSelected,
  bookingDateSelectDetails,
  isAlreadyBooked,
  hideCardSelectIcon,
  teamName,
  spotsRemaining,
  numberOfSpots,
  measurementUnitPluralLabel,
  targetAmount,
  isVolunteerNumberLimited,
}: BookingDateSelectCardProps) => {
  const bookingDateDetails = [...bookingDateSelectDetails];

  if (teamName) {
    bookingDateDetails.push({
      iconName: 'users',
      text: teamName,
    });
  }

  const isHourTarget = measurementUnitPluralLabel === 'Hours';
  const parsedTargetAmount = isHourTarget
    ? secondsToHoursMinutes(targetAmount as number).hours
    : targetAmount;

  return (
    <BookingDateSelect.Root isSelected={isSelected}>
      <HStack w={'full'}>
        {organizationLogo || organizationLogo ? (
          <Hidden till={'md'}>
            <Avatar
              rounded={12}
              w={12}
              h={12}
              minW={12}
              mr={6}
              _image={{
                rounded: 12,
              }}
              source={{
                uri: organizationLogo,
              }}
            >
              {organizationName && getNameInitials(organizationName)}
            </Avatar>
          </Hidden>
        ) : null}
        <VStack space={4}>
          {!hideActivitySummary && (
            <ActivityDetailSummary
              organizationLogo={organizationLogo}
              activityTitle={activityTitle}
              appName={appName}
              organizationName={organizationName}
            />
          )}

          {!!day && !!month && (
            <Flex flexDir={'row'} w={'100%'}>
              <BookingDateSelect.Date day={day} month={month} />
              <BookingDateSelect.Details
                bookingDateSelectDetails={bookingDateDetails}
                numberOfSpots={spotsRemaining}
                isVolunteerNumberLimited={isVolunteerNumberLimited}
              />
            </Flex>
          )}

          {targetAmount && measurementUnitPluralLabel && (
            <HStack alignItems={'center'} space={2}>
              <Icon isCustom size={8} icon={'target_and_arrow'} />
              <Text fontWeight={500} fontSize={'xl'}>
                {parsedTargetAmount} {measurementUnitPluralLabel}
              </Text>
            </HStack>
          )}
        </VStack>
        {(!hideCardSelectIcon || isAlreadyBooked) && (
          <BookingDateSelect.Icon
            isSelected={isSelected}
            isAlreadyBooked={isAlreadyBooked}
          />
        )}
      </HStack>
    </BookingDateSelect.Root>
  );
};
