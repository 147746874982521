import FeatherIcon from 'feather-icons-react';
import { Box, HStack, Input, Stack, Text } from 'native-base';
import React from 'react';
import { Pressable } from 'react-native';

import { colors } from '../../theme/colors';
import Loading from '../Loading';
import { SuggestionItem } from './components/SugestionItem';
import { useInputSearchLocationInlineController } from './controller';
import { IInputSearchLocationInline } from './types';

const InputSearchLocationInline = (props: IInputSearchLocationInline) => {
  const {
    title = 'Location',
    inputIcon = 'map-pin',
    location,
    placeholder,
    fixedSugestions,
    brandColor = colors.primary[400],
    onSelect,
    onFetch,
    onDetail,
  } = props;

  const {
    inputValue,
    suggestions,
    isSubmitting,
    isSuggestionsLoading,
    handleInputChange,
    handleSelection,
    handleClear,
  } = useInputSearchLocationInlineController({
    onFetch,
    onSelect,
    onDetail,
    location,
  });

  if (!!isSubmitting) {
    return (
      <Loading containerHeight="50vh" spinnerSize="lg" color={brandColor} />
    );
  }

  return (
    <Stack w="100%">
      <HStack
        flex={1}
        width="100%"
        alignContent="center"
        justifyContent="center"
        background="white"
        borderRadius={12}
        zIndex={9999}
      >
        <Box width={'100%'}>
          <Text
            mb={2}
            fontSize={'sm'}
            fontWeight={500}
            color={colors.gray[600]}
          >
            {title}
          </Text>

          <Input
            h="3.25rem"
            width={'100%'}
            value={location?.rawLocation || inputValue}
            onChange={(e: any) => handleInputChange(e.target.value)}
            type={'text'}
            backgroundColor="white"
            placeholder={placeholder || ''}
            px={2}
            fontWeight={!!location ? 500 : 400}
            InputLeftElement={
              <Box ml={2} variant="unstyled">
                <FeatherIcon
                  icon={inputIcon}
                  size={16}
                  color={!location ? colors.gray[400] : brandColor}
                />
              </Box>
            }
            InputRightElement={
              <>
                <Box mx={2} variant="unstyled">
                  {location && (
                    <Pressable onPress={handleClear}>
                      <FeatherIcon
                        icon={'x'}
                        size={16}
                        color={colors.gray[500]}
                      />
                    </Pressable>
                  )}
                </Box>
              </>
            }
          />
        </Box>
      </HStack>

      <Box py={3}>
        {(fixedSugestions ?? []).map((suggestion: any, index) => {
          return (
            <React.Fragment key={index}>
              <SuggestionItem
                data={suggestion}
                onPress={handleSelection}
                highlight
              />
            </React.Fragment>
          );
        })}

        {isSuggestionsLoading ? (
          <Loading
            containerHeight="140px"
            spinnerSize="sm"
            color={brandColor}
          />
        ) : (
          (suggestions ?? []).map((suggestion: any, index) => {
            return (
              <React.Fragment key={index}>
                <SuggestionItem data={suggestion} onPress={handleSelection} />
              </React.Fragment>
            );
          })
        )}
      </Box>
    </Stack>
  );
};

export default InputSearchLocationInline;
