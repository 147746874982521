export type CalendarDateTimeProps = {
  date: Date;
  onPress: (date: Date) => void;
  isSelected: boolean;
  isBlocked: boolean;
};

export enum CalendarDateTimeState {
  available = 1,
  selected = 2,
  blocked = 3,
}

export type CalendarDateTimeStyle = {
  backgroundColor: string;
  tooltipLabel: string;
  borderColor: string;
  fontColor: string;
};
