import Compressor from 'compressorjs';

export const compressImage = (file: File, quality = 0.1) => {
  const compressedImage = new Promise((resolve, reject) => {
    new Compressor(file, {
      quality,
      convertTypes: 'image/webp',
      success(result) {
        resolve(result);
      },
      error(err) {
        reject(err);
      },
    });
  });

  return compressedImage;
};
