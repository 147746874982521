import { Button, Flex, Hidden, Stack, Text } from 'native-base';
import { Controller } from 'react-hook-form';
import CircleZone from 'ui/components/Dropzone/CircleZone';
import Loading from 'ui/components/Loading';
import MaskInput from 'ui/components/MaskInput';
import PhoneInput from 'ui/components/PhoneInput';
import Select from 'ui/components/v2/inputs/Select';
import TextInput from 'ui/components/v2/inputs/TextInput';
import LocationAutoComplete from 'ui/components/v2/LocationInputs/LocationAutoComplete';
import { colors } from 'ui/theme/colors';

import { ModalDiscardChanges } from '~/components/ModalDiscardChanges';
import { Tab } from '~/components/Tabs';
import { useUserProfileController } from '~/pages/Authenticated/UserProfile/useUserProfileController';
import { HELP_TEXT_MESSAGE } from '~/pages/Authenticated/UserProfile/utils';
import { GooglePlacesAutoCompleteAdapter } from '~/services/google/google-places-auto-auto-complete';

const googlePlacesAutoComplete = GooglePlacesAutoCompleteAdapter.getSingleton();

export const UserProfile = () => {
  const {
    control,
    errors,
    isLoading,
    profileImageTitle,
    departmentsOptions,
    saveOrDiscardModalRef,
    isOrganizationLoading,
    isUserProfileLoading,
    doesOrganizationHasExternalId,
    handleCloseSaveOrDiscardModal,
    handleOpenSaveOrDiscardModal,
    handleDiscardChanges,
    onSubmitHandler,
  } = useUserProfileController();

  if (isOrganizationLoading || isUserProfileLoading)
    return <Loading containerHeight="80vh" />;

  return (
    <Flex
      flex={1}
      h={{ base: 'auto', md: 'full' }}
      w={{ base: 'auto', md: 'full' }}
      bg="white"
      rounded={8}
    >
      {/* Header */}
      <Hidden till="md">
        <Stack flexShrink={0}>
          <Tab
            key={'AccountSettings'}
            label="Profile"
            description="View and edit your personal information"
            icon="user"
            isActive
          />
        </Stack>
      </Hidden>

      {/* Body */}
      <Stack
        direction={{ base: 'column', md: 'row' }}
        p={{ base: 4, md: 8 }}
        space={10}
        h={{ base: 'auto', md: 'full' }}
        flexShrink={{ base: 0, md: 1 }}
      >
        <Stack flex={{ base: 'auto', md: 0.15 }}>
          <Controller
            name="profilePicture"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CircleZone
                filesField="image"
                acceptedWidth={400}
                acceptedHeight={400}
                title={profileImageTitle}
                handleDropFile={onChange}
                defaultImage={value as string}
                btnIconColor={colors.singletons.black}
                btnBgColor={colors.gray[100]}
                btnLabelColor={colors.singletons.black}
                btnLabel="Upload image"
                leftIconName="upload"
                circleWidth="128px"
                circleHeight="128px"
                titleColor="gray.600"
              />
            )}
          />
        </Stack>
        <Stack
          flex={{ base: 'auto', md: 0.85 }}
          space={6}
          mb={{ base: 48, md: 0 }}
        >
          <Stack direction={{ base: 'column', md: 'row' }} space={6} w="full">
            <Stack w={{ base: '100%', md: '33%' }}>
              <TextInput.Controlled
                isRequired
                control={control}
                name="firstName"
                label="First name"
                errorMessage={errors?.firstName?.message}
                helpText={HELP_TEXT_MESSAGE}
              />
            </Stack>
            <Stack w={{ base: '100%', md: '33%' }}>
              <TextInput.Controlled
                isRequired
                control={control}
                name="lastName"
                label="Last name"
                errorMessage={errors?.lastName?.message}
                helpText={HELP_TEXT_MESSAGE}
              />
            </Stack>

            <Stack w={{ base: '100%', md: '33%' }}>
              <Select.Controlled
                isRequired
                control={control}
                label="Group"
                name="organizationDepartment"
                placeholder="Select department"
                options={departmentsOptions}
                isClearable
                errorMessage={errors?.organizationDepartment?.message || ''}
              />
            </Stack>
          </Stack>
          <Stack direction={{ base: 'column', md: 'row' }} space={6} w="full">
            <Stack w={{ base: '100%', md: '50%' }}>
              <MaskInput
                name="birthday"
                control={control}
                placeholder="Date of birth"
                label="Date of birth"
                labelColor={colors.gray[600]}
                leftIconName="calendar"
                errorMessage={errors.birthday?.message}
                helperText={HELP_TEXT_MESSAGE}
                isFullWidth
              />
            </Stack>
            <Stack w={{ base: '100%', md: '50%' }}>
              <PhoneInput
                control={control}
                name="phoneNumber"
                label="Phone number"
                w="100%"
                errorMessage={errors?.phoneNumber?.message}
                helperText={HELP_TEXT_MESSAGE}
              />
            </Stack>
          </Stack>

          {doesOrganizationHasExternalId && (
            <Stack>
              <TextInput.Controlled
                control={control}
                name="externalId"
                label="External ID"
                errorMessage={errors?.externalId?.message}
              />
            </Stack>
          )}

          <Stack>
            <Controller
              name="location"
              control={control}
              render={({ field: { onChange, value } }) => (
                <LocationAutoComplete
                  isRequired
                  label="Full address"
                  onChange={onChange}
                  defaultValue={value || undefined}
                  placeholder="Enter your location"
                  errorMessage={errors?.location?.message?.toString()}
                  helpText={HELP_TEXT_MESSAGE}
                  height={'3.25rem'}
                  provider={googlePlacesAutoComplete}
                />
              )}
            />
          </Stack>
        </Stack>
      </Stack>

      {/* Footer */}
      <Stack
        position={{ base: 'fixed', lg: 'relative' }}
        bottom={{ base: 16, sm: 0 }}
        w="full"
        alignSelf="center"
        direction={{ base: 'column-reverse', md: 'row' }}
        p={{ base: 4, md: 6 }}
        space={4}
        justifyContent={{ base: 'space-between', md: 'center' }}
        bg="white"
      >
        <Button
          minW={{ base: 'full', md: '200px' }}
          w={{ base: 'full', md: '200px' }}
          minH="44px"
          variant="ghost"
          onPress={handleOpenSaveOrDiscardModal}
        >
          <Text
            color="singletons.darkText"
            fontSize={{ base: 'md', md: 'sm' }}
            fontWeight="medium"
          >
            Cancel
          </Text>
        </Button>
        <Button
          isLoading={isLoading}
          onPress={onSubmitHandler}
          minW={{ base: 'full', md: '200px' }}
          w={{ base: 'full', md: '200px' }}
          minH="44px"
        >
          <Text
            color="white"
            fontSize={{ base: 'md', md: 'sm' }}
            fontWeight="medium"
          >
            Save changes
          </Text>
        </Button>
      </Stack>

      {/* Modals */}
      <ModalDiscardChanges
        modalRef={saveOrDiscardModalRef}
        handleOnSave={onSubmitHandler}
        handleCloseModal={handleCloseSaveOrDiscardModal}
        handleDiscardChanges={handleDiscardChanges}
        isLoading={isLoading}
      />
    </Flex>
  );
};
