import {
  Box,
  Hidden,
  HStack,
  Image,
  PresenceTransition,
  Pressable,
  Skeleton,
  Stack,
  Text,
  VStack,
} from 'native-base';
import React from 'react';
import placeholderImage from 'ui/assets/svgs/placeholder.svg';
import Button from 'ui/components/Button';
import ActivityTypeBadge from 'ui/components/v2/Badges/ActivityTypeBadge';

import DistanceLabel from '~/pages/Authenticated/InApp/components/SearchResultItem/fragments/DistanceLabel';
import { SearchResultItemProps } from '~/pages/Authenticated/InApp/components/SearchResultItem/types';
import { colors } from '~/theme/colors';

export const SearchResultItem = (props: SearchResultItemProps) => {
  const { data, onPress } = props;

  return (
    <PresenceTransition
      visible={true}
      style={{
        width: '100%',
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 350 } }}
    >
      <Pressable p={4} onPress={onPress}>
        <HStack
          flex={1}
          w="full"
          space={3}
          alignItems={'center'}
          backgroundColor={{ md: colors.gray[100] }}
          borderRadius={8}
          borderWidth={{ md: 1 }}
          borderColor={colors.gray[200]}
        >
          <Box
            w={{ base: 24, md: '150px' }}
            h={{ base: 24, md: '150px' }}
            borderRadius={100}
            padding={1}
            backgroundColor={colors.white}
            borderColor={colors.gray[200]}
            borderWidth={1}
            overflow={'hidden'}
          >
            <Stack width="100%" height="100%">
              <Skeleton width="100%" height="100%" />
            </Stack>

            <Image
              source={{ uri: data.coverImageUrl || '' }}
              size={12}
              w={'full'}
              h="full"
              resizeMode="cover"
              borderRadius={100}
              alt={`Logo from activity ${data.title}`}
              fallbackSource={{ uri: placeholderImage }}
            />
          </Box>
          <VStack space={{ base: 2, md: 3 }} flex={1} p={{ base: 0, md: 4 }}>
            <HStack
              justifyContent={{ base: 'space-between', md: 'flex-start' }}
              space={3}
            >
              <ActivityTypeBadge activityType={data.semanticType} />
              {!data.hideDistance && <DistanceLabel distance={data.distance} />}
            </HStack>

            <VStack>
              <Text
                fontSize={{ base: 'sm', md: 'lg' }}
                fontWeight={500}
                color={colors.darkText}
                noOfLines={1}
                lineHeight={'140%'}
              >
                {data.title}
              </Text>

              <Text
                fontSize={{ base: 'xs', md: 'sm' }}
                fontWeight={400}
                color={colors.gray[600]}
                noOfLines={1}
                lineHeight={'125%'}
              >
                by {data.organizationName}
              </Text>
            </VStack>
          </VStack>
          <Hidden till="lg">
            <Box position={'absolute'} top={4} right={5}>
              <Button
                px={{ lg: 6, xl: 8 }}
                h={9}
                minW={'unset'}
                variant="solid"
                backgroundColor={colors.black}
                onPress={onPress}
              >
                <Text color={colors.white} fontSize={'sm'} fontWeight="medium">
                  Apply
                </Text>
              </Button>
            </Box>
          </Hidden>
        </HStack>
      </Pressable>
    </PresenceTransition>
  );
};

export default React.memo(SearchResultItem);
