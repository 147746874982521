import { Avatar, HStack, Stack, Text } from 'native-base';

import { colors } from '~/theme/colors';

import { FooterProps } from './types';
const BHO_DEFAULT_TITLE = 'The Big Help Out';
const BHO_DEFAULT_DESCRIPTION = (
  <>
    The Big Help Out aims to raise awareness about volunteering throughout the
    UK. It is a platform for people to experience volunteering, lend a hand to a
    cause they love and make a difference in their communities. This is not a
    profit making initiative and is run by the /together initiative. You can
    find out more about /together
    <a style={{ color: '#00f' }} target="blank" href="https://together.org.uk/">
      {' '}
      here
    </a>
    .
    <br />
    Visit {''}
    <a
      style={{ color: '#00f' }}
      target="_blank"
      rel="bighepout noreferrer"
      href="https://www.thebighelpout.org.uk"
    >
      www.thebighelpout.org.uk
    </a>{' '}
    to know more about the campaign!
  </>
);

import { DEFAULT_APP_ID } from '~/config';

export const Footer = (props: FooterProps) => {
  const { appLogo, appName, text = '', appId } = props;
  return (
    <Stack py={8}>
      <HStack alignItems="center" space={3}>
        <Avatar
          source={{
            uri: appLogo,
          }}
          width={'3.5rem'}
          height={'3.5rem'}
          rounded={16}
          _image={{ rounded: 16 }}
          borderWidth={1}
          borderColor="gray.300"
          bgColor="none"
        />
        <Stack space={1}>
          <Text color="gray.700" fontSize="md">
            About
          </Text>
          <Text
            color={colors.singletons.darkText}
            fontWeight={700}
            fontSize="xl"
          >
            {appId && appId === DEFAULT_APP_ID ? BHO_DEFAULT_TITLE : appName}
          </Text>
        </Stack>
      </HStack>

      <Stack mt={6}>
        <Text maxW="37.5rem" fontSize="md" color={colors.singletons.darkText}>
          {appId && appId === DEFAULT_APP_ID
            ? BHO_DEFAULT_DESCRIPTION
            : text || BHO_DEFAULT_DESCRIPTION}
        </Text>
      </Stack>
    </Stack>
  );
};
