import { Box, Hidden, HStack, Image, Stack, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import Input from 'ui/components/Input';
import Loading from 'ui/components/Loading';
import { colors } from 'ui/theme/colors';
import { transformInputValueToLowerCase } from 'ui/utils/formatter';

import logoDoit from '~/assets/images/doit-logo.svg';
import logoDoitBlack from '~/assets/images/doit-logo-black.svg';
import { HelpCentreLink } from '~/components/HelpCentre/HelpCentreLink';

import { useLoginController } from './useLoginController';

const SignIn = () => {
  const {
    control,
    onSubmit,
    errors,
    canSignIn,
    handleGoToSignUp,
    handleGoToForgotPassword,
    onKeyDown,
    isLoading,
    isLoadingEcosystem,
    signInEcosystem,
    isDefaultSignInEcosystem,
    logoWidth,
    logoHeight,
  } = useLoginController();

  return isLoadingEcosystem ? (
    <Stack h="100vh" w="100vw" alignItems="center" justifyContent="center">
      <Loading />
    </Stack>
  ) : (
    <VStack>
      <Hidden from="md">
        <HStack
          pt={2}
          pb={3}
          justifyContent={'flex-end'}
          background="singletons.lightText"
        >
          <HelpCentreLink />
        </HStack>
      </Hidden>

      <Box
        flex={1}
        flexDirection={{ sm: 'column', md: 'row' }}
        minHeight="100vh"
        width="100%"
        background="singletons.lightText"
        alignSelf="center"
      >
        <Stack
          position="relative"
          overflow="hidden"
          w={{ base: '100%', md: '45%' }}
          px={{ base: 4, md: 12 }}
          pt={{ base: 12, md: '15vh' }}
          pb={{ base: 0, md: '15vh' }}
          flex={{ base: 0.3, md: 'auto' }}
        >
          <Box
            flexDir={{ base: 'row', md: 'column' }}
            alignItems={{ base: 'center', md: 'flex-start' }}
            justifyContent={'center'}
            mt={1}
            mb={6}
          >
            <Box
              mr={{ base: 2, md: 0 }}
              mb={{ base: 0, md: '2vh' }}
              borderRadius={isDefaultSignInEcosystem ? 'none' : 'full'}
              overflow="hidden"
            >
              <Image
                src={
                  isDefaultSignInEcosystem
                    ? logoDoitBlack
                    : signInEcosystem?.logo
                }
                w={logoWidth}
                h={logoHeight}
                resizeMode="cover"
                alt="logo"
              />
            </Box>
            <Stack>
              <Stack w="100%">
                <Text
                  fontSize={{ base: 'md', md: '4xl' }}
                  fontWeight={700}
                  color="singletone.darkText"
                  mt={{ base: 0, md: '2vh' }}
                >
                  {isDefaultSignInEcosystem
                    ? 'Volunteers'
                    : signInEcosystem?.name}
                </Text>
              </Stack>

              <Hidden from="md">
                {!isDefaultSignInEcosystem ? (
                  <Box flexDir="row" mt={1}>
                    <Text
                      fontSize="xs"
                      color="gray.500"
                      fontWeight="medium"
                      mr={1}
                    >
                      Powered by
                    </Text>
                    <Image src={logoDoit} width="28px" height="16px" />
                  </Box>
                ) : null}
              </Hidden>
            </Stack>
          </Box>
          <Hidden from="base" till="md">
            <Box mb={'calc(5vh)'}>
              {!isDefaultSignInEcosystem ? (
                <Box flexDir="row" alignItems="center" mb={8}>
                  <Text fontSize="lg" color="gray.500" mr={2}>
                    Powered by
                  </Text>
                  <img src={logoDoit} width="57px" height="31px" />
                </Box>
              ) : null}
              <Box>
                <Text
                  fontSize="xl"
                  color="gray.500"
                  fontWeight="bold"
                  lineHeight={24}
                >
                  Together.
                </Text>
                <Text
                  fontSize="xl"
                  color="gray.500"
                  fontWeight="bold"
                  lineHeight={24}
                >
                  Doing Good.
                </Text>
                <Text
                  fontSize="xl"
                  color="gray.500"
                  fontWeight="bold"
                  mb={3}
                  lineHeight={24}
                >
                  Living Well.
                </Text>
                <Text
                  fontSize="sm"
                  color="gray.500"
                  maxW="180"
                  fontWeight="medium"
                >
                  A world of opportunities to make a diference!
                </Text>
              </Box>
            </Box>
          </Hidden>
        </Stack>

        <Stack
          w={{ sm: '100%', md: '55%' }}
          px={{ base: 4, md: '10vw' }}
          py={{ base: 6, md: '15vh' }}
          background="singletons.white"
          flex={{ base: 0.7, md: 'auto' }}
        >
          <VStack maxWidth="550px" w="100%">
            <Hidden till="md">
              <HStack mb={10} justifyContent={'flex-end'}>
                <HelpCentreLink />
              </HStack>
            </Hidden>
            <Box mb={{ base: 8, md: 10 }}>
              <Text
                textAlign="initial"
                fontSize={{ base: 'xl', md: '2xl' }}
                fontWeight="medium"
              >
                Sign in to your account
              </Text>
              <Text mt={3} maxWidth="400px" fontSize={{ base: 'sm', md: 'md' }}>
                Welcome back to {signInEcosystem?.name}
              </Text>
            </Box>
            <Stack width="100%">
              <Input
                name="email"
                isRequired
                control={control}
                placeholder="Type your email"
                label="Email"
                errorMessage={errors.email?.message}
                iconErrorMessage="info"
                isFullWidth
                backgroundColor="white"
                borderColor="muted.200"
                leftIconName="user"
                leftIconColor={colors.gray[400]}
                onChangeTransform={transformInputValueToLowerCase}
              />
              <Box mb={6} />
              <Input
                name="password"
                control={control}
                isRequired
                placeholder="Type your password"
                type="password"
                label="Password"
                errorMessage={errors.password?.message}
                iconErrorMessage="info"
                isFullWidth
                backgroundColor="white"
                borderColor="muted.200"
                onKeyPress={onKeyDown}
                leftIconName="lock"
                leftIconColor={colors.gray[400]}
              />
              <Button variant="unstyled" mt={4} mb={10}>
                <Text
                  textDecorationLine="underline"
                  textAlign="center"
                  onPress={handleGoToForgotPassword}
                  fontWeight="medium"
                  color="singletons.darktext"
                >
                  I forgot my password
                </Text>
              </Button>
            </Stack>
            <Stack mb={6}>
              <Button
                onPress={onSubmit}
                isDisabled={canSignIn || isLoading}
                isLoading={isLoading}
                p={6}
              >
                <Text fontSize="medium" color="white" fontWeight="medium">
                  Sign in
                </Text>
              </Button>
            </Stack>
            <VStack alignItems="center" justifyContent="center">
              <Text color="black" fontSize="md" mb={4}>
                You don&apos;t have an account?
              </Text>
              <Button variant="unstyled" onPress={handleGoToSignUp}>
                <Text
                  color="singletons.black"
                  fontWeight="medium"
                  fontSize="md"
                  textDecoration="none"
                  pr={3}
                >
                  Sign up now
                </Text>
              </Button>
            </VStack>
          </VStack>
        </Stack>
      </Box>
    </VStack>
  );
};

export default SignIn;
