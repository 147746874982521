import { useBreakpointValue } from 'native-base';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { useAppSelector } from '~/hooks/useAppSelector';
import {
  SearchBarFormFields,
  SearchTopBarProps,
} from '~/pages/Authenticated/InApp/components/SearchTopBar/types';
import { ActivitySearchSection } from '~/pages/Authenticated/InApp/Explorer/types';

export const useSearchBarController = (props: SearchTopBarProps) => {
  const { onSubmit, initialValues } = props;
  const [showModal, setShowModal] = useState(false);

  const { selectedApp } = useAppSelector(({ inApp }) => inApp);

  const { control, handleSubmit, watch, setValue } =
    useForm<SearchBarFormFields>({
      defaultValues: {
        causes: initialValues?.causes ?? [],
        searchComposition: {
          value: [initialValues.sectionType, initialValues.search],
        },
      },
    });

  const selectedCauses = watch('causes');
  const searchComposition = watch('searchComposition');
  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleOnSubmit = (data: SearchBarFormFields) => {
    const [sectionType, search] = data.searchComposition?.value ?? [];
    onSubmit({
      causes: data.causes,
      sectionType: sectionType ?? initialValues.sectionType,
      search: search || '',
    });
  };

  const handleMobileOnSubmit = (data: {
    search: string;
    sectionType?: ActivitySearchSection;
  }) => {
    const sectionType = data.sectionType ?? initialValues.sectionType;
    const search = data.search || '';
    setValue('searchComposition', {
      value: [sectionType, search],
    });

    handleSubmit(handleOnSubmit)();
    setShowModal(false);
  };

  const handleMobileClearSubmit = () => {
    if (isMobile) {
      handleSubmit(handleOnSubmit)();
    }
  };

  const handleCausesChange = (
    newCauses: Array<{ value: string; label: string }>,
  ) => {
    setValue('causes', newCauses);
  };

  const toggleModal = () => {
    if (isMobile || showModal === true) {
      setShowModal((prev) => !prev);
    }
  };

  return {
    control,
    selectedCauses,
    handleSubmit,
    handleOnSubmit,
    handleMobileOnSubmit,
    handleCausesChange,
    handleMobileClearSubmit,
    showModal,
    toggleModal,
    isMobile,
    searchComposition,
    selectedApp,
  };
};
