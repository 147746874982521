import { yupResolver } from '@hookform/resolvers/yup';
import { differenceInSeconds } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ActivityTypeEnum } from 'ui/enums';
import { secondsToHoursAndMinutes } from 'ui/utils/secondsToDuration';

import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import { LogHoursActivityFormProps } from '~/pages/Authenticated/LogActivity/components/LogHoursActivity/types';
import { schemaValidator } from '~/pages/Authenticated/LogActivity/components/schema';
import { ActivitiesService } from '~/services/resources/activities';
import { ActivityApplicationsService } from '~/services/resources/activity-applications';
import { measurementsDataSliceActions } from '~/store/slices/measurementsData';
import { IActivity, IActivityApplication } from '~/types/interfaces/activity';
import { getMeasurementsMap } from '~/utils/getMeasurementsMap';

export const getTitleTextByActivityType = (activityType: ActivityTypeEnum) => {
  switch (activityType) {
    case ActivityTypeEnum.Event:
      return 'Did you attend the event?';
    case ActivityTypeEnum.OngoingOpportunity:
      return 'Log ongoing opportunity';
    default:
      return 'Log action';
  }
};

export const getSubTitleTextByActivityType = (
  activityType: ActivityTypeEnum,
) => {
  switch (activityType) {
    case ActivityTypeEnum.Event:
      return 'Please register your participation';
    default:
      return 'Enter the amount for the day.';
  }
};

export const useLogHoursActivityController = (
  activity: IActivity,
  activityApplication: IActivityApplication,
) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { goBack, goToRoute } = useRouter();

  const { selectedUserProfile } = useAppSelector(({ auth }) => auth);
  const dispatch = useAppDispatch();

  const userNotRegisteredParticipationInEvent = useMemo(() => {
    return (
      activity?.activityDefinitionSubDocument?.type ===
        ActivityTypeEnum.Event && !activityApplication?.isAttendanceConfirmed
    );
  }, [activity, activityApplication]);

  const eventDuration = useMemo(() => {
    if (activity?.endDate && activity.startDate) {
      const totalSecondsDuration = differenceInSeconds(
        new Date(activity.endDate),
        new Date(activity.startDate),
      );
      const { hours, minutes } = secondsToHoursAndMinutes(totalSecondsDuration);
      return {
        hours: Number(hours) < 0 ? '' : hours,
        minutes: Number(minutes) <= 0 ? '' : minutes,
      };
    }
    return null;
  }, [activity]);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<LogHoursActivityFormProps>({
    resolver: yupResolver(schemaValidator),
    mode: 'onChange',
    defaultValues: {
      date: new Date(),
    },
  });

  const onSubmit = handleSubmit(async (values: LogHoursActivityFormProps) => {
    try {
      setIsSubmitting(true);
      if (userNotRegisteredParticipationInEvent) {
        await handleConfirmAttendance();
      }
      const hoursInSeconds = (+values.hours || 0) * 60 * 60;
      const minutesInSeconds = (+values.minutes || 0) * 60;
      const amount = hoursInSeconds + minutesInSeconds;
      await ActivityApplicationsService.createMeasurementAmount(
        activityApplication._id,
        {
          amount,
          measurementDate: values.date,
        },
      );
      reset({
        hours: '',
        minutes: '',
      });
      if (selectedUserProfile?._id) {
        const { mapApplicationsData } = await getMeasurementsMap(
          selectedUserProfile._id,
        );
        dispatch(
          measurementsDataSliceActions.setMeasurementsData({
            ...mapApplicationsData,
          }),
        );
      }

      goToRoute(`/my-wallet/log-activity/${activityApplication._id}/success`);
      toast.success('Your activity was updated.');
    } catch (err) {
      toast.error('An error occurred when trying to log activity.');
    } finally {
      setIsSubmitting(false);
    }
  });

  const handleConfirmAttendance = async () => {
    try {
      if (!activity?._id || !activityApplication?.user) return;
      await ActivitiesService.confirmAttendanceToActivity(
        activity._id,
        activityApplication.user,
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      activity?.activityDefinitionSubDocument.type === ActivityTypeEnum.Event
    ) {
      reset({
        hours: eventDuration?.hours,
        minutes: eventDuration?.minutes,
        date: new Date(),
      });
    }
  }, [reset, eventDuration, activity]);

  return {
    control,
    isValid,
    errors,
    isSubmitting,
    userNotRegisteredParticipationInEvent,
    onSubmit,
    goBack,
  };
};
