import { useInfiniteQuery } from 'react-query';

import { IPagination } from '~/hooks/useInfinityActivity/useInfinityActivity.types';
import { api } from '~/services/api';
import { PopulatedMeasurementDefinition } from '~/types/interfaces/measurement-definition';

import { GenericObject } from '../types';

const ITEMS_PER_PAGE = 10;

export type UseInfinityMeasurementDefinitionResponse = Promise<{
  responseData: IPagination<PopulatedMeasurementDefinition[]>;
  skip: number;
}>;

const getPaginatedMeasurementDefinition = async (args: {
  page?: number;
  disabled?: boolean;
  props?: GenericObject;
}): UseInfinityMeasurementDefinitionResponse => {
  const { page = 0, props } = args;

  const params: GenericObject = {
    skip: page,
    limit: ITEMS_PER_PAGE,
    sort: JSON.stringify({ updatedAt: 'desc' }),
  };

  if (props?.filter) {
    params['filter'] = JSON.stringify({
      ...props.filter,
    });
  }

  const { data: responseData } = await api.get(
    'measurement-definition/off-platform',
    { params },
  );

  return {
    responseData,
    skip: page + ITEMS_PER_PAGE,
  };
};

export default function useInfinityOffPlatformMeasurementDefinitions(
  props: GenericObject,
  disabled = false,
): any {
  const queryKey = [
    'infinity-query-off-platform-measurement-definitions',
    props,
  ];

  return useInfiniteQuery(
    queryKey,
    ({ pageParam }) =>
      getPaginatedMeasurementDefinition({
        page: pageParam,
        disabled,
        props,
      }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.responseData.data.length < ITEMS_PER_PAGE)
          return undefined;
        return lastPage.skip;
      },
    },
  );
}
