import debounce from 'debounce-promise';
import { StatusCodes } from 'http-status-codes';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useAppSelector } from '~/hooks/useAppSelector';
import { UserProfileService } from '~/services/resources/user-profile';
import { IUserProfile } from '~/types/interfaces/user';

import { IMemberValue } from '../../../../../../types/interfaces/team';
import { validateEmail } from '../../../../../../utils/validations/validateEmail';

export const useAddMemberInputController = () => {
  const { control, watch, resetField } = useForm();

  const [member, setMember] = useState<IMemberValue | null>(null);

  const watchMember = watch('member');

  useEffect(() => {
    setMember(watchMember as IMemberValue);
  }, [watchMember]);

  const isDisabled = (): boolean => {
    if (member?.__isNew__) {
      return !validateEmail(member?.value);
    }
    return !member;
  };

  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);
  const loadMembersAutocomplete = useCallback(
    async (text: string) => {
      try {
        const { data, status } =
          await UserProfileService.userProfileAutoCompleteSearch(
            text,
            selectedEcosystem?._id as string,
          );
        if (status === StatusCodes.OK) {
          const options = data?.map(
            (memberAutoComplete: Partial<IUserProfile>) => {
              return {
                value: memberAutoComplete._id,
                label: memberAutoComplete?.userSummary?.name,
                email: memberAutoComplete?.userSummary?.email,
              };
            },
          );

          return options;
        }
      } catch (error) {
        console.error(error);
      }
    },
    [selectedEcosystem],
  );

  const getAsyncOptions = (inputValue: string) => {
    return new Promise((resolve) => {
      resolve(loadMembersAutocomplete(inputValue));
    });
  };

  const loadOptions = debounce(getAsyncOptions, 1000);

  return { control, loadOptions, member, resetField, isDisabled };
};
