import { ActivityRegionType } from 'ui/enums';

export enum ActivityType {
  Event = 'Event',
  Action = 'Action',
  Ongoing = 'OngoingOpportunity',
}

/* 
-- ActivitySemanticType
  This enum concerns how we handle activity types in the volunteer app. 
  Treating 'team event' as an activity type in the frontend may facilitate maintenance
*/

export enum ActivitySemanticType {
  Event = 'Event',
  Action = 'Action',
  Ongoing = 'OngoingOpportunity',
  TeamEvent = 'TeamEvent',
}

export type ActivityCategory = {
  _id?: string;
  displayName?: string;
  icon?: string;
  createdAt?: string;
  updatedAt?: string;
  relatedTo?: RelatedToEnum;
};

export enum RelatedToEnum {
  REQUIREMENT_OPTIONS = 'requirementOptions',
  CAUSE_OPTIONS = 'causeOptions',
}

export type GeoJSONPoint = 'Point';

export interface ActivityRegion {
  _id: string;
  displayName: string;
  relatedTo?: string | null;
  type: ActivityRegionType;
  geocenterLocation: {
    lon: number;
    lat: number;
  };
}

export interface MappedRegions {
  mainRegionData: ActivityRegion;
  hasAllRegions?: boolean;
  relatedRegions: ActivityRegion[];
}

export interface ActivityNation extends ActivityRegion {
  type: ActivityRegionType.Nation;
}
