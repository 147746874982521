import { extendTheme } from 'native-base';

export const { colors } = extendTheme({
  colors: {
    primary: {
      50: '#EBF3F2',
      100: '#B4DDD8',
      200: '#3DB7A7',
      300: '#179D8B',
      400: '#018F88',
      500: '#138374',
      600: '#037973',
      700: '#006560',
      800: '#00413E',
      900: '#002A28',
    },
    secondary: {
      50: '#FDACA5',
      100: '#F38077',
      200: '#E66A60',
      300: '#DE594F',
      400: '#DB3C30',
      500: '#C0352A',
      600: '#A3281F',
      700: '#731912',
      800: '#4F0D08',
      900: '#2A0704',
    },
    grey: {
      50: '#E9EDF2',
      100: '#E2E5EB',
      200: '#D0D5DB',
      300: '#B9BDC7',
      400: '#A4A7B3',
      500: '#71717A',
      600: '#797C8F',
      700: '#525466',
      800: '#3B3C52',
      900: '#1A1A23',
    },
    tertiary: {
      50: '#FCDED3',
      100: '#F9BDA7',
      200: '#F79C7C',
      300: '#F47B50',
      400: '#F15A24',
      500: '#CD4D1F',
      600: '#A93F19',
      700: '#913616',
      800: '#60240E',
      900: '#371508',
    },
    info: {
      50: '#E2E8F4',
      100: '#C5D2E8',
      800: '#2C394F',
    },
    singletons: {
      white: '#FFFFFF',
      black: '#27272a',
      lightText: '##F7F7F7',
      darkText: '#27272a',
      mediumWhite: '#F7F7F7',
    },
  },
});
