import { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';
import { TeamMemberToDelete } from 'ui/components/Teams/TeamMemberList/types';
import { Team } from 'ui/types/teams';
import { getTeamMembersTotal, groupTeamMembersByStatus } from 'ui/utils/teams';

import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useDeleteOrLeaveTeamFlow } from '~/hooks/useDeleteOrLeaveTeamFlow';
import { useRouter } from '~/hooks/useRouter';
import ActivityApplicationService from '~/services/resources/activityApplication';
import TeamService from '~/services/resources/teams';
import { IActivity } from '~/types/interfaces/activity';
import {
  ITeamPopulateEcosystem,
  TeamMemberStatus,
} from '~/types/interfaces/team';
import { getLeaderAndMembersByStatus } from '~/utils/activityTeamMembers';

export const useViewTeamController = () => {
  const [activities, setActivities] = useState<IActivity[]>([]);
  const [isPublicInvitationLoading, setIsPublicInvitationLoading] =
    useState(false);
  const [
    isSubmittingRemoveExternalMemberTeam,
    setIsSubmittingExternalMemberTeam,
  ] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState<
    TeamMemberToDelete | undefined
  >(undefined);

  const deleteExternalTeamModalRef = useRef<IModalRefProps>();

  const {
    goToRoute,
    params: { id: teamId },
  } = useRouter();

  const { selectedEcosystem } = useAppSelector(({ ecosystem }) => ecosystem);
  const { selectedUserProfile } = useAppSelector(({ auth }) => auth);

  const {
    data: teamInfoResponse,
    isLoading: isLoadingTeam,
    refetch: refetchTeam,
  } = useQuery({
    queryKey: ['team', teamId],
    queryFn: () =>
      TeamService.findAll({
        filter: JSON.stringify({
          _id: teamId,
        }),
        populate: JSON.stringify(['ecosystem']),
      }),
    enabled: !!teamId,
  });

  const { data: applicationsData, isLoading: isLoadingApplications } = useQuery(
    {
      queryKey: ['applicationsTeamId', teamId],
      queryFn: () =>
        ActivityApplicationService.findAll({
          filter: JSON.stringify({
            team: teamId,
          }),
        }),
      enabled: !!teamId && !!selectedEcosystem?._id,
    },
  );

  const {
    deleteTeamModalRef,
    handleOpenModal,
    handleCloseModal,
    isLoading: isLoadingModal,
    handleModalSubmit,
    modalInfo,
  } = useDeleteOrLeaveTeamFlow({
    teamId: teamId as string,
    isViewDetails: true,
    isLeader: selectedUserProfile?._id === teamInfoResponse?.data[0]?.leader,
  });

  const onEditTeam = () => {
    return goToRoute(`${PAGES.EditTeam}/${teamId}`, {
      state: {
        showBackButton: true,
      },
    });
  };
  const onDeleteTeam = () => {
    return handleOpenModal();
  };

  const onLeaveTeam = () => {
    return handleOpenModal();
  };

  useEffect(() => {
    if (!isLoadingApplications && applicationsData) {
      const activities = applicationsData.data.map(
        (application) => application.activitySubDocument,
      );
      setActivities(activities);
    }
  }, [applicationsData, isLoadingApplications]);

  // ?
  const isLoading = useMemo(
    () => isLoadingTeam || isLoadingApplications,
    [isLoadingApplications, isLoadingTeam],
  );

  const { membersQuantity, leader, approvedMembers, pendingMembers } =
    useMemo(() => {
      if (!teamInfoResponse?.data?.[0]) {
        return {
          leader: { name: '', avatarUrl: '', phoneNumber: '' },
          approvedMembers: [],
          pendingMembers: [],
        };
      }

      const { leader, members: approvedMembers } = getLeaderAndMembersByStatus(
        teamInfoResponse?.data?.[0] as ITeamPopulateEcosystem,
        TeamMemberStatus.ACCEPTED,
      );

      const { members: pendingMembers } = getLeaderAndMembersByStatus(
        teamInfoResponse?.data?.[0] as ITeamPopulateEcosystem,
        TeamMemberStatus.PENDING,
      );

      const membersQuantity = getTeamMembersTotal(
        teamInfoResponse?.data?.[0] as unknown as Team,
      );

      return {
        membersQuantity,
        leader,
        approvedMembers,
        pendingMembers,
      };
    }, [teamInfoResponse?.data]);

  const teamInfo = useMemo(() => {
    return teamInfoResponse?.data[0] ?? null;
  }, [teamInfoResponse]);

  const handlePublicInvitationReload = async () => {
    if (!teamInfo) return;
    try {
      setIsPublicInvitationLoading(true);
      await TeamService.reloadPublicInvitation(teamInfo._id);
      refetchTeam();
      toast.success('Public invite updated');
    } catch (error) {
      toast.error('Failed to perform operation');
    } finally {
      setIsPublicInvitationLoading(false);
    }
  };

  const onDeleteTeamMember = (data: TeamMemberToDelete) => {
    setMemberToDelete(data);
    handleOpenDeleteTeamMemberModal();
  };

  const handleOpenDeleteTeamMemberModal = () => {
    deleteExternalTeamModalRef.current?.open();
  };

  const handleCloseDeleteTeamMemberModal = () => {
    deleteExternalTeamModalRef.current?.close();
  };

  const handleDeleteExternalTeamMember = async () => {
    try {
      if (!teamId || !memberToDelete) return;
      setIsSubmittingExternalMemberTeam(true);
      await TeamService.deleteExternalTeamMember({
        teamId,
        externalMemberId: memberToDelete._id,
      });
      handleCloseDeleteTeamMemberModal();
      refetchTeam();
    } catch (error) {
      toast.error(`Error on trying to remove member.`);
    } finally {
      setIsSubmittingExternalMemberTeam(false);
    }
  };

  const handleDeleteInternalTeamMember = async () => {
    try {
      if (!teamId || !memberToDelete) return;
      setIsSubmittingExternalMemberTeam(true);
      await TeamService.removeTeamMembers({
        team: teamId,
        members: [memberToDelete._id],
      });
      handleCloseDeleteTeamMemberModal();
      refetchTeam();
    } catch (error) {
      toast.error(`Error on trying to remove member.`);
    } finally {
      setIsSubmittingExternalMemberTeam(false);
    }
  };

  const groupedMembers = useMemo(() => {
    if (!teamInfo) return null;
    return groupTeamMembersByStatus(teamInfo as unknown as Team);
  }, [teamInfo]);

  return {
    teamInfo,
    leaderAndApprovedMembers: { members: approvedMembers, leader },
    pendingMembers,
    isLoading,
    activities,
    selectedUserProfile,
    onEditTeam,
    onDeleteTeam,
    onLeaveTeam,
    deleteTeamModalRef,
    handleOpenModal,
    handleCloseModal,
    isLoadingModal,
    handleModalSubmit,
    modalInfo,
    membersQuantity,
    handlePublicInvitationReload,
    handleDeleteExternalTeamMember,
    handleCloseDeleteTeamMemberModal,
    handleDeleteInternalTeamMember,
    onDeleteTeamMember,
    isPublicInvitationLoading,
    groupedMembers,
    deleteExternalTeamModalRef,
    isSubmittingRemoveExternalMemberTeam,
    memberToDelete,
  };
};
