export const secondsToDuration = (totalSeconds: number) => {
  const totalMinutes = Math.floor(totalSeconds / 60);

  const seconds = totalSeconds % 60;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return { hours, minutes, seconds };
};

export const secondsToHoursAndMinutes = (totalSeconds: number) => {
  if (totalSeconds === 0) return { hours: '00', minutes: '00' };
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  return { hours: formattedHours, minutes: formattedMinutes };
};
