import { Flex } from 'native-base';

import { PageScaffold } from '~/components/Layouts/Scaffolds/PageScaffold';
import { NavBar } from '~/components/NavBar';
import { RootPortal } from '~/components/RootPortal';
import { PreferenceLocationModal } from '~/components/v2/ModalTemplates/PreferenceLocationModal';
import { useEnablePreferenceLocationModal } from '~/hooks/useUserLocation/useEnablePreferenceLocationModal';

export const AuthLayout = ({
  hideBackButton = false,
  hideMobileNavBar = false,
  children,
}: {
  hideBackButton?: boolean;
  hideWebSideBar?: boolean;
  isMobileBordered?: boolean;
  hideMobileNavBar?: boolean;
  children: JSX.Element | JSX.Element[];
}) => {
  const { handleCloseModal, locationModalRef } =
    useEnablePreferenceLocationModal();

  return (
    <Flex flex={1} bg={'gray.100'} minH={'100vh'}>
      <NavBar
        hideBackButton={hideBackButton}
        hideMobileNavBar={hideMobileNavBar}
      />

      <PageScaffold>{children}</PageScaffold>

      <RootPortal>
        <PreferenceLocationModal
          modalRef={locationModalRef}
          handleCloseModal={handleCloseModal}
        />
      </RootPortal>
    </Flex>
  );
};
