import {
  Box,
  Flex,
  Hidden,
  HStack,
  Pressable,
  Text,
  VStack,
} from 'native-base';
import Button from 'ui/components/Button';
import { FilterButton } from 'ui/components/FilterButton';
import { colors } from 'ui/theme/colors';

import { RootPortal } from '~/components/RootPortal';
import { ActivityAutocomplete } from '~/pages/Authenticated/InApp/components/ActivityAutoComplete';
import { CauseOptionsAutoComplete } from '~/pages/Authenticated/InApp/components/CauseOptionsAutoComplete';
import OptionsLabelWrap from '~/pages/Authenticated/InApp/components/OptionsLabelWrap';
import { useSearchBarController } from '~/pages/Authenticated/InApp/components/SearchTopBar/controller';
import SearchModal from '~/pages/Authenticated/InApp/components/SearchTopBar/modals/SearchModal';
import { SearchTopBarProps } from '~/pages/Authenticated/InApp/components/SearchTopBar/types';

export const SearchTopBar = (props: SearchTopBarProps) => {
  const {
    onFilterPress,
    searchFoundResults,
    causesOptions,
    showResults,
    initialValues,
    showTypeLabel = false,
  } = props;

  const {
    control,
    isMobile,
    showModal,
    selectedCauses,
    toggleModal,
    handleSubmit,
    handleOnSubmit,
    handleCausesChange,
    handleMobileOnSubmit,
    handleMobileClearSubmit,
    searchComposition,
    selectedApp,
  } = useSearchBarController(props);

  return (
    <>
      <VStack flex={1} px={2} space={2}>
        <HStack w="full" space={3} alignItems={{ lg: 'center' }}>
          <Box width={'100%'} position={'relative'}>
            <form onSubmit={handleSubmit(handleOnSubmit)}>
              <ActivityAutocomplete
                label="Search"
                name="searchComposition"
                control={control}
                placeholder="Activities, organisations..."
                onClear={handleMobileClearSubmit}
                defaultValue={[initialValues.sectionType, '']}
                isSearchable={!isMobile}
                showTypeLabel={showTypeLabel}
              />
            </form>
            <Hidden from="md">
              <Pressable
                onPress={toggleModal}
                position={'absolute'}
                top={0}
                left={0}
                width="calc(100% - 36px)"
                h="full"
              />
            </Hidden>
          </Box>

          <Flex
            minW={{ base: 12, md: 'fit-content' }}
            justifyContent={'flex-end'}
          >
            <HStack
              justifyContent={'flex-end'}
              alignItems={'flex-end'}
              space={{ base: 0, md: 2, lg: 4 }}
              mb={{ lg: 1 }}
            >
              <Hidden from="lg">
                <FilterButton
                  onPress={onFilterPress}
                  filterCount={(initialValues?.causes ?? []).length}
                />
              </Hidden>

              <Hidden till="lg">
                <Box w={'360px'}>
                  <CauseOptionsAutoComplete
                    label="Causes"
                    name="causes"
                    control={control}
                    placeholder="Search by cause name"
                    causesOptions={causesOptions}
                  />
                </Box>
              </Hidden>

              <Hidden till="md">
                <Box h={'3rem'}>
                  <Button
                    h="full"
                    leftIconName="search"
                    leftIconSize={24}
                    fontSize={'md'}
                    fontWeight={500}
                    backgroundColor={
                      selectedApp?.brandColor || colors.primary[400]
                    }
                    onPress={handleSubmit(handleOnSubmit)}
                  >
                    Search
                  </Button>
                </Box>
              </Hidden>
            </HStack>
          </Flex>
        </HStack>

        <Hidden till={'lg'}>
          <Box mt={selectedCauses.length > 0 ? 4 : 0}>
            <OptionsLabelWrap
              options={selectedCauses}
              onChange={handleCausesChange}
            />
          </Box>
        </Hidden>

        {showResults ? (
          <Hidden from={'md'}>
            <Text
              fontSize={'xs'}
              fontWeight={400}
              color={colors.gray[500]}
              noOfLines={1}
            >
              {searchFoundResults} results found
            </Text>
          </Hidden>
        ) : null}
      </VStack>

      <RootPortal>
        <SearchModal
          initialValue={searchComposition?.value[1] || ''}
          isOpen={showModal}
          onClose={toggleModal}
          onSubmit={handleMobileOnSubmit}
        />
      </RootPortal>
    </>
  );
};

export default SearchTopBar;
