import Yup from '~/utils/validations/yup';

export const schemaValidator = Yup.object().shape(
  {
    hours: Yup.string().when('minutes', {
      is: (minutes: number) => minutes,
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required(),
    }),
    minutes: Yup.string().when('hours', {
      is: (hours: number) => hours,
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required(),
    }),
    date: Yup.date().required(),
  },
  [['hours', 'minutes']],
);
