import { useMemo } from 'react';
import { StylesConfig } from 'react-select';

import { ActivityAutocompleteProps } from '~/pages/Authenticated/InApp/components/ActivityAutoComplete/types';
import { ActivitySearchSection } from '~/pages/Authenticated/InApp/Explorer/types';
import { theme } from '~/theme';

const defaultStyle: StylesConfig = {
  control: (baseStyles) => ({
    ...baseStyles,
    fontFamily: theme.fontConfig.default,
    fontSize: theme.fontSizes.sm,
    paddingLeft: 26,
    height: '46px',
    borderRadius: 12,
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    fontFamily: theme.fontConfig.default,
    fontSize: 12,
  }),
  menuPortal: (baseStyles) => ({
    ...baseStyles,
    fontFamily: theme.fontConfig.default,
    fontSize: 12,
    Index: 9999,
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    fontWeight: 400,
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[400],
  }),
};

const activityTypeSuggestions = [
  { label: 'EVENTS', value: ActivitySearchSection.Event },
  { label: 'TEAM EVENTS', value: ActivitySearchSection.TeamEvent },
  { label: 'ACTIONS', value: ActivitySearchSection.Action },
  { label: 'ONGOING OPPORTUNITIES', value: ActivitySearchSection.Ongoing },
];

export const useActivityAutocompleteController = (
  props: ActivityAutocompleteProps,
) => {
  const { control, name } = props;
  const loadOptions = (inputValue: string): Promise<any> => {
    return new Promise((resolve) => {
      const suggestions = activityTypeSuggestions.map((suggestion) => {
        return {
          value: [suggestion.value, inputValue],
          label: suggestion.label,
        };
      });
      resolve(suggestions);
    });
  };

  const selectedType = control._getWatch(name);

  const selectedActivityTypeLabel = useMemo(() => {
    const found = activityTypeSuggestions.find(
      (s) => s.value === selectedType.value[0],
    );
    if (found) {
      return found.label;
    }
    return `${(selectedType.value[0] || '').toUpperCase()}`;
  }, [selectedType]);

  return { defaultStyle, loadOptions, selectedActivityTypeLabel };
};
