import { Flex, Stack, Text } from 'native-base';

import { colors } from '../../theme/colors';
import Button from '../Button';
import Icon from '../Icon';
import { FilterButtonProps } from './types';

export const FilterButton = (props: FilterButtonProps) => {
  const { onPress, customIcon = 'filter', filterCount = 0 } = props;
  return (
    <Button
      maxWidth={{ base: 'unset' }}
      minWidth={{ base: 'unset' }}
      w={12}
      h={12}
      bgColor={'transparent'}
      variant={'solid'}
      onPress={onPress}
      borderWidth={1}
      borderColor={colors.gray[300]}
      position={'relative'}
      backgroundColor={colors.white}
    >
      <Flex alignItems={'center'} justifyContent={'center'}>
        <Icon icon={customIcon} size="22" color={colors.gray[600]} />
      </Flex>

      {filterCount > 0 && (
        <Stack
          w={5}
          h={5}
          borderRadius={100}
          position={'absolute'}
          top={'-75%'}
          right={'-75%'}
          backgroundColor={colors.primary[500]}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Text
            color={colors.white}
            lineHeight={'100%'}
            fontSize={'10px'}
            textAlign={'center'}
            fontWeight={500}
          >
            {filterCount}
          </Text>
        </Stack>
      )}
    </Button>
  );
};

export default FilterButton;
