import { BHO_HELP_CENTER_URL } from 'ui/constants/commonUrl.constants';
import { colors } from 'ui/theme/colors';

import { IPopoverOption } from '~/components/NavBar/components/AccountDropdown/types';
import { DOIT_ORG_ADMIN_URL } from '~/config';
import { PAGES } from '~/constants/pages.constants';

export const optionGroups: Array<IPopoverOption[]> = [
  [
    {
      key: 'apps',
      label: 'Apps',
      icon: 'grid',
      path: PAGES.AllApps,
      action: 'router',
    },
    {
      key: 'activities',
      label: 'Activities',
      icon: 'calendar',
      path: PAGES.Activities,
      action: 'router',
    },
    {
      key: 'teams',
      label: 'Teams',
      icon: 'users',
      path: PAGES.Teams,
      action: 'router',
    },
    {
      key: 'log_activity',
      label: 'Log activity',
      icon: 'plus-circle',
      path: PAGES.LogActivities,
      action: 'router',
    },
    {
      key: 'opportunities',
      label: 'Opportunities',
      icon: 'list',
      path: PAGES.Opportunities,
      action: 'router',
    },
    {
      key: 'my_data',
      label: 'My data',
      icon: 'user',
      path: PAGES.Profile,
      action: 'router',
    },
  ],
  [
    {
      key: 'settings',
      icon: 'settings',
      label: 'Settings',
      path: PAGES.AccountSettings,
      action: 'router',
    },
    {
      key: 'switchPersona',
      icon: 'switch',
      label: 'Switch persona',
      path: PAGES.SwitchPersona,
      action: 'router',
    },
    {
      key: 'go_to_my_admin_area',
      icon: 'external-link',
      label: 'Go to my admin area',
      path: DOIT_ORG_ADMIN_URL,
      action: 'external',
    },
    {
      key: 'help_centre',
      icon: 'question',
      label: 'Help centre',
      path: BHO_HELP_CENTER_URL,
      action: 'external',
    },
    {
      key: 'sign_out',
      icon: 'log-out',
      iconColor: colors.error[600],
      label: 'Sign out',
      path: PAGES.AccountSettings,
      action: 'custom',
    },
  ],
];
