import { isEqual as isDateEqual, isSameDay } from 'date-fns';
import { useEffect, useState } from 'react';

import {
  CalendarInlineProps,
  DateSlot,
  getDateSlotKey,
} from '~/components/CalendarInline/types';

export const useCalendarInlineController = (props: CalendarInlineProps) => {
  const { date, onChange, dates, blockedDates } = props;

  const [calendarMap, setCalendarMap] = useState<Map<string, [DateSlot]>>(
    new Map(),
  );

  const [selectedDate, setSelectedDate] = useState<Date | null>(date);
  const [hightLightDates, setHightLightDates] = useState<Date[]>([]);
  const [dateTimes, setDateTimes] = useState<Date[]>([]);

  useEffect(() => {
    if (!dates) return;

    const newCalendarMap = new Map();
    const newHightLightDates = [];
    for (const date of dates) {
      const isBlocked = blockedDates.find((blockedDate) =>
        isDateEqual(blockedDate, date),
      );
      if (!isBlocked) newHightLightDates.push(date);

      const key = getDateSlotKey(date);
      if (newCalendarMap.get(key)) {
        newCalendarMap.get(key).push({ date, isBlocked: !!isBlocked });
      } else {
        newCalendarMap.set(key, [{ date, isBlocked: !!isBlocked }]);
      }
    }

    setCalendarMap(newCalendarMap);
    setHightLightDates(newHightLightDates);
  }, [dates, blockedDates, date]);

  const isDateBlocked = (date: Date) => {
    const key = getDateSlotKey(date);
    return (
      (calendarMap.get(key) ?? []).find((slot) => isDateEqual(slot.date, date))
        ?.isBlocked || false
    );
  };

  const isDateSlotBlocked = (date: Date) => {
    const key = getDateSlotKey(date);
    const slots = calendarMap.get(key);
    if (slots && slots.length > 0) {
      const result = (calendarMap.get(key) ?? []).every(
        (some) => some.isBlocked === true,
      );
      return result;
    }
    return false;
  };

  const handleDateChange = (newDate: Date) => {
    setSelectedDate(newDate);
  };

  const handleDateSubmit = (finalDate: Date) => {
    onChange(finalDate);
  };

  useEffect(() => {
    if (!selectedDate) return;
    const sameDayDates = dates.filter((date) => isSameDay(selectedDate, date));
    setDateTimes(sameDayDates ?? []);
  }, [selectedDate, dates]);

  return {
    handleDateChange,
    handleDateSubmit,
    isDateSlotBlocked,
    isDateBlocked,
    hightLightDates,
    selectedDate,
    calendarMap,
    dateTimes,
  };
};
