import { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';

import { PAGES } from '~/constants/pages.constants';
import { useRouter } from '~/hooks/useRouter';
import { GenericConfirmationPage } from '~/pages/Common/GenericConfirmationPage';

export const OpportunityApplicationConfirmation = () => {
  const { goToRoute, location } = useRouter();

  const handleGoBackHome = () => {
    if (location?.state?.appId) {
      goToRoute(`/mini-app/${location.state.appId}`, { replace: true });
      return;
    }
    goToRoute(PAGES.AllApps, { replace: true });
  };

  const handleGoToMyActivities = () => {
    goToRoute(PAGES.Activities, { replace: true });
  };

  return (
    <GenericConfirmationPage
      illustrationName={ILLUSTRATIONS_NAME.CONFIRMATION_GREAT}
      onPressPrimaryButton={handleGoBackHome}
      onPressSecondaryButton={handleGoToMyActivities}
      title={'Thank you for your application!'}
      description={
        'The host organisation will review your application and respond as soon as possible.'
      }
    />
  );
};
