import { ActivityType } from 'ui/types/activities';

import { IActivityDefinition } from '~/types/interfaces/activity';

export const getCoverImageFromActivityDefinition = (
  activityDefinition: IActivityDefinition,
) => {
  const organizationLogo =
    activityDefinition?.organizationSubDocument?.logoThumbnail || '';

  const coverImageUrl =
    activityDefinition.type === ActivityType.Ongoing
      ? organizationLogo
      : activityDefinition?.coverImage || '';

  return coverImageUrl;
};
