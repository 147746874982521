import { Flex, Hidden } from 'native-base';
import { colors } from 'ui/theme/colors';

import { InAppNavBar } from '~/components/InAppNavBar';
import { useInAppViewLayoutController } from '~/components/InAppViewLayout/controller';
import { RootPortal } from '~/components/RootPortal';
import { PreferenceLocationModal } from '~/components/v2/ModalTemplates/PreferenceLocationModal';
import { useEnablePreferenceLocationModal } from '~/hooks/useUserLocation/useEnablePreferenceLocationModal';
import { useUserLocationController } from '~/hooks/useUserLocation/useUsersLocation';
import { MobileHeader } from '~/pages/Authenticated/InApp/components/MobileHeader';
import { SplashScreen } from '~/pages/Authenticated/InApp/components/SplashScreen';
import LocationModal from '~/pages/Authenticated/InApp/modals/LocationModal';

export type InAppViewLayoutProps = {
  children: JSX.Element | JSX.Element[];
};

export const InAppViewLayout = ({ children }: InAppViewLayoutProps) => {
  const {
    selectedApp,
    isAppLoading,
    shouldRenderMobileNavBar,
    splashScreenInfo,
  } = useInAppViewLayoutController();

  const { location, showLocationModal, closeLocationModal, handleNewLocation } =
    useUserLocationController();

  const { handleCloseModal, locationModalRef } =
    useEnablePreferenceLocationModal();

  return (
    <>
      <Flex minHeight={'100vh'} bg={'gray.100'} w="full">
        {isAppLoading || !selectedApp ? (
          <SplashScreen
            imageURL={splashScreenInfo.appLogo}
            title={splashScreenInfo.appName}
          />
        ) : (
          <>
            {/* NAV BARS */}
            {shouldRenderMobileNavBar && (
              <Hidden from={'md'}>
                <MobileHeader backButtonIconSize={24} />
              </Hidden>
            )}

            <Hidden till="md">
              <InAppNavBar />
            </Hidden>

            {children}
          </>
        )}
      </Flex>

      <RootPortal>
        {/* INITIAL LOCATION MODAL (USERS PREFERENCE)  */}

        <PreferenceLocationModal
          modalRef={locationModalRef}
          handleCloseModal={handleCloseModal}
          buttonColor={colors.primary[400]}
        />

        {/* LOCATION MODAL */}

        <LocationModal
          isOpen={showLocationModal}
          onClose={closeLocationModal}
          onSubmit={handleNewLocation}
          initialLocation={location}
        />
      </RootPortal>
    </>
  );
};
