import { Skeleton, Stack, Text } from 'native-base';
import { useState } from 'react';
import fallBackCoverImage from 'ui/assets/images/in-app-cover-image-fullhd.webp';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';

import SearchTopBar from '~/pages/Authenticated/InApp/components/SearchTopBar';

import { CoverSearchProps } from './types';

export const CoverSearch = ({
  causesOptions,
  initialValues,
  searchFoundResults,
  showResults,
  coverImage,
  headLine,
  onFilterPress,
  onSubmit,
}: CoverSearchProps) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <Stack
      m={0}
      p={0}
      h={'31.5rem'}
      alignItems="center"
      justifyContent="center"
    >
      <Stack display={isLoading ? 'flex' : 'none'} width="100%" height="100%">
        <Skeleton width="100%" height="100%" />
      </Stack>
      <Illustration
        name={ILLUSTRATIONS_NAME.IN_APP_COVER_IMAGE}
        display={isLoading ? 'none' : 'flex'}
        source={coverImage ? { uri: coverImage } : undefined}
        onLoad={() => setIsLoading(false)}
        width="100%"
        height="100%"
        resizeMode="cover"
        fallbackSource={{ uri: fallBackCoverImage }}
      />
      <Stack
        bgColor="white"
        width="70%"
        maxW={1280}
        position="absolute"
        p={6}
        borderTopRadius="32px"
        bottom={0}
        alignItems="center"
        space={6}
      >
        <Text fontSize="3xl" fontWeight={500}>
          {headLine || ''}
        </Text>
        <Stack width="100%">
          <SearchTopBar
            initialValues={initialValues}
            onSubmit={onSubmit}
            searchFoundResults={searchFoundResults}
            onFilterPress={onFilterPress}
            causesOptions={causesOptions}
            showResults={showResults}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
