import { add as dateAdd } from 'date-fns';
import { ActivityType } from 'ui/types/activities';

import { TabNavigator } from '~/components/TabsNavigator/types';
import {
  ModalsState,
  ModalsStateActionName,
  SortByType,
} from '~/pages/Authenticated/InApp/SearchView/types';
import { AttendanceType } from '~/types/interfaces/activity';

export const ATTENDANCE_TYPE_TABS: TabNavigator[] = [
  {
    icon: 'map-pin',
    identifier: AttendanceType.Local,
    label: 'In person',
    subtitle: 'Activities with specific locations',
  },
  {
    icon: 'home',
    identifier: AttendanceType.Online,
    label: 'From Home',
    subtitle: 'Activities you can do remotely',
  },
];

export const SORT_OPTIONS = [
  {
    value: SortByType.NEAREST,
    label: 'Nearest',
    details: ' (Distance from location)',
  },
  { value: SortByType.UPCOMING, label: 'Upcoming', details: '(Start date)' },
  { value: SortByType.NEWEST, label: 'Newest', details: '(Creation date)' },
  { value: SortByType.CLOSEST, label: 'Closest' },
];

export const MODALS_INITIAL_STATE: ModalsState = {
  sort: false,
  location: false,
  search: false,
  filter: false,
  type: false,
  causes: false,
};

export const modalReducer = (
  state: ModalsState,
  action: { name: ModalsStateActionName },
) => {
  if (action.name === '$CLS') return { ...MODALS_INITIAL_STATE };
  return { ...state, [action.name]: !state[action.name] };
};

// -- Form Inital values

const today = new Date();
const endDate = dateAdd(today, { months: 3 });

export const FORM_INITIAL_VALUES = {
  search: '',
  type: ActivityType.Event,
  sortBy: SortByType.POPULAR,
  location: null,
  startDate: today,
  endDate,
  distanceRadius: 30,
  teamsNumber: 0,
  causes: [],
};
