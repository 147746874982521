import { yupResolver } from '@hookform/resolvers/yup';
import { Auth } from 'aws-amplify';
import { isValid as isValidDate, parse as parseDate } from 'date-fns';
import { useBreakpointValue } from 'native-base';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';
import { ISelectOptionsGroup } from 'ui/components/v2/inputs/Select/types';
import { compressImage } from 'ui/utils/compressImage';
import { formatDateByEnglishType } from 'ui/utils/formatter';

import { DEFAULT_AWS_PUBLIC_BUCKET } from '~/config';
import { USER_MESSAGES } from '~/constants/messages.constants';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useAuth } from '~/hooks/useAuth';
import { useListenToBrowserBackAction } from '~/hooks/useListenToBrowserBackAction';
import { useLoadOrganization } from '~/hooks/useLoadOrganization';
import { useLoadUserProfileById } from '~/hooks/useLoadUserProfiles/useLoadUserProfileById';
import { useRouter } from '~/hooks/useRouter';
import { useUserLocationController } from '~/hooks/useUserLocation/useUsersLocation';
import { USER_PROFILE_INITIAL_FORM_DATA } from '~/pages/Authenticated/UserProfile/constants';
import { IUserProfileFormFields } from '~/pages/Authenticated/UserProfile/types';
import {
  getUserFirstName,
  getUserLastNames,
  schemaValidation,
} from '~/pages/Authenticated/UserProfile/utils';
import { UploadsService } from '~/services/resources/uploads';
import UserService from '~/services/resources/user';
import { UserProfileService } from '~/services/resources/user-profile';
import { UploadsSignedURLObjectTarget } from '~/services/types';
import { authSliceActions } from '~/store/slices/auth';
import { IUserAtributes, Preferences } from '~/types/interfaces/user';

export const useUserProfileController = () => {
  // Provider
  const { clearLocation } = useUserLocationController();
  const { handleGetUserInformation } = useAuth();
  const dispatch = useAppDispatch();
  const { goBack } = useRouter();

  const { user, userAttributes, selectedUserProfile } = useAppSelector(
    ({ auth }) => auth,
  );

  // States
  const saveOrDiscardModalRef = useRef<IModalRefProps>(null);
  const [isLoading, setIsLoading] = useState(false);

  // Hooks
  const { data: organization, isLoading: isOrganizationLoading } =
    useLoadOrganization(selectedUserProfile?.organization);

  const { data: userProfile, isLoading: isUserProfileLoading } =
    useLoadUserProfileById(selectedUserProfile?._id);

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isDirty, isSubmitted },
  } = useForm<IUserProfileFormFields>({
    resolver: yupResolver(schemaValidation),
    defaultValues: USER_PROFILE_INITIAL_FORM_DATA,
  });

  useEffect(() => {
    if (!userProfile || !user) return;

    const { organizationDepartmentSubDocument, externalId, profilePicture } =
      userProfile;

    reset({
      firstName: getUserFirstName(user?.name),
      lastName: getUserLastNames(user?.name),
      phoneNumber: user?.phoneNumber || userAttributes?.phone_number || '',
      birthday: user?.birthDate
        ? `${formatDateByEnglishType(user?.birthDate, 'british')}`
        : userAttributes?.birthdate || '',
      profilePicture: profilePicture,
      location: {
        rawLocation: user?.preferences?.address?.street,
        lng: user?.preferences?.address?.location?.coordinates[0],
        lat: user?.preferences?.address?.location?.coordinates[1],
      },
      externalId: externalId || '',
      organizationDepartment: {
        value: organizationDepartmentSubDocument?._id || '',
        label: organizationDepartmentSubDocument?.name || '',
      },
    });
  }, [reset, userProfile, user, userAttributes]);

  const onSubmitHandler = handleSubmit(async (values) => {
    try {
      setIsLoading(true);
      const authUser = await Auth.currentAuthenticatedUser();

      const profilePictureHasChanged =
        typeof values.profilePicture === 'object';

      const profilePicture = values.profilePicture as File[];
      let profilePictureURL = userProfile?.profilePicture;

      /* TODO: Podiamos criar um hook controller para isso */
      if (profilePicture?.length && profilePictureHasChanged) {
        try {
          const compressedImage = (await compressImage(
            profilePicture[0],
          )) as File;
          const signedURLResponse = await UploadsService.generatePreSignedURL({
            Target: UploadsSignedURLObjectTarget.ProfilePicture,
            FileExtension: compressedImage.type.split('/').pop() || '',
          });

          profilePictureURL = `${DEFAULT_AWS_PUBLIC_BUCKET}/${signedURLResponse.Key}`;

          await UploadsService.uploadFileToS3({
            file: compressedImage,
            signedURL: signedURLResponse,
          });
        } catch {
          toast.error(USER_MESSAGES.ERROR_ON_UPLOAD);
          return;
        }
      }

      const newAtributes: Partial<IUserAtributes> = {
        birthdate: values.birthday,
        name: `${values.firstName} ${values.lastName}`,
        phone_number: values.phoneNumber || '',
      };

      const preferencesPayload = {
        address: {
          location: {
            type: 'Point',
            coordinates: [values.location?.lng, values.location?.lat],
          },
          street: values.location?.rawLocation,
        },
      };

      await Auth.updateUserAttributes(authUser, newAtributes);

      let birthDate: Date | undefined;
      if (values.birthday) {
        const parsedDate = parseDate(values.birthday, 'dd/MM/yyyy', new Date());
        if (isValidDate(parsedDate)) {
          birthDate = parsedDate;
        }
      }

      await UserService.updateUser(user?._id as string, {
        name: `${values.firstName} ${values.lastName}`,
        preferences: preferencesPayload as Preferences,
        birthDate,
        phoneNumber: values.phoneNumber,
      });

      const updatedProfileResponse = await UserProfileService.updateUserProfile(
        String(selectedUserProfile?._id),
        {
          profilePicture: String(profilePictureURL),
          externalId: values.externalId,
          organizationDepartment: values?.organizationDepartment?.value || null,
        },
      );

      await handleGetUserInformation({ shouldFetchAttributes: true });
      dispatch(
        authSliceActions.update({
          selectedUserProfile: updatedProfileResponse.data,
        }),
      );
      reset({
        profilePicture: String(profilePictureURL),
        ...values,
      });
      toast.success(USER_MESSAGES.PROFILE_UPDATED);
      clearLocation();
      goBack();
      dispatch(
        authSliceActions.update({
          selectedUserProfile: updatedProfileResponse.data,
        }),
      );
      reset({
        profilePicture: String(profilePictureURL),
        ...values,
      });
      toast.success(USER_MESSAGES.PROFILE_UPDATED);
    } catch (error: any) {
      toast.error('An error occurred while attempting to save the data.');
    } finally {
      setIsLoading(false);
    }
  });

  const doesOrganizationHasExternalId = useMemo(() => {
    if (!organization) return false;
    return organization?.organizationSettings?.hasExternalId === true;
  }, [organization]);

  const departmentsOptions = useMemo(() => {
    const departments = organization?.organizationSettings?.departments || [];
    if (!departments.length) return [];

    const options = departments.map((department) => {
      return { value: department._id, label: department.name };
    });

    return options;
  }, [organization]);

  const profileImageTitle = useBreakpointValue({
    base: '',
    md: 'Profile image',
  });

  // Handlers

  const handleOpenSaveOrDiscardModal = () => {
    saveOrDiscardModalRef.current?.open();
  };

  const handleCloseSaveOrDiscardModal = () => {
    saveOrDiscardModalRef.current?.close();
  };

  const handleDiscardChanges = () => {
    goBack();
    handleCloseSaveOrDiscardModal();
  };

  // Hook to listen to the browser back action, to avoid leaving the form with unsaved changes.
  useListenToBrowserBackAction({
    isEnabled: isDirty && !isSubmitted,
    onBackAction: handleOpenSaveOrDiscardModal,
  });

  return {
    errors,
    control,
    isLoading,
    organization,
    profileImageTitle,
    departmentsOptions,
    isUserProfileLoading,
    isOrganizationLoading,
    saveOrDiscardModalRef,
    doesOrganizationHasExternalId,
    handleOpenSaveOrDiscardModal,
    handleCloseSaveOrDiscardModal,
    handleDiscardChanges,
    onSubmitHandler,
    goBack,
  };
};
