import { EEventApplicationType } from 'ui/enums';
import { ActivityCategory, ActivityType } from 'ui/types/activities';
import { ILocation } from 'ui/types/interfaces';

export enum InPersonEventSearchRelevance {
  Nearest = 'Nearest',
  Closest = 'Closest',
  Popular = 'Popular',
  Newest = 'Newest',
}

export enum FromHomeEventSearchRelevance {
  Upcoming = 'Upcoming',
  Popular = 'Popular',
  Newest = 'Newest',
}

export enum ActionSearchRelevance {
  Popular = 'Popular',
  Newest = 'Newest',
}

export type InPersonOngoingSearchRelevance = InPersonEventSearchRelevance;
export type FromHomeOngoingSearchRelevance = FromHomeEventSearchRelevance;

export interface CommonSearchFilters {
  relevance: string;
  text?: string;
  radius?: number;
  apps: string;
  position: Pick<ILocation, 'lat' | 'lng'>;
  limit?: number;
  skip?: number;
  categories?: ActivityCategory;
  startDate?: Date;
  endDate?: Date;
  eventApplicationTypes?: EEventApplicationType[];
  teamsMinSize?: number;
  teamsMaxSize?: number;
  activityType: ActivityType;
}

// -- Event Search Params

export interface EventInPersonSearchFilters extends CommonSearchFilters {
  relevance: InPersonEventSearchRelevance;
}

export interface EventOnlineSearchFilters extends CommonSearchFilters {
  relevance: FromHomeEventSearchRelevance;
  showOnline?: boolean;
}

// -- OnGoing Search Params

export interface OngoingInPersonSearchFilters extends CommonSearchFilters {
  relevance: InPersonOngoingSearchRelevance;
}

export interface OngoingOnlineSearchFilters extends CommonSearchFilters {
  relevance: FromHomeOngoingSearchRelevance;
  showOnline?: boolean;
}

// -- Action Search Params
export interface ActionSearchFilters {
  text?: string;
  apps: string;
  skip?: number;
  limit?: number;
  showOnline?: boolean;
  categories?: ActivityCategory;
  relevance: ActionSearchRelevance;
}
