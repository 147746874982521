import { HStack } from 'native-base';

import BaseSelect from '../../../BaseSelect';
import { ISelectOptionsGroup, SelectValue } from '../../../types';
import SelectGroupActionButton from '../ActionButton';
import { SelectGroupActionVariants } from '../ActionButton/types';

export type CustomSelectProps = {
  value?: SelectValue;
  options: ISelectOptionsGroup[];
  variant: SelectGroupActionVariants;
  onChange?: (newValue: SelectValue) => void;
  onAction?: VoidFunction;
};

export const CustomSelect = ({
  value,
  options,
  variant,
  onAction,
  onChange,
}: CustomSelectProps) => {
  return (
    <HStack space="6">
      <BaseSelect
        value={value}
        options={options}
        isLoading={!options.length}
        onChange={onChange}
        isClearable={variant === 'add'}
      />
      <SelectGroupActionButton
        isDisabled={!value}
        variant={variant}
        onPress={onAction}
      />
    </HStack>
  );
};

export default CustomSelect;
