export interface IQueryParams {
  skip?: number;
  limit?: number;
  filter?: object | string;
  sort?: string;
  populate?: string;
}

export enum UploadsSignedURLObjectTarget {
  OrganizationPicture = 'OrganizationPicture',
  ActivityPicture = 'ActivityPicture',
  ProfilePicture = 'ProfilePicture',
}

export interface UploadsGenerateURLProps {
  Target: UploadsSignedURLObjectTarget;
  FileExtension: string;
  ImportFileId?: string;
}

export interface UploadsUploadFileToS3Props {
  file: File;
  signedURL: SignedURLResponse;
}

export interface SignedURLResponse {
  uploadURL: UploadURL;
  Key: string;
}

export interface UploadURLFields {
  key: string;
  bucket: string;
  'X-Amz-Algorithm': string;
  'X-Amz-Credential': string;
  'X-Amz-Date': string;
  'X-Amz-Security-Token': string;
  Policy: string;
  'X-Amz-Signature': string;
}

export interface UploadURL {
  url: string;
  fields: UploadURLFields;
}
