import { useInfiniteQuery } from 'react-query';

import {
  BASE_URL,
  ITEMS_PER_PAGE,
} from '~/hooks/useInfinityActivitySearch/constants';
import { serializeResponse } from '~/hooks/useInfinityActivitySearch/serializer';
import { publicApi } from '~/services/api';
import { ActionSearchFilters } from '~/types/dtos/activity-definition-public-search';

import { GenericObject, getQueryKey } from '../types';
import {
  EQueryKeys,
  IBaseGetPaginatedData,
  UseInfinityActivitySearchResponse,
} from './types';

const getActionActivitySearch = async (
  props: IBaseGetPaginatedData<ActionSearchFilters>,
): UseInfinityActivitySearchResponse => {
  const { pageParam = 0, params, disabled } = props;
  if (disabled) return { data: [], skip: 0 };

  const url = `${BASE_URL}/action`;

  const { data } = await publicApi.get(url, {
    params: { ...params, skip: pageParam },
  });
  return { data, skip: pageParam + ITEMS_PER_PAGE };
};

function useInfinityActionActivitySearch(params: any, disabled = false) {
  const queryKey = getQueryKey<GenericObject>(
    EQueryKeys.INFINITY_QUERY_ACTION_ACTIVITIES_SEARCH,
    params,
  );

  const response = useInfiniteQuery(
    queryKey,
    ({ pageParam }) => getActionActivitySearch({ pageParam, params, disabled }),
    {
      getNextPageParam: (lastPage) => {
        if ((lastPage.data ?? []).length < ITEMS_PER_PAGE) return undefined;
        return lastPage.skip;
      },
    },
  );

  return serializeResponse(response);
}

export default useInfinityActionActivitySearch;
