import { Hidden, HStack, PresenceTransition } from 'native-base';
import { colors } from 'ui/theme/colors';

import { useInAppNavBarController } from '~/components/InAppNavBar/controller';
import { InAppNavbarHeightInPx } from '~/components/InAppViewLayout/constants';
import { AccountDropdown } from '~/components/NavBar/components/AccountDropdown';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useUserLocationController } from '~/hooks/useUserLocation/useUsersLocation';
import AppSwitcher from '~/pages/Authenticated/InApp/components/AppSwitcher';
import { SelectAddressButton } from '~/pages/Authenticated/InApp/components/SelectAddressButton';

export const InAppNavBar = () => {
  const { selectedApp } = useAppSelector((state) => state.inApp);
  const { onMenuOptionPress, isContentLoading } = useInAppNavBarController();
  const { openLocationModal, location } = useUserLocationController();

  return (
    <>
      <HStack
        w="100%"
        h={`${InAppNavbarHeightInPx}px`}
        py={3}
        px={2}
        alignItems={'center'}
        justifyContent={'space-between'}
        flexShrink={0}
        bg="white"
        borderBottomWidth={{ base: 0, md: '1' }}
        borderBottomColor="gray.200"
      >
        <HStack
          w="100%"
          maxW={'1248px'}
          margin={'auto'}
          space={4}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          {!isContentLoading && (
            <PresenceTransition
              visible={true}
              initial={{ opacity: 0.5 }}
              animate={{ opacity: 1, transition: { duration: 150 } }}
            >
              <AppSwitcher onChange={onMenuOptionPress} />
            </PresenceTransition>
          )}

          <Hidden till="md">
            <HStack h="fit-content" alignItems={'center'} space={6}>
              <SelectAddressButton
                onPress={openLocationModal}
                address={location?.rawLocation || ''}
                brandColor={selectedApp?.brandColor || colors.primary[400]}
              />
              <AccountDropdown />
            </HStack>
          </Hidden>
        </HStack>
      </HStack>
    </>
  );
};
