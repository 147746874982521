import { colors } from 'doit-bho-admin/src/theme/colors';
import { Stack, Text } from 'native-base';
import Button from 'ui/components/Button';
import { CreatableAsyncAutoComplete } from 'ui/components/CreatableAsyncAutoComplete';

import { AddMemberInputProps } from './types';
import { useAddMemberInputController } from './useAddMemberInputController';

export const AddMemberInput = ({
  label = 'Find a member',
  onAddMember,
}: AddMemberInputProps) => {
  const { control, loadOptions, member, resetField, isDisabled } =
    useAddMemberInputController();
  return (
    <Stack mt={8}>
      <Text mb="3" fontSize="sm" fontWeight="medium" color="gray.600">
        {label}
      </Text>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        alignItems="initial"
        space={{ base: 2, md: 3 }}
      >
        <Stack width={'100%'}>
          <CreatableAsyncAutoComplete
            name="member"
            control={control}
            placeholder="Search by name or email"
            loadOptions={loadOptions}
          />
        </Stack>

        <Button
          leftIconName="plus-circle"
          leftIconColor={colors.black}
          py="6"
          variant="outline"
          onPress={() => {
            onAddMember(member);
            resetField('member', { defaultValue: '' });
          }}
          minW={{ base: '100%', md: '20%' }}
          maxW={{ base: '100%', md: '20%' }}
          isDisabled={isDisabled()}
          disabled={isDisabled()}
          borderColor={colors.gray[400]}
        >
          <Text fontSize={16} fontWeight="500">
            Add
          </Text>
        </Button>
      </Stack>
    </Stack>
  );
};
