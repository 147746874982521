import { useBreakpointValue } from 'native-base';
import { useEffect, useMemo, useReducer, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';
import { ActivityTypeEnum, EEventApplicationType } from 'ui/enums';
import { RelatedToEnum } from 'ui/types/activities';
import { ILocation } from 'ui/types/interfaces';

import { useActivityCategories } from '~/hooks/useActivityCategories';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import { SearchDtoValues } from '~/pages/Authenticated/InApp/components/SearchTopBar/types';
import { ALLOWED_ACTIVITY_SEARCH_SECTION } from '~/pages/Authenticated/InApp/Explorer/constants';
import { ActivitySearchSection } from '~/pages/Authenticated/InApp/Explorer/types';
import { useLoadEventsNearYou } from '~/pages/Authenticated/InApp/hooks/useNearYourLocation/useLoadEventsNearYou';
import { useLoadOngoingNearYou } from '~/pages/Authenticated/InApp/hooks/useNearYourLocation/useLoadOngoingNearYou';
import {
  modalReducer,
  MODALS_INITIAL_STATE,
} from '~/pages/Authenticated/InApp/SearchView/constants';
import { ModalsStateActionName } from '~/pages/Authenticated/InApp/SearchView/types';
import { inAppSliceActions } from '~/store/slices/inApp';
import { colors } from '~/theme/colors';
import {
  IActivityDefinition,
  IActivitySummary,
} from '~/types/interfaces/activity';
import { calculateDistance } from '~/utils/calculate-distance';

export const useHomeViewController = () => {
  // -- Providers
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    selectedApp,
    searchFilters,
    isLoading: isContentLoading,
  } = useAppSelector(({ inApp }) => inApp);
  const { user } = useAppSelector(({ auth }) => auth);
  const dispatch = useAppDispatch();
  const { goToRoute } = useRouter();

  // -- States
  const [modalsState, dispatchModalsState] = useReducer(
    modalReducer,
    MODALS_INITIAL_STATE,
  );

  // -- Hooks
  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  });

  const { data: causeOptionsData } = useActivityCategories(
    RelatedToEnum.CAUSE_OPTIONS,
  );

  const { data: eventsNearYou, isLoading: isLoadingEventsNearYou } =
    useLoadEventsNearYou(EEventApplicationType.Individual);

  const { data: teamEventsNearYou, isLoading: isLoadingTeamEventsNearYou } =
    useLoadEventsNearYou(EEventApplicationType.Team);

  const { data: ongoingNearYou, isLoading: isLoadingOngoingNearYou } =
    useLoadOngoingNearYou();

  const activitiesNearYou = useMemo(() => {
    const activities = [];
    if (eventsNearYou && eventsNearYou[0]) {
      activities.push(eventsNearYou[0]);
    }

    if (teamEventsNearYou && teamEventsNearYou[0]) {
      activities.push(teamEventsNearYou[0]);
    }

    if (ongoingNearYou && ongoingNearYou[0]) {
      activities.push(ongoingNearYou[0]);
    }

    return activities;
  }, [eventsNearYou, teamEventsNearYou, ongoingNearYou]);

  const causeSelectOptions = useMemo(() => {
    return (causeOptionsData ?? [])
      .map((options) => ({
        value: options?._id || '',
        label: options?.displayName || '',
      }))
      .sort((a, b) => {
        if (a?.label && b?.label) {
          return a?.label.localeCompare(b?.label);
        }
        return 0;
      });
  }, [causeOptionsData]);

  const sectionType = useMemo(() => {
    const section = searchParams.get('section') as ActivitySearchSection;
    if (!ALLOWED_ACTIVITY_SEARCH_SECTION.includes(section)) {
      return ActivitySearchSection.Event;
    }
    return section;
  }, [searchParams]);

  // -- Handlers
  const handleSearchBarSubmit = (fields: SearchDtoValues) => {
    const newFilters: { [key: string]: unknown } = { search: fields.search };
    if (fields.causes) {
      newFilters.causes = fields.causes;
    }
    dispatch(inAppSliceActions.setSearchFilters(newFilters));

    if (fields.sectionType) {
      handleSearchSectionChange(fields.sectionType);
    }

    if (!isMobile) {
      onSearchPress();
    }
  };

  const handleSearchSectionChange = (newSectionType: ActivitySearchSection) => {
    if (newSectionType !== sectionType) {
      searchParams.set('section', newSectionType);
      setSearchParams(searchParams);
    }
  };

  const openModal = (name: ModalsStateActionName) => {
    dispatchModalsState({ name });
  };

  const onButtonActivityTypePress = (sectionType: ActivitySearchSection) => {
    handleSearchSectionChange(sectionType);
    onSearchPress();
  };

  const onSearchPress = () => {
    if (!selectedApp) return;
    goToRoute(
      `/mini-app/${selectedApp._id}/find-opportunities?${searchParams}`,
    );
  };

  const onCardButtonPress = (activityDefinitionId: string) => {
    goToRoute(`/my-wallet/activities/${activityDefinitionId}`);
  };

  const closeModal = () => {
    dispatchModalsState({ name: '$CLS' });
  };

  const setCauses = (causes: Array<{ label: string; value: string }>) => {
    dispatch(inAppSliceActions.setSearchFilters({ causes }));
  };

  const calculateActivityDistance = (
    activity: IActivitySummary,
    location: ILocation | null,
  ) => {
    let distance = 0;
    const activityCoordinates = activity.address?.location?.coordinates;
    if (location && activityCoordinates) {
      const coordinatesObject = {
        lat: activityCoordinates[1],
        lng: activityCoordinates[0],
      };
      distance = calculateDistance(coordinatesObject, {
        lat: location.lat,
        lng: location.lng,
      });
    }

    return distance;
  };

  const getActivityTagProps = (activity: IActivityDefinition) => {
    if (activity.type === ActivityTypeEnum.Event) {
      if (activity.eventApplicationType === EEventApplicationType.Team) {
        return {
          icon: 'users',
          bgColor: colors.orange[200],
          text: 'TEAM EVENT',
        };
      }

      return {
        icon: 'calendar',
        bgColor: colors.tertiary[100],
        text: 'EVENT',
      };
    }

    if (activity.type === ActivityTypeEnum.OngoingOpportunity) {
      return {
        icon: 'repeat',
        bgColor: colors.secondary[100],
        text: 'ONGOING',
      };
    }

    return {
      icon: 'hand-pointer',
      bgColor: colors.purple[100],
      text: 'ACTION',
    };
  };

  const getActivityCardButtonText = (activity: IActivityDefinition) => {
    if (activity.type === ActivityTypeEnum.OngoingOpportunity) {
      return 'Apply to volunteer';
    }

    if (
      activity.type === ActivityTypeEnum.Event &&
      activity.eventApplicationType === EEventApplicationType.Team
    ) {
      return 'Register for team event';
    }

    return 'Register for this event';
  };

  const isLoadingActivities =
    isLoadingTeamEventsNearYou ||
    isLoadingEventsNearYou ||
    isLoadingOngoingNearYou;

  return {
    sectionType,
    searchFilters,
    causeSelectOptions,
    selectedApp,
    modalsState,
    user,
    activitiesNearYou,
    isLoadingActivities,
    isMobile,
    isContentLoading,
    onCardButtonPress,
    getActivityCardButtonText,
    getActivityTagProps,
    calculateActivityDistance,
    setCauses,
    closeModal,
    onButtonActivityTypePress,
    onSearchPress,
    openModal,
    handleSearchBarSubmit,
  };
};
