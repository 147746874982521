import { Box, FormControl, HStack } from 'native-base';
import { Controller } from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { useGenericOptionsAutoComplete } from '~/pages/Authenticated/InApp/components/GenericOptionsAutoComplete/controller';
import { GenericOptionsAutoCompleteProps } from '~/pages/Authenticated/InApp/components/GenericOptionsAutoComplete/types';

export const GenericOptionsAutoComplete = (
  props: GenericOptionsAutoCompleteProps,
) => {
  const { control, name, label, placeholder, options, leftIcon } = props;

  const { defaultStyle, loadOptions } = useGenericOptionsAutoComplete({
    options,
  });

  const EmptyIndicator = () => <></>;

  return (
    <>
      {label ? <FormControl.Label>{label}</FormControl.Label> : null}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <HStack alignItems={'center'} w="100%">
            <Box position={'absolute'} left={'12px'} top={'16px'} zIndex={9}>
              <Icon
                icon={leftIcon || 'filter'}
                size={'16px'}
                color={colors.gray[400]}
              />
            </Box>
            <Box flex={1}>
              <AsyncSelect
                value={value}
                isMulti
                menuPortalTarget={document.body}
                styles={defaultStyle}
                placeholder={placeholder}
                onChange={onChange}
                defaultOptions={options}
                loadOptions={loadOptions}
                isClearable={false}
                menuPosition={'fixed'}
                controlShouldRenderValue={false}
                components={{
                  DropdownIndicator: EmptyIndicator,
                  IndicatorSeparator: EmptyIndicator,
                }}
              />
            </Box>
          </HStack>
        )}
      />
    </>
  );
};

export default GenericOptionsAutoComplete;
