import 'react-phone-number-input/style.css';

import FeatherIcon from 'feather-icons-react';
import { Box, FormControl, Input as NBInput, Link } from 'native-base';
import { Controller } from 'react-hook-form';
import ReactPhoneNumberInput from 'react-phone-number-input';
import { mask as remask } from 'remask';

import { colors } from '../../theme/colors';
import PasswordRulesList from './PasswordRulesList';
import { IMaskInputProps } from './types';
import { useMaskInputController } from './useInputController';

const MaskInput = ({
  isFullWidth,
  isPhoneInput,
  mask = '99/99/9999',
  ...rest
}: IMaskInputProps) => {
  const {
    leftIconName,
    leftIconColor,
    leftIconSize,
    _formControl,
    rightIconName,
    control,
    name,
    leftIconErrorSize,
    rightIconColor,
    helperText,
    rightIconSize,
    currentType,
    hasError,
    isInvalid,
    defaultCountry,
    isDisabled,
    isReadOnly,
    isRequired,
    errorMessage,
    iconErrorMessage,
    isPasswordField,
    isShowPasswordToggle,
    toggleShowPasswordType,
    showPasswordRules,
    label,
    labelColor,
    restProps,
  } = useMaskInputController(rest);

  return (
    <Box alignItems="center" w={isFullWidth ? 'full' : undefined}>
      <FormControl
        isInvalid={hasError || errorMessage ? true : isInvalid}
        isDisabled={isDisabled}
        isRequired={isRequired}
        isReadOnly={isReadOnly}
        {..._formControl}
      >
        {label ? (
          <FormControl.Label _text={{ color: labelColor }}>
            {label}
          </FormControl.Label>
        ) : null}
        <Controller
          control={control}
          name={name}
          defaultValue=""
          render={({ field: { onChange, value, ...restField } }) => {
            // @mycatdoitbetter // TODO: Move this components to a separate file.
            // or just outside this main component.
            if (isPhoneInput) {
              return (
                <ReactPhoneNumberInput
                  {...restField}
                  value={value}
                  defaultCountry={defaultCountry}
                  onChange={onChange}
                  placeholder={restProps.placeholder}
                  // @mycatdoitbetter // TODO: Handle this inline props properly.
                  style={{
                    marginLeft: 4,
                  }}
                  numberInputProps={{
                    style: {
                      padding: 14,
                      paddingLeft: 16,
                      fontSize: 12,
                      fontFamily: 'Poppins',
                      borderRadius: '12px',
                      border: `solid 1px ${
                        hasError || errorMessage
                          ? colors.error[400]
                          : colors.muted[300]
                      }`,
                    },
                  }}
                />
              );
            }

            return (
              <>
                <NBInput
                  {...restProps}
                  {...restField}
                  value={value}
                  // Native base uses a different typing that is used in web (HTMLInputElement) and ends up giving a typo.
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(newValue: any) => {
                    const maskedValue = remask(newValue?.target?.value, mask);
                    onChange(maskedValue);
                  }}
                  type={currentType}
                  borderColor={
                    hasError || errorMessage ? 'error.400' : undefined
                  }
                  height={'46px'}
                  backgroundColor="white"
                  InputRightElement={
                    isPasswordField && isShowPasswordToggle ? (
                      <Link
                        mr={2}
                        variant="unstyled"
                        onPress={toggleShowPasswordType}
                      >
                        <FeatherIcon
                          icon={currentType === 'password' ? 'eye' : 'eye-off'}
                          size={rightIconSize}
                          color={
                            hasError || errorMessage
                              ? colors.error[700]
                              : rightIconColor
                          }
                        />
                      </Link>
                    ) : rightIconName ? (
                      <Box mr={2}>
                        <FeatherIcon
                          icon={rightIconName}
                          size={rightIconSize}
                          color={
                            hasError || errorMessage
                              ? colors.error[700]
                              : rightIconColor
                          }
                        />
                      </Box>
                    ) : undefined
                  }
                  InputLeftElement={
                    leftIconName ? (
                      <Box ml={2} variant="unstyled">
                        <FeatherIcon
                          icon={leftIconName}
                          size={leftIconSize}
                          color={
                            hasError || errorMessage
                              ? colors.error[700]
                              : leftIconColor
                          }
                        />
                      </Box>
                    ) : undefined
                  }
                />
                {isPasswordField && showPasswordRules ? (
                  <PasswordRulesList value={value} />
                ) : null}
              </>
            );
          }}
        />
        {!hasError && !errorMessage && helperText ? (
          <FormControl.HelperText ml={2}>{helperText}</FormControl.HelperText>
        ) : null}
        {errorMessage ? (
          <FormControl.ErrorMessage
            ml={2}
            leftIcon={
              iconErrorMessage ? (
                <FeatherIcon
                  icon={iconErrorMessage}
                  size={leftIconErrorSize}
                  color={colors.error[400]}
                />
              ) : undefined
            }
          >
            {errorMessage}
          </FormControl.ErrorMessage>
        ) : null}
      </FormControl>
    </Box>
  );
};

export default MaskInput;
