import { useMemo } from 'react';

import { useAppSelector } from '~/hooks/useAppSelector';
import useInfinityActionActivitySearch from '~/hooks/useInfinityActivitySearch/useInfinityActionActivitySearch';
import {
  IUseSearchController,
  SearchControllerProps,
} from '~/pages/Authenticated/InApp/Explorer/controllers/interface';
import { mapActionFilter } from '~/pages/Authenticated/InApp/Explorer/mappers';
import { MapActivityToSearchResult } from '~/pages/Authenticated/InApp/SearchView/mapper';
import { IActivityDefinition } from '~/types/interfaces/activity';

export const useActionActivityController: IUseSearchController = (
  props: SearchControllerProps,
) => {
  // -- Props
  const { appId, attendanceType } = props;

  // -- Providers
  const { searchFilters, regionOptions, location } = useAppSelector(
    (state) => state.inApp,
  );

  const sortBy = searchFilters.sortBy;

  // -- Hooks
  const queryParams: any = useMemo(() => {
    return mapActionFilter(
      appId,
      attendanceType,
      searchFilters,
      regionOptions,
      location,
    );
  }, [appId, searchFilters, attendanceType, regionOptions, location]);

  const {
    data,
    isFetchingNextPage,
    isRefetching,
    hasNextPage,
    isLoading,
    fetchNextPage,
  } = useInfinityActionActivitySearch(queryParams);

  const activitySearchResults = useMemo(() => {
    return (data as any)
      .map(MapActivityToSearchResult(location as any, sortBy))
      .filter((v: IActivityDefinition | null) => v != null);
  }, [data, location]);

  const handleOnScrollEndReached = async () => {
    if (isRefetching || isFetchingNextPage || !hasNextPage) return;
    await fetchNextPage();
  };

  // -- Render statements
  const shouldRenderSkeleton = isLoading || isFetchingNextPage;

  const shoudlRenderNoMoreActivities =
    activitySearchResults.length > 0 && !hasNextPage;

  const shouldRenderIllustration =
    (activitySearchResults ?? []).length == 0 && !isLoading;

  return {
    activitySearchResults,
    shouldRenderSkeleton,
    shoudlRenderNoMoreActivities,
    shouldRenderIllustration,
    handleOnScrollEndReached,
  };
};
