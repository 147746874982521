import { Box, HStack, Pressable, Stack, Text, VStack } from 'native-base';
import { useMemo } from 'react';
import { colors } from 'ui/theme/colors';

import BreadCrumbs from '~/components/Breadcrumbs';
import { useAppSelector } from '~/hooks/useAppSelector';
import { ActivityTypeDecorator } from '~/pages/Authenticated/InApp/components/ActivityTypeDecotaror';
import { makeBreadCrumbs } from '~/pages/Authenticated/InApp/components/ExplorerHeader/utils';
import {
  ActivityTypeInfoMap,
  sectionTypeList,
} from '~/pages/Authenticated/InApp/Explorer/constants';
import { ActivitySearchSection } from '~/pages/Authenticated/InApp/Explorer/types';

export interface ExporerHeaderProps {
  sectionType: ActivitySearchSection;
  searchFoundResults: number;
  searchText: string;
  onSubmit: (type: ActivitySearchSection) => void;
}

export const ExporerHeader = (props: ExporerHeaderProps) => {
  const { searchText, sectionType, searchFoundResults, onSubmit } = props;

  const info = useMemo(() => {
    return ActivityTypeInfoMap.get(sectionType);
  }, [sectionType]);

  const breadCrumbs = useMemo(() => makeBreadCrumbs(searchText), [searchText]);

  const { selectedApp } = useAppSelector(({ inApp }) => inApp);

  return (
    <Box bgColor={colors.white} py={4} px={2}>
      <VStack w="100%" maxW={'1248px'} margin={'auto'} space={4}>
        <Stack>
          <BreadCrumbs breadcrumbs={breadCrumbs} />
        </Stack>
        <HStack flex={1} space={4} justifyContent={'space-between'}>
          <VStack space={4} flex={1}>
            <HStack alignItems={'center'} space={4}>
              <ActivityTypeDecorator icon={info?.icon} size="xl" />
              <Text
                fontSize={'36px'}
                lineHeight={'125%'}
                fontWeight={700}
                color={selectedApp?.brandColor || colors.primary[400]}
              >
                {info?.text}
              </Text>
            </HStack>

            <VStack>
              <Text
                fontSize={'xl'}
                lineHeight={'150%'}
                fontWeight={500}
                color={colors.darkText}
                h={8}
              >
                {(searchText || '').length > 0 &&
                  ` Search result for ”${searchText}”`}
              </Text>

              <Text
                fontSize={'sm'}
                lineHeight={'150%'}
                fontWeight={400}
                color={colors.gray[500]}
                h={6}
              >
                {`${searchFoundResults} result found`}
              </Text>
            </VStack>
          </VStack>

          <VStack justifyContent={'center'} space={4}>
            <Text
              fontSize={'md'}
              lineHeight={'150%'}
              fontWeight={500}
              color={colors.darkText}
              marginLeft={8}
            >
              Change search for:
            </Text>

            <HStack>
              {sectionTypeList.map(({ type, label, icon }) => {
                return (
                  <>
                    {type !== sectionType && (
                      <Pressable key={type} onPress={() => onSubmit(type)}>
                        <VStack w="120px" alignItems={'center'}>
                          <ActivityTypeDecorator icon={icon} size="lg" />
                          <Text
                            fontSize={'sm'}
                            lineHeight={'150%'}
                            fontWeight={500}
                            color={colors.gray[600]}
                            textAlign={'center'}
                          >
                            {label}
                          </Text>
                        </VStack>
                      </Pressable>
                    )}
                  </>
                );
              })}
            </HStack>
          </VStack>
        </HStack>
      </VStack>
    </Box>
  );
};
