import { EEventApplicationType, LocationOptionsEnum } from 'ui/enums';
import { ActivityType } from 'ui/types/activities';
import { ILocation } from 'ui/types/interfaces';
import { mapToActivitySemanticType } from 'ui/utils/activity/mappers';
import { formatDateByEnglishType } from 'ui/utils/formatter';

import { ActivitySearchResult } from '~/pages/Authenticated/InApp/components/SearchResultItem/types';
import { SortByType } from '~/pages/Authenticated/InApp/SearchView/types';
import { getCoverImageFromActivityDefinition } from '~/pages/Authenticated/InApp/utils/activity-utils';
import { convertMilesToMeters } from '~/pages/Authenticated/InApp/utils/metersToMiles';
import { getActivitySummaryByRelevance } from '~/pages/Authenticated/InApp/utils/processActivitySummaries';
import { IActivityDefinition } from '~/types/interfaces/activity';

export const MapActivityToSearchResult =
  (
    location: ILocation | null = null,
    sortBy: SortByType,
    maxDistanceRadius?: number,
    minDate?: Date,
    maxDate?: Date,
  ) =>
  (activityDefinition: IActivityDefinition): ActivitySearchResult => {
    // -- Acitivty Definition
    const type = activityDefinition.type as ActivityType;

    const organizationName = activityDefinition?.organizationSubDocument?.name;

    const isTeamEvent =
      activityDefinition.eventApplicationType === EEventApplicationType.Team;

    const coverImageUrl =
      getCoverImageFromActivityDefinition(activityDefinition);

    // -- Activity
    const activitySummaries = activityDefinition.activitiesSummary ?? [];

    let activity;
    if (activitySummaries.length > 0) {
      activity = getActivitySummaryByRelevance(
        activitySummaries,
        type,
        location,
        sortBy,
        maxDistanceRadius && convertMilesToMeters(maxDistanceRadius),
        minDate,
        maxDate,
      );
    }

    const distance = activity?.distance ?? -1;
    let startDate = null;
    if (activity?.startDate) {
      startDate = `${formatDateByEnglishType(activity.startDate, 'british')}`;
    }

    const semanticType = mapToActivitySemanticType(
      activityDefinition?.type as ActivityType,
      activityDefinition?.eventApplicationType,
    );

    const result: ActivitySearchResult = {
      // Activity Definition
      id: activityDefinition._id,
      title: activityDefinition?.title || '',
      organizationName: organizationName || '',
      type: activityDefinition.type as ActivityType,
      coverImageUrl,
      thumbnailImage: activityDefinition.thumbnailImage,
      isTeamEvent,
      applicationType: activityDefinition?.eventApplicationType,
      hideDistance:
        activityDefinition.locationOption === LocationOptionsEnum.FromHome,
      // Selected activity
      selectedActivity: activity,
      startDate,
      distance,
      semanticType,
    };

    return result;
  };
