import { ActivitySearchSection } from '~/pages/Authenticated/InApp/Explorer/types';

export const activityTypesProps = [
  {
    text: 'Events',
    icon: 'calendar',
    type: ActivitySearchSection.Event,
  },
  {
    text: 'Team Events',
    icon: 'users',
    type: ActivitySearchSection.TeamEvent,
  },
  {
    text: 'Actions',
    icon: 'hand-pointer',
    type: ActivitySearchSection.Action,
  },
  {
    text: 'Ongoing Opportunities',
    icon: 'repeat',
    type: ActivitySearchSection.Ongoing,
  },
];

export const activityLoadingSkeletonCards = ['one', 'two', 'three'];
