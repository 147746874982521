import { Flex, Input, Text } from 'native-base';
import { Controller } from 'react-hook-form';
import { NativeSyntheticEvent, TextInputChangeEventData } from 'react-native';

import { TimeInputProps } from './types';

const onInputChange = (
  e: NativeSyntheticEvent<TextInputChangeEventData>,
  maxInputNumber: number,
) => {
  const inputValue = e.nativeEvent.text.replace(/\D/g, '').slice(0, 2);
  let numberValue = parseInt(inputValue, 10);
  numberValue = Math.min(numberValue, maxInputNumber);

  return isNaN(numberValue) ? undefined : numberValue;
};

export const TimeInput = ({
  control,
  name,
  unit,
  maxInputNumber,
}: TimeInputProps) => (
  <Flex flexDirection="row" alignItems="center">
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange, onBlur } }) => (
        <Input
          width={16}
          bg="white"
          padding={2}
          rounded="xl"
          keyboardType="numeric"
          placeholder="00"
          fontSize="3xl"
          value={value?.toString()}
          onBlur={onBlur}
          onChange={(e) => {
            const value = onInputChange(e, maxInputNumber);
            onChange(value);
          }}
        />
      )}
    />
    <Text numberOfLines={2} isTruncated size="lg" color="gray.400" ml="2">
      {unit}
    </Text>
  </Flex>
);
