import { Box, VStack } from 'native-base';
import { useMemo } from 'react';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { useAppSelector } from '~/hooks/useAppSelector';

export type ActivityTypeDecoratorProps = {
  icon: string | any;
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
};

export const BoxSizeMpa = {
  xs: '4px',
  sm: '8px',
  md: '16px',
  lg: '56px',
  xl: '60px',
};

export const ActivityTypeDecorator = (props: ActivityTypeDecoratorProps) => {
  const { icon, size } = props;

  const boxSize = useMemo(() => {
    return BoxSizeMpa[size];
  }, [size]);

  const { selectedApp } = useAppSelector(({ inApp }) => inApp);

  const brandColor = selectedApp?.brandColor || colors.primary[400];

  return (
    <Box
      h={boxSize}
      w={boxSize}
      borderRadius={'50%'}
      backgroundColor={`${brandColor}22`}
    >
      <VStack justifyContent={'center'} alignItems={'center'} flex={1}>
        <Icon
          enableAutoCustom
          icon={icon}
          size={'24px'}
          color={brandColor}
        ></Icon>
      </VStack>
    </Box>
  );
};
