import { UserProfile } from 'ui/types/user';

import { useQuery } from '~/hooks/useQuery';

export const useLoadUserProfileById = (userProfileId?: string) => {
  const { data, isLoading, refetch } = useQuery<UserProfile>(
    `user-profile/${userProfileId}`,
    {
      queryOptions: { enabled: !!userProfileId },
    },
  );

  return { isLoading, data, refetch };
};
