import './card-activity.css';

import {
  Flex,
  Hidden,
  HStack,
  Pressable,
  Skeleton,
  Stack,
  Text,
  VStack,
} from 'native-base';
import { ActivityTypeEnum } from 'org-admin/src/enums';
import React from 'react';
import Button from 'ui/components/Button';
import { colors } from 'ui/theme/colors';

import { useCardActivityController } from '~/components/ActivityCards/CardActivity/cardActivity.controller';
import {
  CardActivityProps,
  CardActivitySkeletonListProps,
} from '~/components/ActivityCards/CardActivity/cardActivity.types';
import { CardActivityAvatar } from '~/components/ActivityCards/CardActivity/components/CardActivityAvatar';
import { ActivitySummaryTitle } from '~/components/ActivityCards/CardRecommendations/components/ActivitySummaryTitle';
import { CardRecommendationsHeader } from '~/components/ActivityCards/CardRecommendations/components/CardRecommendationsHeader';
import { CardRecommendationsInfoContent } from '~/components/ActivityCards/CardRecommendations/components/CardRecommendationsInfoContent';

export const CardActivity = ({
  activity,
  appName,
  onCancelActivity,
}: CardActivityProps) => {
  const { isOpen, onToggle, eventSummary, handleGoToActivityDetail } =
    useCardActivityController({
      activity,
      appName,
      onCancelActivity,
    });

  return (
    <Pressable onPress={onToggle} flexShrink={0}>
      <div className={`card-activity${isOpen ? '--open' : ''}`}>
        <Flex
          flexShrink={0}
          w={'full'}
          borderWidth={1}
          borderColor={'gray.300'}
          padding={{ base: 3, lg: 4 }}
          borderRadius={12}
          bg={'gray.50'}
        >
          <HStack
            flex={1}
            w={'100%'}
            space={{ base: 0, lg: 6 }}
            justifyContent={'space-between'}
          >
            <Hidden till={'md'}>
              <CardActivityAvatar
                logo={eventSummary.activityCoverImageUrl}
                initials={eventSummary.activityInitials}
              />
            </Hidden>
            <VStack
              flex={1}
              w={'fit-content'}
              space={'2.5'}
              maxW={{ base: '100%', lg: '100%' }}
            >
              <CardRecommendationsHeader
                activityType={eventSummary.activityType as ActivityTypeEnum}
                isTeamEvent={eventSummary.isTeamEvent}
                headerText={eventSummary.headerText}
              />

              <HStack h={'fit-content'} space={2} w={'100%'}>
                <VStack space={3} w={'100%'}>
                  <ActivitySummaryTitle
                    activityName={eventSummary.activityName}
                    appName={eventSummary.appName}
                    organizationName={eventSummary.organizationName}
                  />

                  <CardRecommendationsInfoContent
                    infos={eventSummary.shownInfos}
                  />

                  <div className={'card-activity-content'}>
                    <Hidden from={'sm'}>
                      <VStack h={'full'} space={4} mt={6}>
                        <Button
                          px={8}
                          onPress={() => {
                            handleGoToActivityDetail(
                              activity.activityDefinition,
                            );
                          }}
                          leftIconName={'eye'}
                        >
                          <Text fontSize={'sm'} color={'singletons.white'}>
                            View activity
                          </Text>
                        </Button>
                        <Button
                          onPress={() => onCancelActivity(activity._id)}
                          px={8}
                          leftIconName={'x-circle'}
                          leftIconColor={colors.error[500]}
                          borderColor={'error.500'}
                          variant={'outline'}
                        >
                          <Text fontSize={'sm'} color={'error.500'}>
                            Cancel
                          </Text>
                        </Button>
                      </VStack>
                    </Hidden>
                  </div>
                </VStack>
              </HStack>
            </VStack>
            <Hidden till={'md'}>
              <VStack h={'full'} space={4}>
                <Button
                  px={8}
                  onPress={() => {
                    handleGoToActivityDetail(activity.activityDefinition);
                  }}
                  leftIconName={'eye'}
                >
                  <Text fontSize={'sm'} color={'singletons.white'}>
                    View activity
                  </Text>
                </Button>
                <Button
                  onPress={() => onCancelActivity(activity._id)}
                  px={8}
                  leftIconName={'x-circle'}
                  leftIconColor={colors.error[500]}
                  borderColor={'error.500'}
                  variant={'outline'}
                >
                  <Text fontSize={'sm'} color={'error.500'}>
                    Cancel
                  </Text>
                </Button>
              </VStack>
            </Hidden>
          </HStack>
        </Flex>
      </div>
    </Pressable>
  );
};

export const CardActivitySkeleton = () => {
  return (
    <Flex
      w={'full'}
      borderWidth={1}
      borderColor={'gray.300'}
      padding={{ base: 3, lg: 4 }}
      borderRadius={12}
      bg={'gray.50'}
    >
      <HStack flex={1} justifyContent={'space-between'}>
        <VStack space={3}>
          <HStack space={6} alignItems={'center'}>
            <Skeleton h="6" w={'20'} rounded="full" />
            <Skeleton
              h="3"
              w={{
                base: 100,
                md: 100,
                lg: 150,
              }}
              rounded="full"
            />
          </HStack>
          <VStack
            space={'2.5'}
            ml={{
              base: -1,
              lg: 2,
            }}
          >
            <HStack space={2}>
              <Stack ml={-2} display={{ base: 'none', lg: 'flex' }}>
                <Skeleton h={12} w={12} rounded="full" />
              </Stack>
              <VStack justifyContent={'space-between'}>
                <HStack space={3} alignItems={'center'}>
                  <Skeleton
                    h="3"
                    w={{ base: 70, md: 100, lg: 150 }}
                    rounded="full"
                  />
                  <Skeleton
                    h="2.5"
                    w={{ base: 30, md: 90, lg: 100 }}
                    rounded="full"
                  />
                  <Skeleton
                    h="2.5"
                    w={{ base: 30, md: 90, lg: 100 }}
                    rounded="full"
                  />
                </HStack>
                <Skeleton
                  h="3"
                  w={140}
                  rounded="full"
                  display={{ base: 'none', lg: 'flex' }}
                />
              </VStack>
            </HStack>
            <HStack space={2} display={{ base: 'flex', lg: 'none' }}>
              <VStack mt={'3'} ml={2}>
                <Skeleton
                  h={{ base: '2', sm: '3' }}
                  w={{ base: '40', md: 100, lg: 150 }}
                  rounded="full"
                />
              </VStack>
            </HStack>
          </VStack>
        </VStack>
      </HStack>
    </Flex>
  );
};

export const CardActivitySkeletonList = ({
  quantity,
}: CardActivitySkeletonListProps) => {
  const listOfSkeletons = quantity ? Array(quantity).fill(0) : [1];

  return (
    <VStack space={4}>
      {listOfSkeletons.map((_, index) => {
        return <CardActivitySkeleton key={index} />;
      })}
    </VStack>
  );
};
