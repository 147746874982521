import 'rc-slider/assets/index.css';

import { Box, FormControl, HStack, Text, VStack } from 'native-base';
import Slider from 'rc-slider';
import { Controller } from 'react-hook-form';

import { sliderStyle } from './constants';
import { handleRender } from './fragments/ToolTip';
import { MultiRangeSliderProps } from './types';

export const MultiRangeSliderComponent = (props: MultiRangeSliderProps) => {
  const { name, control, label, errorMessage, min = 1, max = 100 } = props;

  return (
    <Box alignItems="center" w={'full'}>
      <FormControl isInvalid={!!errorMessage}>
        <HStack space={2} alignItems="center">
          {label && <FormControl.Label>{label}</FormControl.Label>}
        </HStack>
        <Controller
          control={control}
          name={name as never}
          render={({ field: { onChange, value } }) => {
            const { min: minValue, max: maxValue } = value as {
              min: number;
              max: number;
            };
            return (
              <VStack pb={12}>
                <Slider
                  range
                  min={min}
                  max={max}
                  value={[minValue, maxValue]}
                  step={1}
                  allowCross={false}
                  defaultValue={[minValue, maxValue]}
                  styles={sliderStyle}
                  handleRender={handleRender}
                  onChange={([min, max]: any) => {
                    onChange({ min, max });
                  }}
                />
                <HStack justifyContent={'space-between'} mt={4}>
                  <Text color="gray.900" fontSize="xs" fontWeight="500">
                    {min}
                  </Text>
                  <Text color="gray.900" fontSize="xs" fontWeight="500">
                    {max}+
                  </Text>
                </HStack>
              </VStack>
            );
          }}
        />

        {errorMessage && (
          <FormControl.ErrorMessage ml={2}>
            {errorMessage}
          </FormControl.ErrorMessage>
        )}
      </FormControl>
    </Box>
  );
};

export default MultiRangeSliderComponent;
