import { HStack, Image, Pressable, Skeleton, Stack, Text } from 'native-base';
import { useState } from 'react';
import placeholder from 'ui/assets/svgs/placeholder.svg';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import Tag from 'ui/components/Tags/Tag';
import { colors } from 'ui/theme/colors';

import DistanceLabel from '~/pages/Authenticated/InApp/components/SearchResultItem/fragments/DistanceLabel';

import { ActivityCardProps } from './types';

export const ActivityCard = ({
  date,
  distance,
  title,
  buttonText = 'Register for this event',
  tag,
  image,
  onPress,
}: ActivityCardProps) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <Pressable onPress={onPress}>
      <Stack
        width="100%"
        borderRadius="2xl"
        borderWidth={1}
        borderColor="gray.300"
        bgColor={colors.singletons.lightText}
      >
        <Stack borderTopRadius="2xl" width="100%" height="8rem">
          <Stack width="100%" height="100%">
            <Skeleton width="100%" height="100%" />
          </Stack>
          <Image
            display={isLoading ? 'none' : 'flex'}
            width="100%"
            height="100%"
            borderTopRadius="2xl"
            source={{
              uri: image,
            }}
            onLoad={() => setIsLoading(false)}
            fallbackSource={{ uri: placeholder }}
          />
        </Stack>
        <Stack p={3} space={3}>
          <HStack justifyContent="space-between">
            <Tag
              text={tag.text}
              tagBgColor={tag.bgColor}
              tagTextColor="black"
              icon={tag.icon}
              leftIconSize={16}
              showIcon
            />
            <DistanceLabel distance={distance} />
          </HStack>
          <Text numberOfLines={1} fontSize="md" fontWeight={700}>
            {title}
          </Text>
          <HStack alignItems="center" space={2}>
            <Icon icon="calendar" size={20} color={colors.gray[400]} />
            <Text fontWeight={400} fontSize="sm" color="gray.600">
              {date || 'Any time'}
            </Text>
          </HStack>

          <Button onPress={onPress} mt={4}>
            {buttonText}
          </Button>
        </Stack>
      </Stack>
    </Pressable>
  );
};
