import {
  Location,
  NavigateOptions,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

interface IHistoryController {
  goBack: () => void;
  goToRoute: (path: string, options?: NavigateOptions) => void;
  replaceRoute: (path: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any; // It has to be any since we don't know what may come from the query params.
  searchParams: URLSearchParams;
  location: Location;
}

export const useRouter = (): IHistoryController => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const goToRoute = (path: string, options?: NavigateOptions) => {
    navigate(path, options);
  };

  const replaceRoute = (path: string) => {
    navigate(path, { replace: true });
  };

  const goBack = () => {
    navigate(-1);
  };

  return { params, searchParams, location, goBack, goToRoute, replaceRoute };
};
