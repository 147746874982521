import { FlatList, Flex, Hidden, Stack, Text, VStack } from 'native-base';
import { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';

import {
  CardActivity,
  CardActivitySkeletonList,
} from '~/components/ActivityCards/CardActivity';
import ModalRemove from '~/components/ModalRemove';
import { Scaffold } from '~/components/Scaffold';
import { AcitivitiesHeader } from '~/pages/Authenticated/Activities/components/ActivitiesHeader';
import { ActivityLogsBanner } from '~/pages/Authenticated/Activities/components/ActivityLogsBanner';
import { MobileAcitivitiesHeader } from '~/pages/Authenticated/Activities/components/MobileActivitiesHeader';

import { ListEmptyState } from '../../../components/EmptyState/ListEmptyState';
import { pageSubtitle, pageTitle } from './constants';
import { useActivitiesController } from './controller';

export const Activities = () => {
  const {
    isLoadingCancelActivity,
    cancelApplicationModalRef,
    handleGoToFindOpportunities,
    mutateCancelActivity,
    handleGoToLogActivity,
    handleOpenCancelApplicationModal,
    handleOnScrollEndReached,
    activityApplications,
    shoudlRenderNoMoreActivities,
    shouldRenderIllustration,
    shouldRenderSkeleton,
  } = useActivitiesController();

  return (
    <>
      <Scaffold onScrollReachEnd={handleOnScrollEndReached}>
        {/* HEADER */}
        <ActivityLogsBanner
          onPress={handleGoToLogActivity}
          isLoading={false}
          count={0}
        />

        {/* MAIN */}
        <VStack flex={1}>
          <Hidden till={'lg'}>
            <AcitivitiesHeader label={pageTitle} description={pageSubtitle} />
          </Hidden>
          <VStack
            bg={'singletons.white'}
            px={4}
            py={{ base: 3, lg: 6 }}
            pb={0}
            flex={1}
            rounded={8}
            roundedTop={0}
            flexGrow={1}
          >
            <Hidden from={'lg'}>
              <MobileAcitivitiesHeader
                label={pageTitle}
                description={pageSubtitle}
              />
            </Hidden>

            {(activityApplications ?? []).length > 0 && (
              <FlatList
                data={activityApplications}
                renderItem={({ item }) => {
                  return (
                    <Stack mb={4}>
                      <CardActivity
                        activity={item.activitySubDocument}
                        appName={item?.app?.name || ''}
                        onCancelActivity={() =>
                          handleOpenCancelApplicationModal(item.activity)
                        }
                      />
                    </Stack>
                  );
                }}
              />
            )}

            {shouldRenderSkeleton && (
              <Flex mb={4} height={'100%'}>
                <CardActivitySkeletonList quantity={3} />
              </Flex>
            )}

            {shouldRenderIllustration && (
              <ListEmptyState
                text={
                  "It seems that you haven't applied to any activity yet. Explore and book upcoming opportunities."
                }
                illustrationName={ILLUSTRATIONS_NAME.ERROR_SORRY_STATE_BROKEN}
                buttonTitle="Explore opportunities"
                onButtonPressed={handleGoToFindOpportunities}
              />
            )}

            {shoudlRenderNoMoreActivities && (
              <Flex>
                <Text
                  alignSelf={'center'}
                  fontSize={'md'}
                  color={'gray.400'}
                  pt={{ base: 4, lg: 8 }}
                  pb={{ base: 3, lg: 6 }}
                >
                  No more activities.
                </Text>
              </Flex>
            )}
          </VStack>
        </VStack>
      </Scaffold>

      {/* MODALS */}
      <ModalRemove
        modalRef={cancelApplicationModalRef}
        headerText={'Cancel application'}
        handleDelete={(activityApplicationId) => {
          if (activityApplicationId) {
            mutateCancelActivity(activityApplicationId);
          }
        }}
        handleCloseModal={() => {
          cancelApplicationModalRef.current?.close();
        }}
        rightBtnLoading={isLoadingCancelActivity}
        rightBtnText={'Cancel'}
      >
        <Text fontSize={'sm'} color={'gray.600'}>
          Are you sure you want to cancel this application?
        </Text>
      </ModalRemove>
    </>
  );
};
