import { Stack, Text } from 'native-base';
import Button from 'ui/components/Button';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import { colors } from 'ui/theme/colors';

import { NearYouEmptyStateProps } from './types';

export const NearYouEmptyState = ({
  brandColor,
  onLocationButtonPress,
}: NearYouEmptyStateProps) => {
  return (
    <Stack space={6} width="100%" alignItems="center">
      <Illustration
        width={{ base: '7.5rem', lg: '10.5rem' }}
        height={{ base: '7.5rem', lg: '10.5rem' }}
        name={ILLUSTRATIONS_NAME.NEAR_BY}
      />
      <Stack>
        <Text
          textAlign="center"
          color="gray.600"
          fontSize={{ base: 'sm', lg: 'md' }}
          maxW="13rem"
        >
          {`It looks like there aren't any opportunities nearby.`}
        </Text>
        <Text
          textAlign="center"
          color="gray.600"
          fontSize={{ base: 'sm', lg: 'md' }}
        >{`Let's explore other locations!`}</Text>
      </Stack>
      <Button
        borderColor={brandColor || colors.primary[400]}
        maxW="10.5rem"
        width="100%"
        variant="outline"
        _text={{ color: brandColor || colors.primary[400] }}
        leftIconName="map-pin"
        leftIconColor={brandColor || colors.primary[400]}
        leftIconSize={16}
        onPress={onLocationButtonPress}
      >
        Change location
      </Button>
    </Stack>
  );
};
