import { useDispatch } from 'react-redux';

import { inAppSliceActions } from '~/store/slices/inApp';

export const useInAppFiltersController = () => {
  const dispatch = useDispatch();

  const clearFilters = () => {
    dispatch(inAppSliceActions.clearSearchFilters());
  };

  return { clearFilters };
};
