import { ActivitySearchSection } from '~/pages/Authenticated/InApp/Explorer/types';

export type SearchModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  onSubmit: (payload: {
    search: string;
    sectionType?: ActivitySearchSection;
  }) => void;
  initialValue: string;
};

export const searchSectionTypeSuggestions = [
  { label: 'EVENTS', value: ActivitySearchSection.Event },
  { label: 'TEAM EVENTS', value: ActivitySearchSection.TeamEvent },
  { label: 'ACTIONS', value: ActivitySearchSection.Action },
  { label: 'ONGOING OPPORTUNITIES', value: ActivitySearchSection.Ongoing },
];
