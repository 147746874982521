export const ENVIRONMENT: string = import.meta.env.MODE || '';
export const SENTRY_DNS_KEY: string = import.meta.env.VITE_SENTRY_DNS_KEY;
export const DEFAULT_ECOSYSTEM_ID: string =
  import.meta.env.VITE_DEFAULT_ECOSYSTEM_ID || '';
export const DEFAULT_APP_ID: string = import.meta.env.VITE_DEFAULT_APP_ID || '';
export const DOIT_APP_ID: string = import.meta.env.VITE_DOIT_APP_ID || '';

export const DEFAULT_AWS_PUBLIC_BUCKET: string = import.meta.env
  .VITE_AWS_PUBLIC_BUCKET;
export const DEFAULT_URL_UPLOADS_API: string = import.meta.env
  .VITE_URL_UPLOADS_API;
export const DEFAULT_MEASUREMENT_UNIT_TIME_ID: string = import.meta.env
  .VITE_DEFAULT_MEASUREMENT_UNIT_TIME_ID;
export const DOIT_ORG_ADMIN_URL: string = import.meta.env
  .VITE_DOIT_ORG_ADMIN_URL;
export const DOIT_VOLUNTEER_URL: string = import.meta.env
  .VITE_DOIT_VOLUNTEER_URL;

export const BHO_ORGANIZATION: string = import.meta.env.VITE_BHO_ORGANIZATION;

export const GOOGLE_PLACE_PROXY_API =
  import.meta.env.VITE_GOOGLE_PLACE_PROXY_API || '';
