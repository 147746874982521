import { Box, HStack, Pressable, Text } from 'native-base';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

export interface LocationToggleProps {
  label?: string;
  brandColor?: string;
  onPress: VoidFunction;
}

export const LocationToggle = (props: LocationToggleProps) => {
  const {
    label = 'Select your location',
    brandColor = colors.primary[400],
    onPress,
  } = props;

  return (
    <Pressable onPress={onPress} w="full">
      <HStack
        w="full"
        h="full"
        alignItems={'center'}
        justifyContent={'space-between'}
        space={2.5}
      >
        <Icon icon={'map-pin'} size={16} color={brandColor} />
        <Text
          w="full"
          flex={1}
          fontSize={'sm'}
          lineHeight={'150%'}
          fontWeight={400}
          noOfLines={1}
          color={colors.darkText}
        >
          {label}
        </Text>
        <Box px={4}>
          <Icon icon={'chevron-down'} size={16} color={brandColor} />
        </Box>
      </HStack>
    </Pressable>
  );
};

export default LocationToggle;
