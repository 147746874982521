import { Box, Text } from 'native-base';
import { colors } from 'ui/theme/colors';

export const FilterModalSectionLabel = (props: { label: string }) => {
  const { label } = props;
  return (
    <Box borderBottomWidth={1} borderColor={colors.gray[200]} mb={4}>
      <Text fontSize={'sm'} fontWeight={500} color={colors.darkText} pb={2}>
        {label}
      </Text>
    </Box>
  );
};
