import { useMemo, useRef } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import useInfinityActivityApplication from '~/hooks/useInfinityActivityApplication';
import { expectedApplicationStatus } from '~/pages/Authenticated/Activities/constants';
import { ActivitiesService } from '~/services/resources/activities';

export const useActivitiesController = () => {
  // -- Providers
  const navigate = useNavigate();
  const {
    selectedUserProfile,
    user,
    availableAppsFromPermissionGroupsById: myApps,
  } = useAppSelector((state) => state.auth);

  // -- States
  const cancelApplicationModalRef = useRef<IModalRefProps>();

  // -- Hooks
  const queryParams = useMemo(
    () => ({
      filter: {
        userProfile: selectedUserProfile?._id,
        status: {
          $in: expectedApplicationStatus,
        },
        app: { $in: Object.keys(myApps || {}).map((app) => app) },
      },
      sort: { updatedAt: 'desc' },
      populate: [
        {
          model: 'App',
          path: 'app',
          select: ['_id', 'name'],
        },
      ],
    }),
    [selectedUserProfile, myApps],
  );

  const { data, refetch, fetchNextPage, ...dataState } =
    useInfinityActivityApplication(queryParams);

  const activityApplications = useMemo(
    () => data?.pages?.flatMap((page) => page.responseData?.data) ?? [],
    [data],
  );

  const handleCancelApplication = async (activityApplicationId: string) => {
    if (!user?._id) return;
    return await ActivitiesService.cancelAttendanceToActivity(
      activityApplicationId,
      user?._id,
    );
  };

  const { mutate: mutateCancelActivity, isLoading: isLoadingCancelActivity } =
    useMutation({
      mutationKey: 'cancelActivity',
      mutationFn: handleCancelApplication,
      onSuccess: async () => {
        await refetch();
        navigate(PAGES.CancelApplicationConfirmation);
      },
    });

  // -- Handlers
  const handleOpenCancelApplicationModal = async (
    activityApplicationId: string,
  ) => {
    cancelApplicationModalRef.current?.open(activityApplicationId);
  };

  const handleGoToFindOpportunities = () => {
    navigate(PAGES.AllApps);
  };

  const handleGoToLogActivity = (activityApplicationId?: string) => {
    navigate(`/my-wallet/log-activity/${activityApplicationId || ''}`);
  };

  const handleOnScrollEndReached = async () => {
    const { isRefetching, isFetchingNextPage, hasNextPage } = dataState;
    if (isRefetching || isFetchingNextPage || !hasNextPage) return;
    await fetchNextPage();
  };

  // Computed
  const shouldRenderSkeleton =
    dataState.isLoading || dataState.isFetchingNextPage;

  const shoudlRenderNoMoreActivities =
    activityApplications.length > 0 && !dataState.hasNextPage;

  const shouldRenderIllustration =
    (activityApplications ?? []).length == 0 && !dataState.isLoading;

  return {
    activityApplications,
    shouldRenderSkeleton,
    shoudlRenderNoMoreActivities,
    shouldRenderIllustration,
    isLoadingCancelActivity,
    cancelApplicationModalRef,
    handleOnScrollEndReached,
    handleOpenCancelApplicationModal,
    handleGoToFindOpportunities,
    mutateCancelActivity,
    handleGoToLogActivity,
  };
};
