import { calculateDistance } from '~/utils/calculate-distance';

interface GeoPoint {
  lat: number;
  lng: number;
}

export const sortByGeoPoints = <T>(
  array: T[],
  referencePoint: GeoPoint,
  getter?: (item: T) => GeoPoint,
) => {
  return array.sort((a, b) => {
    const refA = (getter ? getter(a) : a) as GeoPoint;
    const refB = (getter ? getter(b) : b) as GeoPoint;

    // Checagem se latitude e longitude são zero
    const isAZero = refA.lat === 0 && refA.lng === 0;
    const isBZero = refB.lat === 0 && refB.lng === 0;

    // Se ambos são zero, mantém a ordem atual
    if (isAZero && isBZero) return 0;
    // Se A é zero, B deve vir antes de A
    if (isAZero) return 1;
    // Se B é zero, A deve vir antes de B
    if (isBZero) return -1;

    const distanceA = calculateDistance(referencePoint, refA);
    const distanceB = calculateDistance(referencePoint, refB);
    return distanceA - distanceB;
  });
};

export const sortByDate = <T>(array: T[], getter: (item: T) => Date) => {
  return array.sort((a, b) => {
    const refA = getter(a);
    const refB = getter(b);
    return refA.getTime() - refB.getTime();
  });
};

export const sortAscending = <T>(array: T[], getter: (item: T) => number[]) => {
  return array.sort((a, b) => {
    const [primaryRelavanceA, secondaryRelevanceA] = getter(a);
    const [primaryRelavanceB, secondaryRelevanceB] = getter(b);

    const diff = primaryRelavanceA - primaryRelavanceB;
    const diff2 = secondaryRelevanceA - secondaryRelevanceB;
    return diff == 0 ? diff2 : diff;
  });
};
