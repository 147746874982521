import { useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { useAppSelector } from '~/hooks/useAppSelector';
import {
  CausesModalFormFields,
  CausesModalProps,
} from '~/pages/Authenticated/InApp/modals/CausesModal/types';

export const useCausesModalController = (props: CausesModalProps) => {
  const previousInitialValue = useRef('');

  const { selectedApp } = useAppSelector(({ inApp }) => inApp);

  const { onClose, onSubmit, initialValues, causeOptions } = props;
  const { control, handleSubmit, reset, formState } =
    useForm<CausesModalFormFields>({
      defaultValues: { causes: initialValues.map((c) => c.value) },
    });
  const hasChanges = formState.isDirty;
  const resetForm = useCallback(() => {
    reset({ causes: [] });
  }, [reset]);

  const handleSubmitButton = (data: CausesModalFormFields) => {
    const resolvedCauses = data.causes.map((value) =>
      causeOptions.find((c) => c.value === value),
    );
    onSubmit(resolvedCauses as Array<{ label: string; value: string }>);
    onClose();
  };

  const handleCancelButton = () => {
    onClose();
  };

  const handleClearFilters = () => {
    resetForm();
    onClose();
    handleSubmit(handleSubmitButton)();
  };

  return {
    handleCancelButton,
    handleSubmitButton,
    handleClearFilters,
    selectedApp,
    control,
    handleSubmit,
    hasChanges,
  };
};
