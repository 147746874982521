import { Stack, Text, VStack } from 'native-base';
import { ChangeEvent } from 'react';
import Button from 'ui/components/Button';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';
import Modal from 'ui/components/Modals/Modal';
import { LocationAutoComplete } from 'ui/components/v2/LocationInputs/LocationAutoComplete';
import { colors } from 'ui/theme/colors';

import { GooglePlacesAutoCompleteAdapter } from '~/services/google/google-places-auto-auto-complete';

import { usePreferenceLocationModalController } from './controller';
import { PreferenceLocationModalProps } from './types';

const googlePlacesAutoComplete = GooglePlacesAutoCompleteAdapter.getSingleton();

export const PreferenceLocationModal = (
  props: PreferenceLocationModalProps,
) => {
  const { handleCloseModal, modalRef, buttonColor } = props;
  const {
    handleChangeLocation,
    handleUpdateUserLocation,
    selectedLocation,
    isLoading,
    errors,
  } = usePreferenceLocationModalController({ handleCloseModal });

  return (
    <Modal
      ref={modalRef}
      headerText="Enable location"
      headerFontSize={{ base: 'md', lg: 'lg' }}
      modalContentMaxW={{ base: '100vw', lg: '669' }}
      modalContentMaxH={{ base: '100vh', lg: 'auto' }}
      modalContentW={{ base: '100vw', lg: '669' }}
      modalContentH={{ base: '100vh', lg: 'auto' }}
      p={0}
      hideCloseBtn
      onClose={(e: ChangeEvent<HTMLInputElement>) => e.preventDefault()}
    >
      <VStack
        h={{ base: '85vh', md: 'auto' }}
        py="2"
        space={6}
        justifyContent="space-between"
      >
        <Stack space={6}>
          <Stack space={8}>
            <Text fontSize="md" color={colors.gray[600]} mt={4}>
              To discover opportunities near you, please allow us access to your
              location, or enter your preferred address.
            </Text>
            <VStack space={2}>
              <Text fontSize="sm">Location</Text>

              <LocationAutoComplete
                isRequired
                onChange={handleChangeLocation}
                placeholder="Address or Post code"
                errorMessage={errors?.location?.message?.toString()}
                provider={googlePlacesAutoComplete}
              />

              <Stack
                alignItems={'center'}
                justifyContent={'center'}
                py={6}
                zIndex={-1}
              >
                <Illustration
                  resizeMode={'contain'}
                  w={'160px'}
                  h={'160px'}
                  name={ILLUSTRATIONS_NAME.NEAR_BY}
                />
              </Stack>
            </VStack>
          </Stack>
        </Stack>
        <Button
          zIndex={-1}
          onPress={handleUpdateUserLocation}
          isLoading={isLoading}
          disabled={!selectedLocation || isLoading}
          backgroundColor={buttonColor}
        >
          Set location
        </Button>
      </VStack>
    </Modal>
  );
};
