import { useState } from 'react';

import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import { MobileHeaderProps } from '~/pages/Authenticated/InApp/components/MobileHeader/types';

export const useMobileHeaderController = (props: MobileHeaderProps) => {
  const { backButtonPath } = props;

  // -- Providers
  const { selectedApp } = useAppSelector((state) => state.inApp);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { goToRoute } = useRouter();

  // -- Handlers
  const openMenu = () => {
    setIsMenuOpen(true);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const goBack = () => {
    const path = backButtonPath || PAGES.AllApps;
    goToRoute(path);
  };

  return { isMenuOpen, goBack, openMenu, closeMenu, selectedApp };
};
