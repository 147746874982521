import { IUserProfileFormFields } from '~/pages/Authenticated/UserProfile/types';

export const USER_PROFILE_INITIAL_FORM_DATA: IUserProfileFormFields = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  birthday: '',
  profilePicture: '',
  location: null,
  externalId: '',
  organizationDepartment: null,
};
