import { HStack, Skeleton, Stack } from 'native-base';

import { colors } from '~/theme/colors';

export const ActivityCardLoading = () => {
  return (
    <Stack
      width="100%"
      borderRadius="2xl"
      borderWidth={1}
      borderColor="gray.300"
      bgColor={colors.singletons.lightText}
    >
      <Stack borderTopRadius="2xl" width="100%" height="8rem">
        <Skeleton width="100%" height="100%" />
      </Stack>

      <Stack p={3} space={3}>
        <HStack alignItems="center" justifyContent="space-between">
          <Skeleton borderRadius="2xl" width="40%" height="1.85rem" />
          <Skeleton.Text width="40%" lines={1} />
        </HStack>
        <Skeleton.Text lines={1} width="50%" />
        <Skeleton.Text lines={1} width="50%" />

        <Skeleton borderRadius="lg" />
      </Stack>
    </Stack>
  );
};
