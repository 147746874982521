import { Box, HStack, Pressable, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import FilterForm from '~/pages/Authenticated/InApp/components/FilterForm';
import { useFilterSidebarController } from '~/pages/Authenticated/InApp/modals/FilterSideBar/controller';
import { FilterSidebarProps } from '~/pages/Authenticated/InApp/modals/FilterSideBar/types';

const FilterSidebar = (props: FilterSidebarProps) => {
  const {
    handlCancelButton,
    handleSubmitButton,
    formController,
    selectedApp,
    hasChanges,
  } = useFilterSidebarController(props);

  return (
    <>
      <Box borderWidth={1} borderColor={colors.gray[200]} borderRadius={12}>
        {/* header */}
        <HStack
          borderBottomWidth={1}
          px={4}
          py={4}
          borderColor={colors.gray[200]}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <HStack space={2}>
            <Icon icon="sliders" size={'24px'} color={colors.gray[500]} />
            <Text color={colors.darkText} fontSize={'md'} fontWeight={500}>
              Filter
            </Text>
          </HStack>

          <Pressable onPress={handlCancelButton}>
            <HStack space={2}>
              <Icon icon="delete" size={'16px'} color={colors.gray[500]} />
              <Text color={colors.darkText} fontSize={'xs'} fontWeight={500}>
                Clear all filters
              </Text>
            </HStack>
          </Pressable>
        </HStack>

        {/* body */}
        <Box px={4} pt={6}>
          <FilterForm
            form={formController.form}
            activityType={props.activityType}
            attendanceType={props.attendanceType}
            filterWhiteList={props.filterWhiteList}
            isTeam={props.isTeam}
          />
        </Box>

        {/* footer */}
        <VStack width={'100%'} px={4} pb={6} mt={10}>
          <Button
            onPress={formController.form.handleSubmit(handleSubmitButton)}
            px={4}
            height={'42px'}
            backgroundColor={selectedApp?.brandColor || colors.primary[400]}
            isDisabled={!hasChanges}
          >
            <Text color={colors.white} fontWeight={500} fontSize={'md'}>
              Apply filter
            </Text>
          </Button>
        </VStack>
      </Box>
    </>
  );
};

export default FilterSidebar;
