import { Stack, Text, VStack } from 'native-base';
import Button from 'ui/components/Button';
import CardPropInfo from 'ui/components/Cards/CardPropInfo';
import Tag from 'ui/components/Tags/Tag';
import { AdvertiseRegionOptionsEnum, LocationOptionsEnum } from 'ui/enums';

import { RegionTag } from '~/pages/Authenticated/v2/ActivityView/components/ActivityLocation/RegionTag';
import { getGoogleMapLink } from '~/utils/getGoogleMapLink';

import { useActivityLocationController } from './controller';
import { ActivityLocationProps } from './types';

export const ActivityLocation = (props: ActivityLocationProps) => {
  const {
    coordinates,
    mappedRegions,
    advertiseRegionOption,
    remoteAddress,
    streetAddress,
    isFromHome,
  } = useActivityLocationController(props);

  const renderInPersonLocation = () => {
    return (
      <a
        style={{ textDecoration: 'none', color: 'unset' }}
        target="_blank"
        href={getGoogleMapLink(coordinates)}
        rel="noreferrer"
      >
        <Button
          bg={'gray.100'}
          leftIconName={'external-link'}
          leftIconColor={'singletons.black'}
        >
          <Text fontSize={12} color={'singletons.black'}>
            View map
          </Text>
        </Button>
      </a>
    );
  };

  const renderFromHome = () => {
    return (
      <VStack mt={2}>
        <Stack borderBottomWidth={1} borderBottomColor={'gray.300'} pb={2}>
          <Text fontSize={{ base: 'lg' }}>Regions</Text>
        </Stack>
        {advertiseRegionOption == AdvertiseRegionOptionsEnum.SelectRegions ? (
          mappedRegions?.map((mappedRegion) => (
            <RegionTag
              mappedRegion={mappedRegion}
              key={mappedRegion.mainRegionData._id}
            />
          ))
        ) : (
          <VStack mt={2}>
            <Tag text="Anywhere in UK" />
          </VStack>
        )}
      </VStack>
    );
  };

  return (
    <VStack space={6}>
      {!isFromHome && !!streetAddress && (
        <CardPropInfo
          icon={'map'}
          info={streetAddress}
          justifyContent={'flex-start'}
        />
      )}

      {!!remoteAddress && (
        <CardPropInfo
          icon={'video'}
          info={remoteAddress}
          link={remoteAddress}
          justifyContent={'flex-start'}
          isLink
        />
      )}

      {isFromHome ? renderFromHome() : renderInPersonLocation()}
    </VStack>
  );
};

export default ActivityLocation;
