import { Box, HStack, Input, Stack, Text } from 'native-base';
import { IoReloadCircleSharp } from 'react-icons/io5';
import Button from 'ui/components/Button';
import { colors } from 'ui/theme/colors';

import { useCopyLinkController } from './useCopyLinkController';

export interface ICopyTeamInviteLinkProps {
  link?: string;
  label?: string;
  onReload?: VoidFunction;
}

export const CopyTeamInviteLink = (props: ICopyTeamInviteLinkProps) => {
  const { link = '', label = '', onReload } = props;
  const { handleCopyLink } = useCopyLinkController();

  return (
    <>
      <Box h="8" />
      {label && (
        <Text mb="3" fontSize="md" fontWeight="medium" color="gray.600">
          {label}
        </Text>
      )}
      <HStack alignItems="center" space={5} w="full">
        <Button
          width={1}
          minW={{ base: 'fit-content' }}
          variant="outline"
          borderColor="primary.600"
          py="6"
          onPress={() => onReload && onReload()}
        >
          <Text color="primary.600" lineHeight={1}>
            <IoReloadCircleSharp size={24} />
          </Text>
        </Button>
        <Stack width="100%">
          <Input
            value={link}
            w="100%"
            style={{ textDecorationStyle: 'dashed' }}
            editable={false}
            isDisabled
          />
        </Stack>
        <Button
          flex="1"
          borderColor="primary.600"
          leftIconName="copy"
          leftIconColor={colors.green[600]}
          py="6"
          variant="outline"
          onPress={() => handleCopyLink(link)}
          minW={{ base: 'fit-content' }}
        >
          <Text color="primary.600" fontSize="md" fontWeight="500">
            Copy
          </Text>
        </Button>
      </HStack>
    </>
  );
};

export default CopyTeamInviteLink;
