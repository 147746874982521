import { useMemo } from 'react';
import { EEventApplicationType } from 'ui/enums';

import { useAppSelector } from '~/hooks/useAppSelector';
import { useQuery } from '~/hooks/useQuery';
import { useUserLocationController } from '~/hooks/useUserLocation/useUsersLocation';
import { SortByType } from '~/pages/Authenticated/InApp/SearchView/types';
import { DEFAULT_LOCATON as DEF_LOCATON } from '~/store/slices/inApp';
import { IActivityDefinition } from '~/types/interfaces/activity';

const startDate = new Date();
const endDate = new Date(startDate.getTime());
endDate.setDate(startDate.getDate() + 90);

export const useLoadEventsNearYou = (
  applicationType: EEventApplicationType,
) => {
  const { selectedApp } = useAppSelector(({ inApp }) => inApp);
  const { location } = useUserLocationController();

  const filter = useMemo(() => {
    if (!selectedApp?._id) return null;
    const params: { [key: string]: unknown } = {
      eventApplicationTypes: JSON.stringify([applicationType]),
      relevance: SortByType.NEAREST,
      apps: JSON.stringify([selectedApp._id]),
      startDate,
      endDate,
      limit: 1,
      position: location
        ? JSON.stringify({ lat: location.lat, lng: location.lng })
        : JSON.stringify({ lat: DEF_LOCATON.lat, lng: DEF_LOCATON.lng }),
    };

    return params;
  }, [location, applicationType, selectedApp]);

  const { data, isLoading } = useQuery<IActivityDefinition[]>(
    `/activity-definition-public/search/event/in-person`,
    {
      key: `eventsNearYou-${applicationType}-${JSON.stringify(filter)}`,
      queryOptions: {
        enabled: !!filter,
      },
      requestOptions: {
        params: filter,
      },
    },
  );

  return { data, isLoading };
};
