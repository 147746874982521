import { Pressable, Stack, Text } from 'native-base';
import Button from 'ui/components/Button';
import Icon from 'ui/components/Icon';

import { ActivityTypeButtonProps } from './types';

export const ActivityTypeButton = ({
  buttonBgColor,
  iconName,
  iconColor,
  text,
  iconSize = '1.5rem',
  height = '3.5rem',
  width = '3.5rem',
  onPress,
}: ActivityTypeButtonProps) => {
  return (
    <Pressable onPress={onPress}>
      <Stack space={2} alignItems="center" width="100%">
        <Button
          maxW={width}
          maxH={height}
          minH={height}
          minW={width}
          width="100%"
          height="100%"
          borderRadius="full"
          bgColor={`${buttonBgColor}22`}
          onPress={onPress}
        >
          <Icon isCustom icon={iconName} size={iconSize} color={iconColor} />
        </Button>
        <Text
          textAlign="center"
          fontSize={{ base: 'xs', lg: 'md' }}
          fontWeight={{ base: 500, lg: 400 }}
        >
          {text}
        </Text>
      </Stack>
    </Pressable>
  );
};
