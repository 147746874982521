import {
  Box,
  FlatList,
  Hidden,
  HStack,
  PresenceTransition,
  Stack,
  Text,
  VStack,
} from 'native-base';
import Button from 'ui/components/Button';
import { IconEnum } from 'ui/components/Icon';
import Loading from 'ui/components/Loading';
import { colors } from 'ui/theme/colors';

import { MobileAppBar } from '~/components/MobileAppBar/MobileAppBar';
import { Scaffold } from '~/components/Scaffold';
import { useUserLocationController } from '~/hooks/useUserLocation/useUsersLocation';
import { ActivityCardLoading } from '~/pages/Authenticated/InApp/components/ActivityCardLoading';
import { ActivityTypeButton } from '~/pages/Authenticated/InApp/components/ActivityTypeButton';
import { CoverSearch } from '~/pages/Authenticated/InApp/components/CoverSearch';
import { Footer } from '~/pages/Authenticated/InApp/components/Footer';
import { NearYouEmptyState } from '~/pages/Authenticated/InApp/components/NearYouEmptyState';
import SearchTopBar from '~/pages/Authenticated/InApp/components/SearchTopBar';
import { SelectAddressButton } from '~/pages/Authenticated/InApp/components/SelectAddressButton';
import { MapActivityToActivityCard } from '~/pages/Authenticated/InApp/HomeView/mapper';
import CausesModal from '~/pages/Authenticated/InApp/modals/CausesModal';

import { ActivityCard } from '../components/ActivityCard';
import { activityLoadingSkeletonCards, activityTypesProps } from './constants';
import { useHomeViewController } from './controller';

export const InAppHomeView = () => {
  const {
    sectionType,
    searchFilters,
    modalsState,
    selectedApp,
    causeSelectOptions,
    activitiesNearYou,
    isLoadingActivities,
    isMobile,
    isContentLoading,
    onCardButtonPress,
    getActivityCardButtonText,
    setCauses,
    closeModal,
    onButtonActivityTypePress,
    onSearchPress,
    openModal,
    getActivityTagProps,
    handleSearchBarSubmit,
  } = useHomeViewController();

  const { openLocationModal, location } = useUserLocationController();

  const renderActivityCards = () => {
    if (isLoadingActivities) {
      return (
        <HStack width="100%" alignItems="center" justifyContent="center">
          <FlatList
            data={activityLoadingSkeletonCards}
            renderItem={() => {
              return (
                <Stack width="18rem" mr={4}>
                  <ActivityCardLoading />
                </Stack>
              );
            }}
            horizontal={true}
            showsHorizontalScrollIndicator={false}
          />
        </HStack>
      );
    }

    if (activitiesNearYou.length) {
      return (
        <HStack width="100%" alignItems="center" justifyContent="center">
          <FlatList
            data={activitiesNearYou}
            renderItem={({ item }) => {
              const { bgColor, icon, text } = getActivityTagProps(item);

              const { title, distance, date, image } =
                MapActivityToActivityCard(location)(item);

              return (
                <Stack width="18rem" mr={7}>
                  <ActivityCard
                    date={date}
                    title={title}
                    image={image}
                    distance={distance}
                    tag={{ bgColor, text, icon: icon as IconEnum }}
                    buttonText={getActivityCardButtonText(item)}
                    onPress={() => onCardButtonPress(item._id)}
                  />
                </Stack>
              );
            }}
            horizontal={true}
            showsHorizontalScrollIndicator={false}
          />
        </HStack>
      );
    }

    return (
      <Stack mt={5} pr={10}>
        <NearYouEmptyState
          brandColor={selectedApp?.brandColor}
          onLocationButtonPress={openLocationModal}
        />
      </Stack>
    );
  };

  return (
    <>
      {isContentLoading ? (
        <Loading color={selectedApp?.brandColor || colors.primary[400]} />
      ) : (
        <PresenceTransition
          visible={true}
          initial={{ opacity: 0.5 }}
          animate={{ opacity: 1, transition: { duration: 250 } }}
        >
          <Scaffold
            bgColor={colors.singletons.lightText}
            minH="100vh"
            pb={{ base: 16, md: 0 }}
          >
            <Hidden till="lg">
              <CoverSearch
                initialValues={{
                  sectionType,
                  search: searchFilters.search,
                  causes: searchFilters.causes,
                }}
                onSubmit={handleSearchBarSubmit}
                searchFoundResults={0}
                onFilterPress={() => openModal('causes')}
                causesOptions={causeSelectOptions}
                coverImage={selectedApp?.coverImage}
                headLine={selectedApp?.headLine}
              />
            </Hidden>

            <VStack p={{ base: 4, lg: 0 }} space={{ base: 3, lg: 0 }}>
              <Hidden from="lg">
                <>
                  {/*Page title */}
                  <Stack mb={3}>
                    <Text fontSize="md" fontWeight={500} color="gray.700">
                      Discover
                    </Text>
                  </Stack>
                  {/* Search input */}
                  <Stack mx={-2}>
                    <SearchTopBar
                      initialValues={{
                        sectionType,
                        search: searchFilters.search,
                        causes: searchFilters.causes,
                      }}
                      onSubmit={handleSearchBarSubmit}
                      searchFoundResults={0}
                      onFilterPress={() => openModal('causes')}
                      causesOptions={causeSelectOptions}
                      showTypeLabel
                    />
                  </Stack>
                  {/*Location component */}
                  <Stack>
                    <SelectAddressButton
                      address={location?.rawLocation || ''}
                      onPress={openLocationModal}
                      brandColor={
                        selectedApp?.brandColor || colors.primary[400]
                      }
                    />
                  </Stack>
                </>
              </Hidden>

              {/* select event type + list activity component */}
              <Stack bgColor="white" mx={-2}>
                <div id="discover">
                  <Stack width="100%" alignItems="center">
                    <HStack
                      width={{ base: 'full', lg: '50%' }}
                      padding={4}
                      pb={{ base: 0, md: 4 }}
                      space={{ base: 3, lg: 10 }}
                      justifyContent="center"
                    >
                      {activityTypesProps.map(({ text, icon, type }) => (
                        <Stack
                          key={text}
                          maxW={'7.5rem'}
                          width="100%"
                          alignItems="center"
                        >
                          <ActivityTypeButton
                            text={text}
                            iconName={icon}
                            buttonBgColor={
                              selectedApp?.brandColor || colors.primary[400]
                            }
                            iconColor={
                              selectedApp?.brandColor || colors.primary[400]
                            }
                            onPress={() => onButtonActivityTypePress(type)}
                            height={isMobile ? '3.5rem' : '4.8rem'}
                            width={isMobile ? '3.5rem' : '4.8rem'}
                            iconSize={'1.5rem'}
                          />
                        </Stack>
                      ))}
                    </HStack>
                  </Stack>
                </div>

                <Hidden from="lg">
                  <div
                    id="nearToYou"
                    style={{ width: '100%', padding: '16px' }}
                  >
                    <Stack space={5}>
                      <Text color="gray.700" fontSize="lg" fontWeight={500}>
                        Opportunities near you!
                      </Text>
                      {renderActivityCards()}
                    </Stack>
                  </div>
                </Hidden>
              </Stack>

              <Hidden till="lg">
                <Stack
                  width="100%"
                  bgColor={colors.singletons.lightText}
                  justifyContent="center"
                  alignItems="center"
                  py="5.5rem"
                >
                  <Stack
                    maxWidth="63rem"
                    width="100%"
                    justifyContent="center"
                    alignItems={
                      activitiesNearYou.length === 3 ||
                      activitiesNearYou.length === 0
                        ? 'center'
                        : 'initial'
                    }
                  >
                    <div id="nearToYou">
                      <Stack space={5}>
                        {activitiesNearYou.length ? (
                          <Text color="gray.700" fontSize="lg" fontWeight={500}>
                            Opportunities near you!
                          </Text>
                        ) : null}
                        {renderActivityCards()}
                      </Stack>
                    </div>
                  </Stack>
                </Stack>
              </Hidden>

              <Stack bgColor={{ lg: 'white' }} alignItems="center">
                <Stack
                  py={{ lg: '5.5rem' }}
                  justifyContent="center"
                  alignItems="center"
                  width={{ base: '100%', lg: '50%' }}
                >
                  {/* About */}
                  <div id="about">
                    <Footer
                      appId={selectedApp?._id}
                      appName={selectedApp?.name || ''}
                      appLogo={selectedApp?.logo}
                      text={selectedApp?.description || ''}
                    />
                  </div>
                </Stack>
              </Stack>
            </VStack>
          </Scaffold>
        </PresenceTransition>
      )}

      <Hidden from="lg">
        <>
          <Box h={12} />
          <Stack
            bgColor="white"
            width="100%"
            p={4}
            bottom={{ base: 16, md: 0 }}
            position="fixed"
          >
            <Button
              onPress={onSearchPress}
              p={6}
              width="100%"
              leftIconName="search"
              leftIconSize={24}
              bgColor={selectedApp?.brandColor || colors.primary[400]}
            >
              <Text fontWeight={500} fontSize={'md'} color={colors.white}>
                Search
              </Text>
            </Button>
          </Stack>
        </>
      </Hidden>

      <Hidden from={'md'}>
        <MobileAppBar />
      </Hidden>

      <CausesModal
        causeOptions={causeSelectOptions}
        initialValues={searchFilters.causes}
        isOpen={modalsState.causes}
        onSubmit={setCauses}
        onClose={closeModal}
      />
    </>
  );
};
