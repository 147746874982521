import {
  Avatar,
  Divider,
  Flex,
  HStack,
  Link,
  Pressable,
  Stack,
  Text,
} from 'native-base';
import Icon from 'ui/components/Icon';
import { BHO_HELP_CENTER_URL } from 'ui/constants/commonUrl.constants';
import { getNameInitials } from 'ui/utils/getNameInititals';

import { colors } from '~/theme/colors';

import { menuList, useSideBarController } from './controller';
import { SideBarProps } from './types';

export const Sidebar = ({ onClose }: SideBarProps) => {
  const { user, selectedEcosystem, gotoMyWallet, onMenuOptionPress } =
    useSideBarController({ onClose });

  return (
    <Stack position="fixed" top={0} zIndex={1} height="100vh" width="100%">
      <Flex direction="row" height="100%">
        <Stack
          px={3}
          position={'relative'}
          height="100%"
          width="80%"
          bgColor="white"
        >
          {/*Header */}
          <Stack paddingX={3} paddingY={6} space={3}>
            <HStack>
              <Avatar source={{ uri: user?.userProfiles[0]?.profilePicture }}>
                {getNameInitials(user?.name || '')}
              </Avatar>
              <Avatar
                ml={-4}
                bgColor="red.100"
                borderWidth={1}
                borderColor="white"
                source={{ uri: selectedEcosystem?.logo }}
              >
                {getNameInitials(selectedEcosystem?.name || '')}
              </Avatar>
            </HStack>
            <Stack space={1}>
              <Text color="darkText" fontSize="md" fontWeight={500}>
                {user?.name || ''}
              </Text>
              <Text fontSize="sm">{selectedEcosystem?.name || ''}</Text>
            </Stack>
          </Stack>

          <Divider />

          <Stack paddingX={3} paddingY={6} space={2}>
            <Text color="gray.600">Menu</Text>

            {menuList.map((item) => (
              <Pressable
                key={item.text}
                onPress={() => onMenuOptionPress(item.href)}
              >
                <Stack paddingY={3}>
                  <Text fontWeight={500} color="black" fontSize="md">
                    {item.text}
                  </Text>
                </Stack>
              </Pressable>
            ))}
            <Link href={BHO_HELP_CENTER_URL} isExternal>
              <Stack paddingY={3}>
                <Text fontWeight={500} color="black" fontSize="md">
                  Help centre
                </Text>
              </Stack>
            </Link>
          </Stack>

          <Stack
            justifyContent="flex-end"
            paddingY={1}
            paddingX={3}
            bottom={0}
            position="absolute"
            width="100%"
            mb={6}
          >
            <Pressable paddingY={3} onPress={gotoMyWallet}>
              <HStack space={2}>
                <Icon icon="chevron-left" size={24} />
                <Text fontWeight={500} color="black" fontSize="md">
                  Go to My Wallet
                </Text>
              </HStack>
            </Pressable>
          </Stack>
        </Stack>

        <div
          style={{
            width: '20%',
            backgroundColor: colors.darkText,
            opacity: 0.3,
          }}
          onClick={onClose}
        />
      </Flex>
    </Stack>
  );
};
