import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { ActivityRegionType } from 'ui/enums';
import { RelatedToEnum } from 'ui/types/activities';

import { useActivityCategories } from '~/hooks/useActivityCategories';
import { useActivityNations } from '~/hooks/useActivityRegions/useActivityNations';
import { useActivityRegions } from '~/hooks/useActivityRegions/useActivityRegions';
import { useAppSelector } from '~/hooks/useAppSelector';
import {
  ALLOWED_ACTIVITY_SEARCH_SECTION,
  SectionConfigMap,
} from '~/pages/Authenticated/InApp/Explorer/constants';
import { ActivitySearchSection } from '~/pages/Authenticated/InApp/Explorer/types';
import { inAppSliceActions } from '~/store/slices/inApp';

export const useExplorerController = () => {
  // -- Providers
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  // -- States
  const [isLoading, setIsLoading] = useState(false);
  const [showSectionSelectModal, setShowSectionSelectModal] = useState(false);
  const { selectedApp } = useAppSelector(({ inApp }) => inApp);

  // -- Hooks
  const sectionType = useMemo(() => {
    const section = searchParams.get('section') as ActivitySearchSection;
    if (!ALLOWED_ACTIVITY_SEARCH_SECTION.includes(section)) {
      return ActivitySearchSection.Event;
    }
    return section;
  }, [searchParams]);

  const sectionConfig = useMemo(() => {
    if (!sectionType) return null;
    return SectionConfigMap[sectionType];
  }, [sectionType]);

  useEffect(() => setIsLoading(false), [sectionConfig]);

  // -- Common resources
  const { data: causeOptionsData } = useActivityCategories(
    RelatedToEnum.CAUSE_OPTIONS,
  );
  const { data: nationsdata } = useActivityNations();
  const { data: regionsData } = useActivityRegions();

  const causeSelectOptions = useMemo(() => {
    return (causeOptionsData ?? []).map((options) => ({
      value: options._id,
      label: options.displayName,
    })) as { value: string; label: string }[];
  }, [causeOptionsData]);

  const regionOptions = useMemo(() => {
    return (regionsData?.data ?? []).filter(
      (r) => r.type === ActivityRegionType.Region,
    );
  }, [regionsData]);

  const nationOptions = useMemo(() => {
    return nationsdata?.data ?? [];
  }, [nationsdata]);

  useEffect(() => {
    if (nationOptions.length == 0 || regionOptions?.length === 0) {
      return;
    }
    dispatch(
      inAppSliceActions.setRegionOptions({
        nationOptions: nationOptions ?? [],
        regionOptions: regionOptions ?? [],
      }),
    );
  }, [dispatch, nationOptions, regionOptions]);

  // -- Handlers
  const handleSearchSectionChange = (newSectionType: ActivitySearchSection) => {
    if (newSectionType !== sectionType) {
      setIsLoading(true);
      searchParams.set('section', newSectionType);
      setSearchParams(searchParams);
      setShowSectionSelectModal(false);
    }
  };

  const toggleSectionSelectModal = () => {
    setShowSectionSelectModal((prev) => !prev);
  };

  return {
    showSectionSelectModal,
    causeSelectOptions,
    sectionConfig,
    sectionType,
    isLoading,
    selectedApp,
    handleSearchSectionChange,
    toggleSectionSelectModal,
  };
};
