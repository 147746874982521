import { MeasurementUnit } from 'ui/types/measurementUnit';

import { useQuery } from '~/hooks/useQuery';

export const useLoadMeasurementUnitsByOrganization = (props: {
  organizationId: string;
}) => {
  const { organizationId } = props;

  const { data, isLoading, refetch } = useQuery<{
    data: MeasurementUnit[];
    count: number;
  }>(`measurement-units/organization/${organizationId}/volunteer`, {
    key: `measurement-unitis-public-organization${organizationId}`,
    queryOptions: { enabled: !!organizationId },
  });

  return { data, isLoading, refetch };
};
