import { Box, HStack, Pressable, Text, VStack } from 'native-base';
import Icon from 'ui/components/Icon';
import Input from 'ui/components/Input';

import { FilterDrawerScaffold } from '~/pages/Authenticated/InApp/components/FilterDrawerScaffold';
import { useSearchModal } from '~/pages/Authenticated/InApp/components/SearchTopBar/modals/SearchModal/controller';
import {
  SearchModalProps,
  searchSectionTypeSuggestions,
} from '~/pages/Authenticated/InApp/components/SearchTopBar/modals/SearchModal/types';
import { colors } from '~/theme/colors';

const SearchModal = (props: SearchModalProps) => {
  const { isOpen = false, onClose } = props;

  const { control, search, selectedApp, selectOption, handleKeyPress } =
    useSearchModal(props);

  return (
    <>
      <FilterDrawerScaffold
        title="Search"
        isOpen={isOpen}
        onClose={onClose}
        onCancel={onClose}
        onAction={() => null}
        showActionButton={false}
        brandColor={selectedApp?.brandColor}
      >
        <VStack px={2} space={2}>
          <Box width={'100%'} mb={4}>
            <Input
              h="3.25rem"
              width={'100%'}
              control={control}
              name="search"
              placeholder="Activities, organisations..."
              label="Search"
              leftIconName={'search'}
              leftIconColor={colors.gray[400]}
              labelColor={colors.gray[600]}
              borderColor={'red'}
              borderWidth={1}
              onKeyPress={handleKeyPress}
            />
          </Box>

          {search.length > 0 && (
            <Text color={colors.gray[600]} fontWeight={500} fontSize={'sm'}>
              Searching for:
            </Text>
          )}

          {search.length > 0 &&
            searchSectionTypeSuggestions.map((opt) => {
              return (
                <Pressable
                  key={opt.value}
                  onPress={() => selectOption(opt.value)}
                >
                  <HStack py={1} px={1} space={4} alignItems={'center'}>
                    <Icon
                      icon="search"
                      size={'16px'}
                      color={colors.gray[400]}
                    />
                    <VStack>
                      <Text
                        color={colors.gray[800]}
                        fontSize={'sm'}
                        fontWeight={500}
                      >
                        {search}
                      </Text>
                      <Text color={colors.gray[600]} fontSize={'xs'}>
                        in {opt.label}
                      </Text>
                    </VStack>
                  </HStack>
                </Pressable>
              );
            })}
        </VStack>
      </FilterDrawerScaffold>
    </>
  );
};

export default SearchModal;
