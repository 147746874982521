import {
  Box,
  HStack,
  Image,
  Link,
  Popover,
  Pressable,
  Radio,
  Text,
  VStack,
} from 'native-base';
import { useState } from 'react';
import Icon from 'ui/components/Icon';
import { colors } from 'ui/theme/colors';

import { PAGES } from '~/constants/pages.constants';
import {
  AppSwitcherPopOverProps,
  IAppPopoverOption,
} from '~/pages/Authenticated/InApp/components/AppSwitcher/types';

export const AppSwitcherPopOver = (props: AppSwitcherPopOverProps) => {
  const { children, onPress, options, selected } = props;

  // -- States
  const [showPopOver, setShowPopOver] = useState<boolean>(false);

  // -- Handlers
  const handleShowPopOver = (show: boolean) => {
    setShowPopOver(show);
  };

  const handleOptionPress = (option: IAppPopoverOption) => {
    onPress(option);
    setShowPopOver(false);
  };

  return (
    <Popover
      isOpen={showPopOver}
      trigger={(triggerOptions) => (
        <Pressable
          {...triggerOptions}
          style={{ cursor: 'pointer' }}
          onPress={() => handleShowPopOver(true)}
        >
          {children}
        </Pressable>
      )}
      onClose={() => handleShowPopOver(false)}
    >
      <Popover.Content
        w={{ base: 0, md: 328 }}
        top={2}
        left={{ base: 2, xl: '10%' }}
        borderRadius={10}
      >
        <Popover.Body py={3} px={2}>
          <VStack space={2}>
            <Text
              fontSize={'xs'}
              px={2}
              fontWeight={500}
              color={colors.gray[500]}
            >
              SWITCH APP
            </Text>

            {options.map((option: IAppPopoverOption) => (
              <Pressable
                key={option.label}
                onPress={() => handleOptionPress(option)}
              >
                <HStack
                  p={3}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <HStack space={3}>
                    <Image
                      source={{ uri: option?.logo || '' }}
                      w={8}
                      h={8}
                      alt={`${option.label} App Logo`}
                      borderWidth={1}
                      borderColor={colors.gray[300]}
                      borderRadius={'12px'}
                    />
                    <Text fontSize={'md'} color={colors.gray[600]}>
                      {option.label}
                    </Text>
                  </HStack>
                  <Box>
                    <Radio.Group name={'app'} value={selected}>
                      <Radio
                        _icon={{
                          color: colors.black,
                        }}
                        style={{
                          borderColor: colors.black,
                          borderRadius: 100,
                        }}
                        borderColor={colors.gray[400]}
                        textDecorationColor={colors.black}
                        color={colors.black}
                        value={option.key}
                        w="18px"
                        h="18px"
                      />
                    </Radio.Group>
                    <Box
                      w="100%"
                      h="100%"
                      style={{
                        position: 'absolute',
                        left: 0,
                      }}
                    ></Box>
                  </Box>
                </HStack>
              </Pressable>
            ))}

            <HStack alignItems={'center'} justifyContent={'center'} p={2}>
              <Link href={PAGES.AllApps}>
                <HStack
                  alignItems={'center'}
                  justifyContent={'center'}
                  space="10px"
                >
                  <Text
                    fontWeight={500}
                    fontSize={'xs'}
                    color={colors.darkText}
                  >
                    View all
                  </Text>
                  <Icon
                    icon="chevron-down"
                    size={'16px'}
                    color={colors.darkText}
                  />
                </HStack>
              </Link>
            </HStack>
          </VStack>
        </Popover.Body>
      </Popover.Content>
    </Popover>
  );
};
