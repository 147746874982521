import { Box, Hidden, Stack, VStack } from 'native-base';
import { colors } from 'ui/theme/colors';

import { ShareActivity } from '~/components/ShareActivity';
import ActivityCover from '~/pages/Authenticated/v2/ActivityView/components/ActivityCover';
import ActivityFeed from '~/pages/Authenticated/v2/ActivityView/components/ActivityFeed';
import ActivityHeader from '~/pages/Authenticated/v2/ActivityView/components/ActivityHeader';
import ActivityMobileFooter from '~/pages/Authenticated/v2/ActivityView/components/ActivityMobileFooter';
import ActivityOrganizationBio from '~/pages/Authenticated/v2/ActivityView/components/ActivityOrganizationBio';
import { useActivityViewController } from '~/pages/Authenticated/v2/ActivityView/controllers/activity-view-controller';
import { makeActivityLink } from '~/pages/Authenticated/v2/ActivityView/utils';
import ActivityInteractiveTabs from '~/pages/Authenticated/v2/ActivityView/views/ActivityInteractiveTabs';
import { ViewActivityDetailsProps } from '~/pages/Authenticated/ViewActivityDetails/types';

export const ActivityView: React.FC<ViewActivityDetailsProps> = (
  props: ViewActivityDetailsProps,
) => {
  const { selectedActivity, activityDefinition, activityType } =
    useActivityViewController(props);

  return (
    <Box bg={'singletons.white'} w={'full'} flexShrink={0}>
      {/* -- Cover */}
      <ActivityCover
        activityType={activityType || undefined}
        coverImage={activityDefinition?.coverImage}
      />

      {/* -- Main */}

      <Stack w="full" maxW="1660px" px={[0, 0, 8, 12, 20]} mr="auto" ml="auto">
        <VStack
          w="full"
          p={{ base: 4, md: 8, lg: 12 }}
          mb={{ base: '32', md: '12' }}
          bg={colors.white}
          roundedTop={32}
          roundedBottom={{ base: 0, md: 32 }}
          m={'auto'}
          space={{ base: 0, md: 4 }}
        >
          {/* -- Header */}
          <Hidden till="md">
            <ActivityHeader activityDefinition={activityDefinition} />
          </Hidden>

          {/* -- Content */}
          <Stack
            w="full"
            direction={{ base: 'column', lg: 'row' }}
            space={{ base: 12, md: 20 }}
          >
            {/* -- Article */}
            <Stack w="full" flex={1}>
              <ActivityFeed
                activityDefinition={activityDefinition}
                selectedActivity={selectedActivity || undefined}
              >
                <ActivityInteractiveTabs />
              </ActivityFeed>
            </Stack>

            {/* -- Aside */}
            <VStack
              w={{ base: 'full', lg: '440px' }}
              space={{ base: 0, lg: 12 }}
            >
              <Hidden till="lg">
                <ActivityInteractiveTabs />
              </Hidden>

              <ActivityOrganizationBio
                organization={activityDefinition.organizationSubDocument}
              />
            </VStack>
          </Stack>
        </VStack>
      </Stack>

      {/* -- Footer */}
      <ActivityMobileFooter>
        <VStack w="full" alignItems={'center'}>
          <ShareActivity
            activityLink={makeActivityLink(activityDefinition._id)}
          />
        </VStack>
      </ActivityMobileFooter>
    </Box>
  );
};

export default ActivityView;
