import {
  Box,
  Checkbox,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from 'native-base';
import { Controller } from 'react-hook-form';
import { CustomDatePicker } from 'ui/components/DateTimePickers/DatePicker';
import Loading from 'ui/components/Loading';
import MultiRangeSlider from 'ui/components/MultiRangeSlider';
import { Slider } from 'ui/components/Slider';
import { colors } from 'ui/theme/colors';

import { FilterModalSectionLabel } from '~/pages/Authenticated/InApp/components/FilterForm/fragments/FilterModalSectionLabel';
import { FilterFormProps } from '~/pages/Authenticated/InApp/components/FilterForm/types';
import { useRegionsController } from '~/pages/Authenticated/InApp/components/FilterForm/useRegionsController';
import { GenericOptionsAutoComplete } from '~/pages/Authenticated/InApp/components/GenericOptionsAutoComplete';
import OptionsLabelWrap from '~/pages/Authenticated/InApp/components/OptionsLabelWrap';
import { WhiteListOption } from '~/pages/Authenticated/InApp/SearchView/types';
import { AttendanceType } from '~/types/interfaces/activity';

const FilterForm = (props: FilterFormProps) => {
  const { form, activityType, attendanceType, filterWhiteList, isTeam } = props;

  const { nationSelectOptions, regionSelectOptions } = useRegionsController({
    form,
  });

  const {
    setValue,
    control,
    watch,
    formState: { errors },
  } = form;

  const isMobile = useBreakpointValue({ base: true, md: false });

  const values = watch();

  if (!activityType) return <Loading containerHeight="100px" />;

  const verifyWhiteListOption = (
    option: WhiteListOption,
    sub: AttendanceType,
  ) => option === true || (option as any)?.[sub] === true;

  const isDistanceRadiusAllowed = verifyWhiteListOption(
    filterWhiteList.distanceRadius,
    attendanceType,
  );

  const isDateRangeAllowed = verifyWhiteListOption(
    filterWhiteList.dateRange,
    attendanceType,
  );

  const isTeamSizeAllowed = verifyWhiteListOption(
    filterWhiteList.teamSize,
    attendanceType,
  );

  const shouldRenderWithinArea = verifyWhiteListOption(
    filterWhiteList.withinArea,
    attendanceType,
  );

  const shouldRenderShowOnline = verifyWhiteListOption(
    filterWhiteList.showOnline,
    attendanceType,
  );

  return (
    <VStack px={2} space={8}>
      {shouldRenderShowOnline && (
        <VStack width={'100%'}>
          <Controller
            control={control}
            name="showOnline"
            render={({ field: { onChange, value } }) => {
              return (
                <Checkbox
                  onChange={onChange}
                  value={'false'}
                  isChecked={value}
                  borderColor={'gray.300'}
                  _hover={{ borderColor: 'gray.300' }}
                  size={'md'}
                >
                  <Text
                    fontSize={'sm'}
                    fontWeight={400}
                    color={colors.darkText}
                  >
                    Show only online activities
                  </Text>
                </Checkbox>
              );
            }}
          />
        </VStack>
      )}

      {isDistanceRadiusAllowed && (
        <VStack width={'100%'}>
          <>
            <FilterModalSectionLabel label="Distance radius" />
            <Slider
              unityLabel="mi"
              name="distanceRadius"
              control={control}
              errorMessage={errors?.distanceRadius?.message || ''}
            />
          </>
        </VStack>
      )}

      {isDateRangeAllowed && (
        <VStack width={'100%'}>
          <FilterModalSectionLabel label="Date of the activity" />

          <Stack
            flexDirection={{ base: 'row', md: 'column' }}
            space={6}
            w="full"
          >
            <Stack w={{ base: 'full' }} marginRight={{ base: 1.5, md: 0 }}>
              <CustomDatePicker
                usePortal={isMobile}
                portalId="modal-root"
                control={control}
                name={`startDate`}
                label="From"
                placeholder="Type start date"
                errorMessage={errors?.startDate?.message}
                isRequired
                dateFormat="dd/MM/yyyy"
                timeIntervals={10}
              />
            </Stack>

            <Stack w={{ base: 'full' }} marginLeft={{ base: 1.5, md: 0 }}>
              <CustomDatePicker
                usePortal={isMobile}
                portalId="modal-root"
                control={control}
                name={`endDate`}
                label="To"
                placeholder="Type start date"
                errorMessage={errors?.endDate?.message}
                isRequired
                dateFormat="dd/MM/yyyy"
                timeIntervals={10}
              />
            </Stack>
          </Stack>
        </VStack>
      )}

      {shouldRenderWithinArea && (
        <VStack width={'100%'}>
          <FilterModalSectionLabel label="Within area" />

          <VStack width={'100%'} space={6}>
            <GenericOptionsAutoComplete
              options={nationSelectOptions}
              name="countries"
              control={control}
              leftIcon="map"
              label="countries"
              placeholder="Select countries"
            />
            {values.countries?.length > 0 && (
              <Box>
                <OptionsLabelWrap
                  alternative
                  options={values.countries}
                  onChange={(v) => setValue('countries', v)}
                />
              </Box>
            )}
            <GenericOptionsAutoComplete
              options={regionSelectOptions}
              name="regions"
              control={control}
              leftIcon="map"
              label="Regions"
              placeholder="Select regions"
            />

            {values.regions?.length > 0 && (
              <Box>
                <OptionsLabelWrap
                  alternative
                  options={values.regions}
                  onChange={(v) => setValue('regions', v)}
                />
              </Box>
            )}
          </VStack>
        </VStack>
      )}

      {isTeam && isTeamSizeAllowed && (
        <VStack width={'100%'}>
          <>
            <FilterModalSectionLabel label="Team size" />

            <MultiRangeSlider
              name="teamsSize"
              control={control}
              errorMessage={errors?.teamsNumber?.message}
              min={0}
              max={10}
            />
          </>
        </VStack>
      )}
    </VStack>
  );
};

export default FilterForm;
