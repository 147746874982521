import { useBreakpointValue } from 'native-base';
import { useQuery } from 'react-query';

import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import TeamService from '~/services/resources/teams';
import { getUserAcceptedTeams } from '~/utils/activityTeamMembers';

export const useTeamsListController = () => {
  const { goToRoute } = useRouter();

  const { selectedUserProfile } = useAppSelector(({ auth }) => auth);
  const quantityOfColumns = useBreakpointValue({
    base: 1,
    xl: 2,
  });

  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: ['userProfile', selectedUserProfile?._id],
    queryFn: async () => {
      const response = await TeamService.getUserTeams(
        selectedUserProfile?._id as string,
      );

      return getUserAcceptedTeams(
        selectedUserProfile?._id as string,
        response.data,
      );
    },

    enabled: !!selectedUserProfile?._id,
  });

  const handleAddTeam = () => {
    goToRoute(PAGES.CreateTeam, {
      state: {
        showBackButton: true,
      },
    });
  };

  return {
    handleAddTeam,
    teamsData: data,
    isLoading,
    refetch,
    isRefetching,
    quantityOfColumns,
  };
};
