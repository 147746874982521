import { isValid as isDateValid } from 'date-fns';
import { ActivityType } from 'ui/types/activities';
import { ILocation } from 'ui/types/interfaces';

import { SortByType } from '~/pages/Authenticated/InApp/SearchView/types';
import {
  IActivitySummary,
  PrecomputedActivitySummary,
} from '~/types/interfaces/activity';
import { sortAscending } from '~/utils/array.utils';
import { calculateDistance } from '~/utils/calculate-distance';
import {
  getCoordinatesFromAddress,
  getCoordinatesFromLocation,
} from '~/utils/getCoordinates';

export function processActivitySummaries(
  activitiesSummary: IActivitySummary[],
  location: ILocation | null,
  sortBy: SortByType,
  maxDistanceRadius?: number,
  minTimeStamp?: number,
  maxTimeStamp?: number,
): PrecomputedActivitySummary[] {
  if (activitiesSummary.length == 0 || !location) return [];

  const currentDate = new Date();
  const computedActivitySummary = [];

  for (const summary of activitiesSummary) {
    let distance = 0;
    let timeToBegin = 0;

    // Pre computa distancia do usuario
    if (summary?.address) {
      const userCoordinates = getCoordinatesFromLocation(location);
      const activityCoordinates = getCoordinatesFromAddress(summary?.address);
      if (activityCoordinates) {
        distance = calculateDistance(activityCoordinates, userCoordinates);
        if (!!maxDistanceRadius && distance > maxDistanceRadius) continue;
      }
    }

    // Pre computa tempo até o inicio da atividade
    if (summary?.startDate) {
      const startDate = new Date(summary?.startDate);
      if (!isDateValid(startDate)) continue;

      const startDateTimeStamp = startDate.getTime();

      if (minTimeStamp && maxTimeStamp) {
        const dateIntersects =
          startDateTimeStamp >= minTimeStamp &&
          startDateTimeStamp <= maxTimeStamp;

        if (!dateIntersects) continue;
      }

      timeToBegin = startDateTimeStamp - currentDate.getTime();
    }

    // Define ordem de relevancia pelo sortby
    let relevanceOrder = [timeToBegin, distance];
    if (sortBy === SortByType.NEAREST) {
      relevanceOrder = [distance, timeToBegin];
    }

    const computedItem = { ...summary, distance, relevanceOrder };
    computedActivitySummary.push(computedItem);
  }

  return computedActivitySummary;
}

export function getActivitySummaryByRelevance(
  activitySummaries: IActivitySummary[],
  type: ActivityType,
  location: ILocation | null,
  sortBy: SortByType,
  maxDistanceRadius?: number,
  minDate?: Date,
  maxDate?: Date,
): PrecomputedActivitySummary | undefined {
  if (type === ActivityType.Action)
    return activitySummaries[0] as PrecomputedActivitySummary;

  let minDateTimeStamp;
  let maxDateTimeStamp;

  if (minDate && isDateValid(minDate)) {
    minDateTimeStamp = minDate.getTime();
  }

  if (maxDate && isDateValid(maxDate)) {
    maxDateTimeStamp = maxDate.getTime();
  }

  const computedSummaries = processActivitySummaries(
    activitySummaries,
    location,
    sortBy,
    maxDistanceRadius,
    minDateTimeStamp,
    maxDateTimeStamp,
  );

  const sortedActivities = sortAscending(
    computedSummaries,
    (summary) => summary.relevanceOrder,
  );

  return sortedActivities[0];
}
