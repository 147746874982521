import { HStack, Stack, Text, VStack } from 'native-base';

import { colors } from '../../theme/colors';
import CopyButton from '../CopyButton';
import Icon from '../Icon';
import { ContactInfoItemProps } from './types';

const ContactInfoItem = ({
  infoName,
  infoValue,
  icon,
  buttonColor,
  buttonIconColor,
  buttonVariant,
  buttonTextColor,
}: ContactInfoItemProps) => {
  return (
    <VStack space={2}>
      <Text fontWeight="medium" color="gray.600">
        {infoName}
      </Text>
      <Stack
        direction={{ base: 'column', xl: 'row' }}
        space={{ base: 4, xl: 10 }}
        alignItems={{ base: 'flex-start', xl: 'flex-end' }}
        justifyContent={{ base: 'space-between', xl: 'flex-start' }}
      >
        <HStack w="100%" space={2} alignItems="center" flexWrap="wrap">
          <Icon icon={icon} color={colors.gray[400]} size={16} />
          <Text color="singletons.darkText">{infoValue}</Text>
        </HStack>

        <CopyButton
          value={infoValue}
          color={buttonColor}
          iconColor={buttonIconColor}
          variant={buttonVariant}
          textColor={buttonTextColor}
        />
      </Stack>
    </VStack>
  );
};

export default ContactInfoItem;
