import { useClipboard } from 'native-base';
import { toast } from 'react-toastify';

import { COMMON_MESSAGES } from '~/constants/messages.constants';

export const useCopyLinkController = () => {
  const { onCopy } = useClipboard();

  const handleCopyLink = (link: string) => {
    onCopy(link);
    toast.success(COMMON_MESSAGES.LINK_COPIED);
  };

  return { handleCopyLink };
};
