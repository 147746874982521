import { yupResolver } from '@hookform/resolvers/yup';
import { useBreakpointValue } from 'native-base';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
} from 'react-native/types';
import { useQuery } from 'react-query';

import { doitVolunteersEcosystem } from '~/constants/ecosystem.constants';
import { PAGES } from '~/constants/pages.constants';
import { EcosystemVisibilityTypeEnum } from '~/enums';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useAuth } from '~/hooks/useAuth';
import { useRouter } from '~/hooks/useRouter';
import EcosystemService from '~/services/resources/ecosystem';
import { ecosystemSliceActions } from '~/store/slices/ecosystem';
import { IAuthDTO } from '~/types/dtos';
import Yup from '~/utils/validations/yup';

const schemaValidation = Yup.object({
  email: Yup.string()
    .transform((value: string) => value?.trim() || '')
    .email(),
  password: Yup.string().password(),
});

export const useLoginController = () => {
  const dispatch = useAppDispatch();

  const { signInEcosystem, isDefaultSignInEcosystem } = useAppSelector(
    ({ ecosystem }) => ecosystem,
  );
  const {
    goToRoute,
    params: { slug: ecosystemSlug },
  } = useRouter();

  const [isLoading, setIsLoading] = useState(false);

  const { handleSignIn } = useAuth();

  const logoWidth = useBreakpointValue({
    base: isDefaultSignInEcosystem ? '112px' : '48px',
    md: isDefaultSignInEcosystem ? '200px' : '112px',
  });

  const logoHeight = useBreakpointValue({
    base: isDefaultSignInEcosystem ? '64px' : '48px',
    md: isDefaultSignInEcosystem ? '112px' : '112px',
  });

  const { isLoading: isLoadingEcosystem } = useQuery({
    queryKey: [EcosystemService.URL],
    queryFn: () =>
      EcosystemService.findOnePublic({ id: ecosystemSlug as string }),
    onSuccess: (response) => {
      const payload = {
        ecosystem: response.data,
        isDefaultEcosystem: false,
      };
      dispatch(ecosystemSliceActions.setSignInEcosystem(payload));
    },
    onError: () => goToRoute(PAGES.Page404),
    enabled: !!ecosystemSlug,
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<IAuthDTO>({
    resolver: yupResolver(schemaValidation),
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const canSignIn = useMemo(() => !isValid || !isDirty, [isValid, isDirty]);

  const handleGoToSignUp = () => {
    if (
      signInEcosystem?.visibility === EcosystemVisibilityTypeEnum.PRIVATE ||
      !signInEcosystem?.visibility
    ) {
      goToRoute(PAGES.Page403);
      const payload = {
        ecosystem: doitVolunteersEcosystem,
        isDefaultEcosystem: true,
      };
      dispatch(ecosystemSliceActions.setSignInEcosystem(payload));
    } else {
      goToRoute(PAGES.AuthorizationPersonalData);
    }
  };
  const handleGoToForgotPassword = () => {
    goToRoute(PAGES.ConfirmEmail);
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      setIsLoading(true);
      await handleSignIn(data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  });

  // NOTE: This is a workaround to dispatch the onSubmit method when the user press the enter key
  // i was not able to do this same thing with the html <form></form>
  const onKeyDown = ({
    nativeEvent,
  }: NativeSyntheticEvent<TextInputKeyPressEventData>): void => {
    if (nativeEvent.key === 'Enter') {
      onSubmit();
    }
  };

  const emailInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.classList.add('doit-email-input');
    }
  }, [emailInputRef]);
  useEffect(() => {
    if (passwordInputRef.current) {
      passwordInputRef.current.classList.add('doit-password-input');
    }
  }, [passwordInputRef]);

  useEffect(() => {
    if (!isLoadingEcosystem && !signInEcosystem) {
      const payload = {
        ecosystem: doitVolunteersEcosystem,
        isDefaultEcosystem: true,
      };
      dispatch(ecosystemSliceActions.setSignInEcosystem(payload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingEcosystem, signInEcosystem]);

  return {
    isValid,
    control,
    isDirty,
    canSignIn,
    handleGoToSignUp,
    handleGoToForgotPassword,
    onSubmit,
    errors,
    onKeyDown,
    isLoading,
    isLoadingEcosystem,
    signInEcosystem,
    isDefaultSignInEcosystem,
    logoWidth,
    logoHeight,
    emailInputRef,
    passwordInputRef,
  };
};
