import { useMemo, useRef } from 'react';
import { IModalRefProps } from 'ui/components/Modals/Modal/types';

import { IPopoverOption } from '~/components/NavBar/components/AccountDropdown/types';
import { useAppSelector } from '~/hooks/useAppSelector';
import { useRouter } from '~/hooks/useRouter';
import { getNameInitials } from '~/utils/getInitials';

export const useAccountDropdownController = () => {
  // -- Providers
  const { user, selectedUserProfile } = useAppSelector((state) => state.auth);

  // --States
  const modalRef = useRef<IModalRefProps>(null);

  const { goToRoute } = useRouter();

  const handleCloseSignOutModal = () => {
    modalRef.current?.close();
  };

  const handleOpenSignOutModal = () => {
    modalRef.current?.open();
  };

  const handleOptionPress = (options: IPopoverOption) => {
    if (options.action === 'external') {
      window.open(options.path, '_blank');
      return;
    } else if (options.action === 'custom') {
      if (options.key === 'sign_out') handleOpenSignOutModal();
      return;
    }
    goToRoute(options.path);
  };

  const userParsedData = useMemo(() => {
    return {
      userName: user?.name || '',
      userInitials: getNameInitials(user?.name || ''),
      userAvatar: selectedUserProfile?.profilePicture || '',
    };
  }, [user, selectedUserProfile]);

  return {
    modalRef,
    userParsedData,
    goToRoute,
    handleOptionPress,
    handleOpenSignOutModal,
    handleCloseSignOutModal,
  };
};
