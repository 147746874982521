import { EEventApplicationType } from 'ui/enums';
import { ActivityRegion } from 'ui/types/activities';
import { ILocation } from 'ui/types/interfaces';

import {
  actionSortbyOption,
  eventSortbyOption,
  ongoingSortbyOption,
} from '~/pages/Authenticated/InApp/Explorer/constants';
import {
  IOption,
  SortByType,
} from '~/pages/Authenticated/InApp/SearchView/types';
import { SearchFilterValues } from '~/store/slices/inApp/types';
import {
  ActionSearchFilters,
  ActionSearchRelevance,
  EventInPersonSearchFilters,
  EventOnlineSearchFilters,
  OngoingInPersonSearchFilters,
  OngoingOnlineSearchFilters,
} from '~/types/dtos/activity-definition-public-search';
import { AttendanceType } from '~/types/interfaces/activity';

export const FALLBACK_LOCATION = { lat: 51.5074, lng: -0.1278 }; // London
const MILES = 1609;

export function commonFilterMap(
  appId: string,
  filterValues: SearchFilterValues,
  location?: ILocation | null,
) {
  const position = location
    ? JSON.stringify({ lat: location.lat, lng: location.lng })
    : JSON.stringify(FALLBACK_LOCATION);

  const causeOptions = (filterValues.causes ?? []).map((cause) => ({
    _id: cause.value,
  }));

  const filter: Record<string, unknown> = {
    apps: JSON.stringify([appId]),
    text: filterValues.search || '',
    relevance: filterValues.sortBy || SortByType.NEWEST,
    position,
  };

  if (causeOptions.length > 0) {
    filter.categories = JSON.stringify({ causeOptions });
  }

  return filter;
}

export function mapActionFilter(
  appId: string,
  attendanceType: AttendanceType,
  filterValues: SearchFilterValues,
  activityRegions: ActivityRegion[],
  location?: ILocation | null,
): ActionSearchFilters {
  const expectedSortByOptions = actionSortbyOption[attendanceType];

  const relevance = expectedSortByOptions.includes(filterValues.sortBy)
    ? filterValues.sortBy
    : expectedSortByOptions[0];

  const filter: Record<string, any> = {
    ...commonFilterMap(appId, filterValues, location),
    showOnline: filterValues.showOnline,
    relevance,
  };

  const { countries, regions } = filterValues;
  const regionsFilter = mapRegions(countries, regions, activityRegions);
  filter.regions = JSON.stringify(regionsFilter);

  return filter as ActionSearchFilters;
}

export function mapEventFilter(
  appId: string,
  attendanceType: AttendanceType,
  filterValues: SearchFilterValues,
  activityRegions: ActivityRegion[],
  location?: ILocation | null,
): EventInPersonSearchFilters | EventOnlineSearchFilters {
  const expectedSortByOptions = eventSortbyOption[attendanceType];

  const relevance = expectedSortByOptions.includes(filterValues.sortBy)
    ? filterValues.sortBy
    : expectedSortByOptions[0];

  const startDate = filterValues.startDate;
  const endDate = filterValues.endDate;

  const filter: Record<string, any> = {
    ...commonFilterMap(appId, filterValues, location),
    relevance,
    startDate,
    endDate,
    eventApplicationTypes: JSON.stringify([EEventApplicationType.Individual]),
  };

  if (attendanceType === AttendanceType.Online) {
    filter.showOnline = filterValues.showOnline;

    const { countries, regions } = filterValues;
    const regionsFilter = mapRegions(countries, regions, activityRegions);
    filter.regions = JSON.stringify(regionsFilter);
  } else {
    filter.radius = filterValues.distanceRadius * MILES;
  }
  return filter as EventInPersonSearchFilters | EventOnlineSearchFilters;
}

export function mapTeamEventFilter(
  appId: string,
  attendanceType: AttendanceType,
  filterValues: SearchFilterValues,
  activityRegions: ActivityRegion[],
  location?: ILocation | null,
): EventInPersonSearchFilters | EventOnlineSearchFilters {
  const teamsMinSize = filterValues.teamsMinSize;
  const teamsMaxSize = filterValues.teamsMaxSize;

  const filter: any = {
    ...mapEventFilter(
      appId,
      attendanceType,
      filterValues,
      activityRegions,
      location,
    ),
    teamsMaxSize,
    teamsMinSize,
    eventApplicationTypes: JSON.stringify([EEventApplicationType.Team]),
  };
  return filter;
}

export function mapOngoingFilter(
  appId: string,
  attendanceType: AttendanceType,
  filterValues: SearchFilterValues,
  activityRegions: ActivityRegion[],
  location?: ILocation | null,
): OngoingInPersonSearchFilters | OngoingOnlineSearchFilters {
  const expectedSortByOptions = ongoingSortbyOption[attendanceType];

  const relevance = expectedSortByOptions.includes(filterValues.sortBy)
    ? filterValues.sortBy
    : ActionSearchRelevance.Newest;

  const filter: Record<string, any> = {
    ...commonFilterMap(appId, filterValues, location),
    eventApplicationTypes: JSON.stringify([EEventApplicationType.Individual]),
    relevance,
  };

  if (attendanceType === AttendanceType.Online) {
    filter.showOnline = filterValues.showOnline;

    const { countries, regions } = filterValues;
    const regionsFilter = mapRegions(countries, regions, activityRegions);
    filter.regions = JSON.stringify(regionsFilter);
  } else {
    filter.radius = filterValues.distanceRadius * MILES;
  }

  return filter as OngoingInPersonSearchFilters | OngoingOnlineSearchFilters;
}

export const mapRegions = (
  selectedCountries: IOption[],
  selectedRegions: IOption[],
  activityRegions: ActivityRegion[],
): string[] => {
  let resultRegions = [...selectedCountries];

  for (const regionOption of selectedRegions) {
    const activityRegion = activityRegions.find(
      (ar) => ar._id === regionOption.value,
    );
    if (!activityRegion) continue;

    resultRegions = resultRegions.filter(
      (opt) => opt.label != activityRegion.relatedTo || '',
    );
    resultRegions.push(regionOption);
  }
  return resultRegions.map((opt) => opt.value);
};
