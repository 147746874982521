import {
  Location,
  NavigateOptions,
  Params,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { PAGES } from '~/constants/pages.constants';
import { useAppSelector } from '~/hooks/useAppSelector';
import { ExecutionPlatform } from '~/store/slices/inApp/types';

interface IHistoryController {
  goBack: () => void;
  goToRoute: (path: string, options?: NavigateOptions) => void;
  replaceRoute: (path: string, options?: NavigateOptions) => void;
  location: Location;
  params: Readonly<Params<string>>;
  searchParams: URLSearchParams;
  isStackEmpty: boolean;
  isRootPage: boolean;
}

const ROOT_PAGE = PAGES.AllApps;

export const useRouter = (): IHistoryController => {
  const { platform, selectedApp } = useAppSelector((state) => state.inApp);
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const goToRoute = (path: string, options?: NavigateOptions) => {
    navigate(path, options);
  };
  const [searchParams] = useSearchParams();

  const replaceRoute = (path: string, options?: NavigateOptions) => {
    navigate(path, { replace: true, ...options });
  };

  const goBack = () => {
    // Go back normally if there are routes on the history
    if (window.history.state.idx > 1) {
      navigate(-1);
      return;
    }

    // Should go to the root otherwise (considering execution platform)
    if (selectedApp && platform === ExecutionPlatform.WEBVIEW) {
      navigate(PAGES.InAppRoot.replace(':appId', selectedApp?._id || ''));
    } else {
      navigate(ROOT_PAGE);
    }
  };

  const isStackEmpty = window.history.state.idx < 2;
  const isRootPage = location.pathname === ROOT_PAGE;

  return {
    goBack,
    goToRoute,
    replaceRoute,
    location,
    params,
    searchParams,
    isStackEmpty,
    isRootPage,
  };
};
