import Yup from '~/utils/validations/yup';

export const getUserFirstName = (name: string | undefined) => {
  if (!name) return '';
  return name.split(' ')[0];
};

export const getUserLastNames = (name: string | undefined) => {
  if (!name) return '';
  return name
    .split(' ')
    .map((value, index) => (index > 0 ? value : ''))
    .join(' ');
};

export const schemaValidation = Yup.object({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  birthday: Yup.string().optional(),
  phoneNumber: Yup.string().optional(),
  profilePicture: Yup.mixed().optional(),
  location: Yup.object().optional(),
  organizationDepartment: Yup.object().nullable(),
});

export const HELP_TEXT_MESSAGE =
  'This information is the same for all profiles';
