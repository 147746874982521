import 'react-phone-number-input/style.css';

import { Box, FormControl, HStack } from 'native-base';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';

import { colors } from '../../theme/colors';
import { IPhoneInputProps } from './types';

const PhoneInput = (props: IPhoneInputProps) => {
  const {
    control,
    name,
    helperText,
    hasError,
    isInvalid,
    defaultCountry = 'GB',
    isDisabled,
    isReadOnly,
    isRequired,
    errorMessage,
    placeholder,
    label,
  } = props;

  return (
    <Box alignItems="center" w={'full'}>
      <FormControl
        isInvalid={hasError || errorMessage ? true : isInvalid}
        isDisabled={isDisabled}
        isRequired={isRequired}
        isReadOnly={isReadOnly}
      >
        <HStack space={2} alignItems="center">
          {label ? (
            <FormControl.Label _text={{ color: colors.gray[600] }}>
              {label}
            </FormControl.Label>
          ) : null}
        </HStack>

        <PhoneInputWithCountry
          name={name}
          control={control}
          defaultCountry={defaultCountry}
          placeholder={placeholder}
          style={{ marginLeft: 4 }}
          numberInputProps={{
            style: {
              padding: 14,
              paddingLeft: 16,
              fontSize: 12,
              fontFamily: 'Poppins',
              borderRadius: '12px',
              border: `solid 1px ${
                hasError || errorMessage ? colors.error[400] : colors.muted[300]
              }`,
            },
          }}
        />
        {!hasError && !errorMessage && helperText ? (
          <FormControl.HelperText ml={2}>{helperText}</FormControl.HelperText>
        ) : null}
        {errorMessage ? (
          <FormControl.ErrorMessage ml={2}>
            {errorMessage}
          </FormControl.ErrorMessage>
        ) : null}
      </FormControl>
    </Box>
  );
};

export default PhoneInput;
