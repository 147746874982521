import { useMediaQuery } from 'native-base';
import { ActivityTypeEnum, EEventApplicationType } from 'ui/enums';

import { BreadCrumbsNode } from '~/components/Breadcrumbs/types';
import { slicedCurrentPath } from '~/pages/Authenticated/ViewActivityDetails/components/ActivityBreadCrumb/helper/sliceCurrentPath';
import {
  IActivityBreadCrumbControllerProps,
  IActivityBreadCrumpProps,
} from '~/pages/Authenticated/ViewActivityDetails/components/ActivityBreadCrumb/types';
import { App } from '~/types/interfaces/app';

export const useActivityBreadCrumpController = ({
  activityDefinition,
}: IActivityBreadCrumbControllerProps) => {
  const { eventApplicationType, type, title, app } = activityDefinition;
  const { _id } = app as App;
  const [isMobile] = useMediaQuery({ maxWidth: 1240 });

  const makeBreadcrumbs = ({
    currentPath,
    ActivityType,
  }: IActivityBreadCrumpProps) => {
    const path = slicedCurrentPath({ currentPath, isMobile });
    const breadcrumbs: Record<ActivityTypeEnum, BreadCrumbsNode[]> = {
      [ActivityTypeEnum.Event]: [
        {
          icon: 'calendar',
          path: `/mini-app/${_id}/find-opportunities`,
          label: 'Events',
        },
        {
          path: '#',
          label: path,
        },
      ],
      [ActivityTypeEnum.Action]: [
        {
          icon: 'hand-pointer',
          isCustom: true,
          path: `/mini-app/${_id}/find-opportunities?section=${ActivityTypeEnum.Action}`,
          label: 'Actions',
        },
        {
          path: '#',
          label: path,
        },
      ],
      [ActivityTypeEnum.OngoingOpportunity]: [
        {
          icon: 'repeat',
          path: `/mini-app/${_id}/find-opportunities?section=${ActivityTypeEnum.OngoingOpportunity}`,
          label: 'Ongoing',
        },
        {
          path: '#',
          label: path,
        },
      ],
    };

    if (eventApplicationType === EEventApplicationType.Team) {
      return [
        {
          icon: 'users',
          path: `/mini-app/${_id}/find-opportunities?section=TeamEvent`,
          label: 'Team Events',
        },
        {
          path: '#',
          label: path,
        },
      ];
    }
    return breadcrumbs[ActivityType];
  };

  const breadcrumbs = makeBreadcrumbs({
    currentPath: title,
    ActivityType: type as ActivityTypeEnum,
  });

  return {
    breadcrumbs,
  };
};
