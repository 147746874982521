import 'react-datepicker/dist/react-datepicker.css';

import { isEqual as isDateEqual } from 'date-fns';
import enGB from 'date-fns/locale/en-GB';
import { HStack, PresenceTransition, Text, VStack } from 'native-base';
import ReactDatePicker, {
  registerLocale,
  setDefaultLocale,
} from 'react-datepicker';

import CalendarDateTime from '~/components/CalendarInline/components/CalendarDateTime';
// eslint-disable-next-line import/namespace
import { CalendarDay } from '~/components/CalendarInline/components/CalendarDay';
import { CustomCalender } from '~/components/CalendarInline/components/CustomCalendar';
import { useCalendarInlineController } from '~/components/CalendarInline/controller';
import { CalendarInlineProps } from '~/components/CalendarInline/types';

registerLocale('enUK', enGB);
setDefaultLocale(`enUK`);

export const CalendarInline = (props: CalendarInlineProps) => {
  const { dates, date } = props;

  const {
    dateTimes,
    selectedDate,
    hightLightDates,
    handleDateChange,
    handleDateSubmit,
    isDateSlotBlocked,
    isDateBlocked,
  } = useCalendarInlineController(props);

  const renderCalendarDay = (dayOfMonth: number, date: Date) => {
    const isBlocked = isDateSlotBlocked(date);
    if (!isBlocked) return dayOfMonth;

    return <CalendarDay dayOfMonth={dayOfMonth} />;
  };

  return (
    <VStack space={2} px={{ base: 0, md: 3 }}>
      <ReactDatePicker
        inline
        closeOnScroll={false}
        shouldCloseOnSelect={false}
        highlightDates={hightLightDates}
        includeDates={dates}
        selected={selectedDate}
        onChange={handleDateChange}
        calendarContainer={CustomCalender}
        renderDayContents={renderCalendarDay}
      />
      {dateTimes.length > 0 && (
        <PresenceTransition
          style={{ width: '100%' }}
          visible
          initial={{ opacity: 0, translateY: 0.75 }}
          animate={{ opacity: 1, translateY: 1, transition: { duration: 300 } }}
        >
          <VStack space={2} pb={2}>
            <Text> Hours </Text>
            <HStack
              maxH="120px"
              overflowX={{ base: 'scroll', md: 'hidden' }}
              overflowY={{ base: 'hidden', md: 'scroll' }}
              flexWrap={{ base: 'nowrap', md: 'wrap' }}
              space={2}
              justifyContent={'flex-start'}
            >
              {dateTimes.map((dateTime, index) => {
                return (
                  <CalendarDateTime
                    key={index}
                    date={dateTime}
                    onPress={handleDateSubmit}
                    isBlocked={isDateBlocked(dateTime)}
                    isSelected={date ? isDateEqual(dateTime, date) : false}
                  />
                );
              })}
            </HStack>
          </VStack>
        </PresenceTransition>
      )}
    </VStack>
  );
};

export default CalendarInline;
