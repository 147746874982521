import { Box, FormControl, HStack, Text, VStack } from 'native-base';
import RcSlider from 'rc-slider';
import { Controller } from 'react-hook-form';

import { sliderStyle } from './constants';
import { handleRender } from './fragments/ToolTip';
import { CustomSliderProps } from './types';

export const Slider = (props: CustomSliderProps) => {
  const {
    name,
    control,
    label,
    errorMessage,
    min = 1,
    max = 100,
    unityLabel,
  } = props;

  return (
    <Box alignItems="center" w={'full'}>
      <FormControl isInvalid={!!errorMessage}>
        <HStack space={2} alignItems="center">
          {label && <FormControl.Label>{label}</FormControl.Label>}
        </HStack>
        <Controller
          control={control}
          name={name as never}
          render={({ field: { onChange, value } }) => {
            return (
              <VStack>
                <RcSlider
                  value={value}
                  minValue={min}
                  maxValue={max}
                  sliderTrackHeight={9}
                  w="full"
                  step={2}
                  colorScheme="indigo"
                  styles={sliderStyle}
                  handleRender={handleRender}
                  onChange={(v: number) => onChange(v)}
                />

                <HStack justifyContent={'space-between'} mt={4}>
                  <Text color="gray.900" fontSize="xs" fontWeight="500">
                    {min} {unityLabel}
                  </Text>
                  <Text color="gray.900" fontSize="xs" fontWeight="500">
                    {max} {unityLabel}
                  </Text>
                </HStack>
              </VStack>
            );
          }}
        />

        {errorMessage && (
          <FormControl.ErrorMessage ml={2}>
            {errorMessage}
          </FormControl.ErrorMessage>
        )}
      </FormControl>
    </Box>
  );
};

export default Slider;
