import { IImageProps } from 'native-base';

export interface IIllustrationProps extends IImageProps {
  name: ILLUSTRATIONS_NAME;
}

export interface IIllustrationControllerProps {
  name: ILLUSTRATIONS_NAME;
}

export enum ILLUSTRATIONS_NAME {
  SEARCH_NOT_FOUND = 'SearchNotFound',
  INVITE_MEMBER = 'InviteMember',
  MAP_MARKER = 'MapMarker',
  PROBLEM_NO_CONNECTION = 'ProblemNoConnection',
  PROBLEM_NO_CONNECTION_GREEN = 'ProblemNoConnectionGreen',
  UPDATE_CONSTRUCTION_SETTINGS = 'UpdateConstructionSettings',
  UPDATE_CONSTRUCTION_SETTINGS_GREEN = 'UpdateConstructionSettingsGreen',
  COMING_SOON_SITE_UPDATE = 'ComingSoonSiteUpdate',
  LOGIN_PASSWORD = 'LoginPassword',
  NO_SIGNAL_CONNECTION_ERROR = 'NoSignalConnectionError',
  NO_SIGNAL_CONNECTION_ERROR_BLACK = 'NoSignalConnectionErrorBlack',
  NO_NOTIFICATIONS_NO_MESSAGE = 'NoNotificationsNoMessage',
  TECHINICAL_ERROR = 'TechinicalError',
  NEW_MESSAGE = 'NewMessage',
  NEW_MESSAGE_GREEN = 'NewMessageEmailGreen',
  NEW_EMAIL_MESSAGE = 'NewMessageEmail',
  NEW_EMAIL_COORP = 'NewMessageEmailCorporate',
  LOADING_WAITING = 'LoadingWaiting',
  PAYMENT_FAILD_PAY_ERROR = 'PaymentFaildPayError',
  SUCCESS_COMPLETE = 'SuccessComplete',
  SUCCESS_COMPLETE_GREEN = 'SuccessCompleteGreen',
  SUCCESS_COMPLETE_CORPORATE = 'SuccessCompleteCorporate',
  WELLDONE_OK = 'WelldoneOk',
  EMPTY_NOT_FOUND = 'EmptyNotFound',
  SOMETHING_WENT_WRONG = 'SomethingWentWrong',
  LOADING = 'Loading',
  NOTIFICATION = 'Notification',
  THE_BIG_HELP_OUT = 'TheBigHelpOut',
  BALLON_BEAR = 'BallonBear',
  SUCCESS_COMPLETE_WEB = 'SuccessCompleteWeb',
  BIG_HELP_OUT_HEADER_LOGO = 'BigHelpOutHeaderLogo',
  APPLE_STORE = 'AppleStore',
  GOOGLE_PLAY = 'GooglePlay',
  SEARCH_NOT_FOUND_VOLUNTEER = 'SearchNotFoundVolunteer',
  DOIT_VOLUNTEER_SIGNUP_NAME = 'DoitVolunteerSignUpName',
  DOIT_SOMETHING_WENT_WRONG = 'DoitSomethingWentWrong',
  DOIT_SUCCESS = 'DoitSuccess',
  INVITATION_DECLINED = 'InvitationDeclined',
  MAN_SITTING_WITH_BEAR_AND_BALLOONS = 'ManSittingWithBearAndBalloons',
  LOG_ACTIVITY_START = 'LogActivityStart',
  LOG_ACTIVITY_STEP_1 = 'LogActivityStep1',
  LOG_ACTIVITY_STEP_2 = 'LogActivityStep2',
  LOG_ACTIVITY_STEP_3 = 'LogActivityStep3',
  DOTS = 'Dots',
  BLACK_DOIT_LOGO = 'BlackDoItLogo',
  DOIT_TRANSPARENT_LOGO = 'DoitTransparentLogo',
  ALERT = 'Alert',
  ERROR_SORRY_STATE_BROKEN = 'ErrorSorryStateBroken',
  THUMBS_UP = 'ThumbsUp',
  MAILER = 'Mailer',
  PARACHUTE = 'Parachute',
  POINTER_UP = 'PointerUp',
  CONFIRMATION_GREAT = 'ConfirmationGreat',
  DATA_MANAGEMENT = 'DataManagement',
  ACCOUNTS_SECURITY = 'AccountsSecurityComputerSecure',
  ERROR_WEB_DEVELOPMENT = 'ErrorWebDevelopment',
  BUSINESS_TASK_MANAGER = 'BusinessTaskManager',
  LOGIN_BACKGROUND = 'LoginBackground',
  IN_APP_COVER_IMAGE = 'InAppCoverImage',
  NEAR_BY = 'NearBy',
  BHO_PARTNER_ASDA_LOGO = 'BhoPartnerAsdaLogo',
  BHO_PARTNER_CTM_LOGO = 'BhoPartnerCtmLogo',
  BHO_PARTNER_DPD_LOGO = 'BhoPartnerDpdLogo',
}
