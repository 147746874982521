import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useLoadActivityDefinition } from '~/hooks/useLoadActivityDefinition';
import { UseOpportunityApplicationCommonControllerProps } from '~/pages/Authenticated/OpportunityApplication/controllers/types';
import { IPopulatedActivityDefinition } from '~/types/interfaces/activity';

/* 
  useOpportunityApplicationCommonController

  On the application screens, we check if the assigned app (from query search `?app=` ) belongs to that activity. 
  If not, the "host" app will be selected.

  This is important because from now on, the user can apply to activities that were published in multiples apps. 
  For this, we need to determine which app was selected.

*/

export const useOpportunityApplicationCommonController = ({
  activityDefinitionId,
}: UseOpportunityApplicationCommonControllerProps) => {
  // Providers
  const [searchParams] = useSearchParams();
  const { data: activityDefinition } = useLoadActivityDefinition(
    String(activityDefinitionId),
  );

  // handlers
  const getApplicationApp = (
    activityDefinition: IPopulatedActivityDefinition | null,
  ): string => {
    if (!activityDefinition) return '';

    const selectedAppId = searchParams.get('app') || '';
    if ((activityDefinition?.publishedApps || []).includes(selectedAppId)) {
      return selectedAppId;
    }

    return activityDefinition.app._id;
  };

  // Hooks
  const applicationAppId = useMemo(
    () => getApplicationApp(activityDefinition),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activityDefinition],
  );

  return { applicationAppId, activityDefinition };
};
