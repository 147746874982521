import { HStack, Image, Stack, Text } from 'native-base';
import RocketAnimation from 'ui/assets/animations/rocket_animation.gif';
import DoitLogo from 'ui/assets/illustrations/BlackDoitLogo.svg';
import Illustration, { ILLUSTRATIONS_NAME } from 'ui/components/Illustration';

import { SplashScreenProps } from './types';
export const SplashScreen = ({ imageURL }: SplashScreenProps) => {
  return (
    <Stack
      bgColor="white"
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      minH="100vh"
      space={12}
    >
      <Stack alignItems="center" space={2}>
        <Image
          width="15rem"
          height="15rem"
          source={{ uri: imageURL || DoitLogo }}
        />
        {/* {title ? (
          <Text fontSize="lg" fontWeight={700}>
            {title}
          </Text>
        ) : null} */}
      </Stack>

      <Stack space={2} alignItems="center">
        <Image
          source={{ uri: RocketAnimation }}
          width="9.5rem"
          height="9.5rem"
        />

        <HStack space={3}>
          <Text fontWeight={500} color="gray.500" fontSize="sm">
            Powered by
          </Text>
          <Illustration
            h="1.3rem"
            w="2.5rem"
            tintColor="gray.500"
            name={ILLUSTRATIONS_NAME.BLACK_DOIT_LOGO}
          />
        </HStack>
      </Stack>
    </Stack>
  );
};
